import React, { useState, useEffect } from "react";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";

import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Typography from "@material-ui/core/Typography";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import {
  ActivityActionToNameMap,
  ActivityFormProps,
  ActivityMode,
  ActivityToNameMap,
} from "../Activity/ActivityTypes";
import {
  LargeText,
  Present,
  PresentDescText,
  PresentMinsText,
  PresentSubtitleText,
  PresentTitleText,
} from "../Presentation/Presentation";
import clsx from "clsx";
import Duration from "./Duration/Duration";

export const getRoutineDesc = () => {
  return "Have learners take a moment to pause and take a break.";
};

export default function BreakForm(props: ActivityFormProps) {
  const [didLoad, setDidLoad] = useState(false);
  const [saveData, setSaveData] = useState({
    activityAction:
      props.saveData && props.saveData.activityAction
        ? props.saveData.activityAction
        : "break",
    duration:
      props.saveData && props.saveData.duration ? props.saveData.duration : 10,
    instructions:
      props.saveData && props.saveData.instructions
        ? props.saveData.instructions
        : "",
  });

  useEffect(() => {
    if (!didLoad) {
      setDidLoad(true);
    }
  }, [didLoad, saveData]);

  const handleDurationChange = (val: string) => {
    setSaveData({ ...saveData, duration: val });
  };

  const handleInstructionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const data = { ...saveData };
    data.instructions = event.target.value;
    setSaveData(data);
  };

  const handleSave = (event: any) => {
    if (props.onSave) {
      props.onSave({ saveData });
    }

    handleClose(event);
  };

  const handleClose = (event: any) => {
    if (props.onClose) {
      props.onClose(event);
    }
  };

  const getSlide = () => {
    return (
      <Present>
        <PresentTitleText
          value={ActivityToNameMap["Break"]}
          colorClass="learnidy-fiord"
        />
        <PresentSubtitleText
          value={ActivityActionToNameMap[saveData.activityAction]}
          colorClass="learnidy-fiord"
        />
        <PresentMinsText value={saveData.duration} />
        <PresentDescText value={getRoutineDesc()} />

        <LargeText
          title="Break Prompt:"
          value={props.saveData.instructions}
          backgroundColor="learnidy-fiord-bg"
          isMultiLine={false}
        />
      </Present>
    );
  };

  return props.activityMode === ActivityMode.SlideShow ? (
    getSlide()
  ) : (
    <Dialog
      fullScreen={false}
      open={true}
      maxWidth={"lg"}
      onClose={handleClose}
      aria-labelledby="break-dialog-title"
    >
      <div className={"activity-dialog"} style={{ width: 1280, height: 720 }}>
        <div className={"activity-form"}>
          <span className={clsx("activity-form-name", props.color)}>Break</span>
          <div className={"activity-form-btn-group"}>
            <ToggleButtonGroup
              value={saveData.activityAction}
              exclusive={true}
              orientation="vertical"
              //   onChange={handleActivityAction}
              aria-label="activity action"
            >
              <ToggleButton
                value="break"
                aria-label="break"
                disabled={false}
                classes={{
                  selected: props.color + "-border",
                }}
              >
                <Typography
                  component="span"
                  variant="subtitle1"
                  className="activity-name"
                >
                  Break
                </Typography>
                {saveData.activityAction === "break" ? (
                  <Badge>
                    <CheckCircleIcon className={props.color} />
                  </Badge>
                ) : null}
                <TimelapseIcon className={"warmup-icon learnidy-fiord"} />
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
        <div className={"activity-form"}>
          <Button
            className={"activity-form-close"}
            variant="outlined"
            onClick={handleClose}
          >
            <CloseRoundedIcon fontSize="small" />
          </Button>
          <span className={clsx("activity-form-name", props.color)}>Break</span>
          <p className={"activity-form-desc"}>
            Have learners take a moment to pause and take a break.
          </p>
          <div
            className={clsx("warmup-header-break", props.color + "-border")}
          />
          <form></form>
          <div className={"warmup-duration"}>
            <Typography
              component="span"
              variant="subtitle1"
              className="warmup-tot-duration-text"
            >
              Total Duration:
            </Typography>
            <Duration
              time={saveData.duration}
              onChange={handleDurationChange}
            />
          </div>
          <div className={"warmup-subsection"}>
            <Typography
              component="span"
              variant="subtitle1"
              className="warmup-tot-duration-text"
            >
              Break Prompt
            </Typography>
            <textarea
              className={"warmup-subtext"}
              placeholder="What activty or routine do you want to provide all learners?"
              rows={5}
              value={saveData.instructions}
              onChange={handleInstructionChange}
            />
          </div>
          <div className={"warmup-footer"}>
            <Button variant="outlined" onClick={handleSave}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
