import React, { useState, useEffect, useCallback } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { formStyles } from "../../../styles/FormStyles";
import {
  LargeText,
  PresentCard,
  PresentCardGroup,
  PresentDescText,
} from "../../Presentation/Presentation";
import clsx from "clsx";
import Duration from "../Duration/Duration";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    formControl: {
      margin: "0 1em",
    },
    formTopSpacer: {
      margin: "1.5em 0 0 0",
    },
    groupCountInput: {
      width: "4em",
      textAlign: "center",
      border: "none",
      backgroundColor: theme.palette.grey[200],
      height: "2em",
    },
    pillSection: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      margin: "1em 0 0 0",
    },
    pill: {
      backgroundColor: theme.palette.grey[200],
      borderRadius: 100,
      padding: "10px 30px",
      textAlign: "center",
    },
    groupsOfText: {
      fontWeight: "bold",
      fontSize: "1.3rem",
    },
  })
);

interface BreakoutProps {
  saveData: any;
  color: string;
  validate?: boolean;
  onChange?: any;
}

export function BreakoutSlide(props: BreakoutProps) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <PresentDescText
        value={`
          Work in smaller teams to discuss a topic, answer a question, or tackle a
          specific challenge. Each learner within a group will be designated a
          role such as a facilitator, notetaker, and etc to increase participation.`}
      />
      <div className={classes.pillSection}>
        <div className={clsx(classes.pill, props.color)}>
          <Typography component="label" className={classes.groupsOfText}>
            {`Groups of ${props.saveData.breakoutGroupCount}`}
          </Typography>
        </div>
      </div>
      <LargeText
        value={props.saveData.breakoutGroupPrompt}
        backgroundColor={props.color + "-bg"}
        isMultiLine={true}
      />
      <PresentCardGroup equalSpace={false} justify="center" align="center">
        {props.saveData.facilitator && (
          <PresentCard
            value="Facilitator"
            color={props.color}
            size="large"
            align="center"
            width="20%"
          />
        )}
        {props.saveData.notetaker && (
          <PresentCard
            value="Notetaker"
            color={props.color}
            size="large"
            align="center"
            width="20%"
          />
        )}
        {props.saveData.reporter && (
          <PresentCard
            value="Reporter"
            color={props.color}
            size="large"
            align="center"
            width="20%"
          />
        )}
        {props.saveData.timekeeper && (
          <PresentCard
            value="Timekeeper"
            color={props.color}
            size="large"
            align="center"
            width="20%"
          />
        )}
        {props.saveData.questioner && (
          <PresentCard
            value="Questioner"
            color={props.color}
            size="large"
            align="center"
            width="20%"
          />
        )}
      </PresentCardGroup>
    </React.Fragment>
  );
}

const getNumberCheckedGroups = (data: any) => {
  let cnt: number = 0;
  cnt += data.facilitator ? 1 : 0;
  cnt += data.notetaker ? 1 : 0;
  cnt += data.reporter ? 1 : 0;
  cnt += data.timekeeper ? 1 : 0;
  cnt += data.questioner ? 1 : 0;

  return cnt;
};

export default function BreakoutForm(props: BreakoutProps) {
  const classes = useStyles();
  const formClasses = formStyles();

  const [hasLoaded, setHasLoaded] = useState(false);
  const [requiredText] = React.useState("");
  const [minGroupCount] = useState(1);
  const [maxGroupCount] = useState(5);

  const [saveData, setSaveData] = useState<any>({
    breakoutGroupsDuration:
      props.saveData && props.saveData.breakoutGroupsDuration
        ? props.saveData.breakoutGroupsDuration
        : 10,
    breakoutGroupPrompt:
      props.saveData && props.saveData.breakoutGroupPrompt
        ? props.saveData.breakoutGroupPrompt
        : "",
    breakoutGroupCount:
      props.saveData && props.saveData.breakoutGroupCount
        ? props.saveData.breakoutGroupCount
        : minGroupCount,
    facilitator:
      props.saveData && props.saveData.facilitator
        ? props.saveData.facilitator
        : false,
    notetaker:
      props.saveData && props.saveData.notetaker
        ? props.saveData.notetaker
        : false,
    reporter:
      props.saveData && props.saveData.reporter
        ? props.saveData.reporter
        : false,
    timekeeper:
      props.saveData && props.saveData.timekeeper
        ? props.saveData.timekeeper
        : false,
    questioner:
      props.saveData && props.saveData.questioner
        ? props.saveData.questioner
        : false,
  });
  const [numCheckedGroups, setNumCheckedGroups] = useState(
    getNumberCheckedGroups(saveData)
  );

  const validateForm = useCallback((): boolean => {
    if (
      !saveData.breakoutGroupSettings ||
      saveData.breakoutGroupSettings.length === 0
    ) {
      return false;
    }

    return true;
  }, [saveData.breakoutGroupSettings]);

  useEffect(() => {
    if (props.validate) {
      validateForm(); //children function of interest
    }
  }, [props.validate, validateForm]);

  useEffect(() => {
    if (!hasLoaded) {
      if (props.onChange)
        props.onChange({
          breakoutGroupsDuration: saveData.breakoutGroupsDuration,
        });

      setHasLoaded(true);
    }
  }, [hasLoaded, saveData, props]);

  const handleDataChange = (key: string, value: any): any => {
    if (!key) {
      return;
    }

    const data = { ...saveData };
    data[key] = value;
    setSaveData(data);

    if (props.onChange) {
      props.onChange({ [key]: value });
    }

    return data;
  };

  const handleDurationChange = (val: string) => {
    handleDataChange("breakoutGroupsDuration", val);
  };

  const handleRoleChange = (key: string, isChecked: any) => {
    const currCount: number = getNumberCheckedGroups(saveData);

    // Don't allow checking above current group count
    if (isChecked && currCount >= saveData.breakoutGroupCount) {
      return;
    }

    handleDataChange(key, isChecked);
    setNumCheckedGroups(isChecked ? currCount + 1 : currCount - 1);
  };

  const handleGroupCountChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    let val: number = parseInt(event.target.value);

    if (isNaN(val)) {
      return;
    }

    if (val < minGroupCount) val = minGroupCount;
    if (val > maxGroupCount) val = maxGroupCount;

    // Uncheck groups if the new count is lower than the original count
    if (val < numCheckedGroups) {
      uncheckAllGroups(val);
    } else {
      handleDataChange("breakoutGroupCount", val);
    }
  };

  const uncheckAllGroups = (breakoutGroupCount: undefined | number) => {
    const data = {
      facilitator: false,
      notetaker: false,
      reporter: false,
      timekeeper: false,
      questioner: false,
    };

    if (breakoutGroupCount) {
      data["breakoutGroupCount"] = breakoutGroupCount;
    }

    setSaveData({ ...saveData, ...data });
    setNumCheckedGroups(0);

    if (props.onChange) {
      props.onChange(data);
    }
  };

  return (
    <React.Fragment>
      <span className={clsx(formClasses.formName, props.color)}>
        Breakout Groups
      </span>
      <p className={formClasses.formDesc}>
        Assign the larger group to work in smaller teams to discuss a topic,
        answer a question, or tackle a specific challenge. Designate roles for
        each learner within a group such as a facilitator, notetaker, and etc.
        Breakout groups are designed to increase participation.
      </p>
      <div className={clsx(formClasses.headerBreak, props.color + "-border")} />
      <div className={formClasses.formSubSection}>
        <Typography
          component="span"
          variant="subtitle1"
          className={formClasses.durationText}
        >
          Total Duration:
        </Typography>
        <Duration
          time={saveData.breakoutGroupsDuration}
          onChange={handleDurationChange}
        />
      </div>
      <div className={formClasses.formSection}>
        <div className={formClasses.formSubSection}>
          <Typography
            component="span"
            variant="subtitle2"
            className={formClasses.formSubtitle}
          >
            Breakout Group Prompt
          </Typography>
        </div>
        <textarea
          className={clsx(formClasses.formText, formClasses.textarea)}
          placeholder="What topic do you want learners to breakout about?"
          rows={3}
          value={saveData.breakoutGroupPrompt}
          onChange={(event) =>
            handleDataChange("breakoutGroupPrompt", event.target.value)
          }
        />
      </div>
      <div className={formClasses.formSection}>
        <div className={formClasses.formSubSection}>
          <Typography
            component="span"
            variant="subtitle2"
            className={formClasses.formSubtitle}
          >
            Breakout Group Settings
          </Typography>
        </div>
        <div className={formClasses.formSubSection}>
          <label>How many breakout groups do you want to create?</label>
          <div>
            <input
              type="number"
              name="breakout-group-count"
              value={saveData.breakoutGroupCount}
              onChange={handleGroupCountChange}
              className={classes.groupCountInput}
            />
            <FormHelperText>{requiredText}</FormHelperText>
          </div>
        </div>
      </div>
      <div className={clsx(formClasses.formSection, classes.formTopSpacer)}>
        <Typography component="p" variant="body2">
          Designate a role for each learner
        </Typography>
        <Typography component="p" variant="caption">
          (Optional, select all that apply)
        </Typography>
      </div>
      <div className={formClasses.formSection}>
        <FormControl
          required
          component="fieldset"
          className={classes.formControl}
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={saveData.facilitator}
                  onChange={(event) =>
                    handleRoleChange(event.target.name, event.target.checked)
                  }
                  name="facilitator"
                  disabled={
                    numCheckedGroups >= saveData.breakoutGroupCount &&
                    !saveData.facilitator
                  }
                />
              }
              label="Facilitator"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={saveData.notetaker}
                  onChange={(event) =>
                    handleRoleChange(event.target.name, event.target.checked)
                  }
                  name="notetaker"
                  disabled={
                    numCheckedGroups >= saveData.breakoutGroupCount &&
                    !saveData.notetaker
                  }
                />
              }
              label="Notetaker"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={saveData.reporter}
                  onChange={(event) =>
                    handleRoleChange(event.target.name, event.target.checked)
                  }
                  name="reporter"
                  disabled={
                    numCheckedGroups >= saveData.breakoutGroupCount &&
                    !saveData.reporter
                  }
                />
              }
              label="Reporter"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={saveData.timekeeper}
                  onChange={(event) =>
                    handleRoleChange(event.target.name, event.target.checked)
                  }
                  name="timekeeper"
                  disabled={
                    numCheckedGroups >= saveData.breakoutGroupCount &&
                    !saveData.timekeeper
                  }
                />
              }
              label="Timekeeper"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={saveData.questioner}
                  onChange={(event) =>
                    handleRoleChange(event.target.name, event.target.checked)
                  }
                  name="questioner"
                  disabled={
                    numCheckedGroups >= saveData.breakoutGroupCount &&
                    !saveData.questioner
                  }
                />
              }
              label="Questioner"
            />
          </FormGroup>
        </FormControl>
      </div>
    </React.Fragment>
  );
}
