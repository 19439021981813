export const MIN_MINUTE_DURATION: number = 5;
export const MAX_MINUTE_DURATION: number = 480;

export const validateMinuteDuration = (minuteStr: string): string => {
  if (minuteStr.length === 0) {
    minuteStr = "0";
  }

  let retValue: number = parseInt(minuteStr, 10);

  // Smallest value is 5 minutes
  if (retValue < MIN_MINUTE_DURATION) {
    retValue = MIN_MINUTE_DURATION;
  } else if (retValue > MAX_MINUTE_DURATION) {
    retValue = MAX_MINUTE_DURATION;
  }

  return retValue.toString();
};
