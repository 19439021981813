import React, { useEffect, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ShareIcon from "@material-ui/icons/Share";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import PeopleIcon from "@material-ui/icons/People";
import DashboardIcon from "@material-ui/icons/Dashboard";
import CircularProgress from "@material-ui/core/CircularProgress";
import Collapse from "@material-ui/core/Collapse";
import SessionMasterGroup from "../SessionPlan/SessionPlanMaster";
import { auth, querySessionPlan, queryClasses } from "../../firebase/firebase";
import { Link } from "react-router-dom";
import { SIGN_IN_ROUTE, SIGN_UP_ROUTE } from "../../constants/routes";
import { SessionPlanModel } from "../../models/SessionPlanModel";
import ClassGroup from "../ClassGroup/ClassGroup";
import ClassModel from "../ClassInformation/ClassModel";
import SessionPlanList from "../SessionPlanList/SessionPlanList";

export enum SidebarTabs {
  Activities,
  MyLessons,
  MyClasses,
  SharedLessons,
  Hidden,
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  classes: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, classes, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      className={classes.tabPanel}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} className={classes.tabPanelBox}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `sidebar-tab-${index}`,
    "aria-controls": `sidebar-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  sidebarRoot: {
    height: "90vh",
    display: "flex",
  },
  tabs: {
    overflow: "visible",
    // width: "clamp(100px, 20%, 300px)",
  },
  tab: {
    fontSize: 12,
    fontWeight: "bold",
    minWidth: 100,
  },
  tabHidden: {
    display: "none",
  },
  tabPanel: {
    paddingTop: 20,
  },
  tabPanelBox: {
    margin: 0,
    padding: 0,
  },
  tabPanelButton: {
    // position: "absolute",
    right: "0",
    top: "50%",
    width: 32,
    minWidth: 32,
    height: 64,
    margin: 0,
    padding: 0,
    backgroundColor: theme.palette.grey.A700,
    color: theme.palette.primary.contrastText,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  collapseContainer: {
    width: 0,
    transitionProperty: "width",
    overflowY: "auto",
    backgroundColor: theme.palette.grey[300],
  },
  collapseEntered: {
    width: "380px",
  },
  collapseHidden: {
    width: 0,
  },
  collapseWrapper: {
    height: "100%",
  },
  collapseWrapperInner: {
    position: "relative",
    padding: "10px 0 0 20px",
  },
}));

interface SidebarProps {
  masterActivities: any;
  sessionPlan: SessionPlanModel;
  onLoadSessionPlan?: (arg0: string) => void;
  onLoadClass?: (arg0: string, arg1?: boolean, arg2?: boolean) => void;
  onDeleteSessionPlan?: (arg0: string) => void;
}

export default function Sidebar(props: SidebarProps) {
  const classes = useStyles();
  const [value, setValue] = useState<number>(SidebarTabs.Activities);
  const [open, setOpen] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [classList, setClassList] = useState<ClassModel[]>([]);
  const [hasSelectedTab, setHasSelectedTab] = useState(false);
  const [isGettingPlans] = useState(false);
  const [isGettingClasses, SetIsGettingClasses] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsSignedIn(!!user);
    });

    return unsubscribe;
  });

  useEffect(() => {
    if (!loaded) {
      // TODO: place any other initialization here
      setLoaded(true);
    }
  }, [loaded]);

  const loadClassList = () => {
    setClassList([]);
    SetIsGettingClasses(true);
    queryClasses()
      .then((snapshot) => {
        let newClassList: ClassModel[] = [];
        snapshot.docs.forEach((doc: any) => {
          newClassList.push({ id: doc.id, ...doc.data() });
        });

        setClassList(newClassList);
        SetIsGettingClasses(false);
      })
      .catch((error) => {
        console.error(error);
        SetIsGettingClasses(false);
      });
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (newValue === value && hasSelectedTab) {
      return;
    }

    setValue(newValue);
    setHasSelectedTab(true);
    setOpen(true);

    switch (newValue) {
      case SidebarTabs.MyLessons:
        break;

      case SidebarTabs.MyClasses:
        loadClassList();
        break;

      default:
        break;
    }
  };

  const handleSessionPlanSelect = (event: any, item: any) => {
    if (!item.id) {
      return;
    }

    querySessionPlan(item.id)
      .then((doc) => {
        if (doc.exists) {
          if (props.onLoadSessionPlan) {
            // console.log("props.onLoadSessionPlan", item.id, doc.data());
            props.onLoadSessionPlan(item.id);
            closeTabPanel();
          }
        } else {
          console.error("No such document!");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // const handleClassListClick = (event: any, item: any) => {
  //   if (!item.id) {
  //     return;
  //   }

  //   queryClass(item.id)
  //     .then((doc) => {
  //       if (doc.exists) {
  //         if (props.onLoadClass) {
  //           // console.log("props.onLoadSessionPlan", item.id, doc.data());
  //           props.onLoadClass(item.id, false, true);
  //           closeTabPanel();
  //         }
  //       } else {
  //         console.error("No such class!");
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  const closeTabPanel = () => {
    setOpen(false);
    setValue(SidebarTabs.Hidden);
  };

  const handleCloseTabPanel = () => {
    closeTabPanel();
  };

  const handleSessionPlanDelete = async (plan: SessionPlanModel | null) => {
    if (!plan) {
      return;
    }

    if (props.onDeleteSessionPlan) {
      props.onDeleteSessionPlan(plan.id);
    }
  };

  const getActivities = (index: number) => {
    return (
      <TabPanel value={value} index={index} classes={classes}>
        <SessionMasterGroup
          activities={props.masterActivities}
          sessionPlan={props.sessionPlan}
        />
      </TabPanel>
    );
  };

  const getSignUpOrLoginText = (isLesson: boolean) => {
    return (
      <Typography
        component="span"
        variant="subtitle1"
        // className={classes.logIn}
      >
        <Link to={SIGN_UP_ROUTE}>Sign Up</Link>
        {" or "}
        <Link to={SIGN_IN_ROUTE}>Log In</Link>
        {isLesson && " to access saved lessons"}
        {!isLesson && " to access saved classes"}
      </Typography>
    );
  };

  const getLessons = (index: number) => {
    if (!isSignedIn) {
      return (
        <TabPanel value={value} index={index} classes={classes}>
          {getSignUpOrLoginText(true)}
        </TabPanel>
      );
    } else if (isGettingPlans) {
      return (
        <TabPanel value={value} index={index} classes={classes}>
          <CircularProgress />
        </TabPanel>
      );
    } else {
      return (
        <TabPanel value={value} index={index} classes={classes}>
          <SessionPlanList
            show={true}
            classId={undefined}
            onSessionPlanDelete={handleSessionPlanDelete}
            onSessionPlanSelect={handleSessionPlanSelect}
          />
        </TabPanel>
      );
    }
  };

  const getClassList = (index: number) => {
    if (!isSignedIn) {
      return (
        <TabPanel value={value} index={index} classes={classes}>
          {getSignUpOrLoginText(false)}
        </TabPanel>
      );
    }

    if (isGettingClasses) {
      return (
        <TabPanel value={value} index={index} classes={classes}>
          <CircularProgress />
        </TabPanel>
      );
    }

    return (
      <TabPanel value={value} index={index} classes={classes}>
        {open ? (
          <List
            component="div"
            aria-labelledby="class-list-subheader"
            // className={classes.root}
          >
            {classList.map((item: any, listIndex: number) => {
              return (
                <ClassGroup
                  key={"class_" + listIndex}
                  class={item}
                  onSessionPlanSelect={handleSessionPlanSelect}
                  onSessionPlanDelete={handleSessionPlanDelete}
                />
              );
            })}
          </List>
        ) : (
          <h3>No saved classes</h3>
        )}
      </TabPanel>
    );
  };

  return (
    <div className={classes.sidebarRoot}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
        TabIndicatorProps={{ style: { display: "none" } }}
      >
        <Tab
          label="Add Activity"
          icon={<DashboardIcon />}
          {...a11yProps(SidebarTabs.Activities)}
          className={classes.tab}
        />
        <Tab
          label="My Lessons"
          icon={<LibraryBooksIcon />}
          {...a11yProps(SidebarTabs.MyLessons)}
          className={classes.tab}
        />
        <Tab
          label="My Classes"
          icon={<PeopleIcon />}
          {...a11yProps(SidebarTabs.MyClasses)}
          className={classes.tab}
        />
        <Tab
          label="Shared Lessons"
          icon={<ShareIcon />}
          {...a11yProps(SidebarTabs.SharedLessons)}
          className={classes.tab}
        />
        <Tab
          label=""
          {...a11yProps(SidebarTabs.Hidden)}
          className={classes.tabHidden}
        />
      </Tabs>
      <Collapse
        in={open}
        classes={{
          container: classes.collapseContainer,
          entered: classes.collapseEntered,
          hidden: classes.collapseHidden,
          wrapper: classes.collapseWrapper,
          wrapperInner: classes.collapseWrapperInner,
        }}
      >
        {getActivities(SidebarTabs.Activities)}
        {getLessons(SidebarTabs.MyLessons)}
        <TabPanel
          value={value}
          index={SidebarTabs.SharedLessons}
          classes={classes}
        >
          <Typography component="label" variant="subtitle1">
            Shared Lessons Coming Soon...
          </Typography>
        </TabPanel>
        {getClassList(SidebarTabs.MyClasses)}
      </Collapse>
      {open === true ? (
        <Button
          className={classes.tabPanelButton}
          variant="contained"
          onClick={handleCloseTabPanel}
        >
          <ArrowBackIosIcon fontSize="small" />
        </Button>
      ) : null}
    </div>
  );
}
