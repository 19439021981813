import { DateTime } from "luxon";
import { Subjects } from "./ClassData";

export default interface ClassModel {
  id: string | undefined;
  title: string;
  description: string;
  subject: string;
  otherSubject: string;
  grades: string[];
  startTime: DateTime | null;
  endTime: DateTime | null;
  uploadFiles: [];
}

export const getDefaultClassModel = (): ClassModel => {
  return {
    id: undefined,
    title: "My New Class",
    description: "",
    subject: Subjects[0],
    otherSubject: "",
    grades: [],
    startTime: null,
    endTime: null,
    uploadFiles: [],
  };
};
