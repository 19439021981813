import React from "react";

const defaultMenu = {
  clickedNewLesson: false,
  clickedAddClass: false,
  showClass: false,
  showObjectives: false,
  showStandards: false,
  showReview: false,
  clickedPresent: false,
  onNewLesson: (value) => {},
  onAddClass: (value) => {},
  onShowClass: (value) => {},
  onShowObjectives: (value) => {},
  onShowStandards: (value) => {},
  onShowReview: (value) => {},
  onPresent: (value) => {},
};
export const MenuContext = React.createContext(defaultMenu);
export const MenuProvider = MenuContext.Provider;
export const MenuConsumer = MenuContext.Consumer;
