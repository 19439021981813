import firebase from "firebase/app";

// Reference to Google Analytics
export const analytics = firebase.analytics();

export const logEvent = (name) => {
  analytics.logEvent(name);
};

export const logActivityAddEvent = (name, id, sessionPlanId, classId) => {
  analytics.logEvent("activity_add", {
    name,
    id,
    sessionPlanId,
    classId,
  });
};

export const logActivityRemoveEvent = (name, id, sessionPlanId, classId) => {
  analytics.logEvent("activity_remove", {
    name,
    id,
    sessionPlanId,
    classId,
  });
};

export const logActivityShowEditEvent = (name, id, sessionPlanId, classId) => {
  analytics.logEvent("activity_show_edit", {
    name,
    id,
    sessionPlanId,
    classId,
  });
};

export const logActivityEditEvent = (
  name,
  id,
  saveData,
  sessionPlanId,
  classId
) => {
  analytics.logEvent("activity_edit", {
    name,
    id,
    sessionPlanId,
    classId,
    saveData,
  });
};

export const logSlideshowEvent = (isStarting, sessionPlanId, classId) => {
  analytics.logEvent("activity_slideshow", {
    activity: isStarting ? "start" : "end",
    sessionPlanId,
    classId,
  });
};

export const ObjectiveEventType = {
  Add: "objective_add",
  Remove: "objective_remove",
  Edit: "objective_edit",
};

export const logObjectiveEvent = (
  objectiveEventType,
  objective,
  count,
  sessionPlanId,
  classId
) => {
  analytics.logEvent(objectiveEventType, {
    objective,
    count,
    sessionPlanId,
    classId,
  });
};
