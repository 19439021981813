import { DateTime } from "luxon";

export interface SessionModel {
  startTime: DateTime;
  endTime: DateTime;
  classId?: string | undefined;
  id?: string | undefined;
  sessionPlanId?: string | undefined;
  name?: string;
}

export const getDefaultSession = (props: any = null): SessionModel => {
  return {
    startTime:
      props && props.startTime
        ? props.startTime
        : DateTime.fromObject({
            hour: 9,
            minute: 0,
            second: 0,
            zone: "local",
          }),
    endTime:
      props && props.endTime
        ? props.endTime
        : DateTime.fromObject({
            hour: 10,
            minute: 30,
            second: 0,
            zone: "local",
          }),
    classId: props && props.classId ? props.classId : null,
    id: props && props.id ? props.id : null,
    sessionPlanId: props.sessionPlanId ? props.sessionPlanId : null,
    name: props && props.name ? props.name : "Session 1",
  };
};

export const getUpdatedDataFromSessionPlan = (plan: any) => {
  const data: any = {};

  if (plan.name) data.title = plan.name;
  if (plan.startTime) data.start = plan.startTime;
  if (plan.endTime) data.end = plan.endTime;

  return data;
};
