import { useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  //   IconButton,
} from "@material-ui/core";
import { CalendarEvent, DayOfWeek } from "./CalendarEvent";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Checkbox /*, { CheckboxProps }*/ from "@material-ui/core/Checkbox";
import LuxonUtils from "@date-io/luxon";
import { DateTime, Duration } from "luxon";
import TextField from "@material-ui/core/TextField";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
// import AddIcon from "@material-ui/icons/Add";
// import EditIcon from "@material-ui/icons/Edit";
// import DeleteIcon from "@material-ui/icons/Delete";
import clsx from "clsx";
import SessionPlanList from "../SessionPlanList/SessionPlanList";
import { SessionPlanModel } from "../../models/SessionPlanModel";
// import { buttonStyles } from "../../styles/ButtonStyles";

const useCheckboxStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    icon: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-end",
      borderRadius: 24,
      width: 24,
      height: 24,
      fontSize: 14,
      boxShadow:
        "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
      backgroundColor: "#f5f8fa",
      backgroundImage:
        "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
      "$root.Mui-focusVisible &": {
        outline: "2px auto rgba(19,124,189,.6)",
        outlineOffset: 2,
      },
      "input:hover ~ &": {
        backgroundColor: "#ebf1f5",
      },
      "input:disabled ~ &": {
        boxShadow: "none",
        background: "rgba(206,217,224,.5)",
      },
    },
    checkedIcon: {
      color: theme.palette.primary.contrastText,
      backgroundColor: `${theme.palette.primary.main}`,
      backgroundImage:
        "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
      "&:before": {
        content: '""',
      },
      "input:hover ~ &": {
        backgroundColor: theme.palette.primary.main,
      },
    },
  })
);

interface DayCheckboxProps {
  day: string;
  checked: boolean;
  onChange?: any;
}

function DayCheckbox(props: DayCheckboxProps) {
  const classes = useCheckboxStyles();

  return (
    <Checkbox
      className={classes.root}
      disableRipple
      checked={props.checked}
      onChange={(event) => props.onChange(event)}
      color="default"
      checkedIcon={
        <span className={clsx(classes.icon, classes.checkedIcon)}>
          {props.day}
        </span>
      }
      icon={<span className={classes.icon}>{props.day}</span>}
      inputProps={{ "aria-label": "decorative checkbox" }}
    />
  );
}

const useCalendarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    startSection: {
      display: "flex",
      alignItems: "baseline",
    },
    startsOn: {
      marginRight: 20,
    },
    classTimes: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      alignItems: "baseline",
      gap: 10,
    },
    repeatSection: {
      marginBottom: 20,
    },
    dateTimeSection: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      margin: "0 0 22px 0",
      gap: 14,
    },
    endsOnDate: {
      marginLeft: 20,
    },
    editLessonBtn: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    dialogTitle: {
      "& > .MuiTypography-root": {
        display: "flex",
        justifyContent: "space-between",
      },
    },
    lessonRowSection: {
      marginBottom: "20px",
      display: "flex",
      justifyContent: "space-between",
    },
    lessonRowRight: {
      display: "flex",
    },
    progress: {},
  })
);

interface CalendarDialogProps {
  event: CalendarEvent;
  isNewEvent: boolean;
  sessionPlan?: SessionPlanModel;
  isSaving?: any;
  onClose: any;
  onCreate?: (event: CalendarEvent) => void;
  onEdit?: (data: any, saveData: any) => void;
  onEditLesson?: any;
  onRemoveLesson?: () => void;
}

export default function CalendarDialog(props: CalendarDialogProps) {
  const classes = useCalendarStyles();
  // const buttonClasses = buttonStyles();
  const [startTime, setStartTime] = useState<MaterialUiPickersDate>(
    props.event.start !== null
      ? DateTime.fromJSDate(props.event.start)
      : DateTime.now()
  );
  const [endTime, setEndTime] = useState<MaterialUiPickersDate>(
    props.event.end !== null
      ? DateTime.fromJSDate(props.event.end)
      : DateTime.now()
  );
  const [seriesStartTime, setSeriesStartTime] = useState<MaterialUiPickersDate>(
    props.event.seriesStart !== null
      ? DateTime.fromJSDate(props.event.seriesStart)
      : DateTime.now()
  );
  const [seriesEndTime, setSeriesEndTime] = useState<MaterialUiPickersDate>(
    props.event && props.event.seriesEnd
      ? DateTime.fromJSDate(props.event.seriesEnd)
      : DateTime.now()
  );
  const [repeatOption, setRepeatOption] = useState(
    props.event?.seriesType ? props.event?.seriesType : "custom"
  );
  const [checkedDays, setCheckedDays] = useState(
    props.event.seriesCheckedDays
      ? props.event.seriesCheckedDays
      : {
          [DayOfWeek.Sunday]: props.event.start?.getDay() === 0,
          [DayOfWeek.Monday]: props.event.start?.getDay() === 1,
          [DayOfWeek.Tuesday]: props.event.start?.getDay() === 2,
          [DayOfWeek.Wednesday]: props.event.start?.getDay() === 3,
          [DayOfWeek.Thursday]: props.event.start?.getDay() === 4,
          [DayOfWeek.Friday]: props.event.start?.getDay() === 5,
          [DayOfWeek.Saturday]: props.event.start?.getDay() === 6,
        }
  );
  const [selectedSessionPlan, setSelectedSessionPlan] = useState<
    SessionPlanModel | undefined
  >();

  const getEndDateTimeFromDate = (
    date: MaterialUiPickersDate
  ): MaterialUiPickersDate => {
    if (!date) return null;

    let newEndTime: DateTime = endTime ? endTime : date;

    if (
      date &&
      Number.isInteger(date.year) &&
      Number.isInteger(date.month) &&
      Number.isInteger(date.day)
    ) {
      newEndTime = newEndTime.set({
        year: date.year,
        month: date.month,
        day: date.day,
      });

      if (Number.isInteger(date.hour) && Number.isInteger(date.minute)) {
        let diff: Duration = newEndTime.diff(date, "minutes");
        if (diff.minutes < 0) {
          newEndTime = newEndTime.set({ day: date.day + 1 });
        }
      }
    }

    return newEndTime;
  };

  const updateStartDate = (dateTime: MaterialUiPickersDate) => {
    if (!dateTime) {
      console.error("Invalid date found");
      return;
    }

    if (!seriesStartTime) {
      console.error("Invalid series start time");
      return;
    }

    setStartTime(dateTime);

    // Update the series start time hour/min only
    let newSeriesStartTime: DateTime = dateTime.set({
      year: seriesStartTime.year,
      month: seriesStartTime.month,
      day: seriesStartTime.day,
    });

    setSeriesStartTime(newSeriesStartTime);

    let newEndTime: MaterialUiPickersDate = getEndDateTimeFromDate(dateTime);
    setEndTime(newEndTime);

    if (seriesEndTime && newEndTime && newEndTime > seriesEndTime) {
      setSeriesEndTime(newEndTime);
    }
  };

  const handleStartDateChange = (date: MaterialUiPickersDate) => {
    updateStartDate(date);
  };

  const handleStartTimeChange = (date: string) => {
    if (
      startTime &&
      Number.isInteger(startTime.year) &&
      Number.isInteger(startTime.month) &&
      Number.isInteger(startTime.day)
    ) {
      let newTime: DateTime = DateTime.fromFormat(date, "HH:mm").set({
        year: startTime.year,
        month: startTime.month,
        day: startTime.day,
      });

      updateStartDate(newTime);
    }
  };

  const handleSeriesEndDateChange = (date: MaterialUiPickersDate) => {
    let newEndTime: MaterialUiPickersDate = getEndDateTimeFromDate(date);
    setSeriesEndTime(newEndTime);
  };

  const handleEndTimeChange = (date: string) => {
    const newEndTime: MaterialUiPickersDate = getEndDateTimeFromString(date);
    if (!newEndTime) {
      console.error("Invalid end date/time");
      return;
    }

    if (!seriesEndTime) {
      console.error("Invalid series end date/time");
      return;
    }

    setEndTime(newEndTime);

    // Update the series end time hour/min only
    let newSeriesEndTime: DateTime = newEndTime.set({
      year: seriesEndTime.year,
      month: seriesEndTime.month,
      day: seriesEndTime.day,
    });

    setSeriesEndTime(newSeriesEndTime);
  };

  const handleRepeatChange = (event: any) => {
    setRepeatOption(event.target.value);
  };

  const getEndDateTimeFromString = (date: string): MaterialUiPickersDate => {
    let newEndTime: DateTime = DateTime.fromFormat(date, "HH:mm").set({
      year: DateTime.now().year,
      month: DateTime.now().month,
      day: DateTime.now().day,
    });

    if (
      startTime &&
      Number.isInteger(startTime.year) &&
      Number.isInteger(startTime.month) &&
      Number.isInteger(startTime.day) &&
      Number.isInteger(startTime.hour) &&
      Number.isInteger(startTime.minute)
    ) {
      newEndTime = DateTime.fromFormat(date, "HH:mm").set({
        year: startTime.year,
        month: startTime.month,
        day: startTime.day,
      });

      // If this end time is less than the start time, then this event must be carrying over to the next day
      let diff: Duration = newEndTime.diff(startTime, "minutes");
      if (diff.minutes < 0) {
        newEndTime = newEndTime.set({ day: startTime.day + 1 });
      }
    }

    return newEndTime;
  };

  const getCorrectedHrMinFormat = (
    time: MaterialUiPickersDate
  ): string | null => {
    if (!time) return null;

    return time
      ?.toLocaleString({
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })
      .replace("24:00", "00:00");
  };

  const handleDayCheckboxChange = (event: any, day: DayOfWeek) => {
    if (!event) return;

    const days: any = { ...checkedDays };
    days[day] = event.target.checked;
    setCheckedDays(days);
  };

  const handleSave = (event: any) => {
    if (!props.isNewEvent) {
      handleEdit();
      return;
    }

    const newEvent: CalendarEvent = { ...props.event };
    newEvent.sessionPlanId = null;
    newEvent.title = null;
    if (startTime) newEvent.start = startTime?.toJSDate();
    if (endTime) newEvent.end = endTime.toJSDate();
    if (seriesStartTime) newEvent.seriesStart = seriesStartTime.toJSDate();
    if (seriesEndTime) newEvent.seriesEnd = seriesEndTime.toJSDate();
    if (checkedDays) newEvent.seriesCheckedDays = { ...checkedDays };
    newEvent.seriesType = repeatOption;

    if (props.onCreate) {
      props.onCreate(newEvent);
    }
  };

  const handleEdit = () => {
    const data: any = {};
    data.id = props.event.id;
    data.classId = props.event.classId;
    data.sessionPlanId = selectedSessionPlan ? selectedSessionPlan.id : null;
    data.title = selectedSessionPlan ? selectedSessionPlan.name : null;
    if (startTime) data.start = startTime?.toJSDate();
    if (endTime) data.end = endTime.toJSDate();
    const saveData: any = {
      ...data,
      start: startTime?.toISO(),
      end: endTime?.toISO(),
    };

    if (props.onEdit) {
      props.onEdit(data, saveData);
    }
  };

  const handleDialogClose = (event: any) => {
    if (props.onClose) {
      props.onClose(event);
    }
  };

  const hasStartEndTimeError = (): boolean => {
    return endTime !== null && startTime !== null && endTime < startTime;
  };

  const getNoRepeatElements = () => {
    return (
      <MuiPickersUtilsProvider utils={LuxonUtils}>
        <div className={classes.dateTimeSection}>
          <KeyboardDatePicker
            disableToolbar={false}
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="calendarStartDatePicker"
            label=""
            value={startTime}
            onChange={handleStartDateChange}
            KeyboardButtonProps={{
              "aria-label": "Date",
            }}
            style={{ margin: 0 }}
          />
        </div>
        <div className={classes.classTimes}>
          <TextField
            type="time"
            id="calendarStartTimePicker"
            label="From"
            value={getCorrectedHrMinFormat(startTime)}
            onChange={(event: any) => handleStartTimeChange(event.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
          />
          <TextField
            type="time"
            id="calendarEndTimePicker"
            label="To"
            value={getCorrectedHrMinFormat(endTime)}
            onChange={(event: any) => handleEndTimeChange(event.target.value)}
            error={hasStartEndTimeError()}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
          />
        </div>
      </MuiPickersUtilsProvider>
    );
  };

  const getCheckedDays = () => {
    if (!props.isNewEvent) return null;

    return (
      <div>
        <DayCheckbox
          day="S"
          checked={checkedDays[DayOfWeek.Sunday]}
          onChange={(event: any) =>
            handleDayCheckboxChange(event, DayOfWeek.Sunday)
          }
        />
        <DayCheckbox
          day="M"
          checked={checkedDays[DayOfWeek.Monday]}
          onChange={(event: any) =>
            handleDayCheckboxChange(event, DayOfWeek.Monday)
          }
        />
        <DayCheckbox
          day="T"
          checked={checkedDays[DayOfWeek.Tuesday]}
          onChange={(event: any) =>
            handleDayCheckboxChange(event, DayOfWeek.Tuesday)
          }
        />
        <DayCheckbox
          day="W"
          checked={checkedDays[DayOfWeek.Wednesday]}
          onChange={(event: any) =>
            handleDayCheckboxChange(event, DayOfWeek.Wednesday)
          }
        />
        <DayCheckbox
          day="T"
          checked={checkedDays[DayOfWeek.Thursday]}
          onChange={(event: any) =>
            handleDayCheckboxChange(event, DayOfWeek.Thursday)
          }
        />
        <DayCheckbox
          day="F"
          checked={checkedDays[DayOfWeek.Friday]}
          onChange={(event: any) =>
            handleDayCheckboxChange(event, DayOfWeek.Friday)
          }
        />
        <DayCheckbox
          day="S"
          checked={checkedDays[DayOfWeek.Saturday]}
          onChange={(event: any) =>
            handleDayCheckboxChange(event, DayOfWeek.Saturday)
          }
        />
      </div>
    );
  };

  const getRepeatElements = () => {
    return (
      <MuiPickersUtilsProvider utils={LuxonUtils}>
        <div className={classes.dateTimeSection}>
          {getCheckedDays()}
          <div className={classes.startSection}>
            <KeyboardDatePicker
              id="calendarStartDatePicker"
              label="Starts On"
              value={startTime}
              onChange={handleStartDateChange}
              className={classes.startsOn}
              disableToolbar={false}
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
            <div className={classes.classTimes}>
              <TextField
                type="time"
                id="calendarStartTimePicker"
                label="From"
                value={getCorrectedHrMinFormat(startTime)}
                onChange={(event: any) =>
                  handleStartTimeChange(event.target.value)
                }
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
              />
              <TextField
                type="time"
                id="calendarEndTimePicker"
                label="To"
                value={getCorrectedHrMinFormat(endTime)}
                onChange={(event: any) =>
                  handleEndTimeChange(event.target.value)
                }
                error={hasStartEndTimeError()}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
              />
            </div>
            {endTime !== null &&
              startTime !== null &&
              endTime.day !== startTime.day && (
                <Typography
                  component="span"
                  variant="subtitle1"
                  className={classes.endsOnDate}
                >
                  {endTime.toLocaleString()}
                </Typography>
              )}
          </div>
          {props.isNewEvent && (
            <div>
              <KeyboardDatePicker
                id="calendarEndDatePicker"
                label="Series Ends On"
                value={seriesEndTime}
                onChange={handleSeriesEndDateChange}
                disableToolbar={false}
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                style={{ margin: 0 }}
              />
            </div>
          )}
        </div>
      </MuiPickersUtilsProvider>
    );
  };

  const getTitleElement = () => {
    return (
      <DialogTitle id="calendar-dlg-title" className={classes.dialogTitle}>
        {props.isNewEvent ? "Add New Meeting Times" : "Edit Class Session"}
      </DialogTitle>
    );
  };

  //   const getLessonRowButtons = () => {
  //     if (props.event.sessionPlanId) {
  //       return (
  //         <div>
  //           <IconButton onClick={handleEditLesson}>
  //             <EditIcon />
  //           </IconButton>
  //           <IconButton onClick={handleRemoveLesson}>
  //             <DeleteIcon />
  //           </IconButton>
  //         </div>
  //       );
  //     }

  //     return (
  //       <div>
  //         <IconButton onClick={handleEditLesson}>
  //           <AddIcon />
  //         </IconButton>
  //       </div>
  //     );
  //   };

  const handleSessionPlanSelect = (
    event: any,
    sessionPlan: SessionPlanModel | undefined
  ) => {
    setSelectedSessionPlan(sessionPlan);
  };

  const getLessonRowElement = () => {
    if (props.isNewEvent) {
      return null;
    }

    return (
      <div className={classes.lessonRowSection}>
        <Typography variant="h6">Lesson</Typography>
        <div className={classes.lessonRowRight}>
          <SessionPlanList
            show={true}
            isDropdown={true}
            selectedSessionPlanId={props.event.sessionPlanId}
            onSessionPlanSelect={handleSessionPlanSelect}
          />
          {/* {getLessonRowButtons()} */}
        </div>
      </div>
    );
  };

  const getTypeSelectMenu = () => {
    if (!props.isNewEvent) {
      return null;
    }

    return (
      <div className={classes.repeatSection}>
        <FormControl variant="outlined" size="small">
          <Select
            labelId="rwlContentTypeLabel"
            id="rwlContentTypeSelect"
            value={repeatOption}
            onChange={handleRepeatChange}
            label=""
          >
            <MenuItem value={"custom"}>Custom</MenuItem>
            <MenuItem value={"noRepeat"}>Does not repeat</MenuItem>
          </Select>
        </FormControl>
      </div>
    );
  };

  const getFormElement = () => {
    return (
      <form>
        {getLessonRowElement()}
        {getTypeSelectMenu()}

        {repeatOption === "noRepeat"
          ? getNoRepeatElements()
          : getRepeatElements()}
      </form>
    );
  };

  return (
    <Dialog
      onClose={handleDialogClose}
      aria-labelledby="calendar-dlg-title"
      open={true}
    >
      {getTitleElement()}
      <DialogContent dividers={true}>{getFormElement()}</DialogContent>
      <DialogActions>
        {props.isSaving && <CircularProgress className={classes.progress} />}
        <Button autoFocus={true} onClick={handleSave} color="primary">
          Save
        </Button>
        <Button autoFocus={false} onClick={handleDialogClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
