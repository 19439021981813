import { ActivityTypes, JIGSAW_GROUPS_PER_SLIDE } from "./ActivityTypes";

export const GetSubSlideCount = (
  activityType: ActivityTypes,
  activity: any
): number => {
  const { saveData } = activity;

  switch (activityType) {
    case ActivityTypes.WarmUp:
      return 1;

    case ActivityTypes.ReadWatchListen:
      return 1;

    case ActivityTypes.Reflect:
      return 1;

    case ActivityTypes.Discuss:
      if (!saveData) return 1;
      if (saveData.activityAction === "thinkPairShare") {
        return 4;
      }

      return 1;

    case ActivityTypes.Collaborate:
      if (!saveData) return 1;
      if (saveData.activityAction === "jigsaw") {
        if (saveData.jigsawInstructions) {
          // Find the number of group slides to show plus a start and end slide
          return (
            Math.ceil(
              saveData.jigsawInstructions.length / JIGSAW_GROUPS_PER_SLIDE
            ) + 2
          );
        }
      }

      return 1;

    case ActivityTypes.Assess:
      if (!saveData) return 0;

      if (saveData.activityAction === "knowledgeCheck") {
        return saveData.knowledgeCheckQuestions
          ? saveData.knowledgeCheckQuestions.length
          : 0;
      }

      if (saveData.activityAction === "exitTicket") {
        return saveData.exitTicketQuestions
          ? saveData.exitTicketQuestions.length
          : 0;
      }

      if (saveData.activityAction === "trivia") {
        return saveData.triviaQuestions ? saveData.triviaQuestions.length : 0;
      }

      return 0;

    case ActivityTypes.Practice:
      return 1;

    case ActivityTypes.Investigate:
      return 1;

    case ActivityTypes.Break:
      return 1;

    case ActivityTypes.Custom:
      return 1;

    case ActivityTypes.Placeholder:
      return 1;

    default:
      return 0;
  }
};
