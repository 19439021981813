import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
// import TextField from "@material-ui/core/TextField";
// import Sidebar from "../Sidebar/Sidebar";
// import { SessionModel } from "../Session/SessionModel";
import { DateTime } from "luxon";
import { SessionPlanModel } from "../../models/SessionPlanModel";
import { Present } from "../Presentation/Presentation";
import { getSessionTimes } from "../SessionPlan/SessionPlan";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import React from "react";
import ExitMessage from "./ExitMessage";

export const TitleSlideCount = 2;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
    },
    activityTimes: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
    },
    titleText: {
      color: theme.palette.primary.main,
      fontSize: "2.25rem",
      fontWeight: "bold",
    },
    subtitleText: {
      fontSize: "1.2rem",
      color: theme.palette.grey[700],
    },
    objectiveSubtitleText: {
      fontSize: "1.2rem",
      fontWeight: "bold",
      marginTop: 30,
      color: theme.palette.grey[700],
    },
    activityText: {
      fontSize: "1.25rem",
      color: theme.palette.grey[700],
    },
    activityRow: {
      display: "grid",
      gridTemplateColumns: "150px 1fr",
    },
    activityType: {
      fontWeight: "bold",
    },
  })
);

interface TitleSlideProps {
  sessionPlan: SessionPlanModel;
  classTitle: string | undefined;
  slideIndex: number;
}

export default function TitleSlides(props: TitleSlideProps) {
  const classes = useStyles();

  const getLessonInfo = () => {
    return (
      <Present>
        <Typography
          component="label"
          variant="h6"
          className={classes.titleText}
        >
          {props.sessionPlan.name}
        </Typography>
        <Typography
          component="label"
          variant="h6"
          className={classes.subtitleText}
        >
          {props.classTitle}
        </Typography>
        <Typography
          component="label"
          variant="h6"
          className={classes.subtitleText}
        >
          {props.sessionPlan.startTime?.toLocaleString()}
        </Typography>
        <Typography
          component="label"
          variant="h6"
          className={classes.subtitleText}
        >
          {props.sessionPlan.startTime?.toLocaleString(DateTime.TIME_SIMPLE)}
        </Typography>
      </Present>
    );
  };

  const getActivityDisplayName = (activity: any) => {
    if (
      activity.name === "Custom" &&
      activity &&
      activity.saveData &&
      activity.saveData.activityName &&
      activity.saveData.activityName.length > 0
    ) {
      return activity.saveData.activityName;
    }

    return activity.name;
  };

  const getActivityTimes = () => {
    const times: DateTime[] = getSessionTimes(props.sessionPlan);

    return (
      // <div className={classes.activityTimes}>
      <Present>
        {props.sessionPlan &&
          props.sessionPlan.activities.map((activity: any, index: number) => {
            const time: DateTime = times[index];

            return (
              <div key={activity.id} className={classes.activityRow}>
                <Typography
                  component="label"
                  variant="h6"
                  className={classes.activityText}
                >
                  {time.toLocaleString(DateTime.TIME_SIMPLE)}
                </Typography>
                <Typography
                  component="label"
                  variant="h6"
                  className={clsx(classes.activityText, classes.activityType)}
                >
                  {getActivityDisplayName(activity)}
                </Typography>
              </div>
            );
          })}
        {/* </div> */}
      </Present>
    );
  };

  const getTitleSlide = () => {
    return (
      <React.Fragment>
        <ExitMessage />
        <div className={classes.root}>
          {getLessonInfo()}
          {getActivityTimes()}
        </div>
      </React.Fragment>
    );
  };

  const getObjectivesSlide = () => {
    return (
      <Present>
        <Typography
          component="label"
          variant="h6"
          className={classes.titleText}
        >
          Learning Objectives
        </Typography>
        <Typography
          component="label"
          variant="h6"
          className={classes.objectiveSubtitleText}
        >
          Learners will be able to...
        </Typography>
        <ul>
          {props.sessionPlan &&
            props.sessionPlan.objectives.map(
              (objective: any, index: number) => {
                return (
                  <li
                    key={"objective-" + index}
                    className={classes.activityRow}
                  >
                    <Typography
                      component="label"
                      variant="h6"
                      className={classes.activityText}
                    >
                      {`${objective.value}:`}
                    </Typography>
                    <Typography
                      component="label"
                      variant="h6"
                      className={classes.activityText}
                    >
                      {`${objective.additional}`}
                    </Typography>
                  </li>
                );
              }
            )}
        </ul>
      </Present>
    );
  };

  if (props.slideIndex === 0) {
    return getTitleSlide();
  } else {
    return getObjectivesSlide();
  }
}
