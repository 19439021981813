import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const dialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    dlgRoot: {},
    dlgOuter: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: "20px 40px 0 40px",
    },
    dlgInner: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    dlgVSpacer: {
      margin: "24px 0",
    },
    dlgActions: {
      display: "flex",
      justifyContent: "center",
      margin: "20px 0",
    },
  })
);
