import React, { useState } from "react";
import firebase from "firebase/app";
import { auth } from "../../firebase/firebase";
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

import {
  FINISH_SIGN_UP_ROUTE,
  HOME_ROUTE,
  SIGN_IN_ROUTE,
  TERMS_AND_CONDITIONS_ROUTE,
} from "../../constants/routes";
import { Typography } from "@material-ui/core";
import PasswordStrengthBar from "react-password-strength-bar";
import { validate } from "isemail";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "80vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: theme.palette.background.default,
    },
    card: {
      "& > *": {
        margin: theme.spacing(1),
      },
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: 500,
      // minWidth: 600,
    },
    cardContent: {
      width: "100%",
    },
    formRow: {
      display: "grid",
      gridTemplateColumns: "90% 10%",
      marginBottom: 20,
      alignItems: "center",
    },
    checkmarkSection: {
      width: "100%",
      textAlign: "right",
    },
    checkmarkSuccess: {
      color: theme.palette.success.main,
    },
    checkmarkNone: {
      color: theme.palette.grey[400],
    },
    textField: {
      width: "100%",
    },
    formErrorMsg: {
      color: theme.palette.error.main,
      fontWeight: "bold",
    },
    formWarningMsg: {
      color: theme.palette.warning.main,
      fontWeight: "normal",
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)",
    },
    title: {
      fontWeight: "bold",
      marginBottom: 10,
      color: theme.palette.primary.main,
    },
    subtitle: {
      fontWeight: "bold",
      marginBottom: 10,
      color: theme.palette.getContrastText(theme.palette.background.default),
    },
    haveAccountSection: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "16px",
    },
    haveAccountText: {},
    logIn: {
      fontWeight: "bold",
      marginLeft: "0.4em",
      color: theme.palette.primary.main,
    },
    termsText: {},
    passwordCheck: {
      marginBottom: 24,
    },
  })
);

export default function SignUpForm(props: any) {
  const [email, setEmail] = useState("");
  const [passwordOne, setPasswordOne] = useState("");
  const [passwordTwo, setPasswordTwo] = useState("");
  const [passwordScore, setPasswordScore] = useState(0);
  const [termsChecked, setTermsChecked] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState<string | null>(null);
  const [passwordErrorMsg, setPasswordErrorMsg] = useState<string | null>(null);
  const [passwordSuggestions, setPasswordSuggestions] = useState<[] | null>(
    null
  );
  const [passwordMinSize] = useState(8);
  const [formErrorMsg, setFormErrorMsg] = useState<string | null>(null);
  const [didSendEmailVerification, setDidSendEmailVerification] =
    useState(false);
  // const [error, setError] = useState(null);

  const classes = useStyles();

  const clearForm = (keepEmail: boolean) => {
    if (!keepEmail) setEmail("");

    setPasswordOne("");
    setPasswordTwo("");
    setEmailErrorMsg(null);
    setPasswordErrorMsg(null);
    setPasswordSuggestions(null);
    setFormErrorMsg(null);
  };

  const sendVerification = () => {
    if (!auth.currentUser) {
      return;
    }

    // Verify user's email address
    const actionCodeSettings: firebase.auth.ActionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      url: window.location.origin + FINISH_SIGN_UP_ROUTE,
      // This must be true.
      handleCodeInApp: true,
      // iOS: {
      //   bundleId: 'com.example.ios'
      // },
      // android: {
      //   packageName: 'com.example.android',
      //   installApp: true,
      //   minimumVersion: '12'
      // },
      // dynamicLinkDomain: "example.page.link",
    };

    auth.currentUser
      .sendEmailVerification(actionCodeSettings)
      .then(() => {
        clearForm(true);
        setDidSendEmailVerification(true);
      })
      .catch((error) => {
        console.error("Send email verification error: ", error);
      });
  };

  const routeHome = () => {
    clearForm(true);
    props.history.push(HOME_ROUTE);

    // Temp code to prevent missing function warnings
    if (sendVerification != null) {
      console.log("redirecting to home");
    }
  };

  const onSubmit = (event: any) => {
    event.preventDefault();

    auth
      .createUserWithEmailAndPassword(email, passwordOne)
      .then((userCredential: any) => {
        if (auth.currentUser && userCredential.user) {
          // TODO: replace this routeHome() below with sendVerification() when we need to verify emails again.
          routeHome();

          // sendVerification();
        }
      })
      .catch((error) => {
        if (error && typeof error === "object") {
          if (error.message) {
            setFormErrorMsg(error.message);
          }
        } else {
          console.error("User create error: ", error);
        }
      });
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    if (!validate(event.target.value)) {
      setEmailErrorMsg("Email is invalid");
    } else {
      setEmailErrorMsg(null);
    }

    setFormErrorMsg(null);
  };

  const handleOnChangeScore = (score: any, feedback: any) => {
    console.log(score, feedback);
    setPasswordScore(score);

    if (feedback && feedback.warning) {
      setPasswordErrorMsg(feedback.warning);

      if (feedback.suggestions) {
        setPasswordSuggestions(feedback.suggestions);
      }
    } else {
      setPasswordErrorMsg(null);
      setPasswordSuggestions(null);
    }
  };

  const handleTermsCheckedChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTermsChecked(event.target.checked);
  };

  const isPasswordValid = () => {
    return passwordOne !== "" && passwordScore > 1;
  };

  const isFormInvalid = () => {
    return (
      !validate(email) ||
      !isPasswordValid() ||
      passwordOne !== passwordTwo ||
      termsChecked === false
    );
  };

  const getPasswordCheckElement = () => {
    if (passwordOne.length === 0) return null;

    return (
      <div className={classes.passwordCheck}>
        <PasswordStrengthBar
          password={passwordOne}
          minLength={passwordMinSize}
          onChangeScore={handleOnChangeScore}
        />
        <Typography className={classes.formErrorMsg}>
          {passwordErrorMsg}
        </Typography>
        {passwordSuggestions &&
          passwordSuggestions.map((choice: any, index: number) => {
            return (
              <Typography
                // className={classes.formWarningMsg}
                component="div"
                variant="subtitle2"
              >
                {choice}
              </Typography>
            );
          })}
      </div>
    );
  };

  const getCheckmarkIcon = () => {
    return (
      <div className={classes.checkmarkSection}>
        <CheckCircleOutlineIcon
          fontSize="large"
          className={classes.checkmarkSuccess}
        />
      </div>
    );
  };

  const getCircleIcon = () => {
    return (
      <div className={classes.checkmarkSection}>
        <RadioButtonUncheckedIcon
          fontSize="large"
          className={classes.checkmarkNone}
        />
      </div>
    );
  };

  const getEmailCheckIcon = () => {
    return validate(email) ? getCheckmarkIcon() : getCircleIcon();
  };

  const getPasswordCheckIcon = () => {
    return isPasswordValid() ? getCheckmarkIcon() : getCircleIcon();
  };

  const getPasswordConfirmIcon = () => {
    return isPasswordValid() && passwordOne === passwordTwo
      ? getCheckmarkIcon()
      : getCircleIcon();
  };

  const getTermsConfirmIcon = () => {
    return termsChecked ? getCheckmarkIcon() : getCircleIcon();
  };

  const getCard = () => {
    return (
      <Card className={classes.card} variant="outlined">
        <CardContent className={classes.cardContent}>
          <form onSubmit={(event) => onSubmit(event)}>
            <div className={classes.formRow}>
              <TextField
                id="signup-email-input"
                className={classes.textField}
                label="Email Address"
                type="email"
                autoComplete="username"
                variant="outlined"
                value={email}
                onChange={handleEmailChange}
              />
              {getEmailCheckIcon()}
            </div>
            <Typography className={classes.formErrorMsg}>
              {emailErrorMsg}
            </Typography>
            <div className={classes.formRow}>
              <TextField
                id="signup-password-input"
                className={classes.textField}
                label="Password"
                type="password"
                autoComplete="new-password"
                variant="outlined"
                value={passwordOne}
                onChange={(e) => {
                  setPasswordOne(e.target.value);
                  setFormErrorMsg(null);
                }}
              />
              {getPasswordCheckIcon()}
            </div>
            {getPasswordCheckElement()}
            <div className={classes.formRow}>
              <TextField
                id="signup-confirm-password-input"
                className={classes.textField}
                label="Confirm Password"
                type="password"
                autoComplete="new-password"
                variant="outlined"
                value={passwordTwo}
                onChange={(e) => {
                  setPasswordTwo(e.target.value);
                  setFormErrorMsg(null);
                }}
              />
              {getPasswordConfirmIcon()}
            </div>
            <Typography className={classes.formErrorMsg}>
              {formErrorMsg}
            </Typography>
            <div className={classes.formRow}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={termsChecked}
                    onChange={handleTermsCheckedChange}
                    name="checkedB"
                    color="primary"
                  />
                }
                label={
                  <a
                    rel="noopener noreferrer"
                    href={TERMS_AND_CONDITIONS_ROUTE}
                    target="_blank"
                  >
                    <Typography
                      component="span"
                      variant="subtitle1"
                      className={classes.termsText}
                    >
                      I accept the terms and conditions
                    </Typography>
                  </a>
                }
              />
              {getTermsConfirmIcon()}
            </div>
          </form>
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => onSubmit(e)}
            disabled={isFormInvalid()}
          >
            Sign Up
          </Button>
        </CardActions>
      </Card>
    );
  };

  const getForm = () => {
    return (
      <React.Fragment>
        <Typography component="h5" variant="h5" className={classes.subtitle}>
          Create a new Educator account
        </Typography>
        <span className={classes.haveAccountSection}>
          <Typography
            component="span"
            variant="subtitle1"
            className={classes.haveAccountText}
          >
            Already have an account?
          </Typography>
          <Link to={SIGN_IN_ROUTE}>
            <Typography
              component="span"
              variant="subtitle1"
              className={classes.logIn}
            >
              Log In
            </Typography>
          </Link>
        </span>
        {getCard()}
      </React.Fragment>
    );
  };

  const getEmailVerificationSentElement = () => {
    return (
      <React.Fragment>
        <Typography component="h5" variant="h5" className={classes.subtitle}>
          We sent you an email!
        </Typography>
        <Typography component="span" variant="subtitle1">
          A verification email has been sent to {email}. Please click on the
          link to verify your email address.
        </Typography>
      </React.Fragment>
    );
  };

  const getRenderElement = () => {
    if (didSendEmailVerification) {
      return getEmailVerificationSentElement();
    }

    return getForm();
  };

  return getRenderElement();
}
