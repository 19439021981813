import React from "react";
// import { Typography } from "@material-ui/core";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { makeActivity } from "../Activity/ActivityMaker";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { SessionPlanModel } from "../../models/SessionPlanModel";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sessionPlanMaster: {
      //   height: "100%",
      //   margin: "0 0 0 50px",
      //   flexGrow: 1,
      //   textAlign: "center",
      //   display: "flex",
      //   flexDirection: "column",
      //   justifyContent: "space-between",
    },
    sessionMasterSection: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    sessionMasterList: {
      display: "grid",
      width: "100%",
    },
    title: {
      margin: "20px 0 40px 0",
      textAlign: "center",
    },
    sessionMasterDragging: {
      userSelect: "none",
    },
    sessionMasterDraggable: {
      userSelect: "none",
    },
  })
);

interface SessionMasterProps {
  activities: object[];
  sessionPlan: SessionPlanModel;
  onSave?: any;
  onNext?: any;
}

export default function SessionMasterGroup(props: SessionMasterProps) {
  const classes = useStyles();

  // const cloneActivity = (activity: any): any => {
  //   const newActivity = Object.assign({}, activity);
  //   newActivity.id = "activity-clone-id";
  //   return makeActivity(activity.type, true, newActivity);
  // };

  return (
    <div className={classes.sessionPlanMaster}>
      <div className={classes.sessionMasterSection}>
        {/* <Typography component="div" variant="h6" className={classes.title}>
          Activity Blocks
        </Typography> */}
        <Droppable droppableId="sessionMasterGroup" isDropDisabled={true}>
          {(provideDDC: any, snapshotDDC: any) => (
            <div
              className={classes.sessionMasterList}
              {...provideDDC.droppableProps}
              ref={provideDDC.innerRef}
            >
              {props.activities.map((item: any, index: number) => {
                return (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided: any, snapshot: any) => (
                      <React.Fragment>
                        <div
                          className={
                            snapshot.isDragging
                              ? classes.sessionMasterDragging
                              : classes.sessionMasterDraggable
                          }
                          key={item.id}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {makeActivity(
                            item.type,
                            props.sessionPlan.id,
                            true,
                            item
                          )}
                        </div>
                        {
                          // snapshot.isDragging && cloneActivity(item)
                          // <button style={{backgroundColor:"blue"}} />
                        }
                      </React.Fragment>
                    )}
                  </Draggable>
                );
              })}
              {provideDDC.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </div>
  );
}
