import React, { useState, useEffect } from "react";
import GroupWorkForm, { GroupWorkSlide } from "./GroupWorkForm";
import JigsawForm, { JigsawSlide } from "./JigsawForm";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import Typography from "@material-ui/core/Typography";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ExtensionIcon from "@material-ui/icons/Extension";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import { createActivityActionRender } from "../Editing";
// import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import "../../../styles/styles.css";
import {
  ActivityActionToNameMap,
  ActivityFormProps,
  ActivityMode,
  ActivityToNameMap,
} from "../../Activity/ActivityTypes";
import {
  Present,
  PresentMinsText,
  PresentSubtitleText,
  PresentTitleText,
  presentStyles,
} from "../../Presentation/Presentation";
import clsx from "clsx";

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       width: 1280,
//       height: 820,
//     },
//   })
// );

const getDuration = (data: any): number => {
  switch (data.activityAction) {
    case "jigsaw":
      return data.jigsawDuration;

    case "groupWork":
      return data.groupWorkDuration;

    default:
      return 10;
  }
};

export default function CollaborateForm(props: ActivityFormProps) {
  // const classes = useStyles();
  const presentClasses = presentStyles({});
  const [hasLoaded, setHasLoaded] = useState(false);
  const [activityActionRender, setActivityActionRender] = useState<any>();
  const [doSave, setDoSave] = useState(false);
  const [saveData, setSaveData] = useState<any>(props.saveData);

  useEffect(() => {
    if (!hasLoaded) {
      const data = { ...saveData };
      if (!data.activityAction) {
        data.activityAction = "jigsaw";
        setSaveData(data);
      }

      setActivityActionRender(
        createActivityActionRender(ActivityActionToNameMap[data.activityAction])
      );
      setHasLoaded(true);
    }
  }, [hasLoaded, saveData]);

  const handleActivityAction = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: any
  ) => {
    if (!value) return;

    const data = { ...saveData };
    data.activityAction = value;
    setSaveData(data);
    setActivityActionRender(
      createActivityActionRender(ActivityActionToNameMap[value])
    );
  };

  const handleDataChange = (data: any) => {
    if (!data) return;

    const newObj = Object.assign(saveData, data);
    newObj.duration = getDuration(newObj);
    setSaveData(newObj);

    if (doSave) {
      setDoSave(false);
      handleSaveResume(null);
    }
  };

  const handleSave = (event: any) => {
    if (
      saveData.activityAction === "jigsaw" ||
      saveData.activityAction === "groupWork"
    ) {
      // Tell upload files to save first. Then, we resume saving when it calls back
      setDoSave(true);
    } else {
      handleSaveResume(event);
    }
  };

  const handleSaveResume = (event: any) => {
    if (props.onSave) {
      props.onSave({ saveData, activityActionRender });
    }

    handleClose(event);
  };

  const handleClose = (event: any) => {
    if (props.onClose) {
      props.onClose(event);
    }
  };

  const getSlide = () => {
    return (
      <Present>
        <PresentTitleText
          value={ActivityToNameMap["Collaborate"]}
          colorClass="learnidy-teal"
          icon={
            <PeopleAltOutlinedIcon
              fontSize="large"
              className={clsx("learnidy-teal-bg", presentClasses.titleIcon)}
            />
          }
        />
        <PresentSubtitleText
          value={ActivityActionToNameMap[props.saveData.activityAction]}
          colorClass="learnidy-teal"
        />
        <PresentMinsText value={saveData.duration} />

        {props.saveData.activityAction === "jigsaw" && (
          <JigsawSlide
            saveData={props.saveData}
            subslideIndex={props.subslideIndex ? props.subslideIndex : 0}
            id={props.id}
            sessionPlanId={props.sessionPlanId}
            color={props.color}
          />
        )}
        {props.saveData.activityAction === "groupWork" && (
          <GroupWorkSlide
            saveData={props.saveData}
            id={props.id}
            sessionPlanId={props.sessionPlanId}
            color={props.color}
          />
        )}
      </Present>
    );
  };

  return props.activityMode === ActivityMode.SlideShow ? (
    getSlide()
  ) : (
    <Dialog
      fullScreen={false}
      open={true}
      maxWidth={"lg"}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <div className={"activity-dialog"} style={{ width: 1280, height: 820 }}>
        <div className={"activity-form"}>
          <span className={clsx("activity-form-name", props.color)}>
            {ActivityToNameMap["Collaborate"]}
          </span>
          <div className={"activity-form-btn-group"}>
            <ToggleButtonGroup
              value={saveData.activityAction}
              exclusive={true}
              orientation="vertical"
              onChange={handleActivityAction}
              aria-label="activity action"
            >
              <ToggleButton
                value="jigsaw"
                aria-label="jigsaw"
                disabled={false}
                classes={{
                  selected: props.color + "-border",
                }}
              >
                <Typography
                  component="div"
                  variant="subtitle1"
                  className="activity-name"
                >
                  Jigsaw
                </Typography>
                {saveData.activityAction === "jigsaw" ? (
                  <Badge>
                    <CheckCircleIcon className={props.color} />
                  </Badge>
                ) : null}
                <ExtensionIcon
                  style={{ fontSize: 70 }}
                  classes={{ root: "learnidy-teal" }}
                />
              </ToggleButton>
              <ToggleButton
                value="groupWork"
                aria-label="groupWork"
                classes={{
                  selected: props.color + "-border",
                }}
              >
                <Typography
                  component="span"
                  variant="subtitle1"
                  className="activity-name"
                >
                  Group Work
                </Typography>
                {saveData.activityAction === "groupWork" ? (
                  <Badge>
                    <CheckCircleIcon className={props.color} />
                  </Badge>
                ) : null}
                <LibraryBooksIcon
                  style={{ fontSize: 70 }}
                  classes={{ root: "learnidy-teal" }}
                />
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
        <div className={"activity-form"}>
          <Button
            className={"activity-form-close"}
            variant="outlined"
            onClick={handleClose}
          >
            <CloseRoundedIcon fontSize="small" />
          </Button>
          {saveData.activityAction === "jigsaw" && (
            <JigsawForm
              saveData={saveData}
              subslideIndex={props.subslideIndex ? props.subslideIndex : 0}
              id={props.id}
              sessionPlanId={props.sessionPlanId}
              color={props.color}
              onChange={(data: any) => handleDataChange(data)}
              doSave={doSave}
            />
          )}
          {saveData.activityAction === "groupWork" && (
            <GroupWorkForm
              saveData={saveData}
              id={props.id}
              sessionPlanId={props.sessionPlanId}
              color={props.color}
              onChange={(data: any) => handleDataChange(data)}
              doSave={doSave}
            />
          )}
          <div className={"warmup-footer"}>
            <Button variant="outlined" onClick={handleSave} disabled={doSave}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
