import { useState, useEffect, useCallback, useRef } from "react";
import { getActivityChild } from "../Activity/ActivityMaker";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
// import TextField from "@material-ui/core/TextField";
// import Typography from "@material-ui/core/Typography";
// import Sidebar from "../Sidebar/Sidebar";
// import { SessionModel } from "../Session/SessionModel";
// import { DateTime } from "luxon";
import { queryClass } from "../../firebase/firebase";
import { ActivityMode } from "../Activity/ActivityTypes";
import { SessionPlanModel } from "../../models/SessionPlanModel";
import Dialog from "@material-ui/core/Dialog";
import { GetSubSlideCount } from "../Activity/ActivitySlide";
import TitleSlides, { TitleSlideCount } from "./TitleSlides";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    slideShowContainer: {},
  })
);

interface SlideShowProps {
  sessionPlan: SessionPlanModel;
  onClose?: any;
}

export default function SlideShow(props: SlideShowProps) {
  const classes = useStyles();
  //   const [didLoad, setDidLoad] = useState(false);
  const [open, setOpen] = useState(true);
  const [titleSlideIndex, setTitleSlideIndex] = useState(0);
  const [slideIndex, setSlideIndex] = useState(0);
  const [subslideIndex, setSubslideIndex] = useState(0);
  const [classTitle, setClassTitle] = useState<string | undefined>(undefined);
  const mounted: any = useRef();

  const getClass = (id: string | undefined) => {
    if (!id) {
      return;
    }

    queryClass(id)
      .then((doc) => {
        if (doc.exists) {
          const data = doc.data();
          if (data) {
            if (data.title) setClassTitle(data.title);
            // if (data.description) setDescription(data.description);
            // if (data.subject) setSubject(data.subject);
            // if (data.grades) setGrades(data.grades);
            // if (data.startTime)
            //   setStartTime(DateTime.fromISO(data.startTime));
            // if (data.endTime) setEndTime(DateTime.fromISO(data.endTime));
            // if (data.uploadFiles) setUploadFiles(data.uploadFiles);
          }
        } else {
          console.error("No such document for Learning Objectives!");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (!mounted.current) {
      getClass(props.sessionPlan.classId);

      mounted.current = true;
    }
  }, [props.sessionPlan.classId]);

  const handleClose = useCallback(
    (event: any) => {
      setOpen(false);
      if (props.onClose) props.onClose(event);
    },
    [props]
  );

  const slideChange = useCallback(
    (event: any, forward: boolean) => {
      // Are we showing title slides
      if (forward && titleSlideIndex < TitleSlideCount) {
        setTitleSlideIndex((val) => val + 1);
        return;
      } else if (!forward && slideIndex === 0 && subslideIndex === 0) {
        if (titleSlideIndex > 0) setTitleSlideIndex((val) => val - 1);

        return;
      }

      if (!props.sessionPlan) return;

      // Otherwise, show an objective slide
      // Forward
      if (forward && slideIndex < props.sessionPlan.activities.length) {
        const currActivity: any = props.sessionPlan.activities[slideIndex];
        const subCnt = GetSubSlideCount(currActivity.type, currActivity);

        if (subslideIndex + 1 < subCnt) {
          setSubslideIndex((val) => val + 1);
          return;
        } else if (slideIndex + 1 < props.sessionPlan.activities.length) {
          setSlideIndex((val) => val + 1);
          setSubslideIndex(0);
          return;
        }
      }

      // Backward
      if (!forward) {
        if (subslideIndex > 0) {
          setSubslideIndex((val) => val - 1);
          return;
        } else if (slideIndex > 0) {
          setSlideIndex((val) => val - 1);
          setSubslideIndex(0);
          return;
        }
      }

      handleClose(event);
    },
    [slideIndex, handleClose, props.sessionPlan, subslideIndex, titleSlideIndex]
  );

  const handleKeyPress = useCallback(
    (event: any) => {
      if (event.key === "ArrowLeft") {
        slideChange(event, false);
      } else if (event.key === "ArrowRight") {
        slideChange(event, true);
      }
    },
    [slideChange]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      // unsubscribe event
      window.removeEventListener("keydown", handleKeyPress);
    };
  });

  const handleClick = (event: any) => {
    slideChange(event, true);
  };

  const getSlide = () => {
    if (titleSlideIndex < TitleSlideCount) {
      return (
        <TitleSlides
          sessionPlan={props.sessionPlan}
          classTitle={classTitle}
          slideIndex={titleSlideIndex}
        />
      );
    } else if (
      props.sessionPlan &&
      props.sessionPlan.activities.length > slideIndex
    ) {
      return (
        <div>
          {getActivityChild(
            props.sessionPlan.activities[slideIndex].type,
            props.sessionPlan.activities[slideIndex],
            props.sessionPlan.id,
            ActivityMode.SlideShow,
            subslideIndex
          )}
        </div>
      );
    }

    return null;
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClick={handleClick}
      onEscapeKeyDown={handleClose}
      className={
        classes.slideShowContainer
      } /* onClose={handleClose} TransitionComponent={Transition}*/
    >
      {getSlide()}
    </Dialog>
  );
}
