import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const buttonStyles = makeStyles((theme: Theme) =>
  createStyles({
    primary: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main),
      "&:hover": {
        backgroundColor: theme.palette.action.hover,
      },
      "&.MuiButton-root.Mui-disabled, &.MuiIconButton-root.Mui-disabled": {
        backgroundColor: theme.palette.action.disabledBackground,
        color: theme.palette.action.disabled,
      },
    },
    secondary: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.neutral?.light,
      "&:hover": {
        backgroundColor: theme.palette.secondary.dark,
      },
      "&.MuiButton-root.Mui-disabled, &.MuiIconButton-root.Mui-disabled": {
        backgroundColor: theme.palette.secondary.light,

        color: theme.palette.action.disabled,
      },
    },
    alert: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.neutral?.light,
      "&:hover": {
        backgroundColor: theme.palette.warning.dark,
      },
      "&.MuiButton-root.Mui-disabled, &.MuiIconButton-root.Mui-disabled": {
        backgroundColor: theme.palette.warning.light,
        color: theme.palette.action.disabled,
      },
    },
    default: {
      backgroundColor: theme.palette.grey[400],
      color: theme.palette.getContrastText(theme.palette.grey[400]),
      "&:hover": {
        backgroundColor: theme.palette.grey[500],
      },
      "&.MuiButton-root.Mui-disabled, &.MuiIconButton-root.Mui-disabled": {
        backgroundColor: theme.palette.action.disabledBackground,
        color: theme.palette.action.disabled,
      },
    },
    iconWithText: {
      marginRight: 20,
    },
  })
);
