import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import EventIcon from "@material-ui/icons/Event";
import "../../styles/styles.css";
import "./ClassInformation.css";
import { Grades, GradesToStringMap, Subjects } from "./ClassData";
import { v4 as uuidv4 } from "uuid";
import UploadFiles from "../UploadFiles/UploadFiles";
import { queryClass, saveClass, createClassId } from "../../firebase/firebase";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { DateTime /*, Duration */ } from "luxon";
import ClassModel, { getDefaultClassModel } from "./ClassModel";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { widgetStyles } from "../../styles/WidgetStyles";
import SessionCalendar from "../Session/SessionCalendar";
import { CalendarEvent } from "../Session/CalendarEvent";
import ClassChange from "./ClassChange";
import {
  Dialog,
  FormControlLabel,
  List,
  ListItem,
  MenuItem,
} from "@material-ui/core";
import { SessionPlanModel } from "../../models/SessionPlanModel";
import { UserContext } from "../../hooks/useUser";
import Widget from "../Widget/Widget";
import { buttonStyles } from "../../styles/ButtonStyles";
import {
  CustomDialogActions,
  CustomDialogContent,
  CustomDialogTitle,
} from "../Dialog/CustomDialog";
import { MediaType } from "../../constants/media";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    classDialogPaper: {
      minWidth: 500,
    },
    select: { display: "flex", flexDirection: "column" },
    nestedMenuItem: {
      "&:hover": {
        color:
          theme.palette.getContrastText(theme.palette.background.default) +
          "!important",
        backgroundColor: theme.palette.background.default + "!important",
      },
    },
  })
);

interface GradeLevelDialogProps {
  grades: string[];
  onAccept?: (values: string[]) => void;
  onCancel?: () => void;
}

const cleanupGrades = (grades: string[]): string[] => {
  const values: string[] = grades.filter((grade, index) => {
    return Grades.find((value) => value === grade) !== undefined;
  });

  return values;
};

const GradeLevelDialog = (props: GradeLevelDialogProps) => {
  const widgetClasses = widgetStyles();
  const buttonClasses = buttonStyles();
  const classes = useStyles();
  const [newGrades, setNewGrades] = useState(cleanupGrades(props.grades));

  const handleGradesCancel = () => {
    if (props.onCancel) {
      props.onCancel();
    }
  };

  const handleGradesAccept = () => {
    if (props.onAccept) {
      props.onAccept(newGrades);
    }
  };

  const handleGradeChange = (event: any, checked: boolean, value: string) => {
    let selectedGrades: string[] = [];
    if (checked) {
      selectedGrades = [...newGrades, value];
    } else {
      selectedGrades = newGrades.filter((grade: string) => {
        return grade !== value;
      });
    }

    selectedGrades.sort((a: string, b: string) => {
      const a1 = a.toUpperCase();
      const b1 = b.toUpperCase();
      if (a1 < b1) return -1;
      if (a1 > b1) return 1;
      return 0;
    });

    setNewGrades(selectedGrades);
  };

  return (
    <Dialog
      onClose={handleGradesCancel}
      aria-labelledby="grades-dialog"
      open={true}
    >
      <CustomDialogTitle id="class-dialog-title" onClose={handleGradesCancel}>
        Select the Grades in this Class
      </CustomDialogTitle>
      <CustomDialogContent dividers>
        <List
          id="grade-level-select"
          className={clsx(widgetClasses.selectDisabled, classes.select)}
          style={{ margin: "0 100px" }}
        >
          {Grades.map((item: any, index: number) => (
            <ListItem
              key={item}
              value={item}
              className={classes.nestedMenuItem}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      newGrades &&
                      newGrades.find((val) => val === item) !== undefined
                    }
                    onChange={(event, checked) =>
                      handleGradeChange(event, checked, item)
                    }
                    name={item}
                  />
                }
                label={GradesToStringMap[item]}
              />
            </ListItem>
          ))}
        </List>
      </CustomDialogContent>
      <CustomDialogActions>
        <Button
          autoFocus
          onClick={handleGradesAccept}
          className={buttonClasses.primary}
        >
          Ok
        </Button>
        <Button onClick={handleGradesCancel} className={buttonClasses.primary}>
          Cancel
        </Button>
      </CustomDialogActions>
    </Dialog>
  );
};

interface ClassInformationProps {
  classId: string | undefined;
  sessionPlan: SessionPlanModel;
  selectClass: number;
  show: boolean;
  onCancel?: any;
  onSaveClass?: any;
  onLoadClass?: any;
  onShowClass?: any;
  onLoadSessionPlan?: (id: string) => void;
  onUpdateSessionPlanLocal?: any;
  onSelectSession?: any;
}

export default function ClassInformation(props: ClassInformationProps) {
  const widgetClasses = widgetStyles();
  const buttonClasses = buttonStyles();
  const classes = useStyles();
  const user = useContext(UserContext);
  const defaultClassModel: ClassModel = getDefaultClassModel();
  const [isEditing, setIsEditing] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [saveTimeoutMs] = useState<number>(500);
  const [saveTimeoutId, setSaveTimeoutId] = useState<any>();
  const [classId, setClassId] = useState(props.classId);
  const [title, setClassTitle] = useState(defaultClassModel.title);
  const [description, setDescription] = useState(defaultClassModel.description);
  const [subject, setSubject] = useState(defaultClassModel.subject);
  const [otherSubject, setOtherSubject] = useState("");
  const [grades, setGrades] = useState<string[]>(defaultClassModel.grades);
  const [showGrades, setShowGrades] = useState(false);
  const [startTime, setStartTime] = useState<MaterialUiPickersDate>(
    defaultClassModel.startTime
  );
  const [endTime, setEndTime] = useState<MaterialUiPickersDate>(
    defaultClassModel.endTime
  );
  const [uploadFiles, setUploadFiles] = useState<[]>(
    defaultClassModel.uploadFiles
  );
  const [showCalendar, setShowCalendar] = useState(false);
  const [isSelectingSessionForPlan, setIsSelectingSessionForPlan] =
    useState(false);
  const [doSave, setDoSave] = useState(false);
  const mounted: any = useRef();
  const { onLoadClass } = props;

  const loadClass = useCallback(
    (id: string | undefined, createPlanIfNotExist: boolean = false) => {
      if (!id) return;

      // Set class id here to prevent multiple calls during useEffect
      setClassId(id);

      queryClass(id)
        .then((doc) => {
          if (doc.exists) {
            const data = doc.data();
            if (data) {
              if (data.title) setClassTitle(data.title);
              if (data.description) setDescription(data.description);
              if (data.subject) setSubject(data.subject);
              if (data.otherSubject) setOtherSubject(data.otherSubject);
              if (data.grades) setGrades(data.grades);
              if (data.startTime)
                setStartTime(DateTime.fromISO(data.startTime));
              if (data.endTime) setEndTime(DateTime.fromISO(data.endTime));
              if (data.uploadFiles) setUploadFiles(data.uploadFiles);

              if (onLoadClass) onLoadClass(id, createPlanIfNotExist);

              // Set the class id last so it doesn't cause an infinite loop with useEffect
              setClassId(id);
            }
          } else {
            console.error("No such document for Learning Objectives!");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    [setClassId, onLoadClass]
  );

  const clearForm = useCallback(() => {
    setClassId(props.classId);
    setClassTitle(defaultClassModel.title);
    setDescription(defaultClassModel.description);
    setSubject(defaultClassModel.subject);
    setGrades(defaultClassModel.grades);
    setStartTime(defaultClassModel.startTime);
    setEndTime(defaultClassModel.endTime);
    setUploadFiles(defaultClassModel.uploadFiles);
    setShowCalendar(false);
    setDoSave(false);
  }, [
    props.classId,
    defaultClassModel.title,
    defaultClassModel.description,
    defaultClassModel.subject,
    defaultClassModel.grades,
    defaultClassModel.startTime,
    defaultClassModel.endTime,
    defaultClassModel.uploadFiles,
  ]);

  useEffect(() => {
    if (!mounted.current) {
      loadClass(classId);
      mounted.current = true;
    }
  });

  useEffect(() => {
    if (props.selectClass > 0) {
      setIsSelectingSessionForPlan(true);
      setShowCalendar(true);
    }
  }, [props.selectClass]);

  useEffect(() => {
    if (!props.classId && classId) {
      clearForm();
    } else if (classId !== props.classId) {
      loadClass(props.classId);
    }
  }, [classId, props.classId, loadClass, clearForm]);

  const handleCollapse = (isCollapsed: boolean) => {
    setCollapsed(isCollapsed);
  };

  const handleCancel = () => {
    setIsEditing(false);
    if (props.onCancel) {
      props.onCancel();
    }
  };

  const handleSubjectChange = (value: any) => {
    setSubject(value);
    if (classId) save(classId);
  };

  const handleOtherSubjectChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setOtherSubject(event.target.value);
    if (classId) save(classId);
  };

  const handleClassTitleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setClassTitle(event.target.value);
    if (classId) save(classId);
  };

  // const handleDescriptionChange = (
  //   event: React.ChangeEvent<HTMLTextAreaElement>
  // ) => {
  //   setDescription(event.target.value);
  //   if (classId) save(classId);
  // };

  const handleUploadFiles = (files: any) => {
    // Store the file names and urls, don't allow uploading files again, and resume saving
    setUploadFiles(files);

    if (doSave) {
      setDoSave(false);
      if (classId) saveResume(classId);
    }
  };

  const handleClassChangeCreateClass = () => {
    setIsEditing(true);
  };

  const handleClassChangeCancel = () => {
    setIsEditing(false);
  };

  const handleClassChangeAddClass = (id: string) => {
    if (onLoadClass) {
      onLoadClass(id, false);
    }

    selectForCalendar();
  };

  const handleCreateClass = () => {
    let id: string = createClassId().id;
    save(id, true);
    setIsEditing(false);
  };

  const handleClassChangeShowClass = () => {
    if (props.onShowClass) props.onShowClass(classId, true);
  };

  const handleCalendarClick = (event: any) => {
    setShowCalendar(true);
  };

  const handleCalendarEventSelect = (
    event: CalendarEvent | null,
    isUpdatingLocalOnly: boolean = false
  ) => {
    if (!event) return;

    // Update the current plan if we are selecting one or updating from the calendar
    if (
      isSelectingSessionForPlan ||
      (isUpdatingLocalOnly && event.sessionPlanId === props.sessionPlan.id)
    ) {
      setIsSelectingSessionForPlan(false);
      props.onUpdateSessionPlanLocal({
        sessionId: event.id,
        startTime: event.start ? DateTime.fromJSDate(event.start) : null,
        endTime: event.end ? DateTime.fromJSDate(event.end) : null,
      });
      // Load the lesson plan
    } else if (
      !isUpdatingLocalOnly &&
      props.onLoadSessionPlan &&
      event.sessionPlanId
    ) {
      props.onLoadSessionPlan(event.sessionPlanId);
    }

    setShowCalendar(false);
  };

  const handleCalendarClose = () => {
    setIsSelectingSessionForPlan(false);
    setShowCalendar(false);

    if (props.onLoadSessionPlan) {
      props.onLoadSessionPlan(props.sessionPlan.id);
    }
  };

  const getClassModel = (): ClassModel => {
    let model: ClassModel = {
      id: classId,
      title: title,
      description,
      subject,
      otherSubject,
      grades,
      startTime,
      endTime,
      uploadFiles,
    };

    return model;
  };

  const getSaveDataFormat = (): any => {
    let model: ClassModel = getClassModel();
    return {
      title: model.title,
      description: model.description,
      subject: model.subject,
      otherSubject: model.otherSubject,
      grades: model.grades,
      startTime: model.startTime ? model.startTime.toString() : null,
      endTime: model.endTime ? model.endTime.toString() : null,
      uploadFiles: model.uploadFiles,
    };
  };

  const selectForCalendar = () => {
    setIsSelectingSessionForPlan(true);
    setShowCalendar(true);
    setIsEditing(false);
  };

  const save = (id: string, skipUploadFiles: boolean = false) => {
    if (!id) {
      console.error("Can't save class. Class Id not valid");
      return;
    }

    // Don't upload to the cloud if we aren't a logged in user
    if (!user.loggedIn) {
      updateSaveCallbacks(id);
      return;
    }

    // Set the class id so it is available during saveResume
    setClassId(id);

    // First, Upload any files which will trigger a callback in order to save the rest of the class information
    if (skipUploadFiles) saveResume(id);
    else setDoSave(true);
  };

  const saveResume = (id: string) => {
    // Save after a little while so we don't spam the server with saves

    clearTimeout(saveTimeoutId);
    const timeoutId = setTimeout(() => {
      const data = getSaveDataFormat();
      saveClass(data, id)
        .then(() => {
          updateSaveCallbacks(id);

          // If we are editing, show the calendar as the next step after a class save or load
          if (isEditing) {
            selectForCalendar();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }, saveTimeoutMs);

    setSaveTimeoutId(timeoutId);
  };

  const updateSaveCallbacks = (
    id: string,
    createPlanIfNotExist: boolean = false
  ) => {
    let model: ClassModel = getClassModel();
    model.id = id;
    setClassId(id);

    if (props.onSaveClass) props.onSaveClass(model, createPlanIfNotExist);
  };

  const getClassChangeDialog = () => {
    return (
      <ClassChange
        classId={classId}
        onCreateClass={handleClassChangeCreateClass}
        onAddClass={handleClassChangeAddClass}
        onShowClass={handleClassChangeShowClass}
        onCancel={handleClassChangeCancel}
      />
    );
  };

  const handleGradesAccept = (values: string[]) => {
    setShowGrades(false);
    setGrades(values);
    if (classId) save(classId);
  };

  const handleGradesCancel = () => {
    setShowGrades(false);
  };

  const getClassInfo = () => {
    return (
      <React.Fragment>
        <div className={widgetClasses.widgetField}>
          <Typography
            component="label"
            variant="subtitle2"
            // className="class-label"
          >
            Class Name
          </Typography>
          <TextField
            id="title"
            // label="Enter Class Title"
            variant="outlined"
            value={title}
            onChange={handleClassTitleChange}
            size="small"
            fullWidth={true}
          />
        </div>
        <div className={widgetClasses.widgetRow}>
          <FormControl variant="outlined" size="small">
            <InputLabel id="class-subject-label">Subject</InputLabel>
            <Select
              labelId="class-subject-label"
              id="class-subject-select"
              label="Subject"
              value={subject}
              className={widgetClasses.selectDisabled}
              onChange={(e) => handleSubjectChange(e.target.value)}
            >
              {Subjects.map((item: any, index: number) => (
                <MenuItem key={uuidv4()} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {subject === "Other" && (
            <TextField
              id="title"
              label="Subject Name"
              variant="outlined"
              value={otherSubject}
              onChange={handleOtherSubjectChange}
              size="small"
              fullWidth={false}
            />
          )}
        </div>
        <div className={widgetClasses.widgetRow}>
          <div
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography component="label" variant="subtitle1">
                Grade Level
              </Typography>
              <Button
                onClick={(event) => setShowGrades(true)}
                variant="contained"
              >
                Edit
              </Button>
            </div>
            <Typography
              component="label"
              variant="subtitle1"
              style={{ maxWidth: 300 }}
            >
              {grades
                .map((grade, index) => {
                  return GradesToStringMap[grade];
                })
                .join(", ")}
            </Typography>
            {showGrades && (
              <GradeLevelDialog
                grades={grades}
                onAccept={handleGradesAccept}
                onCancel={handleGradesCancel}
              />
            )}
          </div>
        </div>
        {!isEditing && (
          <div
            className={widgetClasses.widgetRow}
            style={{ width: "100%", justifyContent: "space-between" }}
          >
            <Typography
              component="label"
              variant="subtitle1"
              // className="class-label"
            >
              Class Sessions
            </Typography>
            {showCalendar && (
              <SessionCalendar
                classId={classId}
                sessionPlan={props.sessionPlan}
                isSelectingSessionForPlan={isSelectingSessionForPlan}
                onEventSelect={handleCalendarEventSelect}
                onClose={handleCalendarClose}
              />
            )}
            <Button
              // classes={{ root: classes.customEventButton }}
              // variant=""
              onClick={handleCalendarClick}
              variant="contained"
            >
              <EventIcon fontSize="default" />
            </Button>
          </div>
        )}
        <UploadFiles
          uploadFiles={uploadFiles}
          mediaType={MediaType.Any}
          classId={classId}
          onChange={handleUploadFiles}
          doUpload={doSave}
        />
      </React.Fragment>
    );
  };

  const getDialogRender = () => {
    return (
      <Dialog
        classes={{ paper: classes.classDialogPaper }}
        fullScreen={false}
        open={true}
        maxWidth={"lg"}
        onClose={handleCancel}
        aria-labelledby="responsive-dialog-title"
      >
        <CustomDialogTitle id="class-dialog-title" onClose={handleCancel}>
          Class Information
        </CustomDialogTitle>
        <CustomDialogContent dividers>{getClassInfo()}</CustomDialogContent>
        <CustomDialogActions>
          <Button
            autoFocus
            onClick={handleCreateClass}
            className={buttonClasses.primary}
          >
            Save
          </Button>
        </CustomDialogActions>
      </Dialog>
    );
  };

  const getWidgetRender = () => {
    return (
      <Widget
        title={"Class Information"}
        startCollapsed={collapsed}
        onCollapse={handleCollapse}
        onCancel={handleCancel}
      >
        {getClassInfo()}
      </Widget>
    );
  };

  if (props.show && isEditing) {
    return getDialogRender();
  } else if (props.show && classId) {
    return getWidgetRender();
  } else {
    return getClassChangeDialog();
  }
}
