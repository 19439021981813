import { useState, useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { UserProvider } from "../hooks/useUser";
import { MenuProvider } from "../hooks/useMenu";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { lightTheme /*darkTheme*/ } from "./Theme/theme";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import {} from "react-router";
import Home from "../pages/Home";
import Landing from "../pages/Landing";
import SignIn from "../pages/SignIn/index";
import SignUp from "../pages/SignUp";
import FinishSignUp from "../pages/FinishSignUp";
import Templates from "../pages/Templates";
import "./App.css";
import { ConfigContext, HistoryContext, ResetContext } from "../hooks";
import { LessonProvider } from "../hooks/useLesson";
import { onAuthStateChange } from "../firebase/firebase";
import ResetPassword from "src/pages/ResetPassword";
import {
  SessionPlanModel,
  getDefaultSessionPlan,
} from "../models/SessionPlanModel";

const useStyles = makeStyles((theme: Theme) => ({
  appContainer: {
    width: "100vw",
    display: "grid",
    gridTemplateRows: "auto 1fr",
  },
}));

export default function AppComponent(props: any) {
  const [theme] = useState(lightTheme());
  // const [darkMode] = useState(false);
  const classes = useStyles();
  const [user, setUser] = useState({
    loggedIn: false,
    email: null,
    photoURL: null,
  });

  const [menu, setMenu] = useState({
    clickedNewLesson: false,
    clickedAddClass: false,
    showClass: false,
    showObjectives: true,
    showStandards: false,
    showReview: false,
    clickedPresent: false,
    onNewLesson: (value: boolean) => {
      setMenu((curr) => ({ ...curr, clickedNewLesson: value }));
    },
    onAddClass: (value: boolean) => {
      setMenu((curr) => ({ ...curr, clickedAddClass: value }));
    },
    onShowClass: (value: boolean) => {
      setMenu((curr) => ({ ...curr, showClass: value }));
    },
    onShowObjectives: (value: boolean) => {
      setMenu((curr) => ({ ...curr, showObjectives: value }));
    },
    onShowStandards: (value: boolean) => {
      setMenu((curr) => ({ ...curr, showStandards: value }));
    },
    onShowReview: (value: boolean) => {
      setMenu((curr) => ({ ...curr, showReview: value }));
    },
    onPresent: (value: boolean) => {
      setMenu((curr) => ({ ...curr, clickedPresent: value }));
    },
  });

  const [lesson, setLesson] = useState({
    sessionPlan: getDefaultSessionPlan(),
    updateSessionPlan: (sessionPlan: SessionPlanModel) => {
      setLesson({ ...lesson, sessionPlan });
    },
  });

  useEffect(() => {
    const unsubscribe = onAuthStateChange(setUser);
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      <ConfigContext.Provider value={props.config}>
        <HistoryContext.Provider value={props.history}>
          <ResetContext.Provider value={props.reset}>
            <UserProvider value={user}>
              <MenuProvider value={menu}>
                <LessonProvider value={lesson}>
                  <CssBaseline />
                  <BrowserRouter>
                    <div className={classes.appContainer}>
                      <Switch>
                        <Route path="/home" component={Home} />
                        <Route path="/templates" component={Templates} />
                        <Route path="/signin" component={SignIn} />
                        <Route path="/signup" component={SignUp} />
                        <Route
                          path="/resetpassword"
                          component={ResetPassword}
                        />
                        <Route path="/finishsignup" component={FinishSignUp} />
                        <Route path="/" component={Landing} />
                      </Switch>
                    </div>
                  </BrowserRouter>
                </LessonProvider>
              </MenuProvider>
            </UserProvider>
          </ResetContext.Provider>
        </HistoryContext.Provider>
      </ConfigContext.Provider>
    </MuiThemeProvider>
  );
}

export const App = AppComponent;
