import React, { useState, useEffect, useCallback } from "react";
import Typography from "@material-ui/core/Typography";
import "../../../styles/styles.css";
import IncrementInput from "../../IncrementInput/IncrementInput";
import { LargeText } from "../../Presentation/Presentation";
import clsx from "clsx";
import Duration from "../Duration/Duration";

// const useStyles = makeStyles((theme: any) =>
//   createStyles({
//     radioFormControl: {
//       margin: 0,
//     },
//     radioGroup: {
//       margin: 0,
//     },
//     radioRoot: {
//       margin: 0,
//       padding: "2px 10px",
//     },
//   })
// );

interface IDoYouDoProps {
  saveData: any;
  color: string;
  validate?: boolean;
  onChange?: any;
}

export function IDoYouDoSlide(props: IDoYouDoProps) {
  return (
    <React.Fragment>
      <LargeText
        title="I Do - Guided Practice Instructions:"
        value={props.saveData.iDoPrompt}
        minutes={props.saveData.iDoPromptTime}
        backgroundColor="learnidy-orange-bg"
        isMultiLine={true}
      />
      <LargeText
        title="You Do - Independent Practice Instructions:"
        value={props.saveData.youDoPrompt}
        minutes={props.saveData.youDoPromptTime}
        backgroundColor="learnidy-orange-bg"
        isMultiLine={true}
      />
    </React.Fragment>
  );
}

export default function IDoYouDoForm(props: IDoYouDoProps) {
  // const classes = useStyles();
  const [hasLoaded, setHasLoaded] = useState(false);
  const [saveData, setSaveData] = useState<any>({
    iDoYouDoDuration: 0,
    iDoPrompt:
      props.saveData && props.saveData.iDoPrompt
        ? props.saveData.iDoPrompt
        : "",
    youDoPrompt:
      props.saveData && props.saveData.youDoPrompt
        ? props.saveData.youDoPrompt
        : "",
    iDoPromptTime:
      props.saveData && props.saveData.iDoPromptTime
        ? props.saveData.iDoPromptTime
        : 10,
    youDoPromptTime:
      props.saveData && props.saveData.youDoPromptTime
        ? props.saveData.youDoPromptTime
        : 10,
    iDoYouDoUploadFiles:
      props.saveData && props.saveData.iDoYouDoUploadFiles
        ? props.saveData.iDoYouDoUploadFiles
        : [],
  });

  const validateForm = useCallback((): boolean => {
    if (
      !saveData.iDoYouDoGroupSettings ||
      saveData.iDoYouDoGroupSettings.length === 0
    ) {
      return false;
    }

    return true;
  }, [saveData.iDoYouDoGroupSettings]);

  useEffect(() => {
    if (props.validate) {
      validateForm(); //children function of interest
    }
  }, [props.validate, validateForm]);

  useEffect(() => {
    if (!hasLoaded) {
      const data = { ...saveData };
      data.iDoYouDoDuration = data.iDoPromptTime + data.youDoPromptTime;

      setSaveData(data);
      if (props.onChange) {
        props.onChange({
          iDoYouDoDuration: data.iDoYouDoDuration,
        });
      }

      setHasLoaded(true);
    }
  }, [hasLoaded, saveData, props]);

  const handleDataChange = (key: string, value: any) => {
    if (!key) {
      return;
    }

    const data = { ...saveData };
    data[key] = value;
    setSaveData(data);

    if (props.onChange) {
      props.onChange({ [key]: value });
    }
  };

  const getDuration = (data: any): number => {
    return data.iDoPromptTime + data.youDoPromptTime;
  };

  const handleDurationChange = (val: string) => {
    handleDataChange("iDoYouDoDuration", val);
  };

  const handleTimeChange = (key: string, value: number) => {
    const data = { ...saveData };
    data[key] = value;
    data.iDoYouDoDuration = getDuration(data);
    setSaveData(data);

    if (props.onChange) {
      props.onChange({
        iDoYouDoDuration: data.iDoYouDoDuration,
        [key]: value,
      });
    }
  };

  return (
    <React.Fragment>
      <span className={clsx("activity-form-name", props.color)}>
        I Do, You Do
      </span>
      <p className={"activity-form-desc"}>
        I Do, You Do is a two part practice routine where learners first engage
        in guided practice of a skill with the educator first and then are
        scaffolded and move onto independent practice on their own.
      </p>
      <div className={clsx("warmup-header-break", props.color + "-border")} />
      <div className={"warmup-duration"}>
        <Typography
          component="span"
          variant="subtitle1"
          className="warmup-tot-duration-text"
        >
          Total Duration:
        </Typography>
        <Duration
          time={saveData.iDoYouDoDuration}
          onChange={handleDurationChange}
        />
      </div>
      <div className={"warmup-subsection"}>
        <div className={"warmup-duration"}>
          <Typography
            component="span"
            variant="subtitle2"
            className="warmup-subtitle"
          >
            I Do - Guided Practice Instructions
          </Typography>
          <IncrementInput
            time={saveData.iDoPromptTime}
            onChange={(value: number) =>
              handleTimeChange("iDoPromptTime", value)
            }
          />
        </div>
        <textarea
          className={"warmup-subtext"}
          placeholder="What guided practice prompt do you want to provide all learners?"
          rows={2}
          value={saveData.iDoPrompt}
          onChange={(event) =>
            handleDataChange("iDoPrompt", event.target.value)
          }
        />
      </div>
      <div className={"warmup-subsection"}>
        <div className={"warmup-duration"}>
          <Typography
            component="span"
            variant="subtitle2"
            className="warmup-subtitle"
          >
            You Do - Independent Practice Instructions
          </Typography>
          <IncrementInput
            time={saveData.youDoPromptTime}
            onChange={(value: number) =>
              handleTimeChange("youDoPromptTime", value)
            }
          />
        </div>
        <textarea
          className={"warmup-subtext"}
          placeholder="What independent practice prompt do you want to provide all learners?"
          rows={2}
          value={saveData.youDoPrompt}
          onChange={(event) =>
            handleDataChange("youDoPrompt", event.target.value)
          }
        />
      </div>
    </React.Fragment>
  );
}
