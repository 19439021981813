import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ResetPasswordForm from "../../components/ResetPassword/ResetPasswordForm";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "80vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: theme.palette.background.default,
    },
  })
);

export default function ResetPasswordScreen(props: any) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ResetPasswordForm {...props} />
    </div>
  );
}
