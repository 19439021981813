import React, { useState, useEffect } from "react";
import { DateTime } from "luxon";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { SessionPlanModel } from "../../models/SessionPlanModel";
import LuxonUtils from "@date-io/luxon";
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import {
  CompareHourMinutes,
  GetCorrectedEndDateFromStartDate,
} from "../Session/DateTimeHelper";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    lessonDateTime: {
      marginBottom: 12,
    },
    dateTimeSection: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
    },
    title: {
      marginBottom: 20,
      textAlign: "left",
    },
    sessionTimeSpanDisplay: {
      display: "flex",
      flexDirection: "row",
      flexGrow: 1,
      justifyContent: "center",
      gap: 24,
    },
    sessionTimeSpan: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "baseline",
      gap: 20,
    },
    sessionTimeStart: {
      margin: "0 20px 0 0",
    },
    sessionTimeEnd: {
      margin: "0 0 0 20px",
    },
    objectiveSection: {},
    objectiveTitle: {
      fontWeight: "bold",
    },
    objectiveBtn: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main),
      marginLeft: 20,
    },
  })
);

interface LessonDateTimeProps {
  sessionPlan: SessionPlanModel;
  isDisplayOnly: boolean;
  onDateChange?: any;
}

export default function LessonDateTime(props: LessonDateTimeProps) {
  const classes = useStyles();
  const [didLoad, setDidLoad] = useState(false);

  useEffect(() => {
    if (!didLoad) {
      setDidLoad(true);
    }
  }, [didLoad]);

  const handleStartDateChange = (newStart: MaterialUiPickersDate | null) => {
    if (newStart && props.sessionPlan.endTime) {
      const newEnd: DateTime = GetCorrectedEndDateFromStartDate(
        props.sessionPlan.endTime,
        newStart
      );

      const event: object = {
        startTime: newStart,
        endTime: newEnd,
      };

      if (props.onDateChange) props.onDateChange(event);
    }
  };

  const handleEndDateChange = (newEnd: MaterialUiPickersDate | null) => {
    if (newEnd && props.sessionPlan.startTime) {
      const correctedEnd = GetCorrectedEndDateFromStartDate(
        newEnd,
        props.sessionPlan.startTime
      );

      const event: object = {
        startTime: props.sessionPlan.startTime,
        endTime: correctedEnd,
      };

      if (props.onDateChange) props.onDateChange(event);
    }
  };

  const getDateTimeDisplay = () => {
    return (
      <div className={classes.dateTimeSection}>
        <div className={classes.sessionTimeSpanDisplay}>
          {props.sessionPlan.startTime && props.sessionPlan.endTime && (
            <React.Fragment>
              <Typography component="label" variant="subtitle2">
                {props.sessionPlan.startTime.toLocaleString(DateTime.DATE_MED)}
              </Typography>
              <Typography component="label" variant="subtitle2">
                {props.sessionPlan.startTime.toLocaleString(
                  DateTime.TIME_SIMPLE
                ) +
                  " - " +
                  props.sessionPlan.endTime.toLocaleString(
                    DateTime.TIME_SIMPLE
                  )}
              </Typography>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  };

  const getDateTimeEditable = () => {
    return (
      <MuiPickersUtilsProvider utils={LuxonUtils}>
        <div className={classes.sessionTimeSpan}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            label="Date"
            value={props.sessionPlan?.startTime}
            onChange={handleStartDateChange}
            format="MM/dd/yyyy"
            size="small"
          />
          <KeyboardTimePicker
            margin="normal"
            variant="inline"
            id="classStartTimePicker"
            label="Start Time"
            value={props.sessionPlan?.startTime}
            onChange={handleStartDateChange}
            KeyboardButtonProps={{
              "aria-label": "session start time",
            }}
            size="small"
          />
          <KeyboardTimePicker
            margin="normal"
            variant="inline"
            id="classEndTimePicker"
            label="End Time"
            helperText={
              CompareHourMinutes(
                props.sessionPlan.startTime,
                props.sessionPlan.endTime
              )
                ? ""
                : "(Next Day)"
            }
            value={props.sessionPlan?.endTime}
            onChange={handleEndDateChange}
            KeyboardButtonProps={{
              "aria-label": "session end time",
            }}
            size="small"
          />
        </div>
      </MuiPickersUtilsProvider>
    );
  };

  return (
    <div className={classes.lessonDateTime}>
      {props.isDisplayOnly ? getDateTimeDisplay() : getDateTimeEditable()}
    </div>
  );
}
