import React, { useState, useEffect } from "react";
import KnowledgeCheckForm, { KnowledgeCheckSlide } from "./KnowledgeCheckForm";
import ExitTicketForm, { ExitTicketSlide } from "./ExitTicketForm";
import TriviaForm, { TriviaSlide } from "./TriviaForm";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import DoneOutlineOutlinedIcon from "@material-ui/icons/DoneOutlineOutlined";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import NetworkCheckIcon from "@material-ui/icons/NetworkCheck";
// import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import "../../../styles/styles.css";
import { createActivityActionRender } from "../Editing";
import {
  ActivityActionToNameMap,
  ActivityFormProps,
  ActivityMode,
  ActivityToNameMap,
} from "../../Activity/ActivityTypes";
import {
  Present,
  PresentDescText,
  PresentMinsText,
  PresentSubtitleText,
  presentStyles,
  PresentTitleText,
} from "../../Presentation/Presentation";
import clsx from "clsx";

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       width: 1280,
//       height: 820,
//     },
//   })
// );

export const getRoutineDesc = (action: string) => {
  if (action === "knowledgeCheck")
    return "Knowledge checks are quizzes that are meant to give the learner an idea of how well they know the material, are way for learners to review their learning progress and see where any gaps exist.";
  if (action === "exitTicket")
    return "Exit tickets are a formative assessment tool that give educators a way to assess how well learners understand the material they are learning in class. Exit tickets are usually given at the end of the class session.";
  if (action === "trivia")
    return "Use a trivia format to quiz learners in a fun and competitive setting with the whole class.";

  return "";
};

export const getRoutineSlideDesc = (action: string) => {
  if (action === "knowledgeCheck")
    return "Check your understanding with these questions!";
  if (action === "exitTicket")
    return "Recall what you learned today by writing down your response to this exit ticket question.";
  if (action === "trivia")
    return "Engage in a fun and competitive trivia poll with the whole class.";

  return "";
};

const getDuration = (data: any): number => {
  switch (data.activityAction) {
    case "knowledgeCheck":
      return data.knowledgeCheckDuration;

    case "exitTicket":
      return data.exitTicketDuration;

    case "trivia":
      return data.triviaDuration;

    default:
      return 10;
  }
};

export default function AssessForm(props: ActivityFormProps) {
  // const classes = useStyles();
  const [hasLoaded, setHasLoaded] = useState(false);
  const [activityActionRender, setActivityActionRender] = useState<any>();
  const [doSave, setDoSave] = useState(false);
  const [saveData, setSaveData] = useState<any>(props.saveData);

  useEffect(() => {
    if (!hasLoaded) {
      const data = { ...saveData };
      if (!data.activityAction) {
        data.activityAction = "knowledgeCheck";
        setSaveData(data);
      }

      setActivityActionRender(
        createActivityActionRender(ActivityActionToNameMap[data.activityAction])
      );
      setHasLoaded(true);
    }
  }, [hasLoaded, saveData]);

  const handleActivityAction = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: any
  ) => {
    if (!value) return;

    const data = { ...saveData };
    data.activityAction = value;
    setSaveData(data);
    setActivityActionRender(
      createActivityActionRender(ActivityActionToNameMap[value])
    );
  };

  const handleDataChange = (data: any) => {
    if (!data) return;

    const newObj = Object.assign(saveData, data);
    newObj.duration = getDuration(newObj);
    setSaveData(newObj);

    if (doSave) {
      setDoSave(false);
      handleSaveResume(null);
    }
  };

  const handleSave = (event: any) => {
    if (saveData.activityAction === "exitTicket") {
      // Tell upload files to save first. Then, we resume saving when it calls back
      setDoSave(true);
    } else {
      handleSaveResume(event);
    }
  };

  const handleSaveResume = (event: any) => {
    if (props.onSave) {
      props.onSave({ saveData, activityActionRender });
    }

    handleClose(event);
  };

  const handleClose = (event: any) => {
    if (props.onClose) {
      props.onClose(event);
    }
  };

  const getSlide = () => {
    const presentClasses = presentStyles({});

    return (
      <Present>
        <PresentTitleText
          value={ActivityToNameMap["Assess"]}
          colorClass="learnidy-green"
          icon={
            <DoneOutlineOutlinedIcon
              fontSize="large"
              className={clsx("learnidy-green-bg", presentClasses.titleIcon)}
            />
          }
        />
        <PresentSubtitleText
          value={ActivityActionToNameMap[props.saveData.activityAction]}
          colorClass="learnidy-green"
        />
        <PresentMinsText value={props.saveData.duration} />
        <PresentDescText
          value={getRoutineSlideDesc(props.saveData.activityAction)}
        />
        {props.saveData.activityAction === "knowledgeCheck" && (
          <KnowledgeCheckSlide
            saveData={props.saveData}
            subslideIndex={props.subslideIndex ? props.subslideIndex : 0}
            color={props.color}
          />
        )}
        {props.saveData.activityAction === "exitTicket" && (
          <ExitTicketSlide
            saveData={props.saveData}
            id={props.id}
            sessionPlanId={props.sessionPlanId}
            subslideIndex={props.subslideIndex ? props.subslideIndex : 0}
            color={props.color}
          />
        )}
        {props.saveData.activityAction === "trivia" && (
          <TriviaSlide
            saveData={props.saveData}
            subslideIndex={props.subslideIndex ? props.subslideIndex : 0}
            color={props.color}
          />
        )}
      </Present>
    );
  };

  return props.activityMode === ActivityMode.SlideShow ? (
    getSlide()
  ) : (
    <Dialog
      fullScreen={false}
      open={true}
      maxWidth={"lg"}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <div className={"activity-dialog"} style={{ width: 1280, height: 820 }}>
        <div className={"activity-form"}>
          <span className={clsx("activity-form-name", props.color)}>
            Assess
          </span>
          <div className={"activity-form-btn-group"}>
            <ToggleButtonGroup
              value={saveData.activityAction}
              exclusive={true}
              orientation="vertical"
              onChange={handleActivityAction}
              aria-label="activity action"
            >
              <ToggleButton
                value="knowledgeCheck"
                aria-label="knowledgeCheck"
                disabled={false}
                classes={{
                  selected: props.color + "-border",
                }}
              >
                <Typography
                  component="div"
                  variant="subtitle1"
                  className="activity-name"
                >
                  Knowledge Check
                </Typography>
                {saveData.activityAction === "knowledgeCheck" ? (
                  <Badge>
                    <CheckCircleIcon className={props.color} />
                  </Badge>
                ) : null}
                <NetworkCheckIcon
                  style={{ fontSize: 70 }}
                  classes={{ root: "learnidy-green" }}
                />
              </ToggleButton>
              <ToggleButton
                value="exitTicket"
                aria-label="exitTicket"
                classes={{
                  selected: props.color + "-border",
                }}
              >
                <Typography
                  component="span"
                  variant="subtitle1"
                  className="activity-name"
                >
                  Exit Ticket
                </Typography>
                {saveData.activityAction === "exitTicket" ? (
                  <Badge>
                    <CheckCircleIcon className={props.color} />
                  </Badge>
                ) : null}
                <ExitToAppIcon
                  style={{ fontSize: 70 }}
                  classes={{ root: "learnidy-green" }}
                />
              </ToggleButton>
              <ToggleButton
                value="trivia"
                aria-label="trivia"
                classes={{
                  selected: props.color + "-border",
                  disabled: "activity-btn-disabled",
                }}
                disabled={true}
              >
                <Typography
                  component="span"
                  variant="subtitle1"
                  className="activity-name"
                >
                  Trivia (Coming Soon)
                </Typography>
                {saveData.activityAction === "trivia" ? (
                  <Badge>
                    <CheckCircleIcon className={props.color} />
                  </Badge>
                ) : null}
                <LibraryBooksIcon
                  style={{ fontSize: 70 }}
                  classes={{ root: "learnidy-green" }}
                />
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
        <div className={"activity-form"}>
          <Button
            className={"activity-form-close"}
            variant="outlined"
            onClick={handleClose}
          >
            <CloseRoundedIcon fontSize="small" />
          </Button>
          {saveData.activityAction === "knowledgeCheck" && (
            <KnowledgeCheckForm
              saveData={saveData}
              subslideIndex={props.subslideIndex ? props.subslideIndex : 0}
              color={props.color}
              onChange={(data: any) => handleDataChange(data)}
            />
          )}
          {saveData.activityAction === "exitTicket" && (
            <ExitTicketForm
              saveData={saveData}
              id={props.id}
              sessionPlanId={props.sessionPlanId}
              subslideIndex={props.subslideIndex ? props.subslideIndex : 0}
              color={props.color}
              onChange={(data: any) => handleDataChange(data)}
              doSave={doSave}
            />
          )}
          {saveData.activityAction === "trivia" && (
            <TriviaForm
              saveData={saveData}
              onChange={(data: any) => handleDataChange(data)}
              color={props.color}
            />
          )}
          <div className={"warmup-footer"}>
            <Button variant="outlined" onClick={handleSave} disabled={doSave}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
