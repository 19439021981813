import React, { useState, useEffect } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  ActivityActionToResponseMap,
  ActivityActionToNameMap,
} from "../Activity/ActivityTypes";
import { SessionPlanModel } from "../../models/SessionPlanModel";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import { DateTime, DurationObject } from "luxon";
import { VictoryPie } from "victory";
// import "../../styles/styles.css";
import { ColorClassToHexMap } from "../../styles/GlobalStyles";

const useStyles = makeStyles((theme: Theme) => ({
  summaryRoot: {
    backgroundColor: theme.palette.background.paper,
  },
  summarySection: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    alignItems: "flex-start",
    rowGap: 10,
  },
  summarySectionItem: {
    width: "clamp(350px, 450px, 500px)",
  },
  tags: {
    display: "flex",
    flexWrap: "wrap",
    gap: 10,
    margin: "10px 0",
  },
  tag: {},
  addTagSection: {
    display: "flex",
    marginBottom: 40,
  },
  addTagText: {
    flexGrow: 1,
  },
  addTagBtn: {
    marginLeft: 10,
    backgroundColor: "#DFE8F4",
    color: "#737373",
  },
  actionBtnRow: {
    display: "flex",
    flexDirection: "column",
  },
  actionBtns: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  actionTagBtn: {
    marginLeft: 10,
    backgroundColor: "#DFE8F4",
    color: "#737373",
    width: "5em",
    height: "5em",
  },
  actionIcon: {
    fontSize: "2rem !important",
  },
  verticalBtnLabel: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "0.6rem",
  },
}));

const getHoursMinsTime = (duration: DurationObject) => {
  let result: string = "";
  if (duration.hours && duration.hours > 0) {
    result = duration.hours.toString() + " hour ";
    if (duration.minutes && duration.minutes > 0) result += " and ";
  }

  if (duration.minutes && duration.minutes > 0) {
    result += duration.minutes.toString();

    if (duration.minutes > 1) result += " minutes";
    else result += " minute";
  }

  return result;
};

const getActivityTime = (activities: any[]) => {
  let totTime: number = 0;
  for (let i = 0; i < activities.length; ++i) {
    if (activities[i] && activities[i].saveData)
      totTime += activities[i].saveData.duration;
  }

  const hours: number = Math.floor(totTime / 60);
  const mins: number = totTime % 60;
  let result: string = "";
  if (hours === 1) result += `${hours} hour and `;
  else if (hours > 1) result += `${hours} hours and `;

  if (mins === 1) result += `${mins} minute`;
  else result += `${mins} minutes`;

  return result;
};

const getTime = (
  activities: any[],
  startTime: DateTime,
  endTime: DateTime,
  sessionId: string | undefined
): any => {
  const duration: DurationObject = endTime
    .diff(startTime, ["hours", "minutes"])
    .toObject();

  return (
    <span>
      {"You have planned for "} <b>{getActivityTime(activities)}</b>{" "}
      {" of activity"}
      {sessionId && " in a " + getHoursMinsTime(duration) + " class session"}
    </span>
  );
};

const getFiles = (activities: any[]): string[] => {
  let files: string[] = [];
  for (let i = 0; i < activities.length; ++i) {
    const saveData: any = activities[i].saveData;
    if (!saveData || !saveData.uploadFiles) continue;

    files.push(...saveData.uploadFiles);
  }

  return files;
};

const getResponses = (activities: any[]): string[] => {
  let responses: string[] = [];
  for (let i = 0; i < activities.length; ++i) {
    const saveData: any = activities[i].saveData;
    if (!saveData || !saveData.activityAction) continue;

    const isResponse: boolean =
      ActivityActionToResponseMap[saveData.activityAction];
    if (isResponse)
      responses.push(ActivityActionToNameMap[saveData.activityAction]);
  }

  return responses;
};

const getActivityPieData = (plan: SessionPlanModel | undefined): [] => {
  if (!plan) return [];

  return plan.activities.reduce((accumulator, current, index) => {
    // Activity type percentage is total time per activity type over total time of all blocks
    const activity: any = accumulator.find(
      (element: any) => element.name === current.name
    );

    if (activity) {
      activity.y += current.saveData.duration;
    } else if (current.saveData && current.saveData.duration) {
      let duration =
        typeof current.saveData.duration === "string"
          ? parseInt(current.saveData.duration)
          : current.saveData.duration;
      accumulator.push({
        x: current.name,
        y: parseInt(duration), // current.saveData.duration
        name: current.name,
        color: current.color,
      });
    } else {
      // There is a problem! No duration time found
      return accumulator;
    }

    let totalActivityDuration: number = accumulator.reduce(
      (accum: any, curr: any) => {
        return accum + curr.y;
      },
      0
    );

    if (totalActivityDuration > 0) {
      for (let i = 0; i < accumulator.length; ++i) {
        let percentage: string = (
          (accumulator[i].y / totalActivityDuration) *
          100
        ).toPrecision(3);
        accumulator[i].x = accumulator[i].name + "\n" + percentage + "%";
      }
    }

    return accumulator;
  }, []);
};

const getActivityPieColor = (pieData: any): [] => {
  if (!pieData) return [];

  /*
  return pieData.reduce((accumulator, current: any, index) => {
    const activity: any = accumulator.find(
      (element: any) => element.x === current.name
    );
    if (!activity) {
      accumulator.push(current.color);
    }
    return accumulator;
  }, []);
  */

  let colors: any = [];
  for (let i = 0; i < pieData.length; ++i) {
    let color: string = pieData[i].color;
    let hexVal: string = ColorClassToHexMap[color];
    colors.push(hexVal);
  }

  return colors;
};

interface SessionReviewSummaryProps {
  sessionPlan?: SessionPlanModel;
  onPresent?: any;
}

export default function SessionReviewSummary(props: SessionReviewSummaryProps) {
  const classes = useStyles();
  const [timeStr, setTimeStr] = useState<any>();
  const [files, setFiles] = useState<string[]>([]);
  const [responses, setResponses] = useState<string[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [currentTag, setCurrentTag] = useState<string>("");
  const [activityPieData, setActivityPieData] = useState<[]>();
  const [activityPieColorScale, setActivityPieColorScale] = useState<[]>();

  useEffect(() => {
    if (
      props.sessionPlan &&
      props.sessionPlan.startTime &&
      props.sessionPlan.endTime
    )
      setTimeStr(
        getTime(
          props.sessionPlan.activities,
          props.sessionPlan.startTime,
          props.sessionPlan.endTime,
          props.sessionPlan.sessionId
        )
      );

    if (props.sessionPlan && props.sessionPlan.activities) {
      let pieData: [] = getActivityPieData(props.sessionPlan);
      setActivityPieData(pieData);
      setActivityPieColorScale(getActivityPieColor(pieData));
      setFiles(getFiles(props.sessionPlan.activities));
      setResponses(getResponses(props.sessionPlan.activities));
    }
  }, [props.sessionPlan]);

  const handleTagTextChange = (event: any) => {
    setCurrentTag(event.target.value);
  };

  const handleAddTag = (event: any) => {
    const newTags: string[] = [...tags];
    newTags.push(currentTag);
    setTags(newTags);
    setCurrentTag("");
  };

  const handleDeleteTag = (event: any, index: number) => {
    const newTags: string[] = [...tags];
    newTags.splice(index, 1);
    setTags(newTags);
  };

  const getNoActivityRender = () => {
    return (
      <Typography
        component="label"
        variant="subtitle1"
        className={classes.summarySectionItem}
      >
        Add activities to your lesson to see review data
      </Typography>
    );
  };

  const getWidgetRender = () => {
    return (
      <div className={classes.summaryRoot}>
        <div className={classes.summarySection}>
          <Typography
            component="label"
            variant="subtitle1"
            className={classes.summarySectionItem}
          >
            Here’s a quick review of the session plan you created!
          </Typography>
          <Typography
            component="span"
            variant="body1"
            className={classes.summarySectionItem}
          >
            {timeStr}
          </Typography>
          <div className={classes.summarySectionItem}>
            <Typography component="h6" variant="h6">
              Activity Usage
            </Typography>
            <VictoryPie
              radius={100}
              innerRadius={80}
              padAngle={1}
              data={activityPieData}
              colorScale={activityPieColorScale}
              style={{
                labels: {
                  fill: "black",
                  fontSize: 12,
                  fontWeight: "bold",
                  fontFamily: `Nunito, "Roboto", Helvetica, sans-serif`,
                },
              }}
            />
          </div>
          <div className={classes.summarySectionItem}>
            <Typography component="span" variant="body1">
              {"You have uploaded "}
              <b>
                {files.length}
                {files.length === 1 ? " file" : " files"}
              </b>
            </Typography>
            <ul>
              {files.map((file: any, index: number) => (
                <li key={file.name + index.toString()}>{file.name}</li>
              ))}
            </ul>
          </div>
          <div className={classes.summarySectionItem}>
            {responses.length > 0 ? (
              <React.Fragment>
                <Typography component="span" variant="body1">
                  {"Learners will be asked to submit "}
                  <b>
                    {responses.length}
                    {responses.length > 1 ? " responses" : " response"}
                  </b>
                  {" for:"}
                </Typography>
                <ul>
                  {responses.map((response: any, index: number) => (
                    <li key={response + index.toString()}>{response}</li>
                  ))}
                </ul>
              </React.Fragment>
            ) : (
              <Typography component="span" variant="body1">
                No responses are required to be submitted by Learners
              </Typography>
            )}
          </div>
          <div className={classes.summarySectionItem}>
            <Typography component="h6" variant="h6">
              Tag your session
            </Typography>
            <div className={classes.tags}>
              {tags.map((tag: string, index: number) => (
                <Chip
                  key={tag + index.toString()}
                  label={tag}
                  onDelete={(event: any) => handleDeleteTag(event, index)}
                  className={classes.tag}
                />
              ))}
            </div>
            <div className={classes.addTagSection}>
              <TextField
                label="Add a tag"
                variant="outlined"
                value={currentTag}
                size="small"
                onChange={handleTagTextChange}
                className={classes.addTagText}
              />
              <Button
                className={classes.addTagBtn}
                variant="contained"
                onClick={handleAddTag}
                disabled={
                  !currentTag ||
                  currentTag.length === 0 ||
                  tags.find((element) => element === currentTag) !== undefined
                }
              >
                <AddIcon fontSize="default" />
              </Button>
            </div>
          </div>
        </div>
        {/* <div className={classes.actionBtns}>
        <Button
          className={classes.actionTagBtn}
          classes={{ label: classes.verticalBtnLabel }}
          variant="contained"
          // onClick={handleAddTag}
          startIcon={
            <SendOutlinedIcon classes={{ root: classes.actionIcon }} />
          }
          disabled={true}
        >
          Share
        </Button>
        <Button
          className={classes.actionTagBtn}
          classes={{
            label: classes.verticalBtnLabel,
            startIcon: classes.actionIcon,
          }}
          variant="contained"
          // onClick={handleAddTag}
          startIcon={<SaveAltIcon classes={{ root: classes.actionIcon }} />}
          disabled={true}
        >
          Download
        </Button>
        <Button
          className={classes.actionTagBtn}
          classes={{ label: classes.verticalBtnLabel }}
          variant="contained"
          onClick={handlePresentClick}
          startIcon={
            <PlayCircleOutlineIcon classes={{ root: classes.actionIcon }} />
          }
        >
          Present
        </Button>
      </div> */}
      </div>
    );
  };

  if (!activityPieData || activityPieData.length === 0) {
    return getNoActivityRender();
  }

  return getWidgetRender();
}
