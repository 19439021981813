import React, { useState, useEffect } from "react";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import Filter1Icon from "@material-ui/icons/Filter1";
import Filter2Icon from "@material-ui/icons/Filter2";
import Filter3Icon from "@material-ui/icons/Filter3";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import BookOutlinedIcon from "@material-ui/icons/BookOutlined";
import {
  LargeText,
  PresentTitleText,
  PresentMinsText,
  PresentDescText,
  Present,
  PresentSubtitleText,
  presentStyles,
} from "../Presentation/Presentation";
import { createActivityActionRender } from "./Editing";
// import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import "../../styles/styles.css";
import {
  ActivityActionToNameMap,
  ActivityFormProps,
  ActivityMode,
  ActivityToNameMap,
} from "../Activity/ActivityTypes";
import clsx from "clsx";
import Duration from "./Duration/Duration";

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({})
// );

export const getRoutineDesc = (action: string) => {
  if (action === "3-2-1")
    return "When this routine is initiated, students will receive a prompt to write down a reflection on. You can create a template for them to fill out for their reflection.";
  if (action === "SIT")
    return "When this routine is initiated, students will receive a prompt to write down a reflection on about what they suprised, interested, or troubled them in the class. You can create a template for them to fill out for their reflection.";
  if (action === "kwl")
    return "When this routine is initiated, students will receive a prompt to write down a reflection on what they know, what they wonder, and what they learned. You can create a template for them to fill out for their reflection.";
  return "";
};

export default function ReflectForm(props: ActivityFormProps) {
  // const classes = useStyles();
  const presentClasses = presentStyles({});
  const [saveData, setSaveData] = useState<any>({
    activityAction:
      props.saveData && props.saveData.activityAction
        ? props.saveData.activityAction
        : "3-2-1",
    duration:
      props.saveData && props.saveData.duration ? props.saveData.duration : 10,
    prompt1:
      props.saveData && props.saveData.prompt1 ? props.saveData.prompt1 : "",
    prompt2:
      props.saveData && props.saveData.prompt2 ? props.saveData.prompt2 : "",
    prompt3:
      props.saveData && props.saveData.prompt3 ? props.saveData.prompt3 : "",
    surprising:
      props.saveData && props.saveData.surprising
        ? props.saveData.surprising
        : "",
    interesting:
      props.saveData && props.saveData.interesting
        ? props.saveData.interesting
        : "",
    troubling:
      props.saveData && props.saveData.troubling
        ? props.saveData.troubling
        : "",
  });

  const [activityActionRender, setActivityActionRender] = useState<any>({});
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!hasLoaded) {
      setActivityActionRender(
        createActivityActionRender(
          ActivityActionToNameMap[saveData.activityAction]
        )
      );
      setHasLoaded(true);
    }
  }, [hasLoaded, saveData.activityAction]);

  const handleActivityAction = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: any
  ) => {
    const data = { ...saveData };
    data.activityAction = value;
    setSaveData(data);
    setActivityActionRender(
      createActivityActionRender(ActivityActionToNameMap[value])
    );
  };

  const handleDurationChange = (val: string) => {
    handleInputChange("duration", val);
  };

  const handleInputChange = (key: string, value: any) => {
    const data = { ...saveData };
    data[key] = value;
    setSaveData(data);
  };

  const handleSave = (event: any) => {
    if (props.onSave) {
      props.onSave({ saveData, activityActionRender });
    }

    handleClose(event);
  };

  const handleClose = (event: any) => {
    if (props.onClose) {
      props.onClose(event);
    }
  };

  const getSlide = () => {
    const prompts: any =
      saveData.activityAction === "3-2-1"
        ? [
            { name: "prompt3", title: "3 Prompt:", value: saveData.prompt3 },
            { name: "prompt2", title: "2 Prompt:", value: saveData.prompt2 },
            { name: "prompt1", title: "1 Prompt:", value: saveData.prompt1 },
          ]
        : [
            {
              name: "surprising",
              title: "Surprising:",
              value: saveData.surprising,
            },
            {
              name: "interesting",
              title: "Interesting:",
              value: saveData.interesting,
            },
            {
              name: "troubling",
              title: "Troubling:",
              value: saveData.troubling,
            },
          ];

    return (
      <Present>
        <PresentTitleText
          value={ActivityToNameMap["Reflect"]}
          colorClass="learnidy-purple"
          icon={
            <BookOutlinedIcon
              fontSize="large"
              className={clsx("learnidy-purple-bg", presentClasses.titleIcon)}
            />
          }
        />
        <PresentSubtitleText
          value={ActivityActionToNameMap[saveData.activityAction]}
          colorClass="learnidy-purple"
        />
        <PresentMinsText value={saveData.duration} />
        <PresentDescText
          value={
            "Write down your responses to the following reflection questions."
          }
        />
        {(saveData.activityAction === "3-2-1" ||
          saveData.activityAction === "SIT") &&
          prompts.map((item: any, index: number) => {
            return (
              <LargeText
                key={"prompt" + index}
                title={item.title}
                value={item.value}
                backgroundColor="learnidy-purple-bg"
                isMultiLine={true}
                size={"medium"}
              />
            );
          })}
        {saveData.uploadFiles &&
          saveData.uploadFiles.map((file: any, index: number) => {
            return <li key={file.name}>{file.name}</li>;
          })}
      </Present>
    );
  };

  return props.activityMode === ActivityMode.SlideShow ? (
    getSlide()
  ) : (
    <Dialog
      fullScreen={false}
      open={true}
      maxWidth={"lg"}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <div className={"activity-dialog"} style={{ width: 1100, height: 640 }}>
        <div className={"activity-form"}>
          <span className={clsx("activity-form-name", props.color)}>
            Reflect
          </span>
          <p className={"activity-form-desc"}>
            Give prompts for learners to become aware of their own thinking
            processes.
          </p>
          <div className={"activity-form-btn-group"}>
            <ToggleButtonGroup
              value={saveData.activityAction}
              exclusive={true}
              orientation="vertical"
              onChange={handleActivityAction}
              aria-label="activity action"
            >
              <ToggleButton
                className={"toggle-button"}
                value="3-2-1"
                aria-label="3-2-1"
                classes={{
                  selected: props.color + "-border",
                }}
              >
                <Typography className={"toggle-button-title"} variant="h6">
                  {ActivityActionToNameMap["3-2-1"]}
                </Typography>
                {saveData.activityAction === "3-2-1" ? (
                  <Badge>
                    <CheckCircleIcon className={props.color} />
                  </Badge>
                ) : null}
                <div className={"toggle-button-body"}>
                  <Filter3Icon className={"learnidy-purple"} fontSize="large" />
                  <Filter2Icon className={"learnidy-purple"} fontSize="large" />
                  <Filter1Icon className={"learnidy-purple"} fontSize="large" />
                </div>
              </ToggleButton>
              <ToggleButton
                className={"toggle-button"}
                value="SIT"
                aria-label="SIT"
                disabled={false}
                classes={{
                  selected: props.color + "-border",
                }}
              >
                <Typography className={"toggle-button-title"} variant="h6">
                  Surprising, Interesting, Troubling (S.I.T.)
                </Typography>
                {saveData.activityAction === "SIT" ? (
                  <Badge>
                    <CheckCircleIcon className={props.color} />
                  </Badge>
                ) : null}
                <div className={"toggle-button-body"}>
                  <FlashOnIcon
                    className={"learnidy-purple learnidy-font-size-xxlarge"}
                  />
                </div>
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
        <div className={"activity-form"}>
          <Button
            className={"activity-form-close"}
            variant="outlined"
            onClick={handleClose}
          >
            <CloseRoundedIcon fontSize="small" />
          </Button>
          {saveData.activityAction === "3-2-1" ? (
            <React.Fragment>
              <span className={clsx("activity-form-name", props.color)}>
                {ActivityActionToNameMap["3-2-1"]}
              </span>
              <p className={"activity-form-desc"}>{getRoutineDesc("3-2-1")}</p>
              <div
                className={clsx("warmup-header-break", props.color + "-border")}
              />
              <div className={"warmup-duration"}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  className="warmup-tot-duration-text"
                >
                  Total Duration:
                </Typography>
                <Duration
                  time={saveData.duration}
                  onChange={handleDurationChange}
                />
              </div>
              <div className={"warmup-subsection"}>
                <div>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    className="warmup-tot-duration-text"
                  >
                    3 Prompt
                  </Typography>
                  <TextField
                    label="What are three things you learned?"
                    variant="filled"
                    value={saveData.prompt3}
                    onChange={(event) =>
                      handleInputChange("prompt3", event.target.value)
                    }
                  />
                </div>
                <div>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    className="warmup-tot-duration-text"
                  >
                    2 Prompt
                  </Typography>
                  <TextField
                    label="What are two things you found interesting?"
                    variant="filled"
                    value={saveData.prompt2}
                    onChange={(event) =>
                      handleInputChange("prompt2", event.target.value)
                    }
                  />
                </div>
                <div>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    className="warmup-tot-duration-text"
                  >
                    1 Prompt
                  </Typography>
                  <TextField
                    label="What is one question you have?"
                    variant="filled"
                    value={saveData.prompt1}
                    onChange={(event) =>
                      handleInputChange("prompt1", event.target.value)
                    }
                  />
                </div>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <span className={clsx("activity-form-name", props.color)}>
                {ActivityActionToNameMap["SIT"]}
              </span>
              <p className={"activity-form-desc"}>{getRoutineDesc("SIT")}</p>
              <div
                className={clsx("warmup-header-break", props.color + "-border")}
              />
              <div className={"warmup-duration"}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  className="warmup-tot-duration-text"
                >
                  Total Duration:
                </Typography>
                <Duration
                  time={saveData.duration}
                  onChange={handleDurationChange}
                />
              </div>
              <div className={"warmup-subsection"}>
                <div>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    className="warmup-tot-duration-text"
                  >
                    Surprising
                  </Typography>
                  <TextField
                    label="What is one thing you found surprising?"
                    variant="filled"
                    value={saveData.surprising}
                    onChange={(event) =>
                      handleInputChange("surprising", event.target.value)
                    }
                  />
                </div>
                <div>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    className="warmup-tot-duration-text"
                  >
                    Interesting
                  </Typography>
                  <TextField
                    label="What did you find interesting?"
                    variant="filled"
                    value={saveData.interesting}
                    onChange={(event) =>
                      handleInputChange("interesting", event.target.value)
                    }
                  />
                </div>
                <div>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    className="warmup-tot-duration-text"
                  >
                    Troubling
                  </Typography>
                  <TextField
                    label="What did you find troubling?"
                    variant="filled"
                    value={saveData.troubling}
                    onChange={(event) =>
                      handleInputChange("troubling", event.target.value)
                    }
                  />
                </div>
              </div>
            </React.Fragment>
          )}

          <div className={"warmup-footer"}>
            <Button variant="outlined" onClick={handleSave}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
