import React, { useState, useEffect } from "react";
import FishbowlForm, { FishbowlSlide } from "./FishbowlForm";
import ThinkPairShareForm, { ThinkPairShareSlide } from "./ThinkPairShareForm";
import FourCornersForm, { FourCornersSlide } from "./FourCorners";
import DebateForm, { DebateSlide } from "./DebateForm";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import ForumOutlinedIcon from "@material-ui/icons/ForumOutlined";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import debateImg from "../../../assets/debate.png";
import fishbowlImg from "../../../assets/fishbowl.png";
import fourCornersImg from "../../../assets/fourcorners.png";
import thinkPairShareImg from "../../../assets/thinkpairshare.png";
import breakoutGroupsImg from "../../../assets/breakoutgroups.png";
import "../../../styles/styles.css";
import BreakoutForm, { BreakoutSlide } from "./BreakoutForm";
import {
  Present,
  PresentMinsText,
  PresentSubtitleText,
  presentStyles,
  PresentTitleText,
} from "../../Presentation/Presentation";
import {
  ActivityActionToNameMap,
  ActivityFormProps,
  ActivityMode,
  ActivityToNameMap,
} from "../../../components/Activity/ActivityTypes";
import { createActivityActionRender } from "../Editing";
import clsx from "clsx";

const getDuration = (data: any): number => {
  switch (data.activityAction) {
    case "fishbowl":
      return data.fishbowlDuration;

    case "thinkPairShare":
      return data.thinkPairShareDuration;

    case "fourCorners":
      return data.fourCornersDuration;

    case "debate":
      return data.debateDuration;

    case "breakoutGroups":
      return data.breakoutGroupsDuration;

    default:
      return 10;
  }
};

export default function DiscussForm(props: ActivityFormProps) {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [activityActionRender, setActivityActionRender] = useState<any>();
  const [saveData, setSaveData] = useState<any>(props.saveData);
  //   {
  //     activityAction:
  //       props.saveData && props.saveData.activityAction
  //         ? props.saveData.activityAction
  //         : "thinkPairShare",
  //     duration: getDuration(props.saveData),
  //     thinkPrompt:
  //       props.saveData && props.saveData.thinkPrompt
  //         ? props.saveData.thinkPrompt
  //         : "",
  //   });

  useEffect(() => {
    if (!hasLoaded) {
      const data = { ...saveData };
      if (!data.activityAction) {
        data.activityAction = "fishbowl";
        setSaveData(data);
      }

      setActivityActionRender(
        createActivityActionRender(ActivityActionToNameMap[data.activityAction])
      );
      setHasLoaded(true);
    }
  }, [hasLoaded, saveData]);

  const handleActivityAction = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: any
  ) => {
    if (!value) return;

    const data = { ...saveData };
    data.activityAction = value;
    setSaveData(data);
    setActivityActionRender(
      createActivityActionRender(ActivityActionToNameMap[value])
    );
  };

  const handleDataChange = (data: any) => {
    if (!data) return;

    const newObj = Object.assign(saveData, data);
    newObj.duration = getDuration(newObj);
    setSaveData(newObj);
  };

  const handleSave = (event: any) => {
    if (props.onSave) {
      props.onSave({ saveData, activityActionRender });
    }

    handleClose(event);
  };

  const handleClose = (event: any) => {
    if (props.onClose) {
      props.onClose(event);
    }
  };

  const getSlide = () => {
    const presentClasses = presentStyles({});

    return (
      <Present>
        <PresentTitleText
          value={ActivityToNameMap["Discuss"]}
          colorClass={props.color}
          icon={
            <ForumOutlinedIcon
              fontSize="large"
              className={clsx(props.color + "-bg", presentClasses.titleIcon)}
            />
          }
        />
        <PresentSubtitleText
          value={ActivityActionToNameMap[props.saveData.activityAction]}
          colorClass={props.color}
        />
        <PresentMinsText value={props.saveData.duration} />
        {props.saveData.activityAction === "fishbowl" && (
          <FishbowlSlide saveData={props.saveData} color={props.color} />
        )}
        {props.saveData.activityAction === "thinkPairShare" && (
          <ThinkPairShareSlide
            saveData={props.saveData}
            subslideIndex={props.subslideIndex ? props.subslideIndex : 0}
            color={props.color}
          />
        )}
        {props.saveData.activityAction === "fourCorners" && (
          <FourCornersSlide saveData={props.saveData} color={props.color} />
        )}
        {props.saveData.activityAction === "debate" && (
          <DebateSlide saveData={props.saveData} color={props.color} />
        )}
        {props.saveData.activityAction === "breakoutGroups" && (
          <BreakoutSlide saveData={props.saveData} color={props.color} />
        )}
      </Present>
    );
  };

  return props.activityMode === ActivityMode.SlideShow ? (
    getSlide()
  ) : (
    <Dialog
      fullScreen={false}
      open={true}
      maxWidth={"lg"}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <div className={"activity-dialog"} style={{ width: 1280, height: 820 }}>
        <div className={"activity-form"}>
          <span className={clsx("activity-form-name", props.color)}>
            {ActivityToNameMap["Discuss"]}
          </span>
          <p className={"activity-form-desc"}>
            Spark conversations by creating breakout groups, debates, or have
            learners meet in pairs.
          </p>
          <div className={"activity-form-btn-group"}>
            <ToggleButtonGroup
              value={saveData.activityAction}
              exclusive={true}
              orientation="vertical"
              onChange={handleActivityAction}
              aria-label="activity action"
            >
              <ToggleButton
                value="fishbowl"
                aria-label="fishbowl"
                disabled={false}
                classes={{
                  selected: props.color + "-border",
                }}
              >
                <Typography
                  component="div"
                  variant="subtitle1"
                  className="activity-name"
                >
                  {ActivityActionToNameMap["fishbowl"]}
                </Typography>
                {saveData.activityAction === "fishbowl" ? (
                  <Badge>
                    <CheckCircleIcon className={props.color} />
                  </Badge>
                ) : null}
                <img src={fishbowlImg} alt="" />
              </ToggleButton>
              <ToggleButton
                value="thinkPairShare"
                aria-label="thinkPairShare"
                classes={{
                  selected: props.color + "-border",
                }}
              >
                <Typography
                  component="span"
                  variant="subtitle1"
                  className="activity-name"
                >
                  {ActivityActionToNameMap["thinkPairShare"]}
                </Typography>
                {saveData.activityAction === "thinkPairShare" ? (
                  <Badge>
                    <CheckCircleIcon className={props.color} />
                  </Badge>
                ) : null}
                <img src={thinkPairShareImg} alt="" />
              </ToggleButton>
              <ToggleButton
                value="fourCorners"
                aria-label="fourCorners"
                classes={{
                  selected: props.color + "-border",
                }}
              >
                <Typography
                  component="span"
                  variant="subtitle1"
                  className="activity-name"
                >
                  {ActivityActionToNameMap["fourCorners"]}
                </Typography>
                {saveData.activityAction === "fourCorners" ? (
                  <Badge>
                    <CheckCircleIcon className={props.color} />
                  </Badge>
                ) : null}
                <img src={fourCornersImg} alt="" />
              </ToggleButton>
              <ToggleButton
                value="debate"
                aria-label="debate"
                classes={{
                  selected: props.color + "-border",
                }}
              >
                <Typography
                  component="span"
                  variant="subtitle1"
                  className="activity-name"
                >
                  {ActivityActionToNameMap["debate"]}
                </Typography>
                {saveData.activityAction === "debate" ? (
                  <Badge>
                    <CheckCircleIcon className={props.color} />
                  </Badge>
                ) : null}
                <img src={debateImg} alt="" />
              </ToggleButton>
              <ToggleButton
                value="breakoutGroups"
                aria-label="breakoutGroups"
                classes={{
                  selected: props.color + "-border",
                }}
              >
                <Typography
                  component="span"
                  variant="subtitle1"
                  className="activity-name"
                >
                  {ActivityActionToNameMap["breakoutGroups"]}
                </Typography>
                {saveData.activityAction === "breakoutGroups" ? (
                  <Badge>
                    <CheckCircleIcon className={props.color} />
                  </Badge>
                ) : null}
                <img src={breakoutGroupsImg} alt="" />
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
        <div className={"activity-form"}>
          <Button
            className={"activity-form-close"}
            variant="outlined"
            onClick={handleClose}
          >
            <CloseRoundedIcon fontSize="small" />
          </Button>
          {saveData.activityAction === "fishbowl" && (
            <FishbowlForm
              saveData={saveData}
              onChange={(data: any) => handleDataChange(data)}
              color={props.color}
            />
          )}
          {saveData.activityAction === "thinkPairShare" && (
            <ThinkPairShareForm
              saveData={saveData}
              subslideIndex={props.subslideIndex ? props.subslideIndex : 0}
              onChange={(data: any) => handleDataChange(data)}
              color={props.color}
            />
          )}
          {saveData.activityAction === "fourCorners" && (
            <FourCornersForm
              saveData={saveData}
              onChange={(data: any) => handleDataChange(data)}
              color={props.color}
            />
          )}
          {saveData.activityAction === "debate" && (
            <DebateForm
              saveData={saveData}
              onChange={(data: any) => handleDataChange(data)}
              color={props.color}
            />
          )}
          {saveData.activityAction === "breakoutGroups" && (
            <BreakoutForm
              saveData={saveData}
              onChange={(data: any) => handleDataChange(data)}
              color={props.color}
            />
          )}

          <div className={"warmup-footer"}>
            <Button variant="outlined" onClick={handleSave}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
