import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
// import { grey } from "@material-ui/core/colors";

export const widgetColor: string = "#e0e0e0";
export const widgetBorderRadius: string = "6px";
export const widgetBorderStyle: string = `1px solid ${widgetColor}`;

export const widgetStyles = makeStyles((theme: Theme) =>
  createStyles({
    widgetRoot: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      backgroundColor: theme.palette.background.default,
      border: widgetBorderStyle,
      borderRadius: widgetBorderRadius,
    },
    header: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      backgroundColor: widgetColor,
      borderTopLeftRadius: widgetBorderRadius,
      borderTopRightRadius: widgetBorderRadius,
    },
    widgetBody: {
      width: "100%",
      textAlign: "left",
    },
    widgetTitle: {
      padding: "0 16px",
      display: "flex",
      flexGrow: 1,
      alignItems: "center",
      justifyContent: "space-between",
      fontWeight: "bold",
    },
    closeBtn: {},
    widgetCol: {
      display: "flex",
      flexDirection: "column",
      flexBasis: "100%",
      flex: 1,
      margin: "0 20px",
    },
    widgetField: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      marginBottom: 20,
    },
    widgetRow: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      marginBottom: 20,
      gap: 28,
    },
    widgetText: {
      fontWeight: "bold",
    },
    select: {
      minWidth: 140,
    },
    selectDisabled: {
      minWidth: 140,
      "& .Mui-disabled:before": {
        border: "hidden",
      },
      "&:before": {
        border: "hidden !important",
      },
      "& .MuiSelect-icon.Mui-disabled": {
        display: "none",
      },
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    multiItemRow: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      margin: "20px 0",
    },
    alignToEnd: {
      alignItems: "flex-end",
    },
  })
);
