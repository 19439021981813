import React from "react";
import {
  getDefaultSessionPlan,
  SessionPlanModel,
} from "../models/SessionPlanModel";

const defaultLesson = {
  sessionPlan: getDefaultSessionPlan(),
  updateSessionPlan: (plan: SessionPlanModel) => {},
};

export const LessonContext = React.createContext(defaultLesson);
export const LessonProvider = LessonContext.Provider;
export const LessonConsumer = LessonContext.Consumer;
