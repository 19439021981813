import React, { useState, useCallback, useEffect } from "react";
import { getActivityChild } from "../Activity/ActivityMaker";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
// import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
// import Sidebar from "../Sidebar/Sidebar";
// import { SessionModel } from "../Session/SessionModel";
import { SessionPlanModel } from "../../models/SessionPlanModel";
import { DateTime } from "luxon";
import { queryClass } from "../../firebase/firebase";
import { ActivityMode } from "../Activity/ActivityTypes";
import SlideShow from "../Presentation/SlideShow";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
    },
    sessionPresentContainer: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
      overflowY: "auto",
      marginBottom: 20,
    },
    sessionPlanTextfield: {
      marginBottom: 10,
    },
    sessionDrop: {
      display: "flex",
      flexDirection: "row",
      height: "100%",
      margin: "10px 0 0 0",
    },
    sessionPlanList: {
      flex: "1 1 auto",
      listStyleType: "none",
      overflow: "hidden",
      margin: "0",
      padding: "0",
      minWidth: 340,
    },
    sessionTimeSpan: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: 20,
    },
    sessionTimeStart: {
      margin: "0 20px 0 0",
    },
    sessionTimeEnd: {
      margin: "0 0 0 20px",
    },
    sessionPlanTimes: {
      margin: "0 10px",
      padding: 0,
      listStyleType: "none",
    },
    sessionPlanDragLabel: {
      height: 100,
      border: "1px dashed",
      fontSize: "1.4rem",
      width: "12em",
      padding: "0 2em",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    sessionPresent: {
      width: "100%",
      margin: 20,
    },
    presentList: {
      listStyle: "none",
      padding: 0,
    },
    slideShowContainer: {},
  })
);

interface SessionPlanPresentProps {
  activityMode: ActivityMode;
  sessionPlan: SessionPlanModel;
  onClose?: any;
}

export default function SessionPlanPresent(props: SessionPlanPresentProps) {
  const classes = useStyles();
  const [didLoad, setDidLoad] = useState(false);
  const [title, setClassTitle] = useState("");

  const loadClass = useCallback(() => {
    if (!props.sessionPlan?.classId) return;

    queryClass(props.sessionPlan.classId)
      .then((doc) => {
        if (doc.exists) {
          const data = doc.data();
          if (data) {
            if (data.title) setClassTitle(data.title);
          }
        } else {
          console.error("Could not load class from given Class ID");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [props]);

  useEffect(() => {
    if (!didLoad) {
      loadClass();

      setDidLoad(true);
    }
  }, [didLoad, loadClass]);

  const handleClose = (event: any) => {
    if (props.onClose) props.onClose(event);
  };

  const getSlideShow = () => {
    return <SlideShow sessionPlan={props.sessionPlan} onClose={handleClose} />;
  };

  const getPrintScreen = () => {
    return (
      <div className={classes.sessionPresent}>
        <Typography component="h5" variant="h5" className="title">
          {title}
        </Typography>
        <Typography component="label" variant="subtitle1" className="title">
          {props.sessionPlan?.startTime?.toLocaleString(DateTime.DATETIME_MED)}
        </Typography>
        <Typography
          component="h6"
          variant="h6"
          className={classes.sessionPlanTextfield}
        >
          {props.sessionPlan?.name}
        </Typography>

        {props.activityMode === ActivityMode.SlideShow && getSlideShow()}
        {props.activityMode === ActivityMode.Print && getPrintScreen()}
        <div className={classes.sessionPresentContainer}>
          <ul className={classes.presentList}>
            {props.sessionPlan &&
              props.sessionPlan.activities.map(
                (activity: any, index: number) => {
                  return (
                    <li key={activity.id}>
                      {getActivityChild(
                        activity.type,
                        activity,
                        props.sessionPlan.id,
                        props.activityMode
                      )}
                    </li>
                  );
                }
              )}
          </ul>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      {props.activityMode === ActivityMode.SlideShow && getSlideShow()}
      {props.activityMode === ActivityMode.Print && getPrintScreen()}
    </React.Fragment>
  );
}
