import React, { useState, useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import "../../../styles/styles.css";
import {
  LargeText,
  PresentCard,
  PresentCardGroup,
  PresentDescText,
} from "../../Presentation/Presentation";
import clsx from "clsx";
import Duration from "../Duration/Duration";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    instructionText: {
      color: theme.palette.primary.contrastText,
    },
  })
);

interface DebateProps {
  saveData: any;
  color: string;
  onChange?: any;
}

export function DebateSlide(props: DebateProps) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <PresentDescText
        value={`
          In groups of three, you will be assigned a topic to debate. One person
          is in support of the topic, one is against, and one acts as the judge.
          The judge, or “decision maker,” will create a list of questions to ask
          the advocates, which learners will use as their debate outline. Then the
          judge will decide at the end of the debate who the winner is.`}
      />
      <LargeText
        value={props.saveData.debateTopic}
        backgroundColor={props.color + "-bg"}
        isMultiLine={true}
      />
      <PresentCardGroup justify="center" align="center">
        <PresentCard
          value="In Support"
          color={props.color}
          titleSize="large"
          size="large"
          align="center"
          height="300px"
          flipContent={
            <Typography
              component="h6"
              variant="h6"
              className={classes.instructionText}
            >
              {props.saveData.supportStanceInstructions}
            </Typography>
          }
          caption="(Click for instructions)"
        />
        <PresentCard
          value="Against"
          color={props.color}
          titleSize="large"
          size="large"
          align="center"
          height="300px"
          flipContent={
            <Typography
              component="h6"
              variant="h6"
              className={classes.instructionText}
            >
              {props.saveData.againstStanceInstructions}
            </Typography>
          }
          caption="(Click for instructions)"
        />
        <PresentCard
          value="Judge"
          color={props.color}
          titleSize="large"
          size="large"
          align="center"
          height="300px"
          flipContent={
            <Typography
              component="h6"
              variant="h6"
              className={classes.instructionText}
            >
              {props.saveData.judgeInstructions}
            </Typography>
          }
          caption="(Click for instructions)"
        />
      </PresentCardGroup>
    </React.Fragment>
  );
}

export default function DebateForm(props: DebateProps) {
  // const classes = useStyles();
  const [hasLoaded, setHasLoaded] = useState(false);
  const [saveData, setSaveData] = useState<any>({
    debateDuration:
      props.saveData && props.saveData.debateDuration
        ? props.saveData.debateDuration
        : 10,
    debateTopic:
      props.saveData && props.saveData.debateTopic
        ? props.saveData.debateTopic
        : "",
    supportStanceInstructions:
      props.saveData && props.saveData.supportStanceInstructions
        ? props.saveData.supportStanceInstructions
        : "",
    againstStanceInstructions:
      props.saveData && props.saveData.againstStanceInstructions
        ? props.saveData.againstStanceInstructions
        : "",
    judgeInstructions:
      props.saveData && props.saveData.judgeInstructions
        ? props.saveData.judgeInstructions
        : "",
  });

  useEffect(() => {
    if (!hasLoaded) {
      if (props.onChange)
        props.onChange({ debateDuration: saveData.debateDuration });

      setHasLoaded(true);
    }
  }, [hasLoaded, saveData, props]);

  const handleDataChange = (key: string, value: any) => {
    if (!key) {
      return;
    }

    const data = { ...saveData };
    data[key] = value;
    setSaveData(data);

    if (props.onChange) {
      props.onChange({ [key]: value });
    }
  };

  const handleDurationChange = (val: string) => {
    handleDataChange("debateDuration", val);
  };

  return (
    <React.Fragment>
      <span className={clsx("activity-form-name", props.color)}>Debate</span>
      <p className={"activity-form-desc"}>
        Learners are placed into groups of three and assigned a topic to debate.
        One person is in support of the topic, one is against, and one acts as
        the judge. The judge, or “decision maker,” will create a list of
        questions to ask the advocates, which learners will use as their debate
        outline. Then the judge will decide at the end of the debate who the
        winner is. This can be done in front of the class or in groups.
      </p>
      <div className={clsx("warmup-header-break", props.color + "-border")} />
      <div className={"warmup-duration"}>
        <Typography
          component="span"
          variant="subtitle1"
          className="warmup-tot-duration-text"
        >
          Total Duration:
        </Typography>
        <Duration
          time={saveData.debateDuration}
          onChange={handleDurationChange}
        />
      </div>
      <div className={"warmup-subsection"}>
        <div className={"warmup-duration"}>
          <Typography
            component="span"
            variant="subtitle2"
            className="warmup-subtitle"
          >
            Debate Topic
          </Typography>
        </div>
        <textarea
          className={"warmup-subtext"}
          placeholder="What topic do you want learners to debate about?"
          rows={3}
          value={saveData.debateTopic}
          onChange={(event) =>
            handleDataChange("debateTopic", event.target.value)
          }
        />
      </div>
      <div className={"warmup-subsection"}>
        <div className={"warmup-duration"}>
          <Typography
            component="span"
            variant="subtitle2"
            className="warmup-subtitle"
          >
            “In Support” Stance Instructions
          </Typography>
        </div>
        <textarea
          className={"warmup-subtext"}
          placeholder="What instructions do you have for participants in support of the topic?"
          rows={3}
          value={saveData.supportStanceInstructions}
          onChange={(event) =>
            handleDataChange("supportStanceInstructions", event.target.value)
          }
        />
      </div>
      <div className={"warmup-subsection"}>
        <div className={"warmup-duration"}>
          <Typography
            component="span"
            variant="subtitle2"
            className="warmup-subtitle"
          >
            “Against” Stance Instructions
          </Typography>
        </div>
        <textarea
          className={"warmup-subtext"}
          placeholder="What instructions do you have for participants against the topic?"
          rows={3}
          value={saveData.againstStanceInstructions}
          onChange={(event) =>
            handleDataChange("againstStanceInstructions", event.target.value)
          }
        />
      </div>
      <div className={"warmup-subsection"}>
        <div className={"warmup-duration"}>
          <Typography
            component="span"
            variant="subtitle2"
            className="warmup-subtitle"
          >
            Judge Instructions
          </Typography>
        </div>
        <textarea
          className={"warmup-subtext"}
          placeholder="What instructions do you have for the judge role?"
          rows={3}
          value={saveData.judgeInstructions}
          onChange={(event) =>
            handleDataChange("judgeInstructions", event.target.value)
          }
        />
      </div>
    </React.Fragment>
  );
}
