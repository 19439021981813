import * as React from "react";
import Header from "../../components/Header/Header";
import SessionTabs from "../../components/SessionTabs/SessionTabs";

export default function Landing(props: any) {
  return (
    <React.Fragment>
      <Header {...props} />
      <SessionTabs {...props} />
    </React.Fragment>
  );
}
