import React, { useState, useEffect } from "react";
import UploadFiles from "../UploadFiles/UploadFiles";
import MediaViewer from "../Presentation/MediaViewer";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import AddAlarmOutlinedIcon from "@material-ui/icons/AddAlarmOutlined";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import WhatshotOutlinedIcon from "@material-ui/icons/WhatshotOutlined";
import ContactSupportOutlinedIcon from "@material-ui/icons/ContactSupportOutlined";
import {
  HeroText,
  LargeText,
  Present,
  PresentDescText,
  PresentMinsText,
  PresentSubtitleText,
  presentStyles,
  PresentTitleText,
} from "../Presentation/Presentation";
import { createActivityActionRender } from "./Editing";
// import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import "../../styles/styles.css";
import {
  ActivityActionToNameMap,
  ActivityFormProps,
  ActivityMode,
  ActivityToNameMap,
} from "../Activity/ActivityTypes";
import clsx from "clsx";
import Duration from "./Duration/Duration";
import { MediaType } from "../../constants/media";

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//   })
// );

export const getRoutineDesc = (action: string) => {
  if (action === "doNow") {
    return "Give learners a quick task or exercise to work on independently or collaboratively with minimal guidance. A 'Do Now' can be used to activate students learning for the lesson, surface prior knowledge, and familiarize students with lesson vocabulary.";
  }

  if (action === "question") {
    return "Ask learners a question and have them share their responses to the whole class.";
  }

  return "";
};

export const getRoutineSlideDesc = (saveData: any) => {
  if (!saveData || !saveData.activityAction) {
    console.error("No activity action selected");
    return "";
  }

  // Do Now
  if (saveData.activityAction === "doNow") {
    if (saveData.engagement === "independently") {
      return "Independently work on the quick task or exercise below.";
    }

    if (saveData.engagement === "collaboratively") {
      return "Collaboratively work on the quick task or exercise below.";
    }
  }

  // Question
  if (saveData.activityAction === "question") {
    let desc: string =
      "Answer the question below and get ready to share your response to the whole class. ";

    if (saveData.sharingProtocol === "whiparound") {
      desc += "This is a Whip Around routine.";
    }

    if (saveData.sharingProtocol === "wordCloud") {
      desc += "This is a Word Cloud routine.";
    }

    if (saveData.sharingProtocol === "coldCall") {
      desc += "This is a Cold Call routine.";
    }

    if (saveData.sharingProtocol === "volunteers") {
      desc += "This is a Volunteers routine.";
    }

    return desc;
  }

  return "";
};

export default function WarmUpForm(props: ActivityFormProps) {
  // const classes = useStyles();
  const presentClasses = presentStyles({});
  const [didLoad, setDidLoad] = useState(false);
  const [activityActionRender, setActivityActionRender] = useState<any>({});
  const [doSave, setDoSave] = useState(false);
  const [saveData, setSaveData] = useState({
    activityAction:
      props.saveData && props.saveData.activityAction
        ? props.saveData.activityAction
        : "doNow",
    duration:
      props.saveData && props.saveData.duration ? props.saveData.duration : 10,
    question:
      props.saveData && props.saveData.question ? props.saveData.question : "",
    sharingProtocol:
      props.saveData && props.saveData.sharingProtocol
        ? props.saveData.sharingProtocol
        : "whiparound",
    doNow: props.saveData && props.saveData.doNow ? props.saveData.doNow : "",
    engagement:
      props.saveData && props.saveData.engagement
        ? props.saveData.engagement
        : "independently",
    uploadFiles:
      props.saveData && props.saveData.uploadFiles
        ? props.saveData.uploadFiles
        : [],
  });

  useEffect(() => {
    if (!didLoad) {
      setActivityActionRender(
        createActivityActionRender(
          ActivityActionToNameMap[saveData.activityAction]
        )
      );
      setDidLoad(true);
    }
  }, [didLoad, saveData]);

  const handleActivityAction = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: any
  ) => {
    if (!value) {
      return;
    }

    const data = { ...saveData };
    data.activityAction = value;
    setSaveData(data);
    setActivityActionRender(
      createActivityActionRender(ActivityActionToNameMap[value])
    );
  };

  const handleDurationChange = (val: string) => {
    setSaveData({ ...saveData, duration: val });
  };

  const handleQuestionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const data = { ...saveData };
    data.question = event.target.value;
    setSaveData(data);
  };

  const handleDoNowChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const data = { ...saveData };
    data.doNow = event.target.value;
    setSaveData(data);
  };

  const handleSharingProtocol = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: any
  ) => {
    if (!value) {
      return;
    }
    const data = { ...saveData };
    data.sharingProtocol = value;
    setSaveData(data);
  };

  const handleEngagement = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: any
  ) => {
    if (!value) {
      return;
    }
    const data = { ...saveData };
    data.engagement = value;
    setSaveData(data);
  };

  const handleUploadFiles = (files: any) => {
    const data = { ...saveData };
    data.uploadFiles = [...files];
    setSaveData(data);

    if (doSave) {
      setDoSave(false);
      handleSaveResume(null);
    }
  };

  const handleSave = (event: any) => {
    // Tell upload files to save first. Then, we resume saving when it calls back
    setDoSave(true);

    if (saveData.activityAction === "doNow") {
      // Tell upload files to save first. Then, we resume saving when it calls back
      setDoSave(true);
    } else {
      handleSaveResume(event);
    }
  };

  const handleSaveResume = (event: any) => {
    if (props.onSave) {
      props.onSave({ saveData, activityActionRender });
    }

    handleClose(event);
  };

  const handleClose = (event: any) => {
    if (props.onClose) {
      props.onClose(event);
    }
  };

  const getQuestionSlide = () => {
    return (
      <React.Fragment>
        <LargeText
          value={saveData.question}
          backgroundColor="learnidy-cinnabar-bg"
        />
      </React.Fragment>
    );
  };

  const getDoNowSlide = () => {
    return (
      <React.Fragment>
        <HeroText
          value={saveData.doNow}
          backgroundColor="learnidy-cinnabar-bg"
        />
        <MediaViewer uploadFiles={props.saveData.uploadFiles} />
      </React.Fragment>
    );
  };

  const getSlide = () => {
    return (
      <Present>
        <PresentTitleText
          value={ActivityToNameMap["Warmup"]}
          colorClass={"learnidy-cinnabar"}
          icon={
            <WhatshotOutlinedIcon
              fontSize="large"
              className={clsx("learnidy-cinnabar-bg", presentClasses.titleIcon)}
            />
          }
        />
        <PresentSubtitleText
          value={ActivityActionToNameMap[saveData.activityAction]}
          colorClass="learnidy-cinnabar"
        />
        <PresentMinsText value={saveData.duration} />
        <PresentDescText value={getRoutineSlideDesc(saveData)} />
        {saveData.activityAction === "doNow" && getDoNowSlide()}
        {saveData.activityAction === "question" && getQuestionSlide()}
      </Present>
    );
  };

  const getEditDialog = () => {
    return (
      <Dialog
        fullScreen={false}
        open={true}
        maxWidth={"lg"}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div className={"activity-dialog"} style={{ width: 1280, height: 720 }}>
          <div className={"activity-form"}>
            <span className={clsx("activity-form-name", props.color)}>
              {ActivityToNameMap["Warmup"]}
            </span>
            <p className={"activity-form-desc"}>
              Engage the class with a short energy boosting activity or
              introduce a concept.
            </p>
            <div className={"activity-form-btn-group"}>
              <ToggleButtonGroup
                value={saveData.activityAction}
                exclusive={true}
                orientation="vertical"
                onChange={handleActivityAction}
                aria-label="activity action"
              >
                <ToggleButton
                  value="doNow"
                  aria-label="doNow"
                  classes={{
                    selected: props.color + "-border",
                  }}
                >
                  <Typography
                    component="span"
                    variant="subtitle1"
                    className="activity-name"
                  >
                    {ActivityActionToNameMap["doNow"]}
                  </Typography>
                  {saveData.activityAction === "doNow" ? (
                    <Badge>
                      <CheckCircleIcon className={props.color} />
                    </Badge>
                  ) : null}
                  <AddAlarmOutlinedIcon
                    className={clsx("warmup-icon", props.color)}
                  />
                </ToggleButton>
                <ToggleButton
                  value="question"
                  aria-label="question"
                  classes={{
                    selected: props.color + "-border",
                  }}
                >
                  <Typography
                    component="span"
                    variant="subtitle1"
                    className="activity-name"
                  >
                    {ActivityActionToNameMap["question"]}
                  </Typography>
                  {saveData.activityAction === "question" ? (
                    <Badge>
                      <CheckCircleIcon className={props.color} />
                    </Badge>
                  ) : null}
                  <ContactSupportOutlinedIcon
                    className={clsx("warmup-icon", props.color)}
                  />
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
          <div className={"activity-form"}>
            <Button
              className={"activity-form-close"}
              variant="outlined"
              onClick={handleClose}
            >
              <CloseRoundedIcon fontSize="small" />
            </Button>
            {saveData.activityAction === "doNow" ? (
              <React.Fragment>
                <span className={clsx("activity-form-name", props.color)}>
                  {ActivityActionToNameMap["doNow"]}
                </span>
                <p className={"activity-form-desc"}>
                  {getRoutineDesc("doNow")}
                </p>
                <div
                  className={clsx(
                    "warmup-header-break",
                    props.color + "-border"
                  )}
                />
                <div className={"warmup-duration"}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    className="warmup-tot-duration-text"
                  >
                    Total Duration:
                  </Typography>
                  <Duration
                    time={saveData.duration}
                    onChange={handleDurationChange}
                  />
                </div>
                <div className={"warmup-subsection"}>
                  <Typography
                    component="span"
                    variant="subtitle2"
                    className="warmup-subtitle"
                  >
                    Do Now Prompt<span style={{ color: "red" }}>*</span>:
                  </Typography>
                  <textarea
                    className={"warmup-subtext"}
                    placeholder="What is the task or exercise you want learners to work on or think about now?"
                    rows={3}
                    value={saveData.doNow}
                    onChange={handleDoNowChange}
                  />
                </div>
                <div className={"warmup-subsection"}>
                  <Typography
                    component="span"
                    variant="subtitle2"
                    className="warmup-subtitle"
                  >
                    Choose an engagement option
                  </Typography>
                  <Typography
                    component="span"
                    variant="body1"
                    className={"warmup-subtext"}
                  >
                    How do you want learners to engage with the Do Now?
                  </Typography>
                </div>
                <div className={"warmup-grid"}>
                  <ToggleButtonGroup
                    value={saveData.engagement}
                    exclusive={true}
                    onChange={handleEngagement}
                    aria-label="sharing-protocol"
                  >
                    <ToggleButton
                      value="independently"
                      aria-label="independently"
                      classes={{
                        selected: props.color + "-border",
                      }}
                    >
                      Independently
                    </ToggleButton>
                    <ToggleButton
                      value="collaboratively"
                      aria-label="collaboratively"
                      classes={{
                        selected: props.color + "-border",
                      }}
                    >
                      Collaboratively
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
                <UploadFiles
                  uploadFiles={saveData.uploadFiles}
                  mediaType={MediaType.Any}
                  sessionPlanId={props.sessionPlanId}
                  activityId={props.id}
                  onChange={handleUploadFiles}
                  doUpload={doSave}
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <span className={clsx("activity-form-name", props.color)}>
                  {ActivityActionToNameMap["question"]}
                </span>
                <p className={"activity-form-desc"}>
                  {getRoutineDesc("question")}
                </p>
                <div
                  className={clsx(
                    "warmup-header-break",
                    props.color + "-border"
                  )}
                />
                <div className={"warmup-duration"}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    className="warmup-tot-duration-text"
                  >
                    Total Duration:
                  </Typography>
                  <Duration
                    time={saveData.duration}
                    onChange={handleDurationChange}
                  />
                </div>
                <div className={"warmup-subsection"}>
                  <Typography
                    component="span"
                    variant="subtitle2"
                    className="warmup-subtitle"
                  >
                    Question Prompt<span style={{ color: "red" }}>*</span>:
                  </Typography>
                  <textarea
                    className={"warmup-subtext"}
                    placeholder="What question do you want to share with learners? This can be an Icebreaker question for learners to get to know each other or a subject specific question to set up the class for a concept introduction."
                    rows={3}
                    value={saveData.question}
                    onChange={handleQuestionChange}
                  />
                </div>
                <div className={"warmup-subsection"}>
                  <Typography
                    component="span"
                    variant="subtitle2"
                    className="warmup-subtitle"
                  >
                    Sharing Protocol
                  </Typography>
                  <Typography
                    component="span"
                    variant="body1"
                    className={"warmup-subtext"}
                  >
                    How do you want learners to share their answers with the
                    group?
                  </Typography>
                </div>
                <div className={"warmup-grid"}>
                  <ToggleButtonGroup
                    value={saveData.sharingProtocol}
                    exclusive={true}
                    onChange={handleSharingProtocol}
                    aria-label="sharing-protocol"
                  >
                    <ToggleButton
                      value="whiparound"
                      aria-label="whiparound"
                      classes={{
                        selected: props.color + "-border",
                      }}
                    >
                      Whiparound
                    </ToggleButton>
                    <ToggleButton
                      value="wordCloud"
                      aria-label="wordCloud"
                      classes={{
                        selected: props.color + "-border",
                      }}
                    >
                      Word Cloud
                    </ToggleButton>
                    <ToggleButton
                      value="coldCall"
                      aria-label="coldCall"
                      classes={{
                        selected: props.color + "-border",
                      }}
                    >
                      Cold Call
                    </ToggleButton>
                    <ToggleButton
                      value="volunteers"
                      aria-label="volunteers"
                      classes={{
                        selected: props.color + "-border",
                      }}
                    >
                      Volunteers
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </React.Fragment>
            )}
            <div className={"warmup-footer"}>
              <Button variant="outlined" onClick={handleSave} disabled={doSave}>
                Save
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    );
  };

  if (props.activityMode === ActivityMode.SlideShow) {
    return getSlide();
  } else {
    return getEditDialog();
  }
}
