// import React from 'react';
import Activity /*, { calculateHeight }*/ from "./Activity";
import DiscussForm from "../ActivityForms/DiscussForm/DiscussForm";
import ReadWatchListenForm from "../ActivityForms/ReadWatchListenForm";
import ReflectForm from "../ActivityForms/ReflectForm";
import WarmUpForm from "../ActivityForms/WarmUpForm";
import PracticeForm from "../ActivityForms/PracticeForm/PracticeForm";
import CollaborateForm from "../ActivityForms/CollaborateForm/CollaborateForm";
import AssessForm from "../ActivityForms/Assess/AssessForm";
import InvestigateForm from "../ActivityForms/InvestigateForm/InvestigateForm";
import CustomForm from "../ActivityForms/CustomForm";
import BreakForm from "../ActivityForms/BreakForm";
import {
  ACTIVITY_DEFAULT_DURATION,
  // ACTIVITY_PLACE_INTERVAL,
} from "../../constants/session";
import { v4 as uuidv4 } from "uuid";
import BookOutlinedIcon from "@material-ui/icons/BookOutlined";
import DoneOutlineOutlinedIcon from "@material-ui/icons/DoneOutlineOutlined";
import ForumOutlinedIcon from "@material-ui/icons/ForumOutlined";
import ImageSearchOutlinedIcon from "@material-ui/icons/ImageSearchOutlined";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import ReplayOutlinedIcon from "@material-ui/icons/ReplayOutlined";
import StarBorderOutlinedIcon from "@material-ui/icons/StarBorderOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import WhatshotOutlinedIcon from "@material-ui/icons/WhatshotOutlined";
import BatteryChargingFullIcon from "@material-ui/icons/BatteryChargingFull";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import {
  ActivityActionToNameMap,
  ActivityMode,
  ActivityTypes,
} from "./ActivityTypes";
import { createActivityActionRender } from "../ActivityForms/Editing";

export function makeActivity(
  activityType: ActivityTypes,
  sessionPlanId: string,
  isRender: boolean = true,
  baseActivity?: any
): any {
  const id: string =
    baseActivity && baseActivity.id ? baseActivity.id : uuidv4();
  const activeSession: boolean =
    baseActivity && baseActivity.activeSession
      ? baseActivity.activeSession
      : false;
  const onSave: any =
    baseActivity && baseActivity.onSave ? baseActivity.onSave : undefined;
  const onDelete: any =
    baseActivity && baseActivity.onDelete ? baseActivity.onDelete : undefined;
  const onShowEditForm: any =
    baseActivity && baseActivity.onShowEditForm
      ? baseActivity.onShowEditForm
      : undefined;
  const saveData: any =
    baseActivity && baseActivity.saveData
      ? { ...baseActivity.saveData, sessionPlanId }
      : {
          duration: ACTIVITY_DEFAULT_DURATION,
          sessionPlanId,
        };

  let name: string = "";
  let description: string = "";
  let icon: any;
  let color: string = "";

  switch (activityType) {
    case ActivityTypes.WarmUp:
      name = "Warm Up";
      description =
        "Engage the class with a short energy boosting activity or introduce a concept.";
      icon = <WhatshotOutlinedIcon fontSize="large" />;
      color = "learnidy-cinnabar";
      break;

    case ActivityTypes.ReadWatchListen:
      name = "Read, Watch, Listen";
      description =
        "Share video, give a presentation, review images, or read text.";
      icon = <VisibilityOutlinedIcon fontSize="large" />;
      color = "learnidy-pink";
      break;

    case ActivityTypes.Reflect:
      name = "Reflect";
      description =
        "Give prompts for learners to become aware of their own thinking process.";
      icon = <BookOutlinedIcon fontSize="large" />;
      color = "learnidy-purple";
      break;

    case ActivityTypes.Discuss:
      name = "Discuss";
      description =
        "Spark conversations with breakout groups, debate, pairs, etc.";
      icon = <ForumOutlinedIcon fontSize="large" />;
      color = "learnidy-lochmara";
      break;

    case ActivityTypes.Collaborate:
      name = "Collaborate";
      description =
        "Offer opportunities for learners to work together on projects.";
      icon = <PeopleAltOutlinedIcon fontSize="large" />;
      color = "learnidy-teal";
      break;

    case ActivityTypes.Assess:
      name = "Assess";
      description =
        "Create and distribute knowledge checks, quizzes, and polls.";
      icon = <DoneOutlineOutlinedIcon fontSize="large" />;
      color = "learnidy-green";
      break;

    case ActivityTypes.Practice:
      name = "Practice";
      description =
        "Learners adapt their actions to the task and use peer feedback to improve.";
      icon = <ReplayOutlinedIcon fontSize="large" />;
      color = "learnidy-orange";
      break;

    case ActivityTypes.Investigate:
      name = "Investigate";
      description =
        "Guide learners to explore, compare, and critique concepts and ideas.";
      icon = <ImageSearchOutlinedIcon fontSize="large" />;
      color = "learnidy-red";
      break;

    case ActivityTypes.Evaluate:
      name = "Evaluate";
      description =
        "Check in on how learners are doing with feedback and peer reviews.";
      icon = <StarBorderOutlinedIcon fontSize="large" />;
      color = "learnidy-yellow";
      break;

    case ActivityTypes.Break:
      name = "Break";
      description = "Take a moment to pause and recharge.";
      icon = <BatteryChargingFullIcon fontSize="large" />;
      color = "learnidy-fiord";
      break;

    case ActivityTypes.Custom:
      name = "Custom";
      description = "Create a new learning activity.";
      icon = <AddBoxOutlinedIcon fontSize="large" />;
      color = "learnidy-grey";
      break;

    default:
      break;
  }

  const activity: object = {
    activeSession,
    color,
    description,
    icon,
    id,
    name,
    onSave,
    onDelete,
    onShowEditForm,
    saveData,
    type: activityType,
  };

  if (isRender) {
    return makeActivityRender(activityType, activity, sessionPlanId);
  } else {
    return activity;
  }
}

export function makeActivityRender(
  activityType: ActivityTypes,
  activity: any,
  sessionPlanId: string
): any {
  let activityActionRender = createActivityActionRender(
    ActivityActionToNameMap[activity.saveData?.activityAction]
  );

  return (
    <Activity
      key={activity.id}
      id={activity.id}
      type={activity.type}
      name={activity.name}
      description={activity.description}
      icon={activity.icon}
      color={activity.color}
      activeSession={activity.activeSession}
      onSave={activity.onSave}
      onDelete={activity.onDelete}
      onShowEditForm={activity.onShowEditForm}
      saveData={activity.saveData}
      activityActionRender={activityActionRender}
    >
      {getActivityChild(activityType, activity, sessionPlanId)}
    </Activity>
  );
}

export function getActivityChild(
  activityType: ActivityTypes,
  activity: any,
  sessionPlanId: string,
  activityMode: ActivityMode = ActivityMode.Edit,
  subslideIndex: number = 0
): any {
  switch (activityType) {
    case ActivityTypes.WarmUp:
      return (
        <WarmUpForm
          saveData={activity.saveData}
          activityMode={activityMode}
          id={activity.id}
          sessionPlanId={sessionPlanId}
          color={activity.color}
        />
      );

    case ActivityTypes.ReadWatchListen:
      return (
        <ReadWatchListenForm
          saveData={activity.saveData}
          activityMode={activityMode}
          id={activity.id}
          sessionPlanId={sessionPlanId}
          color={activity.color}
        />
      );

    case ActivityTypes.Reflect:
      return (
        <ReflectForm
          saveData={activity.saveData}
          activityMode={activityMode}
          id={activity.id}
          sessionPlanId={sessionPlanId}
          color={activity.color}
        />
      );

    case ActivityTypes.Discuss:
      return (
        <DiscussForm
          saveData={activity.saveData}
          activityMode={activityMode}
          subslideIndex={subslideIndex}
          id={activity.id}
          sessionPlanId={sessionPlanId}
          color={activity.color}
        />
      );

    case ActivityTypes.Collaborate:
      return (
        <CollaborateForm
          saveData={activity.saveData}
          activityMode={activityMode}
          subslideIndex={subslideIndex}
          id={activity.id}
          sessionPlanId={sessionPlanId}
          color={activity.color}
        />
      );

    case ActivityTypes.Assess:
      return (
        <AssessForm
          saveData={activity.saveData}
          activityMode={activityMode}
          subslideIndex={subslideIndex}
          id={activity.id}
          sessionPlanId={sessionPlanId}
          color={activity.color}
        />
      );

    case ActivityTypes.Practice:
      return (
        <PracticeForm
          saveData={activity.saveData}
          activityMode={activityMode}
          subslideIndex={subslideIndex}
          id={activity.id}
          sessionPlanId={sessionPlanId}
          color={activity.color}
        />
      );

    case ActivityTypes.Investigate:
      return (
        <InvestigateForm
          saveData={activity.saveData}
          activityMode={activityMode}
          subslideIndex={subslideIndex}
          id={activity.id}
          sessionPlanId={sessionPlanId}
          color={activity.color}
        />
      );

    case ActivityTypes.Break:
      return (
        <BreakForm
          saveData={activity.saveData}
          activityMode={activityMode}
          subslideIndex={subslideIndex}
          id={activity.id}
          sessionPlanId={sessionPlanId}
          color={activity.color}
        />
      );

    case ActivityTypes.Custom:
      return (
        <CustomForm
          saveData={activity.saveData}
          activityMode={activityMode}
          id={activity.id}
          sessionPlanId={sessionPlanId}
          color={activity.color}
        />
      );

    case ActivityTypes.Placeholder:
      return null;

    default:
      return null;
  }
}

export function makeDefaultActivities(sessionPlanId: string): any[] {
  const activities = [
    makeActivity(ActivityTypes.WarmUp, sessionPlanId, false, null),
    makeActivity(ActivityTypes.ReadWatchListen, sessionPlanId, false, null),
    makeActivity(ActivityTypes.Reflect, sessionPlanId, false, null),
    makeActivity(ActivityTypes.Discuss, sessionPlanId, false, null),
    makeActivity(ActivityTypes.Collaborate, sessionPlanId, false, null),
    makeActivity(ActivityTypes.Assess, sessionPlanId, false, null),
    // makeActivity(ActivityTypes.Practice, sessionPlanId, false, null),
    // makeActivity(ActivityTypes.Investigate, sessionPlanId, false, null),
    // makeActivity(ActivityTypes.Evaluate, sessionPlanId, false, null),
    makeActivity(ActivityTypes.Break, sessionPlanId, false, null),
    makeActivity(ActivityTypes.Custom, sessionPlanId, false, null),
  ];

  return activities;
}

export function createPlaceholderActivities(count: number): any[] {
  const result: any[] = [];
  for (let i = 0; i < count; ++i) {
    const activity: any = makeActivity(ActivityTypes.Placeholder, "", false);
    result.push(activity);
  }

  return result;
}
