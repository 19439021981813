export enum DayOfWeek {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

export interface CalendarEvent {
  id?: string | undefined;
  title?: string | null;
  allDay?: boolean;
  start: Date | null;
  end: Date | null;
  seriesStart: Date | null;
  seriesEnd: Date | null;
  seriesId?: string;
  seriesType?: string;
  seriesCheckedDays: any;
  sessionPlanId?: string | null;
  classId?: string | null;
}

export const getDefaultSessionEvent = (props: any = null): CalendarEvent => {
  return {
    id: props && props.id ? props.id : null,
    title: props && props.title ? props.title : "",
    allDay: props && props.allDay ? props.allDay : false,
    start:
      props.start && props.start instanceof Date
        ? props.start.toISOString()
        : null,
    end:
      props.end && props.end instanceof Date ? props.end.toISOString() : null,
    seriesStart:
      props.seriesStart && props.seriesStart instanceof Date
        ? props.seriesStart.toISOString()
        : null,
    seriesEnd:
      props.seriesEnd && props.seriesEnd instanceof Date
        ? props.seriesEnd.toISOString()
        : null,
    seriesId: props && props.seriesId ? props.seriesId : null,
    seriesType: props && props.seriesType ? props.seriesType : undefined,
    seriesCheckedDays: undefined,
    sessionPlanId: props && props.sessionPlanId ? props.sessionPlanId : null,
    classId: props && props.classId ? props.classId : null,
  };
};

export const getSaveCalendarEvent = (event: CalendarEvent): any => {
  const data: any = { ...event };
  if (!data.sessionPlanId) data.sessionPlanId = null;
  if (!data.classId) data.classId = null;

  data.start =
    event.start && isNaN(event.start.getTime()) === false
      ? event.start.toISOString()
      : null;
  data.end =
    event.end && isNaN(event.end.getTime()) === false
      ? event.end.toISOString()
      : null;
  data.seriesStart =
    event.seriesStart && isNaN(event.seriesStart.getTime()) === false
      ? event.seriesStart.toISOString()
      : null;
  data.seriesEnd =
    event.seriesEnd && isNaN(event.seriesEnd.getTime()) === false
      ? event.seriesEnd.toISOString()
      : null;

  return data;
};

export const loadCalendarEvent = (data: any): CalendarEvent => {
  let event: CalendarEvent = {
    id: data.id,
    title: data.title,
    allDay: data.allDay,
    start: data.start ? new Date(data.start) : null,
    end: data.end ? new Date(data.end) : null,
    seriesStart: data.seriesStart ? new Date(data.seriesStart) : null,
    seriesEnd: data.seriesEnd ? new Date(data.seriesEnd) : null,
    seriesId: data.seriesId,
    seriesType: data.seriesType,
    seriesCheckedDays: data.seriesCheckedDays
      ? { ...data.seriesCheckedDays }
      : {
          Sunday: false,
          Monday: false,
          Tuesday: false,
          Wednesday: false,
          Thursday: false,
          Friday: false,
          Saturday: false,
        },
    sessionPlanId: data.sessionPlanId ? data.sessionPlanId : null,
    classId: data.classId ? data.classId : null,
  };

  return event;
};
