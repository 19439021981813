import React, { useState, useContext, useEffect } from "react";
import AccountCircle from "@material-ui/icons/AccountCircle";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {
  fade,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { SIGN_IN_ROUTE } from "../../constants/routes";
import { Link, useHistory } from "react-router-dom";
import { auth, signOut } from "../../firebase/firebase";
import "./Header.css";
import "../../styles/styles.css";
import logo from "./learnidy-logo2.png";
import { Button } from "@material-ui/core";
import { HOME_ROUTE, SIGN_UP_ROUTE } from "../../constants/routes";
import {} from "@material-ui/core/colors";
import { UserContext } from "../../hooks/useUser";
import { grey } from "@material-ui/core/colors";
import SlideshowIcon from "@material-ui/icons/Slideshow";
import { LessonContext } from "../../hooks/useLesson";
import { MenuContext } from "../../hooks/useMenu";

function stringToColor(s: string) {
  if (!s) return grey[500];

  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < s.length; i += 1) {
    hash = s.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  const tokens: string[] = name.split(" ");
  const children: any =
    tokens.length > 1
      ? `${tokens[0][0]}${tokens[1][0]}`.toUpperCase()
      : `${tokens[0][0]}${tokens[0][1]}`.toUpperCase();

  return {
    style: { backgroundColor: stringToColor(name) },
    children,
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      height: "6.3vh",
    },
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      margin: 0,
      padding: 0,
    },
    title: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto",
      },
    },
    appBar: {
      background:
        "linear-gradient(90deg, rgba(44,53,97,1) 0%, rgba(110,134,244,1) 40%)",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      boxShadow: "none",
    },
    logoSection: {
      display: "flex",
      alignItems: "center",
      textDecoration: "none !important",
    },
    headerRight: {
      display: "flex",
      alignItems: "center",
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
    toolbar: {
      justifyContent: "space-between",
    },
    sectionCompany: {
      display: "flex",
      alignItems: "center",
    },
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    logInButton: {
      backgroundColor: theme.palette.neutral?.main,
      marginRight: "1em",
      "&:hover": {
        backgroundColor: theme.palette.neutral?.dark,
      },
    },
    signUpButton: {
      backgroundColor: theme.palette.secondary.main,
      "&:hover": {
        backgroundColor: theme.palette.secondary.dark,
      },
      color: theme.palette.getContrastText(theme.palette.secondary.main),
      marginRight: "1em",
    },
    hyperLink: {
      "&:hover": {
        textDecoration: "none",
      },
    },
    menuSection: {
      marginLeft: "3em",
    },
    fileButton: {
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    slideshowBtn: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.primary.main,
      border: "1px solid",
      borderColor: theme.palette.primary.main,
      fontWeight: "bold",

      "&.MuiButton-root.Mui-disabled, &.MuiIconButton-root.Mui-disabled": {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.action.disabled,
        borderColor: theme.palette.action.disabled,
        boxShadow: "none",
      },
    },
  })
);

export default function Header(props: any) {
  const classes = useStyles();
  const lesson = useContext(LessonContext);
  const user: any = useContext(UserContext);
  const menu: any = useContext(MenuContext);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    useState<null | HTMLElement>(null);
  const history = useHistory();
  const [fileAnchorEl, setFileAnchorEl] = React.useState<null | HTMLElement>(
    null
  );

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user: any) => {
      setIsSignedIn(!!user);
    });

    return unsubscribe;
  });

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleSignInOut = () => {
    handleMenuClose();
    signOut();
    history.push(SIGN_IN_ROUTE);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const getAvatar = () => {
    if (user.photoURL) return <Avatar alt="avatar" src={user.photoURL} />;
    if (user.email) {
      return <Avatar {...stringAvatar(user.email)} />;
    }

    return <Avatar alt="avatar"></Avatar>;
  };

  const handlePresentClick = () => {
    if (lesson.sessionPlan) {
      menu.onPresent(true);
      handleFileClose();
    }
  };

  const handleFileClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFileAnchorEl(event.currentTarget);
  };

  const handleFileClose = () => {
    setFileAnchorEl(null);
  };

  const handleNewLessonPlanClick = () => {
    menu.onNewLesson(true);
    handleFileClose();
  };

  const handleAddClass = () => {
    menu.onAddClass(true);
    handleFileClose();
  };

  const handleShowClass = () => {
    menu.onShowClass(true);
    handleFileClose();
  };

  const handleShowObjectives = () => {
    menu.onShowObjectives(true);
    handleFileClose();
  };

  const handleShowStandards = () => {
    menu.onShowStandards(true);
    handleFileClose();
  };

  const handleShowReview = () => {
    menu.onShowReview(true);
    handleFileClose();
  };

  const getClassItem = () => {
    if (!lesson.sessionPlan || !lesson.sessionPlan.classId) {
      return (
        <MenuItem disabled={!lesson.sessionPlan} onClick={handleAddClass}>
          Add Class
        </MenuItem>
      );
    }

    return (
      <MenuItem disabled={menu.showClass} onClick={handleShowClass}>
        Show Class
      </MenuItem>
    );
  };

  const getFileElement = () => {
    return (
      <div className={classes.menuSection}>
        <Button
          id="file-button"
          onClick={handleFileClick}
          className={classes.fileButton}
          size="large"
        >
          File
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={fileAnchorEl}
          open={Boolean(fileAnchorEl)}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handleFileClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleNewLessonPlanClick}>
            New Lesson Plan
          </MenuItem>
          {getClassItem()}
          <MenuItem
            disabled={menu.showObjectives}
            onClick={handleShowObjectives}
          >
            Show Learning Objectives
          </MenuItem>
          <MenuItem disabled={menu.showStandards} onClick={handleShowStandards}>
            Show Standards
          </MenuItem>
          <MenuItem disabled={menu.showReview} onClick={handleShowReview}>
            Review Your Lesson
          </MenuItem>
          <MenuItem disabled={menu.showReview} onClick={handlePresentClick}>
            Slideshow
          </MenuItem>
        </Menu>
      </div>
    );
  };

  const getSlideshowElement = () => {
    return (
      <span>
        <Button
          className={classes.slideshowBtn}
          variant="contained"
          onClick={handlePresentClick}
          startIcon={<SlideshowIcon />}
          disabled={
            !lesson.sessionPlan || lesson.sessionPlan.activities.length === 0
          }
        >
          Slideshow
        </Button>
      </span>
    );
  };

  const menuId = "primary-search-account-menu";
  const getRenderMenu = () => {
    if (!anchorEl) return null;

    return (
      <Menu
        anchorEl={anchorEl}
        // anchorOrigin={{ vertical: "top", horizontal: "right" }}
        // transformOrigin={{ vertical: "top", horizontal: "right" }}
        id={menuId}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {isSignedIn ? (
          <MenuItem onClick={handleSignInOut}>Sign Out</MenuItem>
        ) : (
          <MenuItem onClick={handleSignInOut}>Sign In</MenuItem>
        )}
        {/* <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem> */}
      </Menu>
    );
  };

  const mobileMenuId = "primary-search-account-menu-mobile";
  const getRenderMobileMenu = () => {
    if (!mobileMoreAnchorEl) return null;

    return (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={Boolean(mobileMoreAnchorEl)}
        onClose={handleMobileMenuClose}
      >
        {/* <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem> */}
        <MenuItem onClick={handleProfileMenuOpen}>
          <IconButton
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <p>Profile</p>
        </MenuItem>
      </Menu>
    );
  };

  return (
    <div className={classes.header}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.sectionCompany}>
            <Link to={HOME_ROUTE} className={classes.logoSection}>
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
              >
                <img className="logo" src={logo} alt="logo" />
              </IconButton>
              <Typography variant="h6" className={"company-name"}>
                Learnidy
              </Typography>
            </Link>
            {getFileElement()}
          </div>
          <div className={classes.headerRight}>
            {getSlideshowElement()}
            {isSignedIn ? (
              <div>
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  {getAvatar()}
                </IconButton>
              </div>
            ) : (
              <div>
                {props.location.pathname !== SIGN_IN_ROUTE && (
                  <Link to={SIGN_IN_ROUTE} className={classes.hyperLink}>
                    <Button className={classes.logInButton} variant="outlined">
                      Log in
                    </Button>
                  </Link>
                )}
                {props.location.pathname !== SIGN_UP_ROUTE && (
                  <Link to={SIGN_UP_ROUTE} className={classes.hyperLink}>
                    <Button className={classes.signUpButton} variant="outlined">
                      Sign up
                    </Button>
                  </Link>
                )}
              </div>
            )}
          </div>
        </Toolbar>
      </AppBar>
      {getRenderMobileMenu()}
      {getRenderMenu()}
    </div>
  );
}
