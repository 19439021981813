import { useState, useEffect, useCallback, useContext } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import firebase from "firebase/app";
import {
  querySessionTimes,
  saveSessionPlanDB,
  deleteSessionBatch,
  createSessionPlanId,
  createSessionId,
  saveSession,
  saveSessionBatch,
  saveSessionPlanBatchDB,
  deleteSessionPlanFromSessionDB,
  getBatch,
  commitBatch,
  functions,
  auth,
} from "../../firebase/firebase";
import CalendarDialog from "./CalendarDialog";
import {
  createSessionPlanFromEvent,
  SessionPlanModel,
} from "../../models/SessionPlanModel";
import { Calendar, momentLocalizer } from "react-big-calendar";
import {
  CalendarEvent,
  getSaveCalendarEvent,
  loadCalendarEvent,
} from "./CalendarEvent";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { v4 as uuidv4 } from "uuid";
import { UserContext } from "../../hooks/useUser";
import { NO_CLASS_TITLE } from "../../constants/class";
import { IconButton } from "@material-ui/core";

const localizer = momentLocalizer(moment);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
    },
    title: {
      marginBottom: 40,
      textAlign: "center",
    },
    toggleButtonRoot: {
      borderRadius: `12px !important`,
    },
    toggleLabel: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    toggleSelected: {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: `${theme.palette.primary.contrastText} !important`,
      border: "none",
      borderRadius: `12px !important`,
    },
    toggleButtonGroup: {
      display: "grid",
      gap: "1rem",
      gridTemplateColumns: "repeat(auto-fill, minmax(300px, 400px))",
      justifyContent: "center",
      marginTop: "2em",
    },
    footer: {
      marginTop: "20px",
      textAlign: "center",
    },
    nextButton: {
      width: "10em",
      // backgroundColor: `${theme.palette.primary.main} !important`,
      // color: `${theme.palette.primary.contrastText} !important`,
    },
    calendarRoot: {},
    calendarDlgSize: {
      width: "80%",
      height: "80%",
      maxWidth: "none",
      padding: 32,
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(2),
      top: theme.spacing(1),
    },
  })
);

// const getHourMinText = (date: Date): string => {
//   return date.toLocaleTimeString().replace(/:\d+ /, " ");
// };

const getEventDisplayTitle = (
  event: CalendarEvent,
  isSelectingSessionForPlan: boolean
) => {
  return (
    <div>
      {event.sessionPlanId && (
        <Typography component="div" variant="body2">
          {event.title || NO_CLASS_TITLE}
        </Typography>
      )}
      {!event.sessionPlanId && (
        <Typography component="div" variant="caption">
          <i>{"no lesson"}</i>
        </Typography>
      )}
      <Typography component="div" variant="body2">
        {event.start?.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })}
      </Typography>
    </div>
  );
};

const customUseStyles = makeStyles((theme: Theme) =>
  createStyles({
    customSessionEvent: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    customEventButton: {
      minWidth: 32,
      marginLeft: 10,
      color: `${theme.palette.primary.contrastText}`,
    },
    title: {},
    selected: {
      color: `${theme.palette.info.light}`,
    },
    customSessionBadge: {},
  })
);

const CustomSessionEvent = (
  event: any,
  isSelectingSessionForPlan: boolean,
  currCalendarEvent: CalendarEvent | null,
  onClick: any
) => {
  const classes = customUseStyles();
  return (
    <span className={classes.customSessionEvent}>
      <Typography
        component="label"
        variant="body1"
        className={
          currCalendarEvent && event.event.id === currCalendarEvent.id
            ? classes.selected
            : classes.title
        }
      >
        {getEventDisplayTitle(event.event, isSelectingSessionForPlan)}
      </Typography>
      {/* <Button
        classes={{ root: classes.customEventButton }}
        // variant=""
        onClick={(e) => onClick(event)}
        color="default"
      >
        <EditIcon
          fontSize="small"
          className={
            currCalendarEvent && event.event.id === currCalendarEvent.id
              ? classes.selected
              : classes.title
          }
        />
      </Button> */}
    </span>
  );
};

interface SessionCalendarProps {
  classId: string | undefined;
  sessionPlan: SessionPlanModel;
  isSelectingSessionForPlan: boolean;
  onEventSelect?: any;
  onClose?: () => void;
}

export default function SessionCalendar(props: SessionCalendarProps) {
  const classes = useStyles();
  const user: any = useContext(UserContext);
  const [didLoad, setDidLoad] = useState(false);
  const [isNewEvent, setIsNewEvent] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [calendarEvents, setCalendarEvents] = useState<CalendarEvent[]>([]);
  const [currCalendarEvent, setCurrCalendarEvent] =
    useState<CalendarEvent | null>(null);
  const [showCalendarDialog, setShowCalendarDialog] = useState(false);

  const loadSessions = useCallback(() => {
    if (!props.classId) return;

    querySessionTimes(props.classId)
      .then((querySnapshot: any) => {
        const events: CalendarEvent[] = querySnapshot.docs.map((doc: any) =>
          loadCalendarEvent(doc.data())
        );

        // Sort the events since they come back from the db unsorted
        events.sort((a: any, b: any) => {
          return a.start - b.start;
        });

        setCalendarEvents(events);

        // Set the first event as the default selected event
        if (events.length > 0) {
          setCurrCalendarEvent(events[0]);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [props.classId]);

  useEffect(() => {
    if (!didLoad) {
      loadSessions();
      setDidLoad(true);
    }
  }, [didLoad, loadSessions]);

  const getEventTitle = (
    event: CalendarEvent,
    ignoreNewEvent: boolean = false
  ): string | null => {
    // Don't set sessionPlan info when we are just creating sessions
    if (!ignoreNewEvent && isNewEvent) {
      return null;
    }

    if (event.sessionPlanId === props.sessionPlan.id) {
      return props.sessionPlan.name;
    }

    return null;
  };

  const updateSessions = async (
    saveEvents: CalendarEvent[],
    deleteEvents: CalendarEvent[]
  ) => {
    if (!props.classId) {
      console.error("Can't update sessions. Class Id not valid");
      return;
    }

    if (user.loggedIn) {
      const batch: firebase.firestore.WriteBatch = getBatch();
      saveSessions(saveEvents, batch);
      deleteSessions(deleteEvents, batch);

      await commitBatch(batch)
        .then(() => {
          console.log("Sessions saved!");
        })
        .catch((error: any) => {
          console.error(error);
        });
    } else {
      setCalendarEvents(saveEvents);
    }
  };

  const saveSessions = async (
    events: CalendarEvent[],
    batch: firebase.firestore.WriteBatch
  ) => {
    if (!props.classId) {
      console.error("Can't save sessions. Class Id not valid");
      return;
    }

    if (user.loggedIn) {
      for (let i = 0; i < events.length; ++i) {
        const currEvent: CalendarEvent = events[i];
        if (!currEvent.id) currEvent.id = createSessionId().id;
        const data = getSaveCalendarEvent(currEvent);
        saveSessionBatch(batch, props.classId, currEvent.id, data, true);
      }
    }

    setCalendarEvents(events);
  };

  const deleteSessions = async (
    events: CalendarEvent[],
    batch: firebase.firestore.WriteBatch
  ) => {
    if (!props.classId) {
      console.error("Can't delete sessions. Invalid Class Id");
      return;
    }

    if (!user.loggedIn) return;

    for (let i = 0; i < events.length; ++i) {
      const currEvent: CalendarEvent = events[i];
      if (!currEvent.id) continue;

      if (currEvent.sessionPlanId) {
        const newPlanData = {
          sessionId: undefined,
        };
        saveSessionPlanBatchDB(
          batch,
          newPlanData,
          currEvent.sessionPlanId,
          true
        );
      }

      deleteSessionBatch(batch, props.classId, currEvent.id);
    }
  };

  const createSessionPlan = () => {
    if (!currCalendarEvent) return;

    const event: CalendarEvent = currCalendarEvent;
    const id: string =
      event.sessionPlanId && event.sessionPlanId.length > 0
        ? event.sessionPlanId
        : createSessionPlanId().id;

    const data: any = createSessionPlanFromEvent({
      id,
      classId: props.classId,
      event,
    });

    saveSessionPlan(data, id, event);
  };

  const removeSessionPlan = () => {
    if (
      !currCalendarEvent ||
      !currCalendarEvent.classId ||
      !currCalendarEvent.id ||
      !currCalendarEvent.sessionPlanId
    )
      return;

    deleteSessionPlanFromSessionDB(
      currCalendarEvent.classId,
      currCalendarEvent.id,
      currCalendarEvent.sessionPlanId
    )
      .then(() => {
        console.log("lesson plan remove");
        setCurrCalendarEvent({ ...currCalendarEvent, sessionPlanId: null });
      })
      .catch((error: any) => {
        console.error("Couldn't save session plan: ", error);
      });
  };

  const saveSessionPlan = (data: any, id: string, event: CalendarEvent) => {
    saveSessionPlanDB(data, id, true)
      .then(() => {
        event.sessionPlanId = id;
        const title: string | null = getEventTitle(event, true);
        event.title = title;
        saveSession(props.classId, event.id, { sessionPlanId: id, title }, true)
          .then(() => {
            if (props.onEventSelect) {
              props.onEventSelect(event);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error: any) => {
        console.error("Couldn't save session plan: ", error);
      });
  };

  const getDaysBetweenDates = (event: CalendarEvent): CalendarEvent[] => {
    if (!event.seriesStart || !event.end || !event.seriesEnd) return [event];

    const result: CalendarEvent[] = [];
    var start: Date = new Date(event.seriesStart);
    let seriesId: string = event.seriesId ? event.seriesId : uuidv4();

    // While less than end date, add dates to result array
    while (start < event.seriesEnd) {
      for (const [key, value] of Object.entries(event.seriesCheckedDays)) {
        if (value === false) continue;

        const day: number = Number.parseInt(key);
        let current: Date = new Date(start);
        current.setDate(start.getDate() + (day - start.getDay()));

        if (current >= event.seriesStart && current < event.seriesEnd) {
          const newEnd: Date = new Date(
            current.getFullYear(),
            current.getMonth(),
            current.getDate(),
            event.end.getHours(),
            event.end.getMinutes(),
            event.end.getSeconds()
          );

          const newEvent: CalendarEvent = { ...event };
          newEvent.id = undefined;
          newEvent.seriesId = seriesId;
          newEvent.start = new Date(current);
          newEvent.end = newEnd;
          newEvent.title = getEventTitle(newEvent);

          result.push(newEvent);
        }
      }

      start.setDate(start.getDate() + 7);
    }

    return result;
  };

  const getSeriesEventsFromCalendarEvent = (
    event: CalendarEvent
  ): CalendarEvent[] => {
    let events: CalendarEvent[] = getDaysBetweenDates(event);
    return events;
  };

  const modifyEventsFromCalendarEvent = (
    event: CalendarEvent,
    origEvent: CalendarEvent
  ): any => {
    // Only keep the series Ids that are not our modified event
    const saveEvents: CalendarEvent[] = [];
    const deleteEvents: CalendarEvent[] = [];
    const startChange: number =
      event.start && origEvent.start
        ? event.start.getTime() - origEvent.start.getTime()
        : 0;
    const endChange: number =
      event.end && origEvent.end
        ? event.end.getTime() - origEvent.end.getTime()
        : 0;
    for (let i = 0; i < calendarEvents.length; ++i) {
      const curr: CalendarEvent = calendarEvents[i];

      // If the current event is in the series, add it as normal (no change)
      if (calendarEvents[i].seriesId !== event.seriesId) {
        saveEvents.push(calendarEvents[i]);
      } else {
        if (curr.start) {
          curr.start = new Date(curr.start.getTime() + startChange);
        }

        if (curr.end) curr.end = new Date(curr.end.getTime() + endChange);
        if (curr.seriesStart) curr.seriesStart = event.seriesStart;
        if (curr.seriesEnd) curr.seriesEnd = event.seriesEnd;
        curr.seriesCheckedDays = { ...event.seriesCheckedDays };
        curr.title = getEventTitle(curr);

        // Remove this event if it isn't in the checked days
        if (curr.start) {
          let day: number = curr.start.getDay();
          if (curr.seriesCheckedDays[day] === false) {
            deleteEvents.push(curr);
            continue;
          }
        }

        saveEvents.push(curr);
      }
    }

    const seriesEvents: CalendarEvent[] =
      getSeriesEventsFromCalendarEvent(event);

    const newEvents: CalendarEvent[] = seriesEvents.filter(
      (val, index, arr) => {
        let i: number = saveEvents.findIndex((val2: CalendarEvent) => {
          return (
            val.seriesId === val2.seriesId &&
            val.start &&
            val2.start &&
            val2.start.getTime() === val.start.getTime()
          );
        });
        return i < 0;
      }
    );

    saveEvents.push(...newEvents);
    return { saveEvents, deleteEvents };
  };

  const handleSelectSlot = (slotInfo: any) => {
    const start: Date = new Date(slotInfo.start.getTime() + 9 * 60 * 60 * 1000);
    const seriesStart: Date = new Date(start);
    const end: Date = new Date(slotInfo.start.getTime() + 10 * 60 * 60 * 1000);
    const seriesEnd: Date = new Date(
      slotInfo.end.getTime() + 10 * 60 * 60 * 1000
    );

    const newEvent: CalendarEvent = {
      start: start,
      end: end,
      seriesStart: seriesStart,
      seriesEnd: seriesEnd,
      allDay: false,
      title: null,
      seriesType: "custom",
      seriesCheckedDays: undefined,
      sessionPlanId: null,
      classId: props.classId,
    };

    setIsNewEvent(true);
    setCurrCalendarEvent(newEvent);
    setShowCalendarDialog(true);
  };

  const handleEditEvent = (event: CalendarEvent) => {
    setIsNewEvent(false);
    setCurrCalendarEvent(event);

    if (props.isSelectingSessionForPlan) {
      handleSessionSelectForPlan(event);
    } else {
      setShowCalendarDialog(true);
    }
  };

  const handleDialogCreate = (event: CalendarEvent) => {
    let saveEvents: CalendarEvent[] = [];
    let deleteEvents: CalendarEvent[] = [];
    if (event.seriesId) {
      if (currCalendarEvent) {
        const result: any = modifyEventsFromCalendarEvent(
          event,
          currCalendarEvent
        );

        saveEvents = result.saveEvents;
        deleteEvents = result.deleteEvents;
      }
    } else {
      saveEvents = getSeriesEventsFromCalendarEvent(event);
      saveEvents.push(...calendarEvents);
    }

    updateSessions(saveEvents, deleteEvents);

    if (saveEvents && saveEvents.length > 0) {
      setCurrCalendarEvent(saveEvents[0]);
    }

    setShowCalendarDialog(false);
  };

  const handleDialogEdit = async (data: any, saveData: any) => {
    setIsSaving(true);
    const updateSessionFunc = functions.httpsCallable("updateSession");
    const response = await updateSessionFunc({
      userId: auth.currentUser?.uid,
      session: saveData,
    }).catch((e) => console.error("Could not edit session " + saveData.title));

    setIsSaving(false);
    if (response && response.data.status === 200) {
      setShowCalendarDialog(false);

      // Reload the session because multiple sessions may have
      // changed during function update
      loadSessions();
    }
  };

  const handleEditLesson = () => {
    if (!currCalendarEvent) return;

    if (currCalendarEvent.sessionPlanId) {
      if (props.onEventSelect) {
        props.onEventSelect(currCalendarEvent);
      }
    } else {
      createSessionPlan();
    }
  };

  const handleRemoveLesson = () => {
    if (!currCalendarEvent) return;

    removeSessionPlan();
  };

  const handleSessionSelectForPlan = (event: CalendarEvent) => {
    if (!props.sessionPlan.id) {
      console.error("Could not save. No valid session plan id.");
      return;
    }

    setCurrCalendarEvent(event);

    let data: any = {
      sessionId: event.id,
      startTime: event.start?.toISOString(),
      endTime: event.end?.toISOString(),
    };
    saveSessionPlan(data, props.sessionPlan.id, event);
    setShowCalendarDialog(false);
  };

  const handleCalendarDialogClose = () => {
    setShowCalendarDialog(false);
  };

  const handleDialogClose = () => {
    if (props.onClose) props.onClose();
  };

  // const getSessionPopoverElement = () => {
  //   return (
  //     <Popover
  //       id={eventPopoverId}
  //       open={isEventPopoverOpen}
  //       anchorEl={eventPopoverAnchorEl}
  //       onClose={handleSessionPopoverClose}
  //       anchorOrigin={{
  //         vertical: "center",
  //         horizontal: "center",
  //       }}
  //       transformOrigin={{
  //         vertical: "top",
  //         horizontal: "center",
  //       }}
  //     >
  //       <div>
  //         <Button
  //           className={"session-popover-button"}
  //           variant="outlined"
  //           onClick={handleSessionPopoverSelect}
  //         >
  //           <CheckCircleIcon fontSize="small" />
  //         </Button>
  //         <Button
  //           className={"session-popover-button"}
  //           variant="outlined"
  //           onClick={handleSessionPopoverEdit}
  //         >
  //           <EditIcon fontSize="small" />
  //         </Button>
  //         <Button
  //           className={"session-popover-button"}
  //           variant="outlined"
  //           onClick={handleSessionPopoverDelete}
  //         >
  //           <DeleteIcon fontSize="small" />
  //         </Button>
  //         <Button
  //           className={"session-popover-button"}
  //           variant="text"
  //           onClick={handleSessionPopoverClose}
  //         >
  //           <CloseIcon fontSize="small" />
  //         </Button>
  //       </div>
  //     </Popover>
  //   );
  // };

  const getCloseButton = () => {
    return (
      <IconButton
        aria-label="close"
        onClick={handleDialogClose}
        className={classes.closeButton}
      >
        <CloseIcon />
      </IconButton>
    );
  };

  const getCalendarTitle = () => {
    if (props.isSelectingSessionForPlan && calendarEvents.length > 0) {
      return (
        <Typography component="h5" variant="h5" className={classes.title}>
          Choose a time for your lesson plan
        </Typography>
      );
    } else {
      return (
        <Typography component="div" variant="h6" className={classes.title}>
          Edit your class schedule
        </Typography>
      );
    }
  };

  const getCalendarRoot = () => {
    return (
      <div className={classes.root}>
        {getCloseButton()}
        {getCalendarTitle()}
        <Calendar
          selectable={true}
          popup={true}
          localizer={localizer}
          events={calendarEvents}
          startAccessor="start"
          endAccessor="end"
          style={{ height: "clamp(500px, 75%, 75%)" }}
          onSelectSlot={handleSelectSlot}
          onSelectEvent={handleEditEvent}
          onDoubleClickEvent={handleEditEvent}
          components={{
            event: (event) =>
              CustomSessionEvent(
                event,
                props.isSelectingSessionForPlan,
                currCalendarEvent,
                handleEditEvent
              ),
          }}
        />
        {/* {getSessionPopoverElement()} */}
        {showCalendarDialog && currCalendarEvent && (
          <CalendarDialog
            event={currCalendarEvent}
            isNewEvent={isNewEvent}
            isSaving={isSaving}
            sessionPlan={props.sessionPlan}
            onClose={handleCalendarDialogClose}
            onCreate={handleDialogCreate}
            onEdit={handleDialogEdit}
            onEditLesson={handleEditLesson}
            onRemoveLesson={handleRemoveLesson}
          />
        )}
      </div>
    );
  };

  const getDialogRender = () => {
    return (
      <Dialog
        fullScreen={false}
        open={true}
        className={classes.calendarRoot}
        classes={{ paperScrollPaper: classes.calendarDlgSize }}
        onClose={handleDialogClose}
        aria-labelledby="responsive-dialog-title"
      >
        {getCalendarRoot()}
      </Dialog>
    );
  };

  return getDialogRender();
}
