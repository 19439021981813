import { useState, useEffect, useCallback } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Button,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import { SessionPlanModel } from "../../models/SessionPlanModel";
import { DateTime } from "luxon";
import {
  auth,
  querySessionPlans,
  querySessionPlansByClass,
  functions,
} from "../../firebase/firebase";
import { v4 as uuidv4 } from "uuid";
import { NO_CLASS_TITLE } from "../../constants/class";

const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    position: "absolute",
    right: "8px",
    "&:hover": {},
  },
  listItemSection: {
    display: "flex",
    alignItems: "center",
    borderBottom: `1px solid rgba(0,0,0,0.05)`,
  },
  selectItemSection: {
    minWidth: "10em",
    color: "black",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

interface SessionPlanListProps {
  show: boolean;
  classId?: string;
  updateCount?: number;
  isDropdown?: boolean;
  selectedSessionPlanId?: string | null | undefined;
  onSessionPlanSelect?: (
    event: any,
    plan: SessionPlanModel | undefined
  ) => void;
  onSessionPlanDelete?: (plan: SessionPlanModel) => void;
  onCollapse?: (isCollapsed: boolean) => void;
}

export default function SessionPlanList(props: SessionPlanListProps) {
  const classes = useStyles();
  const [sessionPlans, setSessionPlans] = useState<SessionPlanModel[]>([]);
  const [, setIsGettingPlans] = useState(false);
  const [hasFetchedPlans, setHasFetchedPlans] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [selectedSessionPlanId, setSelectedSessionPlanId] = useState<string>(
    props.selectedSessionPlanId || ""
  );
  const [planMarkedForDelete, setPlanMarkedForDelete] =
    useState<SessionPlanModel | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const loadSessionPlans = useCallback(async () => {
    setSessionPlans([]);

    if (isSignedIn) {
      setIsGettingPlans(true);

      try {
        const snapshot = props.classId
          ? await querySessionPlansByClass(props.classId)
          : await querySessionPlans();

        const plans: SessionPlanModel[] = [];
        snapshot.docs.forEach((doc: any) => {
          plans.push({ id: doc.id, ...doc.data() });
        });

        setSessionPlans(plans);

        // Update the selected session plan
        if (selectedSessionPlanId) {
          const plan = plans.find((value) => {
            return value.id === selectedSessionPlanId;
          });
          if (plan) setSelectedSessionPlanId(plan.id);
        }

        setIsGettingPlans(false);
        setHasFetchedPlans(true);
      } catch (error) {
        console.error(error);
        setIsGettingPlans(false);
      }

      // querySessionPlans()
      //   .then((snapshot) => {
      //     snapshot.docs.forEach((doc: any) => {
      //       setSessionPlans((sessionPlans) => [
      //         ...sessionPlans,
      //         { id: doc.id, ...doc.data() },
      //       ]);
      //     });

      //     setIsGettingPlans(false);
      //     setHasFetchedPlans(true);
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //     setIsGettingPlans(false);
      //   });
    }
  }, [isSignedIn, props.classId, selectedSessionPlanId]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsSignedIn(!!user);
    });

    return unsubscribe;
  });

  useEffect(() => {
    if (props.show && !hasFetchedPlans) {
      loadSessionPlans();
    }
  }, [props.show, hasFetchedPlans, loadSessionPlans]);

  const handleSessionPlanClick = (event: any, item: any) => {
    if (props.onSessionPlanSelect) {
      props.onSessionPlanSelect(event, item);
    }

    event.stopPropagation();
  };

  const deleteLessonPlan = async () => {
    if (!planMarkedForDelete) {
      return;
    }

    // Show the progress spinner
    setIsDeleting(true);

    const deleteLessonPlanFunc = functions.httpsCallable("deleteLessonPlan");
    const response = await deleteLessonPlanFunc({
      userId: auth.currentUser?.uid,
      sessionPlanId: planMarkedForDelete.id,
      classId: planMarkedForDelete.classId,
    }).catch((e) =>
      console.error("Could not delete lesson plan " + planMarkedForDelete.name)
    );

    // Done deleting, hide the spinner
    setIsDeleting(false);

    if (response && response.data && response.data.status === 200) {
      // Remove the item from the sidebar list
      const plans = [...sessionPlans];
      const index = plans.findIndex((value) => {
        return value.id === planMarkedForDelete.id;
      });

      if (index !== -1) {
        plans.splice(index, 1);
        setSessionPlans(plans);
      }

      if (props.onSessionPlanDelete) {
        props.onSessionPlanDelete(planMarkedForDelete);
      }

      setPlanMarkedForDelete(null);
      return true;
    } else {
      console.error("Could not delete lesson plan " + planMarkedForDelete.name);
      return false;
    }
  };

  const handleSessionPlanDelete = async (
    event: any,
    plan: SessionPlanModel | null
  ) => {
    event.stopPropagation();

    if (!plan) {
      return;
    }

    setPlanMarkedForDelete(plan);
  };

  const getDeleteDialogWarn = () => {
    return (
      <Dialog
        fullScreen={false}
        open={planMarkedForDelete !== null}
        maxWidth={"lg"}
        // onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">
          {`Delete Lesson Plan - `}
          <b>{planMarkedForDelete?.name}</b>
        </DialogTitle>
        <DialogContent>
          <Typography component="div" variant="subtitle1">
            <div>
              {`You are about to delete this lesson and any uploaded files. This can not be undone.`}
            </div>
            <div>{`Are you sure?`}</div>
          </Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={(e: any) => setPlanMarkedForDelete(null)}
          >
            <CloseIcon />
          </IconButton>
        </DialogContent>
        <DialogActions>
          {isDeleting && <CircularProgress />}
          <Button
            variant="contained"
            onClick={deleteLessonPlan}
            color="primary"
            disabled={isDeleting}
          >
            Yes
          </Button>
          <Button
            variant="contained"
            onClick={(e) => setPlanMarkedForDelete(null)}
            disabled={isDeleting}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleSelectedSessionPlanChange = (event: any) => {
    setSelectedSessionPlanId(event.target.value);
    if (props.onSessionPlanSelect) {
      const plan = sessionPlans.find((value) => {
        return value.id === event.target.value;
      });

      props.onSessionPlanSelect(event, plan);
    }
  };

  const getSessionPlanListElement = () => {
    return (
      <List
        component="div"
        aria-labelledby="lesson-list-subheader"
        style={{ padding: 0 }}
      >
        {sessionPlans.map((item: any, listIndex: number) => {
          let modifiedStr: String = "";

          if (item.startTime) {
            const dt: DateTime = DateTime.fromISO(item.startTime);
            modifiedStr = dt.toLocaleString(DateTime.DATETIME_SHORT);
          }

          return (
            <div key={uuidv4()} className={classes.listItemSection}>
              <ListItem button onClick={(e) => handleSessionPlanClick(e, item)}>
                <ListItemText
                  style={{ fontSize: "0.5rem" }}
                  primary={`${item.name ? item.name : NO_CLASS_TITLE}`}
                  secondary={modifiedStr}
                />
              </ListItem>
              {/* <Button
                className={classes.deleteButton}
                variant="contained"
                onClick={(e) => handleSessionPlanDelete(e, item)}
              >
                <DeleteIcon />
              </Button> */}

              <IconButton
                className={classes.deleteButton}
                onClick={(e) => handleSessionPlanDelete(e, item)}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          );
        })}
        {getDeleteDialogWarn()}
      </List>
    );
  };

  const getSelectValue = () => {
    const plan = sessionPlans.find((value) => {
      return value.id === selectedSessionPlanId;
    });

    if (plan) {
      return selectedSessionPlanId;
    }

    return "";
  };

  const getSessionPlanDropdownElement = () => {
    return (
      <div>
        <InputLabel shrink id="lesson-select-label">
          Lesson
        </InputLabel>
        <Select
          labelId="lesson-select-label"
          id="lesson-select"
          value={getSelectValue()}
          onChange={handleSelectedSessionPlanChange}
          displayEmpty
          className={classes.selectItemSection}
        >
          <MenuItem key={uuidv4()} value={""}>
            None
          </MenuItem>
          {sessionPlans.map((item: any, listIndex: number) => {
            //   let modifiedStr: String = "";
            //   if (item.startTime) {
            //     const dt: DateTime = DateTime.fromISO(item.startTime);
            //     modifiedStr = dt.toLocaleString(DateTime.DATETIME_SHORT);
            //   }

            return (
              <MenuItem key={uuidv4()} value={item.id}>
                {`${item.name ? item.name : NO_CLASS_TITLE} `}
              </MenuItem>
            );
          })}
        </Select>
        {getDeleteDialogWarn()}
      </div>
    );
  };

  const getElement = () => {
    if (props.isDropdown) {
      return getSessionPlanDropdownElement();
    }

    return getSessionPlanListElement();
  };

  return getElement();
}
