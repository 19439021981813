import React from "react";
// import Typography from "@material-ui/core/Typography";
// import Select from "@material-ui/core/Select";
import { AssessQuestionType, QuestionProps } from "./Questions/QuestionTypes";
import { MultipleChoiceQuestion } from "./Questions/MultipleChoiceQuestion";
import { TextboxQuestion } from "./Questions/TextboxQuestion";
import { FillInTheBlankQuestion } from "./Questions/FillInTheBlankQuestion";
import {
  LargeText,
  PresentAnswer,
  PresentChoiceText,
  PresentMultipleChoice,
  PresentMultipleChoiceText,
} from "../../../components/Presentation/Presentation";
import MediaViewer from "../../../components/Presentation/MediaViewer";

export default function AssessQuestion(props: QuestionProps) {
  return (
    <React.Fragment>
      {(props.type === AssessQuestionType.MultipleChoice ||
        props.type === AssessQuestionType.MultipleAnswer) && (
        <MultipleChoiceQuestion
          choices={props.choices}
          answer={props.answer}
          type={props.type}
          onChange={props.onChange}
        />
      )}
      {props.type === AssessQuestionType.Textbox && (
        <TextboxQuestion
          choices={props.choices}
          answer={props.answer}
          type={props.type}
          onChange={props.onChange}
        />
      )}
      {props.type === AssessQuestionType.FillInTheBlank && (
        <FillInTheBlankQuestion
          choices={props.choices}
          answer={props.answer}
          type={props.type}
          onChange={props.onChange}
        />
      )}
    </React.Fragment>
  );
}

interface QuestionSlideProps {
  question: any;
  subslideIndex: number;
  showAnswer: boolean;
  onShowAnswer?: any;
  uploadFiles?: any;
}

export function AssessQuestionSlide(props: QuestionSlideProps) {
  const getIsAnswer = (question: any, index: number): boolean => {
    if (!question || !question.answer) return false;

    if (Array.isArray(question.answer)) {
      return question.answer[index];
    }

    if (typeof question.answer === "string") {
      const result: boolean = parseInt(question.answer) === index;
      return result;
    }

    return false;
  };

  const handleShowAnswer = () => {
    if (props.onShowAnswer) props.onShowAnswer();
  };

  const getMultipleChoiceElement = () => {
    return (
      <React.Fragment>
        <LargeText
          value={props.question.question}
          backgroundColor="learnidy-green-bg"
          isMultiLine={false}
        />
        <PresentMultipleChoice>
          {props.question.choices &&
            props.question.choices.map((choice: any, index: number) => {
              return (
                <PresentMultipleChoiceText
                  key={`exit-choice-` + index}
                  value={choice}
                  backgroundColor="learnidy-green-bg"
                  isMultiLine={true}
                  showAsAnswer={
                    getIsAnswer(props.question, index) && props.showAnswer
                  }
                />
              );
            })}
        </PresentMultipleChoice>
      </React.Fragment>
    );
  };

  const getTextboxElement = () => {
    return (
      <React.Fragment>
        <LargeText
          value={props.question.question}
          backgroundColor="learnidy-green-bg"
          isMultiLine={false}
        />
        <PresentChoiceText
          value={props.question.answer}
          backgroundColor="learnidy-green-bg"
          isMultiLine={true}
          showAsAnswer={props.showAnswer}
        />
      </React.Fragment>
    );
  };

  const getFillInTheBlankElement = () => {
    return (
      <React.Fragment>
        <LargeText
          value={props.question.choices}
          // textbox={props.question.choices}
          backgroundColor="learnidy-green-bg"
          isMultiLine={false}
        />
        <PresentChoiceText
          value={props.question.answer}
          backgroundColor="learnidy-green-bg"
          isMultiLine={true}
          showAsAnswer={props.showAnswer}
        />
      </React.Fragment>
    );
  };

  const getQuestion = () => {
    if (
      props.question.type === AssessQuestionType.MultipleChoice ||
      props.question.type === AssessQuestionType.MultipleAnswer
    ) {
      return getMultipleChoiceElement();
    } else if (props.question.type === AssessQuestionType.Textbox) {
      return getTextboxElement();
    } else if (props.question.type === AssessQuestionType.FillInTheBlank) {
      return getFillInTheBlankElement();
    }

    return null;
  };

  return (
    <React.Fragment key={`question-` + props.subslideIndex}>
      {getQuestion()}
      <PresentAnswer
        onShowAnswer={handleShowAnswer}
        showAnswer={props.showAnswer}
      />
      {props.uploadFiles && props.uploadFiles.length > 0 && (
        <MediaViewer uploadFiles={props.uploadFiles} />
      )}
    </React.Fragment>
  );
}
