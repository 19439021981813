import React, { useState } from "react";
import { auth } from "../../firebase/firebase";
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { SIGN_IN_ROUTE } from "../../constants/routes";
import { Typography } from "@material-ui/core";
import { validate } from "isemail";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "80vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: theme.palette.background.default,
    },
    card: {
      "& > *": {
        margin: theme.spacing(1),
      },
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      maxWidth: 400,
      minWidth: 275,
    },
    cardContent: {
      width: "auto",
    },
    textField: {
      width: "100%",
      marginBottom: 20,
    },
    formErrorMsg: {
      color: theme.palette.error.main,
      fontWeight: "bold",
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)",
    },
    title: {
      fontWeight: "bold",
      marginBottom: 10,
      color: theme.palette.primary.main,
    },
    subtitle: {
      fontWeight: "bold",
      marginBottom: 20,
      color: theme.palette.getContrastText(theme.palette.background.default),
    },
    haveAccountSection: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "16px",
    },
    haveAccountText: {},
    logIn: {
      fontWeight: "bold",
      marginLeft: "0.4em",
      color: theme.palette.primary.main,
    },
    termsText: {},
    pos: {
      marginBottom: 12,
    },
  })
);

export default function ResetPasswordForm(props: any) {
  const [email, setEmail] = useState("");
  const [formErrorMsg, setFormErrorMsg] = useState<string | null>(null);
  const [didSendEmailReset, setDidSendEmailReset] = useState(false);
  // const [error, setError] = useState(null);

  const classes = useStyles();

  const clearForm = (keepEmail: boolean) => {
    if (!keepEmail) setEmail("");

    setFormErrorMsg(null);
  };

  const sendVerification = () => {
    if (!auth) {
      return;
    }

    auth
      .sendPasswordResetEmail(email)
      .then(() => {
        clearForm(true);
        setDidSendEmailReset(true);
      })
      .catch((error) => {
        // console.error("Send password reset error: ", error);

        // For security, don't allow users to guess accounts to see if they exist.
        // Always redirect to password reset sent.
        clearForm(true);
        setDidSendEmailReset(true);
      });
  };

  const onSubmit = (event: any) => {
    event.preventDefault();
    sendVerification();
  };

  const isFormInvalid = () => {
    return !validate(email);
  };

  const getForm = () => {
    return (
      <React.Fragment>
        <Typography component="h4" variant="h4" className={classes.title}>
          Password Reset
        </Typography>
        <Typography component="h6" variant="h6" className={classes.subtitle}>
          Enter your email address below, and we'll email you a password reset
          link.
        </Typography>
        <Card className={classes.card} variant="outlined">
          <CardContent className={classes.cardContent}>
            <form onSubmit={(event) => onSubmit(event)}>
              <TextField
                id="signup-email-input"
                className={classes.textField}
                label="Email Address"
                type="email"
                autoComplete="username"
                variant="outlined"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setFormErrorMsg(null);
                }}
              />
              <Typography className={classes.formErrorMsg}>
                {formErrorMsg}
              </Typography>
              {/* {error && <p>{error?.message}</p>} */}
            </form>
          </CardContent>
          <CardActions>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => onSubmit(e)}
              disabled={isFormInvalid()}
            >
              Send Reset Email
            </Button>
          </CardActions>
        </Card>
        <span className={classes.haveAccountSection}>
          <Typography
            component="span"
            variant="subtitle1"
            className={classes.haveAccountText}
          >
            Already have an account?
          </Typography>
          <Link to={SIGN_IN_ROUTE}>
            <Typography
              component="span"
              variant="subtitle1"
              className={classes.logIn}
            >
              Log In
            </Typography>
          </Link>
        </span>
      </React.Fragment>
    );
  };

  const getEmailVerificationSentElement = () => {
    return (
      <React.Fragment>
        <Typography component="h4" variant="h4" className={classes.title}>
          Password Reset Sent!
        </Typography>
        <Typography component="h6" variant="h6" style={{ maxWidth: 800 }}>
          {`A password reset email has been sent to ${email} if it is a registered
          user. Please allow a few minutes for it to arrive. Check your spam
          folder if you do not see it in your inbox.`}
        </Typography>
      </React.Fragment>
    );
  };

  const getRenderElement = () => {
    if (didSendEmailReset) {
      return getEmailVerificationSentElement();
    }

    return getForm();
  };

  return getRenderElement();
}
