import React, { useState, useEffect } from "react";
// import Typography from "@material-ui/core/Typography";
// import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import { AssessQuestionType, QuestionProps } from "./QuestionTypes";
import { questionStyles } from "../../../../styles/QuestionStyles";
import { FormGroup } from "@material-ui/core";

const getDefaultChoices = (choices: any): string[] => {
  if (choices && Array.isArray(choices)) return choices;

  return ["", "", ""];
};

const getDefaultAnswer = (
  type: AssessQuestionType,
  answer: any
): string | {} => {
  if (type === AssessQuestionType.MultipleChoice) {
    return answer ? answer : "0";
  } else if (type === AssessQuestionType.MultipleAnswer) {
    return answer && typeof answer === "object"
      ? answer
      : [false, false, false];
  }

  return {};
};

export function MultipleChoiceQuestion(props: QuestionProps) {
  const classes = questionStyles();
  const [choices, setChoices] = useState(getDefaultChoices(props.choices));
  const [answer, setAnswer] = useState<any>(
    getDefaultAnswer(props.type, props.answer)
  );
  const [maxNumChoices] = useState(6);

  useEffect(() => {
    setChoices(getDefaultChoices(props.choices));
    if (props.type === AssessQuestionType.MultipleChoice) {
      setAnswer(props.answer ? props.answer : "0");
    } else if (props.type === AssessQuestionType.MultipleAnswer) {
      setAnswer(getDefaultAnswer(props.type, props.answer));
    }
  }, [props.choices, props.type, props.answer]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number | undefined = undefined
  ) => {
    if (props.type === AssessQuestionType.MultipleChoice) {
      setAnswer(event.target.value);
      if (props.onChange) props.onChange(choices, event.target.value);
    } else if (
      props.type === AssessQuestionType.MultipleAnswer &&
      index !== undefined
    ) {
      let data: any = [...answer];
      data[index] = event.target.checked;
      setAnswer(data);
      if (props.onChange) props.onChange(choices, data);
    }
  };

  const handleTextChange = (value: string, index: number) => {
    const newChoices = [...choices];
    newChoices[index] = value;
    setChoices(newChoices);

    if (props.onChange) props.onChange(newChoices, answer);
  };

  const handleDeleteChoice = (index: number) => {
    const newChoices = [...choices];
    newChoices.splice(index, 1);
    setChoices(newChoices);

    if (props.type === AssessQuestionType.MultipleAnswer) {
      const newAnswers = [...answer];
      newAnswers.splice(index, 1);
      setAnswer(newAnswers);
      if (props.onChange) props.onChange(newChoices, newAnswers);
    } else {
      if (props.onChange) props.onChange(newChoices, answer);
    }
  };

  const handleAddChoice = () => {
    const data = [...choices];
    data.push("");
    setChoices(data);

    if (props.type === AssessQuestionType.MultipleAnswer) {
      let newAnswers = [...answer];
      newAnswers.push(false);
      setAnswer(newAnswers);
    }
  };

  const getMultipleChoiceElement = () => {
    return (
      <RadioGroup
        aria-label="multiChoice"
        value={answer}
        onChange={(event) => handleChange(event)}
      >
        {choices.map((item: any, index: number) => (
          <div className={classes.radioRow} key={`aq-choices-${index + 1}`}>
            <FormControlLabel
              name={`aq-choices-${index + 1}`}
              id={`aq-choices-${index + 1}`}
              className={classes.mcRadioBtn}
              value={index.toString()}
              control={<Radio />}
              label=""
            />
            <span className={classes.mcRadioLabel}>
              <TextField
                key={`aq-tf-${index + 1}`}
                name={`aq-tf-${index + 1}`}
                id={`aq-tf-${index + 1}`}
                value={item}
                size="small"
                label={"Answer choice #" + (index + 1)}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleTextChange(event.target.value, index)
                }
                variant="filled"
                fullWidth={true}
                className={classes.fullWidth}
                classes={{ root: classes.mcRadioTextRoot }}
              />
            </span>
            <Button
              variant="contained"
              onClick={() => handleDeleteChoice(index)}
              className={classes.mcTrashBtn}
              size="large"
            >
              <DeleteIcon fontSize="small" />
            </Button>
          </div>
        ))}
      </RadioGroup>
    );
  };

  const getMultipleAnswerElement = () => {
    return (
      <FormGroup aria-label="multiAnswer">
        {choices.map((item: any, index: number) => (
          <div className={classes.radioRow} key={`aq-choices-${index + 1}`}>
            <FormControlLabel
              className={classes.mcRadioBtn}
              control={
                <Checkbox
                  onChange={(event) => handleChange(event, index)}
                  checked={
                    typeof answer === "object" &&
                    answer[index.toString()] !== undefined
                      ? answer[index.toString()]
                      : false
                  }
                  name={index.toString()}
                />
              }
              label=""
            />
            <span className={classes.mcRadioLabel}>
              <TextField
                key={`aq-tf-${index + 1}`}
                name={`aq-tf-${index + 1}`}
                id={`aq-tf-${index + 1}`}
                value={item}
                size="small"
                label={"Answer choice #" + (index + 1)}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleTextChange(event.target.value, index)
                }
                variant="filled"
                fullWidth={true}
                className={classes.fullWidth}
                classes={{ root: classes.mcRadioTextRoot }}
              />
            </span>
            <Button
              variant="contained"
              onClick={() => handleDeleteChoice(index)}
              className={classes.mcTrashBtn}
              size="large"
            >
              <DeleteIcon fontSize="small" />
            </Button>
          </div>
        ))}
      </FormGroup>
    );
  };

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">Choices</FormLabel>
      {props.type === AssessQuestionType.MultipleChoice &&
        getMultipleChoiceElement()}
      {props.type === AssessQuestionType.MultipleAnswer &&
        getMultipleAnswerElement()}
      {choices.length < maxNumChoices && (
        <Button
          className={classes.addAnswer}
          variant="contained"
          onClick={handleAddChoice}
        >
          <Typography
            component="span"
            variant="caption"
            className={classes.addAnswerText}
            classes={{ root: "learnidy-green" }}
          >
            + Add Answer
          </Typography>
        </Button>
      )}
    </FormControl>
  );
}
