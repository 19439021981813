import { DateTime, Duration } from "luxon";

export const GetCorrectedEndDateFromStartDate = (
  end: DateTime,
  start: DateTime
): DateTime => {
  let newEnd: DateTime = end.set({
    year: start.year,
    month: start.month,
    day: start.day,
  });

  // If this end time is less than the start time, then this event must be carrying over to the next day
  let diff: Duration = newEnd.diff(start, "minutes");
  if (diff.minutes < 0) {
    newEnd = newEnd.set({ day: start.day + 1 });
  }

  return newEnd;
};

export const CompareHourMinutes = (
  d1: DateTime | undefined,
  d2: DateTime | undefined
): boolean => {
  if (!d1 || !d2 || d1.isValid === false || d2.isValid === false) {
    console.error("Invalid start/end times for session plan");
    return false;
  }

  let result: boolean =
    DateTime.now().set({ hour: d1.hour, minute: d1.minute }) <
    DateTime.now().set({ hour: d2.hour, minute: d2.minute });

  return result;
};
