import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
// import { createStyles, makeStyles /*, Theme*/ } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import MediaViewer from "../../Presentation/MediaViewer";
import {
  PresentCard,
  PresentCardGroup,
  PresentDescText,
} from "../../Presentation/Presentation";
import "../../../styles/styles.css";
import UploadFiles from "../../../components/UploadFiles/UploadFiles";
import { JIGSAW_GROUPS_PER_SLIDE } from "../../../components/Activity/ActivityTypes";
import { LargeText } from "../../Presentation/Presentation";
import clsx from "clsx";
import Duration from "../Duration/Duration";
import { MediaType } from "../../../constants/media";

// const useStyles = makeStyles((theme: any) =>
//   createStyles({
//     radioFormControl: {
//       margin: 0,
//     },
//     radioGroup: {
//       margin: 0,
//     },
//     radioRoot: {
//       margin: 0,
//       padding: "2px 10px",
//     },
//   })
// );

interface JigsawProps {
  saveData: any;
  subslideIndex: number;
  id: string;
  sessionPlanId: string;
  color: string;
  validate?: boolean;
  onChange?: any;
  doSave?: boolean;
}

export function JigsawSlide(props: JigsawProps) {
  const [groupsPerSlide] = useState(JIGSAW_GROUPS_PER_SLIDE);

  const getCardWidth = () => {
    if (groupsPerSlide <= 0) return "100%";

    return Math.floor(100 / groupsPerSlide).toString() + "%";
  };

  const getStartIndex = (): number => {
    if (props.subslideIndex <= 0) return 0;

    return (props.subslideIndex - 1) * groupsPerSlide;
  };

  const getCardSlide = () => {
    let startIndx: number = getStartIndex();
    let instructions: any[] = [];
    for (
      let i = startIndx;
      i < startIndx + groupsPerSlide &&
      i < props.saveData.jigsawInstructions.length;
      ++i
    ) {
      instructions.push(props.saveData.jigsawInstructions[i]);
    }

    return (
      <React.Fragment>
        <PresentDescText
          value={`
          In your assigned small group, follow the instructions and begin 
          to research and develop your idea. Each small group then has the 
          responsibility to teach it to the rest of the group or class.`}
        />
        <PresentCardGroup equalSpace={false} justify="center" align="stretch">
          {instructions.map((instruction, index) => {
            return (
              <PresentCard
                key={"jigsaw-instruction-" + index}
                title={`Group ${startIndx + index + 1}`}
                value={instruction}
                color={props.color}
                width={getCardWidth()}
                size="xlarge"
                align="center"
              />
            );
          })}
        </PresentCardGroup>
      </React.Fragment>
    );
  };

  const getFirstSlide = () => {
    return (
      <React.Fragment>
        <PresentDescText
          value={`
          In your assigned small group, follow the instructions and begin 
          to research and develop your idea. Each small group then has the 
          responsibility to teach it to the rest of the group or class.`}
        />
        <LargeText
          // title="Jigsaw Prompt:"
          value={props.saveData.jigsawScenario}
          backgroundColor="learnidy-teal-bg"
          isMultiLine={false}
          size="large"
        />
        <MediaViewer uploadFiles={props.saveData.uploadFiles} />
      </React.Fragment>
    );
  };

  const getReporterSlide = () => {
    return (
      <React.Fragment>
        <PresentDescText value={`Share your findings.`} />
        <LargeText
          // title="Jigsaw Prompt:"
          value={`Have a group reporter share back with the larger group.`}
          backgroundColor="learnidy-teal-bg"
          isMultiLine={false}
          size="large"
        />
      </React.Fragment>
    );
  };

  const getSlide = () => {
    if (props.subslideIndex === 0) {
      return getFirstSlide();
    }

    let startIndx: number = getStartIndex();
    if (
      props.saveData.jigsawInstructions &&
      startIndx < props.saveData.jigsawInstructions.length
    ) {
      return getCardSlide();
    }

    return getReporterSlide();
  };

  return getSlide();
}

export default function JigsawForm(props: JigsawProps) {
  //   const classes = useStyles();
  const [hasLoaded, setHasLoaded] = useState(false);
  //   const [groupSettingsError] = React.useState(false);
  //   const [requiredText] = React.useState("Please select a choice");
  const [maxNumTopicGroups] = useState(9);
  const [saveData, setSaveData] = useState<any>({
    jigsawDuration:
      props.saveData && props.saveData.jigsawDuration
        ? props.saveData.jigsawDuration
        : 10,
    jigsawGroupSettings:
      props.saveData && props.saveData.jigsawGroupSettings
        ? props.saveData.jigsawGroupSettings
        : "",
    jigsawScenario:
      props.saveData && props.saveData.jigsawScenario
        ? props.saveData.jigsawScenario
        : "",
    jigsawNumTopicGroups:
      props.saveData && props.saveData.jigsawInstructions
        ? props.saveData.jigsawInstructions.length
        : 1,
    jigsawInstructions:
      props.saveData && props.saveData.jigsawInstructions
        ? props.saveData.jigsawInstructions
        : [""],
    uploadFiles:
      props.saveData && props.saveData.uploadFiles
        ? props.saveData.uploadFiles
        : [],
  });

  useEffect(() => {
    if (!hasLoaded) {
      if (props.onChange)
        props.onChange({
          jigsawDuration: saveData.jigsawDuration,
          jigsawInstructions: saveData.jigsawInstructions,
        });

      setHasLoaded(true);
    }
  }, [hasLoaded, saveData, props]);

  const handleDataChange = (key: string, value: any) => {
    if (!key) {
      return;
    }

    const data = { ...saveData };
    data[key] = value;
    setSaveData(data);

    if (props.onChange) {
      props.onChange({ [key]: value });
    }
  };

  const handleNumTopicChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const data = { ...saveData };
    let len: number = parseInt(event.target.value);
    if (!Number.isInteger(len)) len = 0;

    if (len < 0) len = 0;
    else if (len > maxNumTopicGroups) len = maxNumTopicGroups;

    if (!Array.isArray(data.jigsawInstructions)) return;

    const origLen = data.jigsawInstructions.length;
    data.jigsawInstructions.length = len;
    data.jigsawNumTopicGroups = len;

    if (len >= origLen) {
      data.jigsawInstructions.fill("", origLen);
    }

    setSaveData(data);
  };

  const handleInstructions = (index: number, value: string) => {
    const data = { ...saveData };
    data.jigsawInstructions[index] = value;
    setSaveData(data);

    if (props.onChange) {
      props.onChange({ jigsawInstructions: data.jigsawInstructions });
    }
  };

  const handleDurationChange = (val: string) => {
    handleDataChange("jigsawDuration", val);
  };

  const handleUploadFiles = (files: any) => {
    const data = { ...saveData };
    data.uploadFiles = [...files];
    setSaveData(data);

    if (props.onChange) {
      props.onChange({ uploadFiles: data.uploadFiles });
    }
  };

  return (
    <React.Fragment>
      <span className={clsx("activity-form-name", props.color)}>Jigsaw</span>
      <p className={"activity-form-desc"}>
        The Jigsaw technique gives learners the opportunity to specialize in one
        aspect of a topic, master the topic, and teach back the material to
        group members. This type of cooperative learning strategy allows
        individuals or small groups to become responsible for a subcategory of a
        larger topic. After researching and developing their idea, each
        individual or small group then has the responsibility to teach it to the
        rest of the group or class.
      </p>
      <div className={clsx("warmup-header-break", props.color + "-border")} />
      <div className={"warmup-duration"}>
        <Typography
          component="span"
          variant="subtitle1"
          className="warmup-tot-duration-text"
        >
          Total Duration:
        </Typography>
        <Duration
          time={saveData.jigsawDuration}
          onChange={handleDurationChange}
        />
      </div>
      <div className={"warmup-subsection"}>
        <div className={"warmup-duration"}>
          <Typography
            component="span"
            variant="subtitle2"
            className="warmup-subtitle"
          >
            Jigsaw Prompt
          </Typography>
        </div>
        <textarea
          className={"warmup-subtext"}
          placeholder="What jigsaw prompt do you want to provide all learners?"
          rows={2}
          value={saveData.jigsawScenario}
          onChange={(event) =>
            handleDataChange("jigsawScenario", event.target.value)
          }
        />
      </div>
      <div className={"warmup-subsection"}>
        <div>
          <Typography
            component="span"
            variant="subtitle2"
            className="warmup-subtitle"
          >
            Jigsaw Details
          </Typography>
        </div>
        <div className={"warmup-duration warmup-subsection-small"}>
          <Typography component="span" variant="body2">
            How many topic groups do you want to create?
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            value={saveData.jigsawNumTopicGroups}
            onChange={handleNumTopicChange}
          />
        </div>
      </div>
      {/* <div className={"warmup-subsection warmup-subsection-small"}>
        <FormControl
          component="fieldset"
          error={groupSettingsError}
          className={classes.radioFormControl}
        >
          <RadioGroup
            aria-label="number-topic-groups"
            name="number-topic-groups"
            value={saveData.jigsawGroupSettings}
            className={classes.radioGroup}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleDataChange("jigsawGroupSettings", event.target.value)
            }
          >
            <FormControlLabel
              value={"automatic"}
              control={<Radio classes={{ root: classes.radioRoot }} />}
              label={"Assign groups automatically"}
            />
            <FormControlLabel
              value={"manual"}
              control={<Radio classes={{ root: classes.radioRoot }} />}
              label={"Assign groups manually"}
            />
            <FormControlLabel
              value={"choose"}
              control={<Radio classes={{ root: classes.radioRoot }} />}
              label={"Let learners choose their group"}
            />
          </RadioGroup>
          <FormHelperText>{groupSettingsError && requiredText}</FormHelperText>
        </FormControl>
      </div> */}
      {saveData.jigsawInstructions.map((item: any, index: number) => {
        return (
          <div
            className={"warmup-subsection warmup-subsection-small"}
            key={"jigsaw-instr-" + index.toString()}
            id={"jigsaw-instr-" + index.toString()}
          >
            <div className={"warmup-duration"}>
              <Typography component="span" variant="body2">
                Topic Group #{index + 1} Instructions
              </Typography>
            </div>
            <textarea
              className={"warmup-subtext"}
              placeholder={`What instructions do you want to provide for the topic group #${
                index + 1
              }?`}
              rows={3}
              value={saveData.jigsawInstructions[index]}
              onChange={(event) =>
                handleInstructions(index, event.target.value)
              }
            />
          </div>
        );
      })}
      <UploadFiles
        uploadFiles={saveData.uploadFiles}
        mediaType={MediaType.Any}
        sessionPlanId={props.sessionPlanId}
        activityId={props.id}
        onChange={handleUploadFiles}
        doUpload={props.doSave}
      />
    </React.Fragment>
  );
}
