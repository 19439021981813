import { useState, useEffect } from "react";
import { Dialog, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import SignInForm from "../../components/SignIn/SignInForm";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    signInDialogSection: {
      width: "700px",
      display: "grid",
      gridTemplateColumns: "60% 1fr",
    },
    title: {
      marginBottom: 10,
      color: theme.palette.getContrastText(theme.palette.background.default),
    },
    subtitle: {
      marginBottom: 10,
      color: theme.palette.getContrastText(theme.palette.background.default),
    },
    formSection: {
      padding: "40px",
    },
    imageSection: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: theme.palette.grey[400],
    },
  })
);

interface SignInDialogProps {
  showCount: number;
}

export default function SignInDialog(props: SignInDialogProps) {
  const [showDialog, setShowDialog] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setShowDialog((val) => props.showCount > 0);
  }, [props.showCount]);

  const handleClose = () => {
    setShowDialog(false);
  };

  return (
    <Dialog
      fullScreen={false}
      open={showDialog}
      maxWidth={"lg"}
      aria-labelledby="break-dialog-title"
      onClose={handleClose}
    >
      <div className={classes.signInDialogSection}>
        <div className={classes.formSection}>
          <Typography component="h3" variant="h4" className={classes.title}>
            {"Sign in to Learnidy"}
          </Typography>
          <Typography component="h6" variant="h6" className={classes.subtitle}>
            Sign in to save this lesson plan to your account and discover the
            full power of Learnidy.
          </Typography>
          <SignInForm onSignIn={handleClose} />
        </div>
        <div className={classes.imageSection}>
          <img src="/static/images/signin-reminder.png" alt="" />
        </div>
      </div>
    </Dialog>
  );
}
