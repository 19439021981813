export const MEDIA_VIDEO = "video";
export const MEDIA_DOCUMENT = "document";
export const MEDIA_IMAGE = "image";

export enum MediaType {
  Video,
  Image,
  Document,
  Any,
}
