import { useState, useCallback, useRef, useEffect, useContext } from "react";
import { DateTime } from "luxon";
import "./SessionTabs.css";
import { makeDefaultActivities } from "../Activity/ActivityMaker";
import SessionPlanManager from "../SessionPlan/SessionPlanManager";
import SessionPlanPresent from "../SessionPlan/SessionPlanPresent";
import Sidebar from "../Sidebar/Sidebar";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { DragDropContext } from "react-beautiful-dnd";
import { getDefaultSessionEvent } from "../Session/CalendarEvent";
import { ActivityMode } from "../Activity/ActivityTypes";
import {
  getDefaultSessionPlan,
  getSaveSessionPlanFormat,
  SessionPlanModel,
} from "../../models/SessionPlanModel";
import firebase from "firebase/app";
import {
  auth,
  saveSessionPlanDB,
  querySessionPlan,
  createSessionPlanId,
  getBatch,
  commitBatch,
  saveSessionPlanBatchDB,
  saveSessionBatch,
  queryLatestSessionPlan,
} from "../../firebase/firebase";
import {
  logActivityAddEvent,
  logActivityRemoveEvent,
  logActivityEditEvent,
  logActivityShowEditEvent,
  logSlideshowEvent,
} from "../../firebase/analytics";
import { v4 as uuidv4 } from "uuid";
import { deleteFilesFromDB } from "../UploadFiles/UploadFiles";
import { getUpdatedDataFromSessionPlan } from "../Session/SessionModel";
import { Prompt, useHistory } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import SignInDialog from "../SignIn/SignInDialog";
import {
  HOME_ROUTE,
  SIGN_IN_ROUTE,
  SIGN_UP_ROUTE,
} from "../../constants/routes";
import { LOCAL_STORAGE_SESSIONPLAN_KEY } from "../../constants/session";
import { LessonContext } from "../../hooks/useLesson";
import { MenuContext } from "../../hooks/useMenu";

const useUnload = (fn: any) => {
  const cb = useRef(fn);

  useEffect(() => {
    const onUnload = cb.current;
    window.addEventListener("beforeunload", onUnload);
    return () => {
      window.removeEventListener("beforeunload", onUnload);
    };
  }, [cb]);
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sessionTabs: {
      display: "grid",
      gridTemplateColumns: "auto 1fr",
    },
    appBar: {
      gridColumn: "2 / 3",
      boxShadow: "none",
      backgroundColor: theme.palette.background.default,
      color: theme.palette.getContrastText(theme.palette.background.default),
    },
    tabList: {
      justifyContent: "center",
    },
    tabIndicator: {
      backgroundColor: theme.palette.primary.main,
    },
    tabSelected: {
      color: theme.palette.primary.main,
      fontWeight: "bold",
    },
    tabPanel: {
      height: "100%",
      padding: "0 30px",
    },
    tabPanelBox: {
      height: "100%",
      padding: 0,
    },
    present: {
      display: "flex",
    },
  })
);

enum AppState {
  Class = 0,
  Calendar = 1,
  Objectives = 2,
  Planner = 3,
  Review = 4,
  Present = 5,
}

export default function SessionTabs(props: any) {
  const classes = useStyles();
  const lesson = useContext(LessonContext);

  const defaultProps: {} = {
    start: DateTime.fromObject({
      hour: 9,
      minute: 0,
      second: 0,
      zone: "local",
    }),
    end: DateTime.fromObject({
      hour: 10,
      minute: 30,
      second: 0,
      zone: "local",
    }),
    name: "Session 1",
  };

  const [sessionPlan, setSessionPlan] = useState<SessionPlanModel>(
    getDefaultSessionPlan()
  );
  const [masterActivities] = useState(makeDefaultActivities(sessionPlan.id));
  const [classId, setClassId] = useState<string | undefined>(undefined);
  const [session /*, setSession*/] = useState(
    getDefaultSessionEvent(defaultProps)
  );
  const [appState, setAppState] = useState(AppState.Class);
  const [loadedPlanId, setLoadedPlanId] = useState<string | undefined>();
  const [saveTimeoutMs] = useState<number>(500);
  const [saveTimeoutId, setSaveTimeoutId] = useState<any>();
  const [showClassEvent, setShowClassEvent] = useState(0);
  const [signInDialogCount, setSignInDialogCount] = useState(0);
  const [nextLocation, setNextLocation] = useState<Location | null>(null);
  const [hasLocalStoragePlan, setHasLocalStoragePlan] = useState(
    localStorage[LOCAL_STORAGE_SESSIONPLAN_KEY] !== undefined
  );
  const [showAlert, setShowAlert] = useState(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const mounted: any = useRef();
  const mountedLatest: any = useRef();
  const history = useHistory();
  const menu = useContext(MenuContext);
  const sessionPlanRef: any = useRef();
  sessionPlanRef.current = sessionPlan;

  const setLesson = useCallback(
    (plan: SessionPlanModel) => {
      setSessionPlan(plan);
      lesson.updateSessionPlan(plan);
    },
    [lesson]
  );

  const removeLocalStorage = () => {
    localStorage.removeItem(LOCAL_STORAGE_SESSIONPLAN_KEY);
    setHasLocalStoragePlan(false);
  };

  const handleNewLessonPlan = useCallback(() => {
    setClassId(undefined);

    const plan: SessionPlanModel = getDefaultSessionPlan(null);
    setLesson(plan);
  }, [setLesson]);

  useEffect(() => {
    if (menu.clickedNewLesson) {
      handleNewLessonPlan();
      menu.onNewLesson(false);
    }
  }, [menu.clickedNewLesson, handleNewLessonPlan, menu]);

  useEffect(() => {
    if (menu.clickedPresent) {
      setAppState(AppState.Present);

      // Analytics Event: start slideshow
      logSlideshowEvent(true, sessionPlan.id, sessionPlan.classId);

      menu.onPresent(false);
    }
  }, [menu.clickedPresent, menu, sessionPlan.classId, sessionPlan.id]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user: any) => {
      // If the verified user just logged in, load their sessionPlan from memory
      // and save it to the DB
      if (!user) {
        return;
      }

      if (localStorage.learnidySessionPlan) {
        mountedLatest.current = true;
        const saveData: any = JSON.parse(localStorage.learnidySessionPlan);
        saveSessionPlanDB(saveData, saveData.id)
          .then(() => {
            removeLocalStorage();
            loadSessionPlan(saveData.id);
          })
          .catch((error: any) => {
            console.error("Session plan save from local error: ", error);
          });
      } else {
        loadLatestSessionPlan();
      }
    });

    return unsubscribe;
  });

  // Hook used for browser navigation
  useUnload((e: any) => {
    if (localStorage[LOCAL_STORAGE_SESSIONPLAN_KEY] !== undefined) {
      e.preventDefault();
      const exit = window.confirm("Are you sure you want to leave?");
      if (exit) {
        removeLocalStorage();
        window.close();
      }
    }
  });

  const saveToLocal = (plan: any) => {
    const saveData: any = getSaveSessionPlanFormat(plan);
    if (!saveData || !saveData.id) {
      return;
    }

    // Add lesson plan to local storage
    localStorage.setItem(
      LOCAL_STORAGE_SESSIONPLAN_KEY,
      JSON.stringify(saveData)
    );

    setHasLocalStoragePlan(true);
  };

  const save = useCallback(
    (plan: any, didSessionChange: boolean): Promise<any> => {
      if (!plan) return Promise.resolve(null);

      if (!auth.currentUser) {
        saveToLocal(plan);
        return Promise.resolve(null);
      }

      const saveData: any = getSaveSessionPlanFormat(plan);
      if (!saveData || !saveData.id) return Promise.resolve(null);

      // Only save session data if it changed to avoid unecessary save calls
      if (didSessionChange && plan.classId && plan.sessionId) {
        const batch: firebase.firestore.WriteBatch = getBatch();
        saveSessionPlanBatchDB(batch, saveData, saveData.id, true);

        const sessionData: any = getUpdatedDataFromSessionPlan(saveData);
        saveSessionBatch(
          batch,
          plan.classId,
          plan.sessionId,
          sessionData,
          true
        );
        return commitBatch(batch);
      } else {
        return saveSessionPlanDB(saveData, saveData.id);
      }
    },
    []
  );

  const handleSaveSessionPlan = useCallback(
    (plan: SessionPlanModel, didSessionChange: boolean) => {
      if (!plan) return;

      setLesson(plan);

      clearTimeout(saveTimeoutId);
      const timeoutId = setTimeout(() => {
        save(plan, didSessionChange)
          .then((data) => {
            if (data === undefined) console.log("Session plan saved!");
          })
          .catch((error: any) => {
            // console.error("Session plan save error: ", error);
          });
      }, saveTimeoutMs);

      setSaveTimeoutId(timeoutId);
    },
    [save, saveTimeoutId, saveTimeoutMs, setLesson]
  );

  const handleOnSaveActivity = useCallback(
    (activityData: any) => {
      if (!sessionPlanRef.current) return;

      let plan: SessionPlanModel = sessionPlanRef.current;
      let activities: any[] = [...plan.activities];

      let activity: any = activities.find((value) => {
        return value.id === activityData.id;
      });

      if (activity) {
        try {
          activity.saveData = { ...activityData.saveData };
        } catch (e) {
          // Can't overwrite saveData for Activities if they are loaded from the cloud
          // So set each property individually by undefining the original and then set the new save data
          for (let key in activity.saveData) {
            activity.saveData[key] = undefined;
          }

          for (let key in activityData.saveData) {
            activity.saveData[key] = activityData.saveData[key];
          }
        }

        // Analytics Event: activity edit
        logActivityEditEvent(
          activity.name,
          activity.id,
          activity.saveData,
          plan.id,
          plan.classId
        );

        // Set the session plan before save to avoid activity block render artifacts
        setLesson(plan);

        save(plan, false)
          .then((data) => {
            if (data === undefined) console.log("Session plan saved!");
          })
          .catch((error: any) => {
            console.error("Class create error: ", error);
          });
      }
    },
    [save, setLesson]
  );

  const handleOnDeleteActivity = useCallback(
    async (id: string) => {
      if (!sessionPlanRef.current) return;

      const index: number = sessionPlanRef.current.activities.findIndex(
        (value: any) => value.id === id
      );

      if (index !== -1) {
        const plan: SessionPlanModel = { ...sessionPlanRef.current };
        const removedActivities: any[] = plan.activities.splice(index, 1);
        if (removedActivities.length > 0) {
          const activity: any = removedActivities[0];
          if (activity && activity.saveData && activity.saveData.uploadFiles) {
            await deleteFilesFromDB(
              activity.saveData.uploadFiles,
              plan.id,
              activity.id,
              undefined
            );
          }

          // Analytics Event: activity remove
          logActivityRemoveEvent(
            activity.name,
            activity.id,
            plan.id,
            plan.classId
          );
        }

        handleSaveSessionPlan(plan, false);
      }
    },
    [handleSaveSessionPlan]
  );

  const handleOnShowEditForm = useCallback(
    (value: boolean, activity: any) => {
      if (!sessionPlanRef.current) return;

      // activity.showEditForm = value;
      const plan: SessionPlanModel = { ...sessionPlanRef.current };
      const items: any[] = [...plan.activities];
      const targetActivity: any = items.find(
        (value) => value.id === activity.id
      );

      if (targetActivity && targetActivity.showEditForm !== value) {
        targetActivity.showEditForm = value;
        plan.activities = items;
        setLesson(plan);

        if (targetActivity.showEditForm) {
          // Analytics Event: activity show edit
          logActivityShowEditEvent(
            targetActivity.name,
            targetActivity.id,
            plan.id,
            plan.classId
          );
        }
      }
    },
    [setLesson]
  );

  const loadSessionPlanFromData = useCallback(
    (data: any, id?: string) => {
      const newPlan: SessionPlanModel = getDefaultSessionPlan(data);
      setLesson(newPlan);
      setLoadedPlanId(id);
      setClassId(newPlan.classId);
    },
    [setLesson]
  );

  const loadSessionPlan = useCallback(
    (id: string) => {
      if (!id || id.length === 0) return;

      querySessionPlan(id)
        .then((doc) => {
          if (doc.exists) {
            const data = doc.data();
            if (data) {
              data.onSave = handleOnSaveActivity;
              data.onDelete = handleOnDeleteActivity;
              data.onShowEditForm = handleOnShowEditForm;

              loadSessionPlanFromData(data, id);
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    [
      handleOnSaveActivity,
      handleOnDeleteActivity,
      handleOnShowEditForm,
      loadSessionPlanFromData,
    ]
  );

  const loadLatestSessionPlan = useCallback(() => {
    // Did we already attempt to load latest on entry
    if (mountedLatest.current) {
      return;
    }

    // Load the most recent lesson plan
    mountedLatest.current = true;
    queryLatestSessionPlan().then((snapshot) => {
      snapshot.docs.forEach((doc: any) => {
        const data = doc.data();
        if (data) {
          data.onSave = handleOnSaveActivity;
          data.onDelete = handleOnDeleteActivity;
          data.onShowEditForm = handleOnShowEditForm;

          loadSessionPlanFromData(data, data.id);
          return;
        }
      });
    });
  }, [
    handleOnSaveActivity,
    handleOnDeleteActivity,
    handleOnShowEditForm,
    loadSessionPlanFromData,
  ]);

  // Load the local storage plan if there is one
  useEffect(() => {
    if (!mounted.current) {
      if (localStorage[LOCAL_STORAGE_SESSIONPLAN_KEY]) {
        const data: any = JSON.parse(
          localStorage[LOCAL_STORAGE_SESSIONPLAN_KEY]
        );
        data.onSave = handleOnSaveActivity;
        data.onDelete = handleOnDeleteActivity;
        data.onShowEditForm = handleOnShowEditForm;
        const plan: SessionPlanModel = getDefaultSessionPlan(data);
        setLesson(plan);
      }

      mounted.current = true;
    }
  }, [
    handleOnSaveActivity,
    handleOnDeleteActivity,
    handleOnShowEditForm,
    setLesson,
  ]);

  const handleOnDragEnd = (result: any) => {
    if (!sessionPlan) return;

    const { source, destination } = result;
    if (!destination || !source) {
      return;
    }

    if (
      destination.droppableId === "sessionPlanModules" &&
      source.droppableId === "sessionMasterGroup"
    ) {
      const activity: any = masterActivities.find(
        (value) => value.id === result.draggableId
      );

      if (activity) {
        // Clone the dragged activity which will go into the Session Panel
        const clonedActivity: any = Object.assign({}, activity);
        clonedActivity.id = uuidv4();
        clonedActivity.saveData.sessionPlanId = sessionPlan.id;
        clonedActivity.activeSession = true;
        clonedActivity.showEditForm = false;
        clonedActivity.onSave = handleOnSaveActivity;
        clonedActivity.onDelete = handleOnDeleteActivity;
        clonedActivity.onShowEditForm = (v: any) =>
          handleOnShowEditForm(v, clonedActivity);

        const plan: SessionPlanModel = { ...sessionPlan };
        const items = Array.from(plan.activities);
        items.splice(destination.index, 0, clonedActivity);
        plan.activities = items;
        // this.buildActivityTimes(items);

        // Analytics Event: activity add
        logActivityAddEvent(
          clonedActivity.name,
          clonedActivity.id,
          plan.id,
          plan.classId
        );

        // Set the session plan first to avoid artifacting lag on save
        setLesson(plan);

        // Save the plan
        handleSaveSessionPlan(plan, false);

        // setTimeout(() => {
        //   console.log("sessionActivities", this.state.sessionActivities);
        //   console.log("masterActivities", this.state.masterActivities);
        // }, 1);
      }
    } else if (
      destination.droppableId === "sessionPlanModules" &&
      source.droppableId === "sessionPlanModules"
    ) {
      const plan: SessionPlanModel = { ...sessionPlan };
      const items = Array.from(plan.activities);
      const [reorderedItem] = items.splice(source.index, 1);
      items.splice(destination.index, 0, reorderedItem);
      plan.activities = items;
      // this.buildActivityTimes(items);
      setLesson(plan);

      // Save the plan
      handleSaveSessionPlan(plan, false);
    }
  };

  //   public handleOnDragUpdate = (result: any) => {
  //     console.log("result", result);
  //   }

  const handlePresentClose = () => {
    setAppState(AppState.Review);

    // Analytics Event: start slideshow
    logSlideshowEvent(false, sessionPlan.id, sessionPlan.classId);
  };

  const handleUpdateSessionPlanLocal = (newData: any) => {
    let plan: SessionPlanModel = { ...sessionPlan, ...newData };
    setLesson(plan);
  };

  const handleLoadSessionPlan = (id: string) => {
    // setTabValue(AppState.Planner);
    setLoadedPlanId(id);
    loadSessionPlan(id);
  };

  const handleDeleteSessionPlan = (id: string) => {
    // Clear this plan if it is the one currently showing
    if (sessionPlan.id === id) {
      const plan: SessionPlanModel = getDefaultSessionPlan();
      setLesson(plan);
      setClassId(plan.classId);
    }
  };

  const handleLoadClass = (
    id: string,
    createPlanIfNotExist: boolean = false,
    showClass: boolean = false
  ) => {
    // setTabValue(AppState.Class);
    setAppState(AppState.Class);
    setClassId(id);

    // Create a session plan if it doesn't yet exist
    let planId: string | undefined =
      !sessionPlan.id && createPlanIfNotExist
        ? createSessionPlanId().id
        : sessionPlan.id;

    // Store the class Id in the session plan if this
    // is a valid session plan and it hasn't been stored yet
    if (planId && sessionPlan.classId !== id) {
      const plan: SessionPlanModel = {
        ...sessionPlan,
        id: planId,
        classId: id,
      };
      handleSaveSessionPlan(plan, false);
    }

    if (showClass) {
      setShowClassEvent((val) => val + 1);
    }
  };

  const handleAlertSignUp = () => {
    setShowAlert(false);
    setSignInDialogCount((val) => val + 1);
  };

  useEffect(() => {
    if (confirmedNavigation) {
      setConfirmedNavigation(false);
      if (nextLocation) {
        const path: string = nextLocation.pathname;
        setNextLocation(null);
        history.push(path);
      }
    }
  }, [confirmedNavigation, history, nextLocation]);

  const handleAlertDiscardChanges = () => {
    removeLocalStorage();
    setShowAlert(false);

    if (nextLocation) {
      setConfirmedNavigation(true);
    }
  };

  const handleAlertCancel = () => {
    setShowAlert(false);
  };

  const getShowNavigationAlert = (nextLoc: Location): boolean => {
    if (confirmedNavigation) {
      return true;
    }

    setNextLocation(nextLoc);

    if (
      nextLoc.pathname === SIGN_IN_ROUTE ||
      nextLoc.pathname === SIGN_UP_ROUTE ||
      nextLoc.pathname === HOME_ROUTE
    ) {
      setConfirmedNavigation(true);
      return true;
    }

    const result: boolean = hasLocalStoragePlan;
    setShowAlert(result);

    return !result;
  };

  const getAlertDialog = () => {
    return (
      <Dialog
        open={showAlert}
        onClose={handleAlertCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Would you like to save this lesson?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You are about to leave your lesson plan that is not saved. Create a
            free Learnidy account to save this lesson for later.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAlertSignUp} autoFocus>
            Sign up to save
          </Button>
          <Button
            onClick={handleAlertDiscardChanges}
            variant="contained"
            style={{ backgroundColor: "red", color: "white" }}
          >
            Discard changes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  //   public handleOnDragUpdate = (result: any) => {
  //     console.log("result", result);
  //   }

  if (appState === AppState.Present) {
    return (
      <div className={classes.present}>
        {/* <Sidebar
            onLoadSessionPlan={handleLoadSessionPlan}
            onLoadClass={handleLoadClass}
            masterActivities={masterActivities}
          /> */}
        <SessionPlanPresent
          sessionPlan={sessionPlan}
          activityMode={ActivityMode.SlideShow}
          onClose={handlePresentClose}
        />
      </div>
    );
  } else {
    const { when } = props;

    return (
      <div className={`${classes.sessionTabs} session-tabs`}>
        <DragDropContext
          onDragEnd={handleOnDragEnd}
          // onDragUpdate={this.handleOnDragUpdate}
        >
          <Sidebar
            masterActivities={masterActivities}
            sessionPlan={sessionPlan}
            onLoadSessionPlan={handleLoadSessionPlan}
            onDeleteSessionPlan={handleDeleteSessionPlan}
            onLoadClass={handleLoadClass}
          />
          <SessionPlanManager
            classId={classId}
            session={session}
            sessionPlan={sessionPlan}
            showClassEvent={showClassEvent}
            onSessionPlanChange={handleSaveSessionPlan}
            onLoadClass={handleLoadClass}
            onLoadSessionPlan={handleLoadSessionPlan}
            onUpdateSessionPlanLocal={handleUpdateSessionPlanLocal}
            loadedPlanId={loadedPlanId}
          />
        </DragDropContext>
        <Prompt when={when} message={(e: any) => getShowNavigationAlert(e)} />
        {getAlertDialog()}
        <SignInDialog showCount={signInDialogCount} />
      </div>
    );
  }
}
