import { Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import SignInForm from "../../components/SignIn/SignInForm";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "80vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: theme.palette.background.default,
    },
    title: {
      fontWeight: "bold",
      marginBottom: 10,
      color: theme.palette.primary.main,
    },
    subtitle: {
      fontWeight: "bold",
      marginBottom: 20,
      color: theme.palette.getContrastText(theme.palette.background.default),
    },
  })
);

export default function SignInScreen(props: any) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography component="h3" variant="h3" className={classes.title}>
        Learnidy
      </Typography>
      <Typography component="h5" variant="h5" className={classes.subtitle}>
        Sign In to your Educator account
      </Typography>
      <SignInForm {...props} />
    </div>
  );
}
