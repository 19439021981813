import React from "react";
import * as ReactDOM from "react-dom";
import { App } from "./components/App";
import registerServiceWorker from "./registerServiceWorker";
import "uikit/dist/css/uikit.css";
import GlobalStyles from "./styles/GlobalStyles";

ReactDOM.render(
  <React.Fragment>
    {/* <React.StrictMode> */}
    <GlobalStyles />
    <App />
    {/* </React.StrictMode> */}
  </React.Fragment>,
  document.getElementById("root")
);
registerServiceWorker();
