import { useState } from "react";
import { auth /*, createDbUser*/ } from "../../firebase/firebase";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import { HOME_ROUTE, RESET_PASSWORD_ROUTE } from "../../constants/routes";
import { Typography } from "@material-ui/core";
import {
  TERMS_AND_CONDITIONS_ROUTE,
  PRIVACY_POLICY_ROUTE,
} from "../../constants/routes";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "80vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: theme.palette.background.default,
    },
    card: {
      "& > *": {
        margin: theme.spacing(1),
      },
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      maxWidth: 400,
      minWidth: 275,
    },
    cardContent: {
      width: "auto",
    },
    textField: {
      width: "100%",
      marginBottom: 20,
    },
    formErrorMsg: {
      color: theme.palette.error.main,
      fontWeight: "bold",
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)",
    },
    title: {
      fontWeight: "bold",
      marginBottom: 10,
      color: theme.palette.primary.main,
    },
    subtitle: {
      fontWeight: "bold",
      marginBottom: 10,
      color: theme.palette.getContrastText(theme.palette.background.default),
    },
    haveAccountSection: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "16px",
    },
    haveAccountText: {},
    logIn: {
      fontWeight: "bold",
      marginLeft: "0.4em",
      color: theme.palette.primary.main,
    },
    termsText: {},
    pos: {
      marginBottom: 12,
    },
  })
);

interface SignInEmailFormProps {
  onCancel?: any;
}

export default function SignInEmailForm(props: SignInEmailFormProps) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formErrorMsg, setFormErrorMsg] = useState<string | null>(null);
  const history = useHistory();
  const classes = useStyles();

  const clearForm = (keepEmail: boolean) => {
    if (!keepEmail) setEmail("");

    setPassword("");
    setFormErrorMsg(null);
  };

  const onSubmit = (event: any) => {
    event.preventDefault();

    auth
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        clearForm(true);
        history.push(HOME_ROUTE);
      })
      .catch((error) => {
        if (error.message) {
          setFormErrorMsg(error.message);
        }
      });
  };

  const isFormInvalid = () => {
    return password === "" || email === "";
  };

  const handleOnCancel = (event: any) => {
    if (props.onCancel) {
      props.onCancel();
    }
  };

  const getFormElement = () => {
    return (
      <form onSubmit={(event) => onSubmit(event)}>
        <Card className={classes.card} variant="outlined">
          <CardContent className={classes.cardContent}>
            <TextField
              id="signup-email-input"
              className={classes.textField}
              label="Email Address"
              type="email"
              autoComplete="username"
              variant="outlined"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setFormErrorMsg(null);
              }}
            />
            <TextField
              id="signup-password-input"
              className={classes.textField}
              label="Password"
              type="password"
              autoComplete="new-password"
              variant="outlined"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setFormErrorMsg(null);
              }}
            />
            <Typography className={classes.formErrorMsg}>
              {formErrorMsg}
            </Typography>
            {/* {error && <p>{error?.message}</p>} */}

            <span className={classes.haveAccountSection}>
              <Typography
                component="span"
                variant="subtitle2"
                className={classes.haveAccountText}
              >
                Forgot password?
              </Typography>
              <Link to={RESET_PASSWORD_ROUTE} id="reset-password-link">
                <Typography
                  component="span"
                  variant="subtitle2"
                  className={classes.logIn}
                >
                  Reset Password
                </Typography>
              </Link>
            </span>

            <Typography variant="caption">
              {"By continuing, you are indicating that you accept our "}
              <Link to={TERMS_AND_CONDITIONS_ROUTE}>Terms of Service</Link>
              {" and "}
              <Link to={PRIVACY_POLICY_ROUTE}>Privacy Policy</Link>
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={(e) => onSubmit(e)}
              disabled={isFormInvalid()}
            >
              Sign In
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOnCancel}
            >
              Cancel
            </Button>
          </CardActions>
        </Card>
      </form>
    );
  };

  return getFormElement();
}
