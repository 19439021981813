export enum ActivityTypes {
  Placeholder,
  WarmUp,
  ReadWatchListen,
  Reflect,
  Discuss,
  Collaborate,
  Assess,
  Practice,
  Investigate,
  Evaluate,
  Break,
  Custom,
}

export interface ActivityFormProps {
  saveData: any;
  id: string;
  sessionPlanId: string;
  onSave?: any;
  onClose?: any;
  activityMode?: ActivityMode;
  subslideIndex?: number;
  color: string;
}

export const ActivityToNameMap: object = {
  Warmup: "Warm Up",
  ReadWatchListen: "Read, Watch, Listen",
  Reflect: "Reflect",
  Discuss: "Discuss",
  Collaborate: "Collaborate",
  Assess: "Assess",
  Practice: "Practice",
  Investigate: "Investigate",
  Evaluate: "Evaluate",
  Break: "Break",
  Custom: "Custom",
};

export const ActivityActionToNameMap: object = {
  doNow: "Do Now",
  question: "Question",
  "3-2-1": "3-2-1",
  SIT: "S.I.T.",
  kwl: "KWL",
  fishbowl: "Fishbowl",
  thinkPairShare: "Think, Pair, Share",
  fourCorners: "Four Corners",
  debate: "Debate",
  breakoutGroups: "Breakout Groups",
  jigsaw: "Jigsaw",
  groupWork: "Group Work",
  knowledgeCheck: "Knowledge Check",
  exitTicket: "Exit Ticket",
  trivia: "Trivia",
  roleplay: "Roleplay",
  iDoYouDo: "I Do, You Do",
  timeline: "Timeline",
  conceptMap: "Concept Map",
};

export const ActivityActionToResponseMap: object = {
  doNow: false,
  question: false,
  "3-2-1": true,
  SIT: true,
  fishbowl: false,
  thinkPairShare: true,
  fourCorners: true,
  debate: false,
  breakoutGroups: false,
  jigsaw: false,
  groupWork: false,
  knowledgeCheck: true,
  exitTicket: true,
  trivia: true,
  roleplay: false,
  iDoYouDo: false,
};

export enum ActivityMode {
  Edit,
  SlideShow,
  Print,
}

export const JIGSAW_GROUPS_PER_SLIDE: number = 3;
