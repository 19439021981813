import React, { useState, useEffect } from "react";
import MediaViewer from "../Presentation/MediaViewer";
import UploadFiles from "../UploadFiles/UploadFiles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import "../../styles/styles.css";
import {
  ActivityFormProps,
  ActivityMode,
  ActivityToNameMap,
} from "../Activity/ActivityTypes";
import {
  LargeText,
  Present,
  PresentDescText,
  PresentMinsText,
  PresentSubtitleText,
  presentStyles,
  PresentTitleText,
} from "../Presentation/Presentation";
import clsx from "clsx";
import Duration from "./Duration/Duration";
import {
  MEDIA_DOCUMENT,
  MEDIA_VIDEO,
  MEDIA_IMAGE,
} from "../../constants/media";
import { MediaType } from "../../constants/media";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentSection: {
      width: "100%",
      marginTop: 24,
    },
    playerSection: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      marginTop: 32,
    },
  })
);

export const getRoutineDesc = () => {
  return "Share video, images, or review documents with your class.";
};

export default function ReadWatchListenForm(props: ActivityFormProps) {
  const classes = useStyles();
  const presentClasses = presentStyles({});
  const [didLoad, setDidLoad] = useState(false);
  const [doSave, setDoSave] = useState(false);
  const [saveData, setSaveData] = useState({
    duration:
      props.saveData && props.saveData.duration ? props.saveData.duration : 10,
    contentType:
      props.saveData && props.saveData.contentType
        ? props.saveData.contentType
        : MEDIA_VIDEO,
    instructions:
      props.saveData && props.saveData.instructions
        ? props.saveData.instructions
        : "",
    url: props.saveData && props.saveData.url ? props.saveData.url : "",
    uploadFiles:
      props.saveData && props.saveData.uploadFiles
        ? props.saveData.uploadFiles
        : [],
  });

  useEffect(() => {
    if (!didLoad) {
      setDidLoad(true);
    }
  }, [didLoad, saveData]);

  const handleDurationChange = (val: string) => {
    setSaveData({ ...saveData, duration: val });
  };

  const handleInstructionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const data = { ...saveData };
    data.instructions = event.target.value;
    setSaveData(data);
  };

  const handleContentType = (event: React.ChangeEvent<{ value: unknown }>) => {
    const data = { ...saveData };
    data.contentType = event.target.value;
    setSaveData(data);
  };

  const handleUrlChange = (event: any) => {
    const data = { ...saveData };
    data.url = event.target.value;
    setSaveData(data);
  };

  const handleUploadFiles = (files: any) => {
    const data = { ...saveData };
    data.uploadFiles = [...files];
    setSaveData(data);

    if (doSave) {
      setDoSave(false);
      handleSaveResume(null);
    }
  };

  const handleSave = (event: any) => {
    // Images and documents must be uploaded to storage first
    if (
      saveData.contentType === MEDIA_DOCUMENT ||
      saveData.contentType === MEDIA_IMAGE
    ) {
      // Tell upload files to save first. Then, we resume saving when it calls back
      setDoSave(true);
    } else {
      handleSaveResume(event);
    }
  };

  const handleSaveResume = (event: any) => {
    if (props.onSave) {
      props.onSave({ saveData });
    }

    handleClose(event);
  };

  const handleClose = (event: any) => {
    if (props.onClose) {
      props.onClose(event);
    }
  };

  const getContentTypeName = (value: string): string => {
    // if (value === "presentation") return "Presentation";
    if (value === MEDIA_VIDEO) return "Video Link";
    // if (value === "link") return "Link";
    if (value === MEDIA_DOCUMENT) return "Document";
    if (value === MEDIA_IMAGE) return "Image";
    // if (value === "other") return "Other";
    return "Presentation";
  };

  const getUploadElement = () => {
    if (saveData.contentType === MEDIA_VIDEO) {
      return (
        <TextField
          label="https://www.youtube.com/watch?v=TMkoX1kfyDs"
          variant="outlined"
          className={classes.contentSection}
          value={saveData.url}
          onChange={handleUrlChange}
        />
      );
    } else if (saveData.contentType === MEDIA_DOCUMENT) {
      return (
        <div className={classes.contentSection}>
          <UploadFiles
            uploadFiles={saveData.uploadFiles}
            mediaType={MediaType.Document}
            sessionPlanId={props.sessionPlanId}
            activityId={props.id}
            onChange={handleUploadFiles}
            doUpload={doSave}
          />
        </div>
      );
    } else if (saveData.contentType === MEDIA_IMAGE) {
      return (
        <div className={classes.contentSection}>
          <UploadFiles
            uploadFiles={saveData.uploadFiles}
            mediaType={MediaType.Image}
            sessionPlanId={props.sessionPlanId}
            activityId={props.id}
            onChange={handleUploadFiles}
            doUpload={doSave}
          />
        </div>
      );
    }

    return null;
  };

  const getForm = () => {
    return (
      <div className={"activity-form"}>
        <Button
          className={"activity-form-close"}
          variant="outlined"
          onClick={handleClose}
        >
          <CloseRoundedIcon fontSize="small" />
        </Button>
        <span className={clsx("activity-form-name", props.color)}>
          {ActivityToNameMap["ReadWatchListen"]}
        </span>
        <p className={"activity-form-desc"}>{getRoutineDesc()}</p>
        <div className={clsx("warmup-header-break", props.color + "-border")} />
        <div className={"warmup-duration"}>
          <Typography
            component="span"
            variant="subtitle1"
            className="warmup-tot-duration-text"
          >
            Total Duration:
          </Typography>
          <Duration time={saveData.duration} onChange={handleDurationChange} />
        </div>
        <div className={"warmup-subsection"}>
          <Typography
            component="span"
            variant="subtitle1"
            className="warmup-tot-duration-text"
          >
            Describe your activity instructions.
            <span style={{ color: "red" }}>*</span>
          </Typography>
          <textarea
            className={"warmup-subtext"}
            placeholder="1. Introduce Material (2mins)
2. Show Presentation 20 mins)
3. Summarize (3mins)"
            rows={4}
            value={saveData.instructions}
            onChange={handleInstructionChange}
          />
        </div>
        <div className={"warmup-duration"}>
          <Typography
            component="span"
            variant="subtitle1"
            className="warmup-tot-duration-text"
          >
            Content Type:
          </Typography>
          <FormControl variant="outlined">
            <Select
              labelId="rwlContentTypeLabel"
              id="rwlContentTypeSelect"
              value={saveData.contentType}
              onChange={handleContentType}
            >
              <MenuItem value={MEDIA_VIDEO}>
                {getContentTypeName(MEDIA_VIDEO)}
              </MenuItem>
              <MenuItem value={MEDIA_DOCUMENT}>
                {getContentTypeName(MEDIA_DOCUMENT)}
              </MenuItem>
              <MenuItem value={MEDIA_IMAGE}>
                {getContentTypeName(MEDIA_IMAGE)}
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        {getUploadElement()}
        <div className={"warmup-footer"}>
          <Button variant="outlined" onClick={handleSave} disabled={doSave}>
            Save
          </Button>
        </div>
      </div>
    );
  };

  const getSlide = () => {
    return (
      <Present>
        <PresentTitleText
          value={ActivityToNameMap["ReadWatchListen"]}
          colorClass={props.color}
          icon={
            <VisibilityOutlinedIcon
              fontSize="large"
              className={clsx(props.color + "-bg", presentClasses.titleIcon)}
            />
          }
        />
        <PresentSubtitleText
          value={getContentTypeName(props.saveData.contentType)}
          colorClass={props.color}
        />
        <PresentMinsText value={props.saveData.duration} />
        <PresentDescText
          value={
            "Watch and listen to this presentation. Pay attention to the theme and key ideas."
          }
        />
        <LargeText
          value={props.saveData.instructions}
          backgroundColor={props.color + "-bg"}
        />
        <MediaViewer
          uploadFiles={
            props.saveData.contentType === MEDIA_IMAGE ||
            props.saveData.contentType === MEDIA_DOCUMENT
              ? props.saveData.uploadFiles
              : undefined
          }
          videoUrl={
            props.saveData.contentType === MEDIA_VIDEO
              ? props.saveData.url
              : undefined
          }
        />
      </Present>
    );
  };

  return props.activityMode === ActivityMode.SlideShow ? (
    getSlide()
  ) : (
    <Dialog
      fullScreen={false}
      open={true}
      maxWidth={"lg"}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <div className={"activity-dialog"} style={{ width: 700, height: "auto" }}>
        {getForm()}
      </div>
    </Dialog>
  );
}
