import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import { Button, Card, CardContent, CardHeader } from "@material-ui/core";
import { /*createStyles,*/ makeStyles, Theme } from "@material-ui/core/styles";
import "../../styles/styles.css";
import { buttonStyles } from "../../styles/ButtonStyles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
// import BookOutlinedIcon from "@material-ui/icons/BookOutlined";
// import DoneOutlineOutlinedIcon from "@material-ui/icons/DoneOutlineOutlined";
// import ForumOutlinedIcon from "@material-ui/icons/ForumOutlined";
// import ImageSearchOutlinedIcon from "@material-ui/icons/ImageSearchOutlined";
// import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
// import ReplayOutlinedIcon from "@material-ui/icons/ReplayOutlined";
// import StarBorderOutlinedIcon from "@material-ui/icons/StarBorderOutlined";
// import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
// import BatteryChargingFullIcon from "@material-ui/icons/BatteryChargingFull";
// import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import clsx from "clsx";

export const presentStyles = makeStyles((theme: Theme) => ({
  present: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    margin: "32px 80px",
  },
  titleIcon: {
    fontSize: "2.2rem",
    position: "absolute",
    left: 36,
    borderRadius: 6,
    color: theme.palette.common.white,
  },
  heroTextSection: {
    width: "100%",
    borderRadius: "20px",
    minHeight: "600px",
    margin: "30px 0 0 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  heroMultiTextSection: {
    width: "100%",
    borderRadius: "20px",
    marginTop: 30,
    padding: 30,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  largeTextSection: {
    width: "100%",
    borderRadius: "20px",
    minHeight: "200px",
    marginTop: 30,
    padding: 30,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  titleText: {
    fontWeight: "bold",
  },
  xlargeText: {
    fontSize: "3rem",
    margin: "20px o",
    color: theme.palette.primary.contrastText,
  },
  largeText: {
    fontSize: "2rem",
    lineHeight: "2.5rem",
    margin: 0,
    padding: 20,
    color: theme.palette.primary.contrastText,
  },
  mediumText: {
    fontSize: "1.5rem",
    margin: "20px 0",
    color: theme.palette.primary.contrastText,
  },
  smallText: {
    fontSize: "1.2rem",
    margin: "20px 0",
    color: theme.palette.primary.contrastText,
  },
  alignLeft: {
    textAlign: "left",
  },
  alignCenter: {
    textAlign: "center",
  },
  alignRight: {
    textAlign: "right",
  },
  minsText: {
    fontWeight: "bold",
  },
  descriptionText: {
    textAlign: "left",
    marginTop: 40,
  },
  mcSection: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: "2em",
    rowGap: "2em",
    marginTop: "2em",
  },
  mcText: {
    width: "100%",
    borderRadius: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  showAnswerSection: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: "2em",
  },
  showAnswerBtn: {
    width: 300,
    height: 50,
  },
  answerIcon: {
    color: theme.palette.common.white,
    fontSize: "3.5rem",
    position: "relative",
    right: "",
  },
  cardGroup: {
    width: "100%",
    display: "grid",
    gridAutoFlow: "column",
    gridAutoColumns: "1fr",
    gap: 20,
    marginTop: "2em",
  },
  cardGroupFlex: (props: any) => ({
    width: "100%",
    display: "flex",
    gap: 20,
    marginTop: "2em",
    justifyContent: props.cardGroupJustify || "unset",
    alignItems: props.cardGroupAlign || "unset",
  }),
  card: (props: any) => ({
    borderRadius: 20,
    overflow: "auto",
    width: props.cardWidth || "unset",
    height: props.cardHeight || "unset",
  }),
  cardDisabled: {
    opacity: "0.4",
  },
  cardHeaderContent: {
    borderBottom: "1px solid " + theme.palette.primary.contrastText,
  },
  cardPillSection: {
    marginBottom: 20,
  },
  cardPill: {
    backgroundColor: theme.palette.background.default,
    borderRadius: 100,
    textAlign: "center",
    padding: "10px 30px",
  },
  cardButton: {
    height: "100%",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

export function Present(props: any) {
  const classes = presentStyles({});
  return <div className={classes.present}>{props && props.children}</div>;
}

interface LargeTextProps {
  value: string;
  backgroundColor: string;
  title?: string;
  textbox?: string;
  size?: "xlarge" | "large" | "medium" | "small";
  minutes?: number;
  isMultiLine?: boolean;
  showAsAnswer?: boolean;
}

export function LargeText(props: LargeTextProps) {
  const classes = presentStyles({});

  const getTextClassSize = () => {
    if (props.size === "small") return classes.smallText;
    if (props.size === "medium") return classes.mediumText;
    if (props.size === "xlarge") return classes.xlargeText;

    return classes.largeText;
  };

  return (
    <div
      className={clsx(
        props.isMultiLine
          ? classes.heroMultiTextSection
          : classes.largeTextSection,
        props.backgroundColor
      )}
    >
      {props.title && (
        <Typography
          component="label"
          variant="h6"
          className={getTextClassSize()}
        >
          {props.title}
        </Typography>
      )}
      {props.value && (
        <Typography
          component="label"
          variant="h6"
          className={getTextClassSize()}
        >
          {props.value}
        </Typography>
      )}
      {props.textbox && (
        <Typography
          component="label"
          variant="h6"
          className={getTextClassSize()}
        >
          {props.textbox}
        </Typography>
      )}
      {props.minutes && (
        <Typography
          component="label"
          variant="body1"
          className={getTextClassSize()}
        >
          {`(${props.minutes} mins)`}
        </Typography>
      )}
    </div>
  );
}

interface PresentCardGroupProps {
  children: React.ReactNode;
  equalSpace?: boolean;
  align?: "start" | "center" | "end" | "stretch";
  justify?: "start" | "center" | "end" | "stretch";
}

export function PresentCardGroup(props: PresentCardGroupProps) {
  const styleProps: any = {
    cardGroupAlign: props.align || "center",
    cardGroupJustify: props.justify || "center",
  };
  const classes = presentStyles(styleProps);

  const getGroupClass = () => {
    if (props.equalSpace === false) return classes.cardGroupFlex;

    return classes.cardGroup;
  };

  return <div className={getGroupClass()}>{props.children}</div>;
}

interface PresentCardProps {
  value: string;
  color: string;
  title?: string;
  subtitle?: string;
  caption?: string;
  textbox?: string;
  button?: string;
  titleSize?: "xlarge" | "large" | "medium" | "small";
  size?: "xlarge" | "large" | "medium" | "small";
  align?: "start" | "center" | "end";
  width?: string;
  height?: string;
  disabled?: boolean;
  flipContent?: React.ReactNode;
}

export function PresentCard(props: PresentCardProps) {
  const styleProps: any = {
    cardWidth: props.width || "unset",
    cardHeight: props.height || "unset",
  };
  const classes = presentStyles(styleProps);
  const [showFlip, setShowFlip] = useState(false);

  const getTextClassSize = (size: string | undefined) => {
    if (size === "small") return classes.smallText;
    if (size === "medium") return classes.mediumText;
    if (size === "xlarge") return classes.xlargeText;

    return classes.largeText;
  };

  const getTextAlignClass = () => {
    if (props.align === "start") return classes.alignLeft;
    if (props.align === "center") return classes.alignCenter;
    if (props.align === "end") return classes.alignRight;

    return classes.alignLeft;
  };

  const handleFlipClick = (event: any) => {
    setShowFlip((val) => !val);
    event.stopPropagation();
  };

  const getContent = () => {
    if (showFlip) {
      return <CardContent>{props.flipContent}</CardContent>;
    } else {
      return (
        <CardContent classes={{}}>
          {props.subtitle && props.subtitle.length > 0 && (
            <div className={classes.cardPillSection}>
              <Typography
                component="label"
                className={clsx(classes.cardPill, props.color)}
                classes={{ root: getTextClassSize(props.size) }}
              >
                {props.subtitle}
              </Typography>
            </div>
          )}
          <Typography
            variant="h5"
            component="p"
            classes={{
              root: clsx(getTextClassSize(props.size), getTextAlignClass()),
            }}
          >
            {props.value}
          </Typography>
          {props.caption && (
            <Typography
              variant="subtitle2"
              component="p"
              classes={{
                root: clsx(getTextClassSize("medium"), classes.alignCenter),
              }}
            >
              {props.caption}
            </Typography>
          )}
        </CardContent>
      );
    }
  };

  const getContentAsButton = () => {
    return (
      <div
        className={clsx(classes.cardButton)}
        onClick={(event) => handleFlipClick(event)}
      >
        {getContent()}
      </div>
    );
  };

  return (
    <Card
      className={clsx(
        classes.card,
        props.color + "-bg",
        props.disabled ? classes.cardDisabled : null
      )}
    >
      {props.title && (
        <CardHeader
          title={props.title}
          // subheader="September 14, 2016"
          classes={{
            title: clsx(getTextClassSize(props.titleSize), classes.titleText),
            content: classes.cardHeaderContent,
          }}
        />
      )}
      {props.flipContent ? getContentAsButton() : getContent()}
    </Card>
  );
}

interface HeroTextProps {
  value: string;
  backgroundColor: string;
}

export function HeroText(props: HeroTextProps) {
  const classes = presentStyles(props);
  return (
    <div className={`${classes.heroMultiTextSection} ${props.backgroundColor}`}>
      <Typography component="label" variant="h6" className={classes.largeText}>
        {props.value}
      </Typography>
    </div>
  );
}

export function PresentChoiceText(props: LargeTextProps) {
  const classes = presentStyles(props);

  if (props.showAsAnswer === true || props.showAsAnswer === undefined) {
    return (
      <div
        className={`${classes.heroMultiTextSection} ${props.backgroundColor}`}
      >
        <Typography component="h6" variant="h6" className={classes.largeText}>
          {props.value}
        </Typography>
      </div>
    );
  }

  return null;
}

interface PresenTitleTextProps {
  value: string;
  colorClass: string;
  icon?: any;
}

export function PresentTitleText(props: PresenTitleTextProps) {
  const classes = presentStyles(props);

  return (
    <span>
      {props.icon}
      <Typography
        className={clsx(props.colorClass, classes.titleText)}
        component="h5"
        variant="h5"
      >
        {props.value}
      </Typography>
    </span>
  );
}

export function PresentSubtitleText(props: PresenTitleTextProps) {
  return (
    <Typography className={props.colorClass} component="h6" variant="h6">
      {props.value}
    </Typography>
  );
}

export function PresentMinsText(props: { value: string }) {
  const classes = presentStyles({});
  return (
    <Typography
      component="label"
      variant="subtitle1"
      classes={{
        root: classes.minsText,
      }}
    >
      {`${props.value} minutes`}
    </Typography>
  );
}

export function PresentDescText(props: { value: string }) {
  const classes = presentStyles({});
  return (
    <Typography
      component="label"
      variant="subtitle1"
      classes={{
        root: classes.descriptionText,
      }}
    >
      {props.value}
    </Typography>
  );
}

export function PresentMultipleChoice(props: any) {
  const classes = presentStyles({});

  return <div className={classes.mcSection}>{props.children}</div>;
}

export function PresentMultipleChoiceText(props: LargeTextProps) {
  const classes = presentStyles({});

  return (
    <div className={`${classes.mcText} ${props.backgroundColor}`}>
      <Typography component="label" variant="h6" className={classes.largeText}>
        {props.value}
      </Typography>
      {props.showAsAnswer && <CheckCircleIcon className={classes.answerIcon} />}
    </div>
  );
}

export function PresentAnswer(props: any) {
  const classes = presentStyles({});
  const buttonClasses = buttonStyles();
  const handleClick = (event: any) => {
    if (props.onShowAnswer) {
      props.onShowAnswer();
    }

    // Prevent going to the next slide if we are just clicking this button
    if (event) event.stopPropagation();
  };

  return (
    <div className={classes.showAnswerSection}>
      <Button
        className={clsx(buttonClasses.primary, classes.showAnswerBtn)}
        variant="outlined"
        onClick={handleClick}
        disabled={props.showAnswer}
      >
        Show Answer
      </Button>
    </div>
  );
}
