import React, { useState, useEffect, useCallback } from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteIcon from "@material-ui/icons/Delete";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { auth, storage } from "../../firebase/firebase";
import { IsAcceptableFileType } from "./FileTypes";
import { IconButton, ListItemSecondaryAction } from "@material-ui/core";
import SignInDialog from "../SignIn/SignInDialog";
import { MediaType } from "../../constants/media";

function buildFileSelector() {
  const fileSelector = document.createElement("input");
  fileSelector.setAttribute("type", "file");
  fileSelector.setAttribute("multiple", "multiple");
  return fileSelector;
}

const getUploadPath = (
  sessionPlanId: string | undefined,
  activityId: string | undefined,
  classId: string | undefined
): string | null => {
  if (!auth.currentUser) {
    return null;
  }

  if (!sessionPlanId && !activityId && !classId) {
    return null;
  }

  let refPath: string = "";
  if (sessionPlanId) {
    refPath = activityId
      ? `users/${auth.currentUser.uid}/sessionPlan/${sessionPlanId}/activity/${activityId}`
      : `users/${auth.currentUser.uid}/sessionPlan/${sessionPlanId}`;
  } else {
    refPath = `users/${auth.currentUser.uid}/classId/${classId}`;
  }

  return refPath;
};

export const deleteFilesFromDB = async (
  deletedFiles: any[],
  sessionPlanId: string | undefined,
  activityId: string | undefined,
  classId: string | undefined
) => {
  let refPath: string | null = getUploadPath(
    sessionPlanId,
    activityId,
    classId
  );
  if (!refPath) return;

  for (let i = 0; i < deletedFiles.length; ++i) {
    const file: any = deletedFiles[i];
    const fileRef = storage.ref(refPath).child(file.name);
    await fileRef
      .delete()
      .then(async (refPath) => {
        console.log("Deleted: " + file.name);
      })
      .catch((error) => {
        console.error(error);
      });
  }
};

export const uploadFilesToDB = async (
  newFiles: any[],
  selectedFiles: any[],
  sessionPlanId: string | undefined,
  activityId: string | undefined,
  classId: string | undefined
) => {
  let refPath: string | null = getUploadPath(
    sessionPlanId,
    activityId,
    classId
  );
  if (!refPath) return;

  for (let i = 0; i < newFiles.length; ++i) {
    const file: any = newFiles[i];
    const fileRef = storage.ref(refPath).child(file.name);
    await fileRef
      .put(file)
      .then(async () => {
        await fileRef.getDownloadURL().then((url) => {
          console.log("Uploaded: " + file.name);

          // Update the selected files with the upload url
          const currFile: any = selectedFiles.find((item) => {
            return item.name === file.name;
          });

          if (currFile) {
            currFile.url = url;
          }
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }
};

export const resolveFilesToDB = async (
  newFiles: any[],
  deletedFiles: any[],
  selectedFiles: any[],
  props: UploadFileProps
): Promise<any[]> => {
  const currSelectedFiles: any[] = [...selectedFiles];
  if (!auth.currentUser) {
    return currSelectedFiles;
  }

  if (!props.sessionPlanId && !props.classId) {
    console.error("Can't save to cloud. No session plan or Class Id");
    return currSelectedFiles;
  }

  await uploadFilesToDB(
    newFiles,
    currSelectedFiles,
    props.sessionPlanId,
    props.activityId,
    props.classId
  );
  await deleteFilesFromDB(
    deletedFiles,
    props.sessionPlanId,
    props.activityId,
    props.classId
  );
  return currSelectedFiles;
};

interface UploadFileProps {
  uploadFiles: any;
  onChange: any;
  mediaType: MediaType;
  sessionPlanId?: string;
  classId?: string;
  activityId?: string;
  doUpload?: boolean;
}

export default function UploadFiles(props: UploadFileProps) {
  const [fileSelector, setFileSelector] = useState<HTMLInputElement>();
  const [selectedFiles, setSelectedFiles] = useState(
    props.uploadFiles ? props.uploadFiles : []
  );
  const [newFiles, setNewFiles] = useState<any[]>([]);
  const [deletedFiles, setDeletedFiles] = useState<any[]>([]);
  const [sessionPlanId, setSessionPlanId] = useState(
    props.sessionPlanId ? props.sessionPlanId : undefined
  );
  const [classId, setClassId] = useState(
    props.classId ? props.classId : undefined
  );
  const [doUpload, setDoUpload] = useState<boolean | undefined>(
    props.doUpload ? props.doUpload : undefined
  );
  const [signInDialogCount, setSignInDialogCount] = useState(0);

  const handleFilesChange = useCallback(
    async (filesToAdd: any[], currProps: UploadFileProps) => {
      const allNewFiles: any[] = [...newFiles];
      const allFilesToSelect: any[] = [...currProps.uploadFiles];

      for (let i = 0; i < filesToAdd.length; ++i) {
        const currFileToAdd: any = filesToAdd[i];
        let indx: number = allFilesToSelect.findIndex((item: any) => {
          return item.name === currFileToAdd.name;
        });

        if (indx === -1) {
          let refPath: string | null = getUploadPath(
            currProps.sessionPlanId,
            currProps.activityId,
            currProps.classId
          );
          allFilesToSelect.push({ name: currFileToAdd.name, url: refPath });
          allNewFiles.push(currFileToAdd);
        }
      }

      setNewFiles(allNewFiles);
      setSelectedFiles(allFilesToSelect);
      if (currProps.onChange) {
        currProps.onChange(allFilesToSelect);
      }
    },
    [newFiles]
  );

  const checkIfFilePropsChanged = useCallback(() => {
    if (!props.uploadFiles || !selectedFiles) return false;

    if (props.uploadFiles.length !== selectedFiles.length) return true;

    return false;
  }, [selectedFiles, props.uploadFiles]);

  // Update the upload files when the props change
  useEffect(() => {
    setSelectedFiles(props.uploadFiles);
  }, [props.uploadFiles]);

  // Begin the upload if the doUpload flag has been set
  useEffect(() => {
    async function uploadData() {
      const updatedFiles: any[] = await resolveFilesToDB(
        newFiles,
        deletedFiles,
        selectedFiles,
        props
      );

      setSelectedFiles(updatedFiles);
      setNewFiles([]);
      setDeletedFiles([]);
      if (props.onChange) {
        props.onChange(selectedFiles);
      }
    }

    // Update the doUpload state on change
    if (props.doUpload !== doUpload) {
      setDoUpload(props.doUpload);

      // Upload the data if we just changed state and have doUpload set
      if (props.doUpload) {
        uploadData();
      }
    }
  }, [newFiles, selectedFiles, deletedFiles, props, doUpload]);

  // Create the file selector
  useEffect(() => {
    // Check if we have created a file selector yet.
    // If not, create one
    // If we have, then see if the files have changed before creating a new one
    let haveFilesChanged: boolean = fileSelector
      ? checkIfFilePropsChanged()
      : true;

    if (
      props.classId !== classId ||
      props.sessionPlanId !== sessionPlanId ||
      haveFilesChanged
    ) {
      setClassId(props.classId);
      setSessionPlanId(props.sessionPlanId);
      setSelectedFiles(props.uploadFiles);
      // }

      // if (!didLoad) {
      const fs = buildFileSelector();
      fs.onchange = (e: any) => {
        const { files } = e?.target;

        if (files && files.length) {
          const newFiles: any[] = Array.from(files).filter((element: any) => {
            const indx = props.uploadFiles.findIndex((item: any) => {
              return element.name === item.name;
            });

            return indx === -1;
          });

          if (newFiles.length === 0) return;

          handleFilesChange(newFiles, props);
        }
      };

      setFileSelector(fs);
    }
  }, [
    fileSelector,
    handleFilesChange,
    checkIfFilePropsChanged,
    props.uploadFiles,
    props.sessionPlanId,
    props.activityId,
    props.classId,
    sessionPlanId,
    classId,
    selectedFiles,
    props,
  ]);

  const handleUploadClick = (e: any) => {
    e.preventDefault();

    if (!auth.currentUser) {
      setSignInDialogCount((val) => val + 1);
      return;
    }

    fileSelector?.click();
  };

  const handleFileClick = (event: any, url: string, name: string) => {
    if (!url) {
      return;
    }

    const isAcceptable: boolean = IsAcceptableFileType(name);
    if (!isAcceptable) {
      return;
    }

    const xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = (event) => {
      const blob = xhr.response;
      console.log("blob: ", blob);
      saveBlob(blob, name);
    };

    xhr.open("GET", url);
    xhr.send();
  };

  const handleDeleteFile = (event: any, index: number) => {
    const files: any[] = [...selectedFiles];
    const removedFiles: any[] = files.splice(index, 1);
    if (removedFiles.length === 0) return;

    setSelectedFiles(files);
    const newFileIndx: number = newFiles.findIndex((value: any) => {
      return value.name === removedFiles[0].name;
    });
    if (newFileIndx !== -1) {
      const tempNewFiles: any[] = [...newFiles];
      tempNewFiles.splice(newFileIndx, 1);
      setNewFiles(tempNewFiles);
    } else {
      const delFiles: any[] = [...deletedFiles, ...removedFiles];
      setDeletedFiles(delFiles);
    }

    if (props.onChange) {
      props.onChange(files);
    }
  };

  const saveBlob = (blob: any, fileName: string) => {
    var a = document.createElement("a");
    a.href = window.URL.createObjectURL(blob);
    a.download = fileName;
    a.dispatchEvent(new MouseEvent("click"));
  };

  const getRenderSelectedFiles = () => {
    if (Array.isArray(selectedFiles) && selectedFiles.length > 0) {
      return (
        <List
          component="div"
          aria-labelledby="nested-list-subheader"
          style={{ width: "100%" }}
        >
          {selectedFiles.map((item: any, index: number) => {
            return (
              <ListItem key={`${item.name}${index}`} button>
                <ListItemText primary={item.name} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="comments"
                    onClick={(event) =>
                      handleFileClick(event, item.url, item.name)
                    }
                  >
                    <CloudDownloadIcon />
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="comments"
                    onClick={(event) => handleDeleteFile(event, index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      );
    }

    return null;
  };

  return (
    <React.Fragment>
      <div className={"warmup-upload"}>
        <div className={"warmup-upload-label"}>
          <Typography
            component="span"
            variant="subtitle1"
            className="warmup-tot-duration-text"
          >
            Optional: Upload materials
          </Typography>
          {(props.mediaType === MediaType.Document ||
            props.mediaType === MediaType.Any) && (
            <Typography
              component="span"
              variant="caption"
              className="warmup-label"
            >
              Documents: pdf, doc, docx, xls, xlsx, txt
            </Typography>
          )}
          {(props.mediaType === MediaType.Image ||
            props.mediaType === MediaType.Any) && (
            <Typography
              component="span"
              variant="caption"
              className="warmup-label"
            >
              Images: jpg, png, bmp, tiff
            </Typography>
          )}
        </div>
        <Button
          className={"warmup-upload-button"}
          variant="contained"
          onClick={handleUploadClick}
        >
          Upload
        </Button>
      </div>
      {getRenderSelectedFiles()}
      <SignInDialog showCount={signInDialogCount} />
    </React.Fragment>
  );
}
