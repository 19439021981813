import React, { useState, useEffect /*, useCallback*/ } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import FormControl from "@material-ui/core/FormControl";
import DeleteIcon from "@material-ui/icons/Delete";
import TextField from "@material-ui/core/TextField";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import NestedMenuItem from "@inmotio/material-ui-nested-menu-item";
import { createSessionPlanId } from "../../firebase/firebase";
import {
  logObjectiveEvent,
  ObjectiveEventType,
} from "../../firebase/analytics";
import { SessionPlanModel } from "../../models/SessionPlanModel";
import { widgetStyles } from "../../styles/WidgetStyles";
// import { buttonStyles } from "../../styles/ButtonStyles";
import clsx from "clsx";
import { getDefaultObjective, ObjectiveType } from "./ObjectiveModel";
import { BloomsVerbMap } from "./BloomsVerbs";
import Widget from "../Widget/Widget";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    multiItemRow: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      margin: "20px 0",
    },
    title: {
      margin: "20px 0",
      textAlign: "left",
    },
    sessionTimeSpan: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "baseline",
      gap: 20,
    },
    sessionTimeStart: {
      margin: "0 20px 0 0",
    },
    sessionTimeEnd: {
      margin: "0 0 0 20px",
    },
    objectiveSection: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    objectiveTitle: {
      fontWeight: "bold",
    },
    smallIconBtn: {
      fontSize: "1.6rem",
      minWidth: "unset",
      width: "2em",
    },
    addDateTimeBtnDisabled: {
      backgroundColor: theme.palette.action.disabledBackground,
      color: theme.palette.action.disabled,
    },
    bloomsSection: {
      display: "flex",
      flexDirection: "column",
    },
    bloomsLabel: {
      margin: 0,
    },
  })
);

const updateAnchors = (objectives: ObjectiveType[]) => {
  const anchors = objectives.map((value: ObjectiveType, index: number) => {
    return null;
  });

  return anchors;
};

interface LearningObjectivesProps {
  // session?: CalendarEvent;
  sessionPlan: SessionPlanModel;
  show: boolean;
  onCancel?: any;
  onSave?: any;
  onLoadSessionPlan?: any;
}

export default function LearningObjectives(props: LearningObjectivesProps) {
  const classes = useStyles();
  const widgetClasses = widgetStyles();
  //   const buttonClasses = buttonStyles();
  const [sessionPlanId, setSessionPlanId] = useState(props.sessionPlan.id);
  const [collapsed, setCollapsed] = useState(false);
  const [maxObjectives] = useState(5);
  const [objectives, setObjectives] = useState<ObjectiveType[]>(
    props.sessionPlan.objectives
  );
  const [anchorsEl, setAnchorsEl] = React.useState<any[]>(
    updateAnchors(props.sessionPlan.objectives)
  );

  useEffect(() => {
    if (sessionPlanId !== props.sessionPlan.id) {
      setObjectives(props.sessionPlan.objectives);
      setSessionPlanId(props.sessionPlan.id);
    }
  }, [sessionPlanId, props.sessionPlan.id, props.sessionPlan.objectives]);

  const handleCancel = () => {
    if (props.onCancel) {
      props.onCancel();
    }
  };

  const handleCollapse = (isCollapsed: boolean) => {
    setCollapsed(isCollapsed);
  };

  const handleBloomsMenuClose = () => {
    setAnchorsEl(updateAnchors(props.sessionPlan.objectives));
  };

  const handleBloomsBtnClick = (event: any, index: number) => {
    const anchors = [...anchorsEl];
    anchors[index] = event.currentTarget;
    setAnchorsEl(anchors);
  };

  const handleObjectiveChange = (event: any, index: number) => {
    if (!event || index >= objectives.length) return;

    const objs: ObjectiveType[] = [...objectives];
    objs[index].value = event.target.textContent;
    setObjectives(objs);
    handleBloomsMenuClose();

    // Analytics Event: objective edit
    logObjectiveEvent(
      ObjectiveEventType.Edit,
      objs[index].value,
      objs.length,
      props.sessionPlan.id,
      props.sessionPlan.classId ? props.sessionPlan.classId : null
    );

    const plan: SessionPlanModel = { ...props.sessionPlan, objectives: objs };
    savePlan(plan);
  };

  const handleAdditionalObjectiveChange = (
    event: React.ChangeEvent<{ value: unknown }>,
    index: number
  ) => {
    if (index >= objectives.length) return;

    const objs: ObjectiveType[] = [...objectives];
    objs[index].additional = event.target.value as string;
    setObjectives(objs);

    const plan: SessionPlanModel = { ...props.sessionPlan, objectives: objs };
    savePlan(plan);
  };

  const handleAddObjective = () => {
    if (!objectives || objectives.length >= maxObjectives) return;

    const objs: ObjectiveType[] = [...objectives];
    const objective: ObjectiveType = getDefaultObjective();
    objs.push(objective);
    setObjectives(objs);

    // Analytics Event: objective add
    logObjectiveEvent(
      ObjectiveEventType.Add,
      objective.value,
      objs.length,
      props.sessionPlan.id,
      props.sessionPlan.classId ? props.sessionPlan.classId : null
    );

    const plan: SessionPlanModel = { ...props.sessionPlan, objectives: objs };
    savePlan(plan);
  };

  const handleDeleteObjective = (index: number) => {
    const objs: ObjectiveType[] = [...objectives];
    const removedObjectives: ObjectiveType[] = objs.splice(index, 1);
    setObjectives(objs);

    if (removedObjectives.length > 0) {
      // Analytics Event: objective remove
      logObjectiveEvent(
        ObjectiveEventType.Remove,
        removedObjectives[0].value,
        objs.length,
        props.sessionPlan.id,
        props.sessionPlan.classId ? props.sessionPlan.classId : null
      );
    }

    const plan: SessionPlanModel = { ...props.sessionPlan, objectives: objs };
    savePlan(plan);
  };

  const savePlan = (plan: SessionPlanModel) => {
    if (!plan.id || plan.id.length === 0) {
      plan.id = createSessionPlanId().id;
    }

    if (props.onSave) props.onSave(plan);
  };

  const getLearningObjectives = () => {
    return (
      <div className={classes.root}>
        <div>
          <Typography
            component="div"
            variant="subtitle2"
            className={classes.title}
          >
            What do you want learners to walk away with after this class?
          </Typography>
          {objectives?.map((item: any, objIndex: number) => {
            return (
              <div key={"learningObjective" + objIndex}>
                <div
                  className={clsx(
                    widgetClasses.multiItemRow,
                    widgetClasses.alignToEnd
                  )}
                >
                  <Typography
                    component="label"
                    variant="body1"
                    className="class-label"
                  >
                    Learners will be able to...
                  </Typography>
                  <FormControl
                    variant="outlined"
                    className={"class-select"}
                    size="small"
                  >
                    <div className={classes.bloomsSection}>
                      <Typography
                        component="label"
                        variant="caption"
                        className={classes.bloomsLabel}
                      >
                        Blooms Taxonomy
                      </Typography>
                      <Button
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(event) =>
                          handleBloomsBtnClick(event, objIndex)
                        }
                        variant="contained"
                      >
                        {item.value}
                      </Button>
                    </div>
                    <Menu
                      id="blooms-menu"
                      anchorEl={anchorsEl[objIndex]}
                      keepMounted
                      open={Boolean(anchorsEl[objIndex])}
                      onClose={handleBloomsMenuClose}
                      //   label="Blooms Taxonomy"
                      //   onChange={(event) => handleObjectiveChange(event, index)}
                    >
                      {Object.entries(BloomsVerbMap).map(([key, value]) => {
                        return (
                          <NestedMenuItem
                            key={key}
                            label={key}
                            parentMenuOpen={Boolean(anchorsEl[objIndex])}
                            onClick={handleBloomsMenuClose}
                          >
                            {value.map((verb: string, verbIndex: number) => {
                              return (
                                <MenuItem
                                  key={verb + verbIndex}
                                  onClick={(event) =>
                                    handleObjectiveChange(event, objIndex)
                                  }
                                >
                                  {verb}
                                </MenuItem>
                              );
                            })}
                          </NestedMenuItem>
                        );
                      })}
                    </Menu>
                  </FormControl>
                  <Button
                    className={classes.smallIconBtn}
                    variant="contained"
                    onClick={() => handleDeleteObjective(objIndex)}
                  >
                    <DeleteIcon fontSize="small" />
                  </Button>
                </div>
                <div className={widgetClasses.widgetField}>
                  <TextField
                    id="classObjectives"
                    label="Enter in the rest of the learning objectives"
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    multiline={true}
                    rows={2}
                    value={item.additional}
                    onChange={(event) =>
                      handleAdditionalObjectiveChange(event, objIndex)
                    }
                  />
                </div>
              </div>
            );
          })}
          {objectives.length < maxObjectives && (
            <div className={classes.objectiveSection}>
              <Typography
                component="span"
                variant="subtitle1"
                className={classes.objectiveTitle}
              >
                Add learning objective
              </Typography>
              <Button
                className={classes.smallIconBtn}
                variant="contained"
                onClick={handleAddObjective}
              >
                <AddIcon fontSize="small" />
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  };

  const getWidgetRender = () => {
    return (
      <Widget
        title={"Learning Objectives"}
        startCollapsed={collapsed}
        onCollapse={handleCollapse}
        onCancel={handleCancel}
      >
        {getLearningObjectives()}
      </Widget>
    );
  };

  if (props.show) return getWidgetRender();
  else return null;
}
