import React, { useState } from "react";
import { questionStyles } from "../../../../styles/QuestionStyles";
import { QuestionProps } from "./QuestionTypes";
import TextField from "@material-ui/core/TextField";

const getDefaultChoices = (choices: any): string => {
  if (choices && typeof choices === "string") {
    return choices;
  }

  return "";
};

export function TextboxQuestion(props: QuestionProps) {
  const classes = questionStyles();
  const [answer, setAnswer] = useState(getDefaultChoices(props.answer));

  const handleQuestionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAnswer(event.target.value);
    if (props.onChange) props.onChange([], event.target.value);
  };

  return (
    <React.Fragment>
      <TextField
        className={classes.questionTextArea}
        variant="outlined"
        label={"Answer"}
        InputLabelProps={{ shrink: true }}
        placeholder={"Enter your answer here"}
        multiline
        rows={6}
        value={answer}
        onChange={handleQuestionChange}
      />
    </React.Fragment>
  );
}
