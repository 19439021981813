import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import "../../styles/styles.css";
import "./ClassInformation.css";
import { queryClasses } from "../../firebase/firebase";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Dialog,
  DialogActions,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { auth } from "../../firebase/firebase";
import { Link } from "react-router-dom";
import { SIGN_IN_ROUTE, SIGN_UP_ROUTE } from "../../constants/routes";
import { dialogStyles } from "../../styles/DialogStyles";
import { UserContext } from "../../hooks/useUser";
import { MenuContext } from "../../hooks/useMenu";
import SignInDialog from "../SignIn/SignInDialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: 14,
    },
    addEditBtn: {
      display: "flex",
      alignItems: "center",
      fontWeight: "bold",
    },
    seperatorText: {
      width: "40%",
      margin: "60px 0",
      padding: "6px 0",
      textAlign: "center",
      borderTop: "1px solid #dcdcdc",
      borderBottom: "1px solid #dcdcdc",
    },
    dlgRoot: {},
    dlgPaper: {
      overflowY: "unset",
    },
    classList: {
      overflowY: "auto",
      height: 200,
      width: "100%",
    },
    listItem: {
      color: theme.palette.primary.main,
      "&.MuiListItem-root:hover": {
        color: theme.palette.primary.main,
      },
    },
  })
);

interface ClassChangeProps {
  classId: string | undefined;
  onCreateClass?: any;
  onAddClass?: any;
  onShowClass?: any;
  onCancel?: any;
}

export default function ClassChange(props: ClassChangeProps) {
  const dialogClasses = dialogStyles();
  const classes = useStyles();
  const user = useContext(UserContext);
  const menu = useContext(MenuContext);
  const [classList, setClassList] = useState<any[]>([]);
  const [show, setShow] = useState(false);
  const [signInDialogCount, setSignInDialogCount] = useState(0);
  const [selected, setSelected] = useState<any>(null);
  const mounted: any = useRef();
  // const { isSignedIn, pending } = useAuth();

  const loadClassList = useCallback(() => {
    if (!user.loggedIn) return;

    setClassList([]);
    queryClasses()
      .then((snapshot) => {
        let newClassList: object[] = [];
        snapshot.docs.forEach((doc: any) => {
          newClassList.push({ id: doc.id, ...doc.data() });
        });

        setClassList(newClassList);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [user.loggedIn]);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    }
  });

  useEffect(() => {
    if (menu.clickedAddClass) {
      if (!auth.currentUser) {
        setSignInDialogCount((val) => val + 1);
        return;
      }

      setShow(true);
      loadClassList();
      menu.onAddClass(false);
    }
  }, [menu.clickedAddClass, menu, loadClassList]);

  const handleCreateNewClass = () => {
    if (props.onCreateClass) {
      props.onCreateClass();
    }
  };

  const handleDialogClose = () => {
    if (props.onCancel) props.onCancel();
    setShow(false);
  };

  const handleClassListClick = (event: any, item: any) => {
    if (!item.id) {
      console.error("Invalid class id selected!");
      return;
    }

    setSelected(item);
  };

  const handleClassAdd = () => {
    if (props.onAddClass) {
      props.onAddClass(selected.id);
      menu.onShowClass(true);
      setShow(false);
    }
  };

  const getClassTitle = (currClass: any) => {
    let title: string = "(No Title)";
    if (currClass.title && currClass.title.length > 0) title = currClass.title;
    else if (currClass.subject) {
      title = currClass.subject;
    }

    return title;
  };

  const getSignUpOrLoginText = () => {
    return (
      <Typography
        component="span"
        variant="subtitle1"
        // className={classes.logIn}
      >
        <Link to={SIGN_UP_ROUTE}>Sign Up</Link>
        {" or "}
        <Link to={SIGN_IN_ROUTE}>Log In</Link>
        {" to access saved classes "}
      </Typography>
    );
  };

  const getSelectClassSection = () => {
    if (user.loggedIn && classList.length === 0) {
      return <div className={dialogClasses.dlgVSpacer}></div>;
    }

    return (
      <React.Fragment>
        <Typography variant="button" className={classes.seperatorText}>
          or
        </Typography>
        <div className={dialogClasses.dlgInner}>
          <Typography variant="h6" className={classes.title}>
            {user.loggedIn
              ? "Select a class to use with this lesson"
              : getSignUpOrLoginText()}
          </Typography>
          <List
            component="div"
            aria-labelledby="class-list-subheader"
            className={classes.classList}
          >
            {classList.map((item: any, listIndex: number) => {
              return (
                <ListItem
                  button
                  key={"class_" + listIndex}
                  onClick={(e) => handleClassListClick(e, item)}
                  selected={item === selected}
                  // className={item === selected ? classes.listItem : ""}
                  classes={{ selected: classes.listItem }}
                >
                  <ListItemText primary={`${getClassTitle(item)}`} />
                  {item === selected && <CheckCircleIcon color="primary" />}
                </ListItem>
              );
            })}
          </List>
        </div>
      </React.Fragment>
    );
  };

  const getLoadDialogSection = () => {
    return (
      <React.Fragment>
        <div className={dialogClasses.dlgOuter}>
          <div className={dialogClasses.dlgInner}>
            <Typography variant="h6" className={classes.title}>
              Add a new class
            </Typography>
            <Button
              variant="contained"
              onClick={() => handleCreateNewClass()}
              className={classes.addEditBtn}
              color="primary"
            >
              <AddCircleIcon />
            </Button>
          </div>
          {getSelectClassSection()}
        </div>
        <DialogActions className={dialogClasses.dlgActions}>
          <Button
            variant="contained"
            autoFocus={false}
            onClick={handleClassAdd}
            color="primary"
            disabled={!selected}
          >
            Add
          </Button>
          <Button
            variant="contained"
            autoFocus={false}
            onClick={handleDialogClose}
            // color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </React.Fragment>
    );
  };

  const getChangeDialogRender = () => {
    return (
      <Dialog
        fullScreen={false}
        open={true}
        maxWidth={"lg"}
        className={classes.dlgRoot}
        classes={{ paperScrollPaper: classes.dlgPaper }}
        onClose={handleDialogClose}
        aria-labelledby="responsive-dialog-title"
      >
        {getLoadDialogSection()}
      </Dialog>
    );
  };

  return (
    <React.Fragment>
      {show && getChangeDialogRender()}
      <SignInDialog showCount={signInDialogCount} />
    </React.Fragment>
  );
}
