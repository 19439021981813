import React, { useState, useRef, useEffect, useCallback } from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Carousel from "react-material-ui-carousel";
import ReactPlayer from "react-player/lazy";
import DocViewer, {
  // TXTRenderer,
  // PDFRenderer,
  //   PNGRenderer,
  //   ImageProxyRenderer,
  //   BMPRenderer,
  //   JPG
  //  Renderer,
  //   TIFFRenderer,
  //   MSGRenderer,
  //   MSDocRenderer,
  //   HTMLRenderer,
  IDocument,
  // IHeaderOverride,
  DocViewerRenderers,
} from "react-doc-viewer";
import Dialog from "@material-ui/core/Dialog";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    carouselSection: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      marginTop: "4em",
    },
    cardSection: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 24,
    },
    card: {
      width: "200px",
    },
    playerSection: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      height: "100%",
      marginTop: "4em",
    },
    carousel: {
      // width: "100%",
    },
    docViewer: {
      width: "80vh",
      // height: "100vh",
      maxWidth: "unset",
      maxHeight: "unset",
      whiteSpace: "pre",
    },
    mediaDialog: {},
    mediaPaperRoot: {
      maxWidth: "unset",
      // width: "80vw",
      height: "100vh",
    },
  })
);

interface MediaViewerProps {
  uploadFiles?: any[];
  videoUrl?: string;
  onClose?: any;
}

export default function MediaViewer(props: MediaViewerProps) {
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState(false);
  const [dialogIndex, setDialogIndex] = useState(0);
  const [cardSections, setCardSections] = useState<any[]>([]);
  const [cardsPerSection] = useState(4);
  const mounted: any = useRef();

  const getCardContent = useCallback((file: any) => {
    const { fileType, image } = getFileType(file.name);

    if (
      fileType === "image/jpg" ||
      fileType === "image/jpeg" ||
      fileType === "image/png"
    ) {
      return (
        <React.Fragment>
          <CardMedia
            component="img"
            alt="PDF"
            image={file.url}
            title="PDF Document"
          />
          <CardContent>
            <Typography gutterBottom variant="body1" component="label">
              {file.name}
            </Typography>
          </CardContent>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <CardMedia
          component="img"
          alt="PDF"
          image={image}
          title="PDF Document"
        />
        <CardContent>
          <Typography gutterBottom variant="body1" component="label">
            {file.name}
          </Typography>
        </CardContent>
      </React.Fragment>
    );
  }, []);

  const createCardSections = useCallback(() => {
    const sections: any[] = [];
    if (!props.uploadFiles) {
      setCardSections(sections);
      return;
    }

    let section: any = null;
    for (let i = 0; i < props.uploadFiles.length; ++i) {
      const file: any = props.uploadFiles[i];
      if (i % cardsPerSection === 0) {
        section = [];
        sections.push(section);
      }

      section.push(
        <Card className={classes.card} key={"card-" + i}>
          <CardActionArea onClick={(event) => handleCardClick(event, i)}>
            {getCardContent(file)}
          </CardActionArea>
        </Card>
      );
    }

    setCardSections(sections);
  }, [cardsPerSection, props.uploadFiles, classes.card, getCardContent]);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    }
  });

  useEffect(() => {
    createCardSections();
  }, [props.uploadFiles, createCardSections]);

  const handleDialogClose = (event: any) => {
    setShowDialog(false);
    setDialogIndex(0);

    // Stop event propagation so we don't go to next slide
    if (event) event.stopPropagation();
  };

  const handleDialogClick = (event: any) => {
    // Stop event propagation so we don't go to next slide
    if (event) event.stopPropagation();
  };

  const handleCardClick = (event: any, index: number) => {
    setShowDialog(true);
    setDialogIndex(index);

    // Stop event propagation so we don't go to next slide
    if (event) event.stopPropagation();
  };

  // const customViewerHeader: IHeaderOverride = (
  //   state,
  //   previousDocument,
  //   nextDocument
  // ) => {
  //   const next = (event: any) => {
  //     nextDocument();
  //     if (event) event.stopPropagation();
  //   };

  //   const prev = (event: any) => {
  //     previousDocument();
  //     if (event) event.stopPropagation();
  //   };

  //   if (!state.currentDocument || state.config?.header?.disableFileName) {
  //     return null;
  //   }

  //   return (
  //     <React.Fragment>
  //       <div>
  //         <button onClick={prev} disabled={state.currentFileNo === 0}>
  //           Previous Document
  //         </button>
  //         <button
  //           onClick={next}
  //           disabled={state.currentFileNo >= state.documents.length - 1}
  //         >
  //           Next Document
  //         </button>
  //       </div>
  //     </React.Fragment>
  //   );
  // };

  const getFileType = (fileName: string) => {
    if (fileName.toLocaleLowerCase().endsWith(".jpg"))
      return { fileType: "image/jpg", image: "/static/images/jpg.png" };
    if (fileName.toLocaleLowerCase().endsWith(".jpeg"))
      return { fileType: "image/jpeg", image: "/static/images/jpg.png" };
    if (fileName.toLocaleLowerCase().endsWith(".png"))
      return { fileType: "image/png", image: "/static/images/png.png" };
    if (fileName.toLocaleLowerCase().endsWith(".txt"))
      return { fileType: "text/plain", image: "/static/images/txt.png" };
    else if (fileName.toLocaleLowerCase().endsWith(".pdf"))
      return { fileType: "application/pdf", image: "/static/images/pdf.png" };
    else if (fileName.toLocaleLowerCase().endsWith(".doc"))
      return {
        fileType: "application/msword",
        image: "/static/images/doc.png",
      };
    else if (fileName.toLocaleLowerCase().endsWith(".docx"))
      return {
        fileType:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        image: "/static/images/doc.png",
      };
    else if (fileName.toLocaleLowerCase().endsWith(".xls"))
      return {
        fileType: "application/vnd.ms-excel",
        image: "/static/images/xls.png",
      };
    else if (fileName.toLocaleLowerCase().endsWith(".xlsx"))
      return {
        fileType:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        image: "/static/images/xls.png",
      };

    return { fileType: "text/plain", image: "/static/images/txt.png" };
  };

  const getVideoPlayer = (url: string) => {
    return (
      <div className={classes.playerSection}>
        <ReactPlayer controls url={url} />
      </div>
    );
  };

  const getCards = () => {
    if (cardSections && cardSections.length > 0) {
      return (
        <div className={classes.carouselSection}>
          <div onClick={handleDialogClick}>
            <Carousel
              autoPlay={false}
              className={classes.carousel}
              animation={"slide"}
            >
              {cardSections.map((section: any, index: number) => {
                return (
                  <div
                    key={"card-section-" + index}
                    className={classes.cardSection}
                  >
                    {[...section]}
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
      );
    }

    return null;
  };

  const getDocViewer = () => {
    // let docs = [
    //     { uri: "https://url-to-my-pdf.pdf" },
    //     { uri: require("./example-files/pdf.pdf") }, // Local File
    //   ];

    // const docs: IDocument[] = props.uploadFiles.map((value: any, index: number) => {
    //   return { uri: value.url, fileType: getFileType(value.name) };
    // });

    let docs: IDocument[] = [];
    if (props.uploadFiles && dialogIndex < props.uploadFiles.length) {
      const file: any = props.uploadFiles[dialogIndex];
      const { fileType } = getFileType(file.name);
      docs = [{ uri: file.url, fileType }];
    }

    return (
      <div className={classes.playerSection}>
        <DocViewer
          documents={docs}
          className={classes.docViewer}
          pluginRenderers={
            DocViewerRenderers
            // [
            // TXTRenderer,
            // PDFRenderer,
            // PNGRenderer,
            // PNGRenderer,
            // ImageProxyRenderer,
            // BMPRenderer,
            // JPGRenderer,
            // TIFFRenderer,
            // MSGRenderer,
            // MSDocRenderer,
            // HTMLRenderer,
            // ]
          }
          config={{
            //header: { overrideComponent: customViewerHeader }
            header: {
              disableHeader: true,
              disableFileName: true,
              retainURLParams: true,
            },
          }}
        />
      </div>
    );
  };

  const getDialogMedia = () => {
    if (props.videoUrl) {
      return getVideoPlayer(props.videoUrl);
    }

    if (props.uploadFiles) {
      return getDocViewer();
    }

    return null;
  };

  const getContent = () => {
    if (props.videoUrl) {
      return getVideoPlayer(props.videoUrl);
    }

    if (props.uploadFiles) {
      return getCards();
    }

    return null;
  };

  return (
    <React.Fragment>
      {getContent()}
      <Dialog
        fullScreen={false}
        open={showDialog}
        onClose={handleDialogClose}
        onClick={handleDialogClick}
        aria-labelledby="media-viewer-title"
        className={classes.mediaDialog}
        classes={{ paper: classes.mediaPaperRoot }}
      >
        {getDialogMedia()}
      </Dialog>
    </React.Fragment>
  );
}
