import { useState, useEffect, useCallback } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import { functions } from "../../firebase/firebase";
import { SessionPlanModel } from "../../models/SessionPlanModel";
import { Jurisdiction, StandardSet, Standard } from "./StandardModel";
import { createSessionPlanId } from "../../firebase/firebase";
import Widget from "../Widget/Widget";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    row: {
      marginTop: 20,
    },
    multiItemRow: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      margin: "20px 0",
    },
    title: {
      textAlign: "left",
    },
    select: {
      minWidth: "20em",
      maxWidth: "35em",
    },
    standardDescription: {
      whiteSpace: "normal",
    },
  })
);

interface StandardsWidgetProps {
  sessionPlan: SessionPlanModel;
  show: boolean;
  onCancel?: any;
  onSave?: any;
  onLoadSessionPlan?: any;
}

const findJurisdiction = (
  jurisdictions: Jurisdiction[],
  id: string
): Jurisdiction | null => {
  if (!jurisdictions || !jurisdictions.length) return null;

  const result: Jurisdiction | undefined = jurisdictions.find(
    (value: Jurisdiction) => id === value.id
  );

  return result ? result : null;
};

const findStandardSet = (
  sets: StandardSet[],
  id: string
): StandardSet | null => {
  if (!sets || !sets.length) return null;

  const result: StandardSet | undefined = sets.find(
    (value: StandardSet) => id === value.id
  );

  return result ? result : null;
};

const findStandard = (stds: Standard[], s: Standard): Standard | null => {
  if (!stds || !stds.length) return null;

  const result: Standard | undefined = stds.find(
    (value: Standard) => s.id === value.id
  );

  return result ? result : null;
};

export default function StandardsWidget(props: StandardsWidgetProps) {
  const classes = useStyles();
  //   const widgetClasses = widgetStyles();
  //   const buttonClasses = buttonStyles();
  const [sessionPlanId, setSessionPlanId] = useState(props.sessionPlan.id);
  const [collapsed, setCollapsed] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [jurisdictions] = useState<Jurisdiction[]>([
    {
      id: "67810E9EF6944F9383DCC602A3484C23",
      title: "Common Core State Standards",
    },
    {
      id: "71E5AA409D894EB0B43A8CD82F727BFE",
      title: "Next Generation Science Standards",
    },
  ]);
  const [jurisdictionChoice, setJurisdictionChoice] = useState<Jurisdiction>();
  const [standardSets, setStandardSets] = useState<StandardSet[]>([]);
  const [standardSetChoice, setStandardSetChoice] =
    useState<StandardSet | null>();
  const [standards, setStandards] = useState<Standard[]>([]);
  const [standardChoices, setStandardChoices] = useState<Standard[]>(
    props.sessionPlan.standards ? props.sessionPlan.standards : []
  );
  const [canLoadStandards, setCanLoadStandards] = useState(false);

  const clearStandards = () => {
    setJurisdictionChoice(undefined);
    setStandardSetChoice(null);
    setStandardChoices([]);
  };

  const getStandardSets = useCallback(async (jurisdictionId: string) => {
    const url: string = "/jurisdictions/" + jurisdictionId;
    setIsFetching(true);
    const getCommonStandards = functions.httpsCallable("getCommonStandards");
    const response = await getCommonStandards({ urlParams: url });
    setIsFetching(false);
    if (response && response.data) {
      return parseStandardSetsResponse(response.data);
    } else {
      console.error("Could not fetch standards");
      return null;
    }
  }, []);

  const getStandards = useCallback(async (standardSetId: string) => {
    const url: string = "/standard_sets/" + standardSetId; // 67810E9EF6944F9383DCC602A3484C23,
    const getCommonStandards = functions.httpsCallable("getCommonStandards");
    const response = await getCommonStandards({ urlParams: url });
    if (response && response.data) {
      return parseStandardsResponse(response.data);
    } else {
      console.error("Couldn't get standards");
      return null;
    }
  }, []);

  const loadSavedStandards = useCallback(async () => {
    if (
      !props.sessionPlan.standards ||
      !Array.isArray(props.sessionPlan.standards) ||
      props.sessionPlan.standards.length === 0
    ) {
      clearStandards();
      return;
    }

    let s: Standard = props.sessionPlan.standards[0];
    if (!s.jurisdictionId || !s.standardSetId || !s.id) return;

    const j: Jurisdiction | null = findJurisdiction(
      jurisdictions,
      s.jurisdictionId
    );
    if (j === null) return;

    setJurisdictionChoice(j);

    const sets = await getStandardSets(s.jurisdictionId);
    if (sets === null) return;

    const set: StandardSet | null = findStandardSet(sets, s.standardSetId);
    if (set === null) return;

    setStandardSetChoice(set);

    const stds: Standard[] | null = await getStandards(s.standardSetId);
    if (stds === null) return;

    let choices: Standard[] = [];
    for (let i = 0; i < props.sessionPlan.standards.length; ++i) {
      s = props.sessionPlan.standards[i];
      let result: Standard | null = findStandard(stds, s);
      if (result) {
        choices.push(result);
      }
    }

    setStandardChoices(choices);
  }, [
    jurisdictions,
    getStandards,
    getStandardSets,
    props.sessionPlan.standards,
  ]);

  useEffect(() => {
    if (sessionPlanId !== props.sessionPlan.id) {
      setSessionPlanId(props.sessionPlan.id);
      setCanLoadStandards(true);
    }
  }, [sessionPlanId, props.sessionPlan.id]);

  useEffect(() => {
    if (canLoadStandards && props.show) {
      loadSavedStandards();
      setCanLoadStandards(false);
    }
  }, [props.show, loadSavedStandards, canLoadStandards]);

  const handleCancel = () => {
    if (props.onCancel) {
      props.onCancel();
    }
  };

  const handleCollapse = (isCollapsed: boolean) => {
    setCollapsed(isCollapsed);
  };

  const parseStandardSetsResponse = (data: any): StandardSet[] => {
    const setObjects: any = data.data.standardSets;
    const sets = setObjects.map((set: any) => {
      return {
        id: set.id,
        title: set.title,
        subject: set.subject,
      };
    });

    setStandardSetChoice(null);
    setStandardSets(sets);
    return sets;
  };

  const handleJurisdictionChange = (
    event: React.ChangeEvent<{ value: any }>
  ) => {
    const jurisdiction = jurisdictions.find(
      (s: any) => s.id === event.target.value
    );

    if (jurisdiction) {
      const choices: Standard[] = [];
      setStandardChoices(choices);
      setStandardSetChoice(null);
      setJurisdictionChoice(jurisdiction);
      getStandardSets(jurisdiction.id);

      const plan: SessionPlanModel = {
        ...props.sessionPlan,
        standards: choices,
      };
      savePlan(plan);
    }
  };

  const parseStandardsResponse = (data: any): Standard[] => {
    const rawStandards: any = data.data.standards;
    const newStandards: Standard[] = [];

    for (const key in rawStandards) {
      const s: any = rawStandards[key];
      newStandards.push({
        id: s.id,
        jurisdictionId: "",
        standardSetId: "", // standardSetChoice state isn't update yet by the time response completes
        asnIdentifier: s.asnIdentifier ? s.asnIdentifier : "",
        statementNotation: s.statementNotation ? s.statementNotation : "",
        description: s.description ? s.description : "",
      });
    }

    setStandardChoices([]);
    setStandards(newStandards);
    return newStandards;
  };

  const handleSubjectChange = (event: React.ChangeEvent<{ value: any }>) => {
    const set: StandardSet | undefined = standardSets.find(
      (standardSet: any) => standardSet.id === event.target.value
    );

    if (set) {
      const choices: Standard[] = [];
      setStandardChoices(choices);
      setStandardSetChoice(set);

      const plan: SessionPlanModel = {
        ...props.sessionPlan,
        standards: choices,
      };
      savePlan(plan);

      setTimeout(() => {
        getStandards(set.id);
      }, 10);
    }
  };

  const handleStandardChange = (
    event: React.ChangeEvent<{ value: any }>,
    index: number
  ) => {
    const selectedStandard: Standard | undefined = standards.find(
      (standardSet: any) => standardSet.id === event.target.value
    );

    if (!jurisdictionChoice || !standardSetChoice || !selectedStandard) return;

    let choices: Standard[] = [...standardChoices];
    choices[index] = selectedStandard;
    choices = choices.map((value, index) => {
      return {
        ...value,
        jurisdictionId: jurisdictionChoice.id,
        standardSetId: standardSetChoice.id,
      };
    });
    setStandardChoices(choices);

    const plan: SessionPlanModel = { ...props.sessionPlan, standards: choices };
    savePlan(plan);
  };

  const savePlan = (plan: SessionPlanModel) => {
    if (!plan.id || plan.id.length === 0) {
      plan.id = createSessionPlanId().id;
    }

    if (props.onSave) props.onSave(plan);
  };

  const getCircularProgressElement = () => {
    if (isFetching) {
      return (
        <CircularProgress
          style={{
            width: "100px",
            height: "100px",
            position: "relative",
            left: "40%",
            top: "30%",
            zIndex: 100,
          }}
        />
      );
    }

    return null;
  };

  const getJurisdictionLabel = () => {
    return jurisdictionChoice ? "Standards Group" : "Select Standards Group";
  };

  const getJurisdictionSelectElement = () => {
    return (
      <FormControl
        variant="outlined"
        className={clsx(classes.select, classes.row)}
      >
        <InputLabel id="standards-group-label">
          {getJurisdictionLabel()}
        </InputLabel>
        <Select
          labelId="standards-group-select-label"
          id="standards-group-select"
          label={getJurisdictionLabel()}
          value={jurisdictionChoice ? jurisdictionChoice.id : ""}
          onChange={handleJurisdictionChange}
          disabled={isFetching}
        >
          <MenuItem value={jurisdictions[0].id}>
            {jurisdictions[0].title}
          </MenuItem>
          <MenuItem value={jurisdictions[1].id}>
            {jurisdictions[1].title}
          </MenuItem>
        </Select>
      </FormControl>
    );
  };

  const getSubjectLabel = () => {
    return standardSetChoice ? "Subject" : "Select Subject";
  };

  const getSubjectsSelectElement = () => {
    return (
      <FormControl
        variant="outlined"
        className={clsx(classes.select, classes.row)}
      >
        <InputLabel id="subjects-group-label">{getSubjectLabel()}</InputLabel>
        <Select
          labelId="subjects-group-select-label"
          id="subjects-group-select"
          label={getSubjectLabel()}
          value={standardSetChoice ? standardSetChoice.id : ""}
          onChange={handleSubjectChange}
          disabled={!jurisdictionChoice || !standardSets || isFetching}
        >
          {standardSets.map((set: StandardSet, index: number) => {
            return (
              <MenuItem key={set.id} value={set.id}>
                <div>
                  <div>{set.subject}</div>
                  <div>{set.title}</div>
                </div>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  const getStandardsLabel = () => {
    return standardSetChoice ? "Standard" : "Choose a Standard";
  };

  const getStandardDisabled = (index: number): boolean => {
    if (!standardSetChoice) return true;
    if (isFetching) return true;

    if (index === 0) return false;

    if (index <= standardChoices.length) return false;

    return true;
  };

  const getStandardsSelectElement = (standardChoicesIndex: number) => {
    return (
      <FormControl
        variant="outlined"
        className={clsx(classes.select, classes.row)}
      >
        <InputLabel id={`standards-group-label-${standardChoicesIndex}`}>
          {getStandardsLabel()}
        </InputLabel>
        <Select
          labelId={`standards-group-select-label-${standardChoicesIndex}`}
          id={`standards-group-select-${standardChoicesIndex}`}
          label={getStandardsLabel()}
          value={
            standardChoices && standardChoices.length > standardChoicesIndex
              ? standardChoices[standardChoicesIndex].id
              : ""
          }
          onChange={(e) => handleStandardChange(e, standardChoicesIndex)}
          disabled={getStandardDisabled(standardChoicesIndex)}
        >
          {standards.map((s: Standard, index: number) => {
            return (
              <MenuItem key={s.id} value={s.id}>
                <div>
                  <div>{s.statementNotation}</div>
                  <div className={classes.standardDescription}>
                    {s.description}
                  </div>
                </div>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  const getTitle = () => {
    return (
      <div className={classes.root}>
        <div>
          <Typography
            component="div"
            variant="subtitle2"
            className={clsx(classes.title, classes.row)}
          >
            Search and add standards to your lesson plan
          </Typography>
        </div>
      </div>
    );
  };

  const getWidgetRender = () => {
    return (
      <Widget
        title={"Standards Alignment"}
        startCollapsed={collapsed}
        onCollapse={handleCollapse}
        onCancel={handleCancel}
      >
        {getTitle()}
        {getCircularProgressElement()}
        {getJurisdictionSelectElement()}
        {getSubjectsSelectElement()}
        {getStandardsSelectElement(0)}
        {getStandardsSelectElement(1)}
      </Widget>
    );
  };

  if (props.show) return getWidgetRender();
  else return null;
}
