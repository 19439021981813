import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const formStyles = makeStyles((theme: Theme) =>
  createStyles({
    formSection: {
      width: "100%",
      textAlign: "left",
      display: "flex",
      flexDirection: "column",
      margin: "10px 0 0 0",
    },
    formSubSection: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      margin: "15px 0 0 0",
    },
    textarea: {
      backgroundColor: theme.palette.grey[200],
    },
    formSubtitle: {
      marginBottom: "5px",
      fontWeight: "bold",
    },
    formText: {
      border: "none",
      /* padding: "10px", */
      fontSize: "0.88rem",
      fontFamily: `Nunito, "Roboto", Helvetica, sans-serif`,
      lineHeight: "1.4",
      resize: "none",
      overflowX: "hidden",
      overflowY: "auto",
    },
    formName: {
      fontSize: "1.4rem",
      fontWeight: "bold",
    },
    formDesc: {
      width: "100%",
      color: "#4d4d4d",
      fontSize: "0.85rem",
      fontFamily: `"Nunito", "Roboto", Helvetica, sans-serif`,
      fontWeight: 400,
      textAlign: "left",
    },
    headerBreak: {
      width: "100%",
      border: "1px solid",
    },
    durationText: {
      fontWeight: "bold",
    },
  })
);
