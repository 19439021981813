import React, { useState, useEffect, useRef, useContext } from "react";
import { DateObjectUnits, DateTime, Duration } from "luxon";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { calculateHeight } from "../Activity/Activity";
import {
  SESSION_STORAGE_HAS_SHOW_CLASS_SLIDE_KEY,
  SESSION_TIME_OFFSET,
} from "../../constants/session";
import { makeActivity } from "../Activity/ActivityMaker";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import {
  getDefaultSessionPlan,
  SessionPlanModel,
} from "../../models/SessionPlanModel";
import { createSessionPlanId } from "../../firebase/firebase";
import LessonDateTime from "../Lesson/LessonDateTime";
import { Button, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { buttonStyles } from "../../styles/ButtonStyles";
import clsx from "clsx";
import {
  widgetBorderStyle,
  widgetBorderRadius,
} from "../../styles/WidgetStyles";
import Grow from "@material-ui/core/Grow";
import { MenuContext } from "src/hooks/useMenu";

export const getSessionTimes = (plan: SessionPlanModel): DateTime[] => {
  const dou: DateObjectUnits = {
    year: 0,
    month: 1,
    day: 1,
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  };

  let times: DateTime[] = [];
  let currTime: DateTime = DateTime.fromObject(dou);
  let start: DateTime = DateTime.fromObject(dou);
  let end: DateTime | undefined = start;

  if (!plan.activities || plan.activities.length === 0) {
    return times;
  }

  if (
    plan.startTime &&
    plan.startTime.isValid &&
    plan.endTime &&
    plan.endTime.isValid
  ) {
    currTime = plan.startTime;
    end = plan.endTime;
  }

  // Add start time
  times.push(currTime);

  for (let i = 0; i < plan.activities.length; ++i) {
    const activity = plan.activities[i];
    currTime = currTime.plus({ minutes: activity.saveData.duration });
    times.push(currTime);
  }

  // times = Interval.fromDateTimes(plan.startTime, plan.endTime)
  //   .splitBy({ minutes: 15 })
  //   .map((d) => d.start);

  const diff: Duration = start.diff(end, ["days", "hours", "minutes"]);
  if (diff.hours > 0 || diff.minutes > 0) {
    // Add the end time
    times.push(end);
  }

  return times;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sessionPlanSection: {
      width: "32em",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
    },
    sessionPlanHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: 10,
    },
    sessionPlanContainer: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
      overflowY: "auto",
      border: widgetBorderStyle,
      borderRadius: widgetBorderRadius,
    },
    sessionPlanTextfield: {
      marginBottom: 10,
    },
    sessionDrop: {
      display: "flex",
      flexDirection: "row",
      flexGrow: 1,
      // height: "100%",
      // margin: "10px 0 0 0",
    },
    sessionPlanList: {
      flex: "1 1 auto",
      listStyleType: "none",
      // overflow: "hidden",
      margin: 0,
      padding: "10px 0 0 0",
      minWidth: 340,
    },
    sessionPlanTimes: {
      minWidth: 60,
      margin: "0 10px 0 0",
      padding: "10px 0 0 0",
      listStyleType: "none",
      // backgroundColor: theme.palette.background.paper,
      // borderRight: widgetBorderStyle,
    },
    sessionPlanDragLabel: {
      height: 100,
      border: "1px dashed",
      fontSize: "1.4rem",
      width: "12em",
      padding: "0 2em",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    menuBtn: {
      minWidth: 40,
      minHeight: 40,
      marginLeft: 10,
    },
    addDateTimeSection: {
      marginBottom: 20,
    },
    lessonTitle: {
      fontWeight: "bold",
    },
    addClassSection: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      margin: "8px 0px 8px 0px",
    },
    addClassBtn: {
      padding: 0,
      maxWidth: "unset",
      maxHeight: "unset",
      minWidth: "unset",
      minHeight: "unset",
      width: "3em",
      height: "3em",
      borderRadius: "100%",
    },
    addClassLabel: {
      marginLeft: 8,
      fontWeight: "bold",
    },
  })
);

interface SessionPlanProps {
  isDropDisabled?: boolean;
  sessionPlan: SessionPlanModel;
  onSave?: any;
  onLoad?: any;
  onSelectSession?: any;
  onShowClass?: any;
}

export default function SessionPlan(props: SessionPlanProps) {
  const classes = useStyles();
  const menu = useContext(MenuContext);
  const buttonClasses = buttonStyles();
  const [isDropDisabled] = useState(
    props.isDropDisabled ? props.isDropDisabled : false
  );
  const [sessionPlan, setSessionPlan] = useState<SessionPlanModel>(
    props.sessionPlan ? props.sessionPlan : getDefaultSessionPlan()
  );
  const [sessionTimes, setSessionTimes] = useState<DateTime[]>();
  const [hasShownClassSlide, setHasShownClassSlide] = useState(
    sessionStorage[SESSION_STORAGE_HAS_SHOW_CLASS_SLIDE_KEY]
      ? sessionStorage[SESSION_STORAGE_HAS_SHOW_CLASS_SLIDE_KEY] === "true"
      : false
  );
  const mounted: any = useRef();

  useEffect(() => {
    if (!mounted.current) {
      const times: DateTime[] = getSessionTimes(sessionPlan);
      setSessionTimes(times);
      mounted.current = true;
    }
  }, [sessionPlan]);

  useEffect(() => {
    if (props.sessionPlan) {
      setSessionPlan(props.sessionPlan);
      const times: DateTime[] = getSessionTimes(props.sessionPlan);
      setSessionTimes(times);
    }
  }, [props.sessionPlan, sessionPlan]);

  const handleNameChange = (event: any) => {
    if (!sessionPlan) return;

    const plan: SessionPlanModel = { ...sessionPlan, name: event.target.value };
    savePlan(plan, true);
  };

  const handleAddClass = () => {
    menu.onAddClass(true);
  };

  const handleAddSession = () => {
    if (props.onSelectSession) {
      props.onSelectSession();
    }
  };

  const handleDateChange = (event: any) => {
    const plan: SessionPlanModel = {
      ...props.sessionPlan,
      startTime: event.startTime,
      endTime: event.endTime,
    };
    savePlan(plan, true);
  };

  const savePlan = (plan: SessionPlanModel, didSessionChange: boolean) => {
    if (!plan.id || plan.id.length === 0) {
      plan.id = createSessionPlanId().id;
    }

    setSessionPlan(plan);

    if (props.onSave) {
      props.onSave(plan, didSessionChange);
    }
  };

  const getAddLessonDateTimeBtn = () => {
    if (props.sessionPlan.classId) {
      return (
        <div className={classes.addClassSection}>
          <Button
            variant="contained"
            onClick={handleAddSession}
            className={clsx(classes.addClassBtn, buttonClasses.alert)}
            // disabled={!props.sessionPlan || !props.sessionPlan.classId}
          >
            <AddIcon />
          </Button>
          <Typography
            component="label"
            variant="subtitle1"
            className={classes.addClassLabel}
          >
            Add date and time
          </Typography>
        </div>
      );
    } else {
      return null;
    }
  };

  const handleShowClassSlide = (event: any) => {
    setHasShownClassSlide(true);

    sessionStorage.setItem(
      SESSION_STORAGE_HAS_SHOW_CLASS_SLIDE_KEY,
      true.toString()
    );
  };

  const getAddButtonRender = () => {
    if (!props.sessionPlan || !props.sessionPlan.classId) {
      return (
        <div className={classes.addClassSection}>
          {(sessionPlan.activities.length > 0 || hasShownClassSlide) && (
            <Grow
              in={sessionPlan.activities.length > 0 || hasShownClassSlide}
              mountOnEnter
              unmountOnExit
              onEntered={handleShowClassSlide}
            >
              <div>
                <Button
                  variant="contained"
                  onClick={handleAddClass}
                  className={clsx(classes.addClassBtn, buttonClasses.alert)}
                  // disabled={props.sessionPlan.activities.length === 0}
                >
                  <AddIcon />
                </Button>
                <Typography
                  component="label"
                  variant="subtitle1"
                  className={classes.addClassLabel}
                >
                  Add class, date, and time
                </Typography>
              </div>
            </Grow>
          )}
        </div>
      );
    } else {
      return null;
    }
  };

  const getRenderTimes = () => {
    return (
      <ul className={classes.sessionPlanTimes}>
        {sessionTimes &&
          sessionTimes.map((dt: DateTime, index: number) => {
            let mins: number = SESSION_TIME_OFFSET;
            if (index < sessionTimes.length - 1) {
              mins =
                sessionTimes[index + 1].hour * 60 +
                sessionTimes[index + 1].minute -
                (dt.hour * 60 + dt.minute);
            }

            return (
              <li
                key={"session-time-" + index}
                style={{
                  height: calculateHeight(mins).toString() + "px",
                  position: "relative",

                  top: index > 0 ? -30 : 0,
                }}
              >
                {sessionPlan.sessionId
                  ? dt.toLocaleString(DateTime.TIME_SIMPLE)
                  : dt.diff(sessionTimes[0]).toFormat("hh:mm")}
                {index > 0 && (
                  <div
                    style={{
                      borderTop: "1px dashed #ccc",
                      width: 74,
                      position: "absolute",
                    }}
                  />
                )}
              </li>
            );
          })}
      </ul>
    );
  };

  return (
    <React.Fragment>
      <div className={classes.sessionPlanSection}>
        <div className={classes.sessionPlanHeader}>
          <Typography
            component="h6"
            variant="h6"
            className={classes.lessonTitle}
          >
            Lesson Plan
          </Typography>
        </div>
        <TextField
          label="Title"
          className={classes.sessionPlanTextfield}
          variant="outlined"
          size="small"
          value={sessionPlan?.name}
          onChange={handleNameChange}
        />
        {(props.sessionPlan?.sessionId && (
          <LessonDateTime
            sessionPlan={props.sessionPlan}
            isDisplayOnly={true}
            onDateChange={handleDateChange}
          />
        )) ||
          getAddLessonDateTimeBtn()}
        {getAddButtonRender()}
        <div className={classes.sessionPlanContainer}>
          <Droppable
            droppableId="sessionPlanModules"
            isDropDisabled={isDropDisabled}
          >
            {(providedProp: any) => (
              <div className={classes.sessionDrop}>
                {getRenderTimes()}
                <ul
                  className={classes.sessionPlanList}
                  {...providedProp.droppableProps}
                  ref={providedProp.innerRef}
                >
                  {sessionPlan && sessionPlan.activities ? (
                    sessionPlan.activities.length === 0 ? (
                      <div className={classes.sessionPlanDragLabel}>
                        Drag your first activity here
                      </div>
                    ) : (
                      sessionPlan.activities.map(
                        (activity: any, index: number) => {
                          return (
                            <Draggable
                              key={activity.id}
                              draggableId={activity.id}
                              index={index}
                              isDragDisabled={
                                activity.showEditForm || isDropDisabled
                              }
                            >
                              {(provided: any) => (
                                <li
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  {makeActivity(
                                    activity.type,
                                    sessionPlan.id,
                                    true,
                                    activity
                                  )}
                                </li>
                              )}
                            </Draggable>
                          );
                        }
                      )
                    )
                  ) : null}
                  {providedProp.placeholder}
                </ul>
              </div>
            )}
          </Droppable>
        </div>
      </div>
    </React.Fragment>
  );
}
