import React, { useState, useEffect } from "react";
import UploadFiles from "../../UploadFiles/UploadFiles";
import MenuItem from "@material-ui/core/MenuItem";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Select from "@material-ui/core/Select";
// import { createStyles, makeStyles /*, Theme*/ } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AssessQuestion, { AssessQuestionSlide } from "./AssessQuestion";
import { questionStyles } from "../../../styles/QuestionStyles";
import "../../../styles/styles.css";
import {
  AssessQuestionType,
  AssessQuestionTypes,
  getAssessQuestionTypeString,
} from "./Questions/QuestionTypes";
import { Button } from "@material-ui/core";
import clsx from "clsx";
import Duration from "../Duration/Duration";
import { MediaType } from "../../../constants/media";

// const useStyles = makeStyles((theme: any) =>
//   createStyles({
//     radioFormControl: {
//       margin: 0,
//     },
//     radioGroup: {
//       margin: 0,
//     },
//     radioRoot: {
//       margin: 0,
//       padding: "2px 10px",
//     },
//   })
// );

const getRoutinePromptText = () => {
  return "Exit Ticket Question:";
};

interface ExitTicketProps {
  saveData: any;
  id: string;
  sessionPlanId: string;
  subslideIndex: number;
  color: string;
  validate?: boolean;
  onChange?: any;
  doSave?: boolean;
}

export function ExitTicketSlide(props: ExitTicketProps) {
  const [showAnswer, setShowAnswer] = useState(false);

  // Hide the answer when the slide changes
  useEffect(() => {
    setShowAnswer(false);
  }, [props.subslideIndex]);

  const handleShowAnswer = () => {
    setShowAnswer(true);
  };

  if (
    props.saveData.exitTicketQuestions &&
    props.subslideIndex < props.saveData.exitTicketQuestions.length
  ) {
    const question: any =
      props.saveData.exitTicketQuestions[props.subslideIndex];

    if (question) {
      return (
        <React.Fragment>
          <AssessQuestionSlide
            question={question}
            subslideIndex={props.subslideIndex}
            showAnswer={showAnswer}
            onShowAnswer={handleShowAnswer}
            uploadFiles={props.saveData.uploadFiles}
          />
        </React.Fragment>
      );
    }
  }

  return <React.Fragment></React.Fragment>;
}

const getDefaultQuestion = (): object => {
  return { type: AssessQuestionType.Textbox, question: "", choices: null };
};

export default function ExitTicketForm(props: ExitTicketProps) {
  //   const classes = useStyles();
  const questionClasses = questionStyles();
  const [maxQuestions] = useState(10);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [saveData, setSaveData] = useState<any>({
    exitTicketDuration:
      props.saveData && props.saveData.exitTicketDuration
        ? props.saveData.exitTicketDuration
        : 10,
    exitTicketQuestions:
      props.saveData && props.saveData.exitTicketQuestions
        ? props.saveData.exitTicketQuestions
        : [getDefaultQuestion()],
    uploadFiles:
      props.saveData && props.saveData.uploadFiles
        ? props.saveData.uploadFiles
        : [],
  });

  useEffect(() => {
    if (!hasLoaded) {
      if (props.onChange)
        props.onChange({
          exitTicketDuration: saveData.exitTicketDuration,
        });

      setHasLoaded(true);
    }
  }, [hasLoaded, saveData, props]);

  const handleDataChange = (key: string, value: any) => {
    if (!key) {
      return;
    }

    const data = { ...saveData };
    data[key] = value;
    setSaveData(data);

    if (props.onChange) {
      props.onChange({ [key]: value });
    }
  };

  const handleDurationChange = (val: string) => {
    handleDataChange("exitTicketDuration", val);
  };

  const handleQuestionChange = (index: number, value: string) => {
    const data = { ...saveData };
    const question: any = data.exitTicketQuestions[index];
    question.question = value;
    setSaveData(data);

    if (props.onChange) {
      props.onChange({ exitTicketQuestions: data.exitTicketQuestions });
    }
  };

  const handleQuestionSelectChange = (index: number, value: unknown) => {
    const data = { ...saveData };
    const question: any = data.exitTicketQuestions[index];
    question.type = value;

    setSaveData(data);
  };

  const handleChoiceChange = (
    index: number,
    choices: any,
    answer: string | number | object | undefined
  ) => {
    const data = { ...saveData };
    const question: any = data.exitTicketQuestions[index];
    question.choices = choices;
    question.answer = answer;

    setSaveData(data);

    if (props.onChange) {
      props.onChange({ exitTicketQuestions: data.exitTicketQuestions });
    }
  };

  const handleAddQuestion = () => {
    const data = { ...saveData };
    if (
      !data.exitTicketQuestions ||
      data.exitTicketQuestions.length >= maxQuestions
    )
      return;

    data.exitTicketQuestions.push(getDefaultQuestion());
    setSaveData(data);

    if (props.onChange) {
      props.onChange({ exitTicketQuestions: data.exitTicketQuestions });
    }
  };

  const handleDeleteQuestion = (index: number) => {
    const data = { ...saveData };
    if (
      !data.exitTicketQuestions ||
      index < 0 ||
      index >= data.exitTicketQuestions.length
    ) {
      return;
    }

    data.exitTicketQuestions.splice(index, 1);
    setSaveData(data);
    if (props.onChange) {
      props.onChange({ exitTicketQuestions: data.exitTicketQuestions });
    }
  };

  const handleUploadFiles = (files: any) => {
    const data = { ...saveData };
    data.uploadFiles = [...files];
    setSaveData(data);

    props.onChange({
      uploadFiles: data.uploadFiles,
    });
  };

  const getFormElement = () => {
    return (
      <React.Fragment>
        <span className={clsx("activity-form-name", props.color)}>
          Exit Ticket
        </span>
        <p className={"activity-form-desc"}>
          Exit tickets are a formative assessment tool that give educators a way
          to assess how well learners understand the material they are learning
          in class. Exit tickets are usually given at the end of the class
          session.
        </p>
        <div className={clsx("warmup-header-break", props.color + "-border")} />
        <div className={"warmup-duration"}>
          <Typography
            component="span"
            variant="subtitle1"
            className="warmup-tot-duration-text"
          >
            Total Duration:
          </Typography>
          <Duration
            time={saveData.exitTicketDuration}
            onChange={handleDurationChange}
          />
        </div>
        {saveData.exitTicketQuestions.map(
          (question: any, questionIndex: number) => (
            <div
              key={`kcf-kcq-${questionIndex + 1}`}
              className={"warmup-subsection"}
            >
              <div className={"warmup-subsection"}>
                <div className={"warmup-duration"}>
                  <Typography
                    component="span"
                    variant="subtitle2"
                    className="warmup-subtitle"
                  >
                    {getRoutinePromptText()}
                  </Typography>
                  <Button
                    variant="contained"
                    className={questionClasses.routineTrashBtn}
                    onClick={() => handleDeleteQuestion(questionIndex)}
                  >
                    <DeleteIcon fontSize="small" />
                  </Button>
                </div>
                <textarea
                  className={questionClasses.question}
                  placeholder="Briefly explain today’s concept and provide an example."
                  rows={2}
                  value={question.question}
                  onChange={(event) =>
                    handleQuestionChange(questionIndex, event.target.value)
                  }
                />
              </div>
              <div className={"warmup-subsection"}>
                <div>
                  <Typography
                    component="span"
                    variant="subtitle2"
                    className="warmup-subtitle"
                  >
                    Settings
                  </Typography>
                  <div className={"warmup-duration"}>
                    <Typography
                      component="span"
                      variant="body1"
                      className="warmup-subtext"
                    >
                      Question Type
                    </Typography>
                    <Select
                      classes={{ root: questionClasses.typeSelect }}
                      variant="filled"
                      value={question.type}
                      onChange={(event) =>
                        handleQuestionSelectChange(
                          questionIndex,
                          event.target.value
                        )
                      }
                    >
                      {AssessQuestionTypes.map(
                        (type: AssessQuestionType, index: number) => (
                          <MenuItem
                            key={`kcf-aqt-${index + 1}`}
                            value={type}
                            disabled={type === AssessQuestionType.Matching}
                          >
                            {getAssessQuestionTypeString(type)}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </div>
                  <AssessQuestion
                    type={question.type}
                    choices={question.choices}
                    answer={question.answer}
                    onChange={(
                      choices: any,
                      answer: string | number | object | undefined
                    ) => handleChoiceChange(questionIndex, choices, answer)}
                  />
                </div>
              </div>
            </div>
          )
        )}
        <div
          className={
            "class-add-objectives class-input class-col-center class-row-center"
          }
        >
          <IconButton
            className={"class-add-objective-button learnidy-green-bg"}
            onClick={handleAddQuestion}
          >
            <AddIcon fontSize="inherit" />
          </IconButton>
          <Typography component="span" variant="body2" className="">
            + Add Exit Ticket Question
          </Typography>
        </div>
        <UploadFiles
          uploadFiles={saveData.uploadFiles}
          mediaType={MediaType.Any}
          sessionPlanId={props.sessionPlanId}
          activityId={props.id}
          onChange={handleUploadFiles}
          doUpload={props.doSave}
        />
      </React.Fragment>
    );
  };

  return getFormElement();
}
