import { useState } from "react";
import Collapse from "@material-ui/core/Collapse";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ClassModel from "../ClassInformation/ClassModel";
import { NO_CLASS_TITLE } from "../../constants/class";
import { SessionPlanModel } from "src/models/SessionPlanModel";
import SessionPlanList from "../SessionPlanList/SessionPlanList";

export const classGroupColor: string = "#e0e0e0";
export const classGroupBorderRadius: string = "6px";

const classStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      padding: 0,
      "&:hover": {
        color: theme.palette.text.primary,
      },
    },
    classGroupRoot: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      backgroundColor: theme.palette.background.default,
      border: `1px solid ${classGroupColor}`,
      borderRadius: 0,
    },
    header: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      backgroundColor: classGroupColor,
      borderTopLeftRadius: classGroupBorderRadius,
      borderTopRightRadius: classGroupBorderRadius,
    },
    widgetTitle: {
      padding: "0 16px",
      display: "flex",
      flexGrow: 1,
      alignItems: "center",
      justifyContent: "space-between",
      fontWeight: "bold",
      color: theme.palette.text.primary,
    },
    widgetCol: {
      display: "flex",
      flexDirection: "column",
      flexBasis: "100%",
      flex: 1,
    },
    widgetBody: {
      width: "100%",
      textAlign: "left",
    },
  })
);

interface ClassGroupProps {
  class: ClassModel;
  onSessionPlanSelect?: (event: any, plan: SessionPlanModel) => void;
  onSessionPlanDelete?: (plan: SessionPlanModel) => void;
  onCollapse?: (isCollapsed: boolean) => void;
}

export default function ClassGroup(props: ClassGroupProps) {
  const classes = classStyles();
  const [collapsed, setCollapsed] = useState<boolean>(true);

  const handleCollapse = (event: any) => {
    if (props.onCollapse) {
      props.onCollapse(!collapsed);
    }

    setCollapsed((val) => !val);
    if (event) {
      event.stopPropagation();
    }
  };

  const handleSessionPlanSelect = (event: any, item: any) => {
    if (props.onSessionPlanSelect) {
      props.onSessionPlanSelect(event, item);
    }

    event.stopPropagation();
  };

  const handleSessionPlanDelete = (item: any) => {
    if (props.onSessionPlanDelete) {
      props.onSessionPlanDelete(item);
    }
  };

  const getClassTitle = () => {
    let title: string = NO_CLASS_TITLE;
    if (props.class.title && props.class.title.length > 0)
      title = props.class.title;
    else if (props.class.subject) {
      title = props.class.subject;
    }

    return title;
  };

  const getWidgetRender = () => {
    return (
      <ListItem button onClick={handleCollapse} className={classes.listItem}>
        <div className={classes.classGroupRoot}>
          <div className={classes.header}>
            <Button onClick={handleCollapse}>
              {collapsed && <ArrowRightIcon fontSize="default" />}
              {!collapsed && <ArrowDropDownIcon fontSize="default" />}
            </Button>
            <Typography
              component="div"
              variant="subtitle1"
              className={classes.widgetTitle}
            >
              {getClassTitle()}
            </Typography>
          </div>
          <Collapse in={!collapsed} style={{ width: "100%" }}>
            <div className={classes.widgetBody}>
              <form className={"class-form"}>
                <div className={classes.widgetCol}>
                  <SessionPlanList
                    show={!collapsed}
                    classId={props.class.id}
                    onSessionPlanDelete={handleSessionPlanDelete}
                    onSessionPlanSelect={handleSessionPlanSelect}
                  />
                </div>
              </form>
            </div>
          </Collapse>
        </div>
      </ListItem>
    );
  };

  const getElement = () => {
    return getWidgetRender();
  };

  return getElement();
}
