import { useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputBase from "@material-ui/core/InputBase";
import {
  MAX_MINUTE_DURATION,
  MIN_MINUTE_DURATION,
  validateMinuteDuration,
} from "../ActivityFormValidation";

export interface DurationProps {
  time: string;
  onChange?: (val: string) => void;
}

export default function Duration(props: DurationProps) {
  const [time, setTime] = useState(props.time);

  const handleDurationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTime(event.target.value);
  };

  const handleDurationFinish = (event: any) => {
    const value = validateMinuteDuration(time);
    setTime(value);

    if (props.onChange) props.onChange(value);
  };

  return (
    <FormControl className={"warmup-time-text"} variant="outlined">
      <InputBase
        type="number"
        id="readWatchListenDuration"
        value={time}
        inputProps={{
          min: MIN_MINUTE_DURATION,
          max: MAX_MINUTE_DURATION,
          "aria-label": "mins",
        }}
        onChange={handleDurationChange}
        onBlur={handleDurationFinish}
        endAdornment={<InputAdornment position="start">mins</InputAdornment>}
        aria-describedby="outlined-weight-helper-text"
      />
    </FormControl>
  );
}
