import React, { useState, useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import "../../../styles/styles.css";
import UploadFiles from "../../../components/UploadFiles/UploadFiles";
import { LargeText } from "../../Presentation/Presentation";
import clsx from "clsx";
import Duration from "../Duration/Duration";
import { MediaType } from "../../../constants/media";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    radioFormControl: {
      margin: 0,
    },
    radioGroup: {
      margin: 0,
    },
    radioRoot: {
      margin: 0,
      padding: "2px 10px",
    },
  })
);

const getRoutinePromptText = () => {
  return "Roleplay Scenario:";
};

interface RoleplayProps {
  saveData: any;
  id: string;
  sessionPlanId: string;
  color: string;
  validate?: boolean;
  onChange?: any;
  doSave?: boolean;
}

export function RoleplaySlide(props: RoleplayProps) {
  return (
    <React.Fragment>
      <LargeText
        title={getRoutinePromptText()}
        value={props.saveData.roleplayScenario}
        backgroundColor="learnidy-orange-bg"
        isMultiLine={true}
      />
    </React.Fragment>
  );
}

export default function RoleplayForm(props: RoleplayProps) {
  const classes = useStyles();
  const [hasLoaded, setHasLoaded] = useState(false);
  const [groupSettingsError] = React.useState(false);
  const [requiredText] = React.useState("Please select a choice");
  const [saveData, setSaveData] = useState<any>({
    roleplayGroupsDuration:
      props.saveData && props.saveData.roleplayGroupsDuration
        ? props.saveData.roleplayGroupsDuration
        : 10,
    roleplayGroupSettings:
      props.saveData && props.saveData.roleplayGroupSettings
        ? props.saveData.roleplayGroupSettings
        : "",
    roleplayScenario:
      props.saveData && props.saveData.roleplayScenario
        ? props.saveData.roleplayScenario
        : "",
    roleplayNumTopicGroups:
      props.saveData && props.saveData.roleplayInstructions
        ? props.saveData.roleplayInstructions.length
        : 1,
    roleplayInstructions:
      props.saveData && props.saveData.roleplayInstructions
        ? props.saveData.roleplayInstructions
        : [""],
    uploadFiles:
      props.saveData && props.saveData.uploadFiles
        ? props.saveData.uploadFiles
        : [],
  });

  useEffect(() => {
    if (!hasLoaded) {
      if (props.onChange)
        props.onChange({
          roleplayGroupsDuration: saveData.roleplayGroupsDuration,
          roleplayInstructions: saveData.roleplayInstructions,
        });

      setHasLoaded(true);
    }
  }, [hasLoaded, saveData, props]);

  const handleDataChange = (key: string, value: any) => {
    if (!key) {
      return;
    }

    const data = { ...saveData };
    data[key] = value;
    setSaveData(data);

    if (props.onChange) {
      props.onChange({ [key]: value });
    }
  };

  const handleNumTopicChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const data = { ...saveData };
    let len: number = parseInt(event.target.value);
    if (!Number.isInteger(len)) len = 0;

    if (len < 0) len = 0;
    else if (len > 4) len = 4;

    if (!Array.isArray(data.roleplayInstructions)) return;

    const origLen = data.roleplayInstructions.length;
    data.roleplayInstructions.length = len;
    data.roleplayNumTopicGroups = len;

    if (len >= origLen) {
      data.roleplayInstructions.fill("", origLen);
    }

    setSaveData(data);

    if (props.onChange) {
      props.onChange({ roleplayInstructions: data.roleplayInstructions });
    }
  };

  const handleInstructions = (index: number, value: string) => {
    const data = { ...saveData };
    data.roleplayInstructions[index] = value;
    setSaveData(data);

    if (props.onChange) {
      props.onChange({ roleplayInstructions: data.roleplayInstructions });
    }
  };

  const handleDurationChange = (val: string) => {
    handleDataChange("roleplayGroupsDuration", val);
  };

  const handleUploadFiles = (files: any) => {
    const data = { ...saveData };
    data.uploadFiles = [...files];
    setSaveData(data);

    if (props.onChange) {
      props.onChange({ uploadFiles: data.uploadFiles });
    }
  };

  return (
    <React.Fragment>
      <span className={clsx("activity-form-name", props.color)}>Roleplay</span>
      <p className={"activity-form-desc"}>
        Assign the larger group to work in smaller teams to discuss a topic,
        answer a question, or tackle a specific challenge. Designate roles for
        each learner within a group such as a facilitator, notetaker, and etc.
        Roleplay groups are designed to increase participation.
      </p>
      <div className={clsx("warmup-header-break", props.color + "-border")} />
      <div className={"warmup-duration"}>
        <Typography
          component="span"
          variant="subtitle1"
          className="warmup-tot-duration-text"
        >
          Total Duration:
        </Typography>
        <Duration
          time={saveData.roleplayGroupsDuration}
          onChange={handleDurationChange}
        />
      </div>
      <div className={"warmup-subsection"}>
        <div className={"warmup-duration"}>
          <Typography
            component="span"
            variant="subtitle2"
            className="warmup-subtitle"
          >
            Roleplay Scenario
          </Typography>
        </div>
        <textarea
          className={"warmup-subtext"}
          placeholder="What roleplay scenario prompt do you want to provide all learners?"
          rows={2}
          value={saveData.roleplayScenario}
          onChange={(event) =>
            handleDataChange("roleplayScenario", event.target.value)
          }
        />
      </div>
      <div className={"warmup-subsection"}>
        <div>
          <Typography
            component="span"
            variant="subtitle2"
            className="warmup-subtitle"
          >
            Roleplay Details
          </Typography>
        </div>
        <div className={"warmup-duration warmup-subsection-small"}>
          <Typography component="span" variant="body2">
            How many topic groups do you want to create?
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            value={saveData.roleplayNumTopicGroups}
            onChange={handleNumTopicChange}
          />
        </div>
      </div>
      <div className={"warmup-subsection warmup-subsection-small"}>
        <FormControl
          component="fieldset"
          error={groupSettingsError}
          className={classes.radioFormControl}
        >
          <RadioGroup
            aria-label="number-topic-groups"
            name="number-topic-groups"
            value={saveData.roleplayGroupSettings}
            className={classes.radioGroup}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleDataChange("roleplayGroupSettings", event.target.value)
            }
          >
            <FormControlLabel
              value={"automatic"}
              control={<Radio classes={{ root: classes.radioRoot }} />}
              label={"Assign groups automatically"}
            />
            <FormControlLabel
              value={"manual"}
              control={<Radio classes={{ root: classes.radioRoot }} />}
              label={"Assign groups manually"}
            />
            <FormControlLabel
              value={"choose"}
              control={<Radio classes={{ root: classes.radioRoot }} />}
              label={"Let learners choose their group"}
            />
          </RadioGroup>
          <FormHelperText>{groupSettingsError && requiredText}</FormHelperText>
        </FormControl>
      </div>
      {saveData.roleplayInstructions.map((item: any, index: number) => {
        return (
          <div
            className={"warmup-subsection warmup-subsection-small"}
            key={"roleplay-instr-" + index.toString()}
            id={"roleplay-instr-" + index.toString()}
          >
            <div className={"warmup-duration"}>
              <Typography component="span" variant="body2">
                Role #{index + 1} Instructions
              </Typography>
            </div>
            <textarea
              className={"warmup-subtext"}
              placeholder="What roleplay scenario prompt do you want to provide all learners?"
              rows={3}
              value={saveData.roleplayInstructions[index]}
              onChange={(event) =>
                handleInstructions(index, event.target.value)
              }
            />
          </div>
        );
      })}
      <UploadFiles
        uploadFiles={saveData.uploadFiles}
        mediaType={MediaType.Any}
        sessionPlanId={props.sessionPlanId}
        activityId={props.id}
        onChange={handleUploadFiles}
        doUpload={props.doSave}
      />
    </React.Fragment>
  );
}
