import { createMuiTheme, ThemeOptions } from "@material-ui/core/styles";
// import { createTheme } from "@material-ui/core/styles";
// import blueGrey from '@material-ui/core/colors/blueGrey';

// https://material-ui.com/customization/themes/
// https://material-ui.com/style/color/

const themeData: ThemeOptions = {
  palette: {
    primary: {
      main: "#6E86F4",
    },
    secondary: {
      main: "#DF8EF6",
    },
    neutral: {
      main: "#fafafa",
      light: "#ffffff",
      dark: "#c8c8c8",
    },
    action: {
      disabledBackground: "#b7c3fa",
      disabled: "#eee",
      hover: "#4d5eab",
    },
    type: "light",
  },
  typography: {
    fontFamily: '"Nunito", "Roboto", "Helvetica", "Arial", sans-serif',
  },
  mixins: {
    // content: {
    //   paddingTop: "1.5rem",
    //   paddingRight: "1rem",
    //   paddingBottom: "1.5rem",
    //   paddingLeft: "1rem",
    //   "@media (min-width: 600px)": {
    //     paddingTop: "2rem",
    //     paddingRight: "1.5rem",
    //     paddingBottom: "2rem",
    //     paddingLeft: "1.5rem",
    //   },
    //   "@media (min-width: 828px)": {
    //     paddingLeft: 0,
    //     paddingRight: 0,
    //     maxWidth: 780,
    //     margin: "0 auto",
    //   },
    // },
  },
};

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    neutral?: PaletteColor;
  }

  interface PaletteOptions {
    neutral?: PaletteColorOptions;
  }
}

export const lightTheme = () => {
  return createMuiTheme(themeData);
};

export const darkTheme = () => {
  const data = { ...themeData };
  if (data && data.palette) data.palette.type = "dark";

  return createMuiTheme(data);
};
