import { createStyles, makeStyles } from "@material-ui/core";

export const blue: string = "#6E86F4";
export const lochmara: string = "#0288D1";
export const red: string = "#D32F2F";
export const cinnabar: string = "#E64A19";
export const orange: string = "#FFA000";
export const pink: string = "#C2185B";
export const purple: string = "#7B1FA2";
export const teal: string = "#0097A7";
export const grey: string = "#616161";
export const green: string = "#388E3C";
export const fiord: string = "#455A64";
export const yellow: string = "#FBC02D";

export const ColorClassToHexMap = {
  "learnidy-blue": blue,
  "learnidy-lochmara": lochmara,
  "learnidy-red": red,
  "learnidy-cinnabar": cinnabar,
  "learnidy-orange": orange,
  "learnidy-pink": pink,
  "learnidy-purple": purple,
  "learnidy-teal": teal,
  "learnidy-grey": grey,
  "learnidy-green": green,
  "learnidy-fiord": fiord,
  "learnidy-yellow": yellow,
};

const useStyles = makeStyles(() =>
  createStyles({
    "@global": {
      ".MuiMenuItem-root, .MuiListItem-root": {
        "&:hover": {
          color: "#fff",
          backgroundColor: blue,
        },
      },
      ".clear-space": {
        margin: 0,
        padding: 0,
      },
      ".learnidy-blue": {
        color: `${blue} !important`,
      },
      "learnidy-blue-bg": {
        backgroundColor: `${blue} !important`,
      },
      ".learnidy-blue-border": {
        borderColor: `${blue} !important`,
      },
      ".learnidy-lochmara": {
        color: `${lochmara} !important`,
      },
      ".learnidy-lochmara-bg": {
        backgroundColor: `${lochmara} !important`,
      },
      ".learnidy-lochmara-border": {
        borderColor: `${lochmara} !important`,
      },
      ".learnidy-red": {
        color: `${red} !important`,
      },
      ".learnidy-red-bg": {
        backgroundColor: `${red} !important`,
      },
      ".learnidy-red-border": {
        borderColor: `${red} !important`,
      },
      ".learnidy-cinnabar": {
        color: `${cinnabar} !important`,
      },
      ".learnidy-cinnabar-bg": {
        backgroundColor: `${cinnabar} !important`,
      },
      ".learnidy-cinnabar-border": {
        borderColor: `${cinnabar} !important`,
      },
      ".learnidy-orange": {
        color: `${orange} !important`,
      },
      ".learnidy-orange-bg": {
        backgroundColor: `${orange} !important`,
      },
      ".learnidy-orange-border": {
        borderColor: `${orange} !important`,
      },
      ".learnidy-pink": {
        color: `${pink} !important`,
      },
      ".learnidy-pink-bg": {
        backgroundColor: `${pink} !important`,
      },
      ".learnidy-pink-border": {
        borderColor: `${pink} !important`,
      },
      ".learnidy-purple": {
        color: `${purple} !important`,
      },
      ".learnidy-purple-bg": {
        backgroundColor: `${purple} !important`,
      },
      ".learnidy-purple-border": {
        borderColor: `${purple} !important`,
      },
      ".learnidy-teal": {
        color: `${teal} !important`,
      },
      ".learnidy-teal-bg": {
        backgroundColor: `${teal} !important`,
      },
      ".learnidy-teal-border": {
        borderColor: `${teal} !important`,
      },
      ".learnidy-grey": {
        color: `${grey} !important`,
      },
      ".learnidy-grey-bg": {
        backgroundColor: `${grey} !important`,
      },
      ".learnidy-grey-border": {
        borderColor: `${grey} !important`,
      },
      ".learnidy-green": {
        color: `${green} !important`,
      },
      ".learnidy-green-bg": {
        backgroundColor: `${green} !important`,
      },
      ".learnidy-green-border": {
        borderColor: `${green} !important`,
      },
      ".learnidy-fiord": {
        color: `${fiord} !important`,
      },
      ".learnidy-fiord-bg": {
        backgroundColor: `${fiord} !important`,
      },
      ".learnidy-fiord-border": {
        borderColor: `${fiord} !important`,
      },
      ".learnidy-yellow": {
        color: `${yellow} !important`,
      },
      ".learnidy-yellow-bg": {
        backgroundColor: `${yellow} !important`,
      },
      ".learnidy-yellow-border": {
        borderColor: `${yellow} !important`,
      },
      ".activity-dialog": {
        display: "flex",
        flexDirection: "row",
      },
      ".activity-form": {
        flexGrow: 1,
        flexBasis: 0,
        margin: 0,
        padding: "20px 40px",
        backgroundColor: "white",
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        boxShadow:
          "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
      },
      ".activity-form .activity-form-name": {
        fontSize: "1.4rem",
        fontFamily: "Nunito, Helvetica, sans-serif",
        fontWeight: "bold",
      },
      ".activity-form .warmup-header-break": {
        width: "100%",
        border: "1px solid",
      },
      ".activity-form-desc": {
        width: "100%",
        color: "#4d4d4d",
        fontSize: "0.85rem",
        fontFamily: `"Nunito", "Roboto", Helvetica, sans-serif`,
        fontWeight: 400,
        textAlign: "left",
      },
      ".activity-form-btn-group .MuiToggleButtonGroup-root": {
        margin: "20px 0 0 0",
        gap: "32px",
      },
      ".activity-form-btn-group .MuiToggleButtonGroup-root .MuiButtonBase-root":
        {
          width: 270,
          height: 150,
          backgroundColor: "white",
          borderRadius: "12px !important",
          borderWidth: "1px",
          border: "1px solid #9d9d9d",
          boxShadow: "none",
          fontFamily: `"Nunito", "Roboto", "Helvetica", sans-serif`,
          fontSize: "1.3rem",
          lineHeight: 1.5,
          padding: "6px 12px",
          textTransform: "none",
        },
      ".activity-form-btn-group .MuiToggleButtonGroup-root .Mui-selected": {
        border: "3px solid #6e86f4",
        borderRadius: "12px !important",
      },
      ".activity-form .warmup-icon": {
        fontSize: "4rem",
      },
      ".activity-form .toggle-button .MuiToggleButton-label": {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "flex-start",
        alignItems: "flex-start",
      },
      ".activity-form .toggle-button-title": {
        color: "#737373",
        fontWeight: "bold",
        textAlign: "left",
        lineHeight: 1.2,
      },
      ".activity-form .warmup-subtitle": {
        marginBottom: "5px",
        fontWeight: "bold",
      },
      ".activity-form .warmup-label": {
        fontWeight: "bold",
      },
      ".activity-form .toggle-button-body": {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "1em",
      },
      ".activity-form .activity-name": {
        position: "absolute",
        left: 10,
        top: 10,
        fontWeight: "bold",
        color: "#4d4d4d",
      },
      ".activity-form .activity-btn-disabled": {
        opacity: 0.7,
      },
      ".activity-form .warmup-time-text .MuiTypography-colorTextSecondary": {
        color: "white",
      },
      ".activity-form .MuiToggleButton-label .MuiBadge-root": {
        position: "absolute",
        right: "-1em",
        top: "-1em",
        backgroundColor: "white",
        borderRadius: "2em",
      },
      ".activity-form-btn-group .MuiToggleButton-label .MuiBadge-root .MuiSvgIcon-root":
        {
          position: "relative",
          top: "0.15em",
          right: "0.15em",
          color: "#6e86f4",
          fontSize: "2rem",
        },
      ".activity-form-btn-group .MuiToggleButton-label": {
        height: "100%",
        width: "100%",
      },
      ".activity-form-btn-group .MuiToggleButton-label img": {
        height: "60%",
        marginTop: 40,
      },
      ".activity-form .warmup-duration": {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "15px 0 0 0",
      },
      ".activity-form .warmup-tot-duration-text": {
        fontWeight: "bold",
      },
      ".activity-form .warmup-tot-duration-text-alt": {
        color: "white",
        padding: "2px 10px",
        borderRadius: 20,
      },
      ".activity-form .warmup-time-text": {
        width: "120px",
        backgroundColor: "#6e86f4",
        borderRadius: 20,
      },
      ".activity-form .warmup-time-text input": {
        color: "rgb(115, 115, 115)",
        backgroundColor: "white",
      },
      ".activity-form .warmup-time-text input, .activity-form .MuiInputAdornment-root .MuiTypography-root":
        {
          padding: 0,
          margin: "5px 0 5px 5px",
          minWidth: "40px",
          fontSize: "0.9rem",
          textAlign: "center",
          borderRadius: "10px",
          webkitAppearance: "none",
          mozAppearance: "textfield",
        },
      /* Hide the spinner*/
      ".activity-form .warmup-time-text input::-webkit-outer-spin-button, .activity-form .warmup-time-text input::-webkit-inner-spin-button":
        {
          webkitAppearance: "none",
          margin:
            "0" /* <-- Apparently some margin are still there even though it's hidden */,
        },
      "input[type=number]": {
        mozAppearance: "textfield" /* Firefox */,
      },
      ".activity-form .MuiInputBase-root": {
        padding: 0,
      },
      ".activity-form .activity-form-close": {
        position: "absolute",
        right: 10,
        top: 10,
        maxWidth: 30,
        minWidth: 30,
        height: 30,
        margin: "0 10px 0 0",
        padding: 0,
        border: "none",
      },
      ".activity-form .MuiList-root": {
        width: "100%",
        padding: "8px 0 8px 20px",
      },
      ".activity-form .MuiList-root .MuiListItem-root": {
        padding: 0,
      },
      ".activity-form .MuiList-root .MuiListItemText-root": {
        marginTop: 0,
      },
      ".activity-form .MuiList-root .MuiListItemText-primary": {
        fontSize: "0.85rem",
        fontWeight: "bold",
      },
      ".warmup-upload": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      },
      ".warmup-upload-label": {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      },
      ".activity-form .warmup-upload-button": {
        width: "120px",
        color: "white",
        backgroundColor: "#737373",
        border: "none",
      },
      ".warmup-subsection": {
        width: "100%",
        textAlign: "left",
        display: "flex",
        flexDirection: "column",
        margin: "10px 0 0 0",
      },
      ".activity-form .warmup-subsection-small": {
        marginTop: 5,
      },
      ".warmup-subsection > div": {
        width: "100%",
        /* textAlign: "left", */
        display: "flex",
        flexDirection: "column",
        /* margin: "20px 0 0 0", */
      },
      ".warmup-subsection textarea": {
        backgroundColor: "#f5f5f5",
        /* height: "5rem", */
      },
      ".warmup-subsection .warmup-subtext": {
        border: "none",
        /* padding: "10px", */
        fontSize: "0.88rem",
        fontFamily: `Nunito, "Roboto", Helvetica, sans-serif`,
        lineHeight: "1.4",
        resize: "none",
        overflowX: "hidden",
        overflowY: "auto",
      },
      ".warmup-grid": {
        width: "100%",
        marginTop: "20px",
      },
      ".warmup-grid > .MuiToggleButtonGroup-root": {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        gridGap: "0.5em",
      },
      ".warmup-grid > .MuiToggleButtonGroup-root > button": {
        aspectRatio: "2 / 1",
        backgroundColor: "white",
        border: "1px solid #e5e5e5",
      },
      ".warmup-grid .MuiToggleButtonGroup-root .Mui-selected": {
        border: "3px solid #6e86f4",
      },
      ".warmup-footer": {
        width: "100%",
        margin: "40px 0 20px 0",
        textAlign: "center",
      },
      ".warmup-footer button": {
        width: "40%",
        height: "3em",
        margin: "10px 0 0 0",
        color: "white",
        backgroundColor: "#6e86f4",
        border: "none",
      },
      ".warmup-footer button:hover": {
        backgroundColor: "#4b68ed",
      },
      ".warmup-footer button .MuiButton-label": {
        fontSize: "1rem",
      },
    },
  })
);

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
