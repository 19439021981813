export const BloomsCategoryList = [
  "Remember",
  "Understand",
  "Apply",
  "Analyze",
  "Evaluate",
  "Create",
];

export const BloomsVerbMap = {
  Remember: [
    "Bookmark",
    "Bullet point",
    "copy",
    "define",
    "describe",
    "duplicate",
    "favour",
    "find",
    "google",
    "highlight",
    "identify",
    "label",
    "like",
    "listen",
    "list",
    "locate",
    "match",
    "memorize",
    "name",
    "network",
    "number",
    "quote",
    "recall",
    "read",
    "recite",
    "recognize",
    "record",
    "retell",
    "repeat",
    "retrieve",
    "search",
    "select",
    "tabulate",
    "tell",
    "viusalize",
  ],
  Understand: [
    "advanced search",
    "annotate",
    "associate",
    "boolean search",
    "categorize",
    "classify",
    "clarify",
    "comment",
    "compare",
    "contrast",
    "convert",
    "demonstrate",
    "describe",
    "differentiate",
    "discuss",
    "discover",
    "distinguish",
    "estimate",
    "exemplify",
    "explain",
    "express",
    "extend",
    "gather",
    "generalize",
    "group",
    "identify",
    "indicate",
    "infer",
    "interpret",
    "journal",
    "paraphrase",
    "predict",
    "relate",
    "represent",
    "subscribe",
    "summarize",
    "tag",
    "translate",
    "tweet",
  ],
  Apply: [
    "act out",
    "administer",
    "apply",
    "calculate",
    "carry out",
    "change",
    "chart",
    "choose",
    "collect",
    "complete",
    "compute",
    "construct",
    "demonstrate",
    "determine",
    "display",
    "edit",
    "examine",
    "execute",
    "experiment",
    "explain",
    "hack",
    "implement",
    "interview",
    "judge",
    "load",
    "operate",
    "play",
    "prepare",
    "present",
    "provide",
    "respond",
    "run",
    "share",
    "sketch",
    "upload",
    "use",
  ],
  Analyze: [
    "advertise",
    "appraise",
    "attribute",
    "break down",
    "calculate",
    "categorize",
    "classify",
    "compare",
    "conclude",
    "contrast",
    "correlate",
    "deconstruct",
    "deduce",
    "differentiate",
    "discriminate",
    "divide",
    "discriminate",
    "distinguish",
    "divide",
    "distinguish",
    "estimate",
    "explain",
    "illustrate",
    "infer",
    "integrate",
    "link",
    "mash",
    "mind map",
    "order",
    "organize",
    "outline",
    "plan",
    "point out",
    "prioritize ",
    "question",
    "select",
    "separate",
    "structure",
    "survey",
  ],
  Evaluate: [
    "argue",
    "assess",
    "check",
    "criticize",
    "comment",
    "conclude",
    "consider",
    "convince",
    "coordinate",
    "critique",
    "debate",
    "defend",
    "detect",
    "editoralize",
    "experiment",
    "grade",
    "hypothesize",
    "judge",
    "justify",
    "measure",
    "moderate",
    "monitor",
    "network",
    "persuade",
    "post",
    "predict",
    "rate",
    "reccomend ",
    "reflect",
    "reframe",
    "review",
    "revise",
    "score",
    "select",
    "support",
    "test",
    "validate",
  ],
  Create: [
    "adapt",
    "animate",
    "blog",
    "build",
    "collaborate",
    "compose",
    "construct",
    "design",
    "develop",
    "devise",
    "direct",
    "facilitate",
    "film",
    "formulate",
    "generate",
    "integrate",
    "invent",
    "lead",
    "make",
    "manage",
    "mix/remix",
    "modify",
    "negotiate",
    "orginate",
    "orate",
    "plan",
    "podcast",
    "produce",
    "program",
    "publish",
    "roleplay",
    "simulate",
    "solve",
    "structure",
    "video blog",
    "wiki build",
    "write",
  ],
};
