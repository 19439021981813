import { DateTime /*, DateObjectUnits*/ } from "luxon";
import { createSessionPlanId } from "../firebase/firebase";
import { v4 as uuidv4 } from "uuid";
import { makeActivity } from "../components/Activity/ActivityMaker";
import {
  getDefaultObjective,
  ObjectiveType,
} from "../components/Lesson/ObjectiveModel";
import { Standard } from "../components/Lesson/StandardModel";
import firebase from "firebase/app";
import { NO_CLASS_TITLE } from "../constants/class";

export interface SessionPlanModel {
  id: string;
  classId?: string | undefined;
  sessionId?: string | undefined;
  activities: any[];
  startTime: DateTime | undefined;
  endTime: DateTime | undefined;
  name: string;
  objectives: ObjectiveType[];
  standards: Standard[];
  tags: string[];
  modifiedAt: firebase.firestore.FieldValue;
}

export const getDefaultSessionPlan = (data: any = null): SessionPlanModel => {
  const sessionPlanId: string =
    data && data.id ? data.id : createSessionPlanId().id;

  // const dou: DateObjectUnits = {
  //   year: 2000,
  //   month: 1,
  //   day: 1,
  //   hour: 0,
  //   minute: 0,
  //   second: 0,
  //   millisecond: 0,
  // };

  let startTime: DateTime | undefined = undefined;
  let endTime: DateTime | undefined = undefined; // DateTime.now().set(dou);

  if (data && (data.start || data.startTime)) {
    if (typeof data.start === "string")
      startTime = DateTime.fromISO(data.start);
    else if (typeof data.startTime === "string")
      startTime = DateTime.fromISO(data.startTime);
    else if (data.start instanceof Date)
      startTime = DateTime.fromJSDate(data.start);
    else startTime = data.startTime;
  }

  if (data && (data.end || data.endTime)) {
    if (typeof data.end === "string") endTime = DateTime.fromISO(data.end);
    else if (typeof data.endTime === "string")
      endTime = DateTime.fromISO(data.endTime);
    else if (data.start instanceof Date)
      endTime = DateTime.fromJSDate(data.end);
    else endTime = data.endTime;
  }

  const newActivities: any[] = [];
  if (data && data.activities) {
    for (let i = 0; i < data.activities.length; ++i) {
      const currActivity: any = data.activities[i];
      const activityId: string = currActivity.id ? currActivity.id : uuidv4();
      const baseActivity: any = {
        id: activityId,
      };

      const activity: any = makeActivity(
        currActivity.type,
        sessionPlanId,
        true,
        baseActivity
      );
      const clonedActivity: any = Object.assign({}, activity.props);
      clonedActivity.activeSession = true;
      clonedActivity.showEditForm = false;
      clonedActivity.saveData = { ...currActivity.saveData };
      if (data.onSave) clonedActivity.onSave = data.onSave;
      if (data.onDelete) clonedActivity.onDelete = data.onDelete;
      if (data.onShowEditForm) {
        clonedActivity.onShowEditForm = (v: any) =>
          data.onShowEditForm(v, clonedActivity);
      }

      newActivities.push(clonedActivity);
    }
  }

  const model: SessionPlanModel = {
    id: sessionPlanId,
    classId: data && data.classId ? data.classId : null,
    sessionId: data && data.sessionId ? data.sessionId : null,
    activities: newActivities,
    startTime,
    endTime,
    name: data && data.name ? data.name : "",
    objectives:
      data && data.objectives ? data.objectives : [getDefaultObjective()],
    standards: data && data.standards ? data.standards : [],
    tags: data && data.tags ? data.tags : [],
    modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
  };

  return model;
};

const getSaveActivityFormat = (activity: any) => {
  return {
    saveData: activity.saveData,
    type: activity.type,
    id: activity.id,
  };
};

export const getSaveSessionPlanFormat = (plan: SessionPlanModel): object => {
  let activities: any = [];
  if (Array.isArray(plan.activities)) {
    activities = plan.activities.map((activity: any) => {
      // // Make sure the saveData's sessionPlan is updated and correct
      // activity.saveData.sessionPlanId = plan.id;
      return getSaveActivityFormat(activity);
    });
  }

  let data: any = {
    id: plan.id,
    activities,
    startTime: plan.startTime ? plan.startTime.toISO() : null,
    endTime: plan.endTime ? plan.endTime.toISO() : null,
    sessionId: plan.sessionId ? plan.sessionId : null,
    classId: plan.classId ? plan.classId : null,
    name: plan.name ? plan.name : NO_CLASS_TITLE,
    objectives: plan.objectives ? plan.objectives : [],
    standards: plan.standards ? plan.standards : [],
    tags: plan.tags ? plan.tags : [],
    modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
  };

  return data;
};

export const createSessionPlanFromEvent = (data: any): object => {
  const plan: any = {
    id: data.id,
    classId: data.classId,
    sessionId: data.event.id,
    startTime: data.event.start.toISOString(),
    endTime: data.event.end.toISOString(),
    name: NO_CLASS_TITLE,
    modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
  };

  return plan;
};
