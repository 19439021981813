import React, { useState, useEffect } from "react";
// import { createStyles, makeStyles /*, Theme*/ } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import "../../../styles/styles.css";
import UploadFiles from "../../../components/UploadFiles/UploadFiles";
import { LargeText } from "../../Presentation/Presentation";
import clsx from "clsx";
import Duration from "../Duration/Duration";
import { MediaType } from "../../../constants/media";

// const useStyles = makeStyles((theme: any) =>
//   createStyles({
//     radioFormControl: {
//       margin: 0,
//     },
//     radioGroup: {
//       margin: 0,
//     },
//     radioRoot: {
//       margin: 0,
//       padding: "2px 10px",
//     },
//   })
// );

interface TimelineProps {
  saveData: any;
  id: string;
  sessionPlanId: string;
  color: string;
  validate?: boolean;
  onChange?: any;
  doSave?: boolean;
}

export function TimelineSlide(props: TimelineProps) {
  return (
    <React.Fragment>
      <LargeText
        title="Timeline Prompt:"
        value={props.saveData.timelinePrompt}
        backgroundColor="learnidy-red-bg"
        isMultiLine={true}
      />
      <LargeText
        title="Evidence:"
        value={props.saveData.evidence}
        backgroundColor="learnidy-red-bg"
        isMultiLine={true}
      />
      <LargeText
        title="Hypothesis:"
        value={props.saveData.hypothesis}
        backgroundColor="learnidy-red-bg"
        isMultiLine={true}
      />
    </React.Fragment>
  );
}

export default function TimelineForm(props: TimelineProps) {
  //   const classes = useStyles();
  const [hasLoaded, setHasLoaded] = useState(false);
  //   const [groupSettingsError] = React.useState(false);
  //   const [requiredText] = React.useState("Please select a choice");
  const [saveData, setSaveData] = useState<any>({
    timelineDuration:
      props.saveData && props.saveData.timelineDuration
        ? props.saveData.timelineDuration
        : 10,
    timelinePrompt:
      props.saveData && props.saveData.timelinePrompt
        ? props.saveData.timelinePrompt
        : "",
    evidence:
      props.saveData && props.saveData.evidence ? props.saveData.evidence : "",
    hypothesis:
      props.saveData && props.saveData.hypothesis
        ? props.saveData.hypothesis
        : "",
    uploadFiles:
      props.saveData && props.saveData.uploadFiles
        ? props.saveData.uploadFiles
        : [],
  });

  useEffect(() => {
    if (!hasLoaded) {
      if (props.onChange)
        props.onChange({
          timelineDuration: saveData.timelineDuration,
        });

      setHasLoaded(true);
    }
  }, [hasLoaded, saveData, props]);

  const handleDataChange = (key: string, value: any) => {
    if (!key) {
      return;
    }

    const data = { ...saveData };
    data[key] = value;
    setSaveData(data);

    if (props.onChange) {
      props.onChange({ [key]: value });
    }
  };

  const handleDurationChange = (val: string) => {
    handleDataChange("timelineDuration", val);
  };

  const handleUploadFiles = (files: any) => {
    const data = { ...saveData };
    data.uploadFiles = [...files];
    setSaveData(data);

    if (props.onChange) {
      props.onChange({ uploadFiles: data.uploadFiles });
    }
  };

  return (
    <React.Fragment>
      <span className={clsx("activity-form-name", props.color)}>Timeline</span>
      <p className={"activity-form-desc"}>
        Learners analyze historical evidence in order to form and test
        hypotheses about past events. Using a timeline format, learners will
        inquire and investigate questions posed by the educator.
      </p>
      <div className={clsx("warmup-header-break", props.color + "-border")} />
      <div className={"warmup-duration"}>
        <Typography
          component="span"
          variant="subtitle1"
          className="warmup-tot-duration-text"
        >
          Total Duration:
        </Typography>
        <Duration
          time={saveData.timelineDuration}
          onChange={handleDurationChange}
        />
      </div>
      <div className={"warmup-subsection"}>
        <div className={"warmup-duration"}>
          <Typography
            component="span"
            variant="subtitle2"
            className="warmup-subtitle"
          >
            Timeline Prompt
          </Typography>
        </div>
        <textarea
          className={"warmup-subtext"}
          placeholder="What timeline question(s) do you want to provide all learners?"
          rows={2}
          value={saveData.timelinePrompt}
          onChange={(event) =>
            handleDataChange("timelinePrompt", event.target.value)
          }
        />
      </div>
      <div className={"warmup-subsection"}>
        <div className={"warmup-duration"}>
          <Typography
            component="span"
            variant="subtitle2"
            className="warmup-subtitle"
          >
            Timeline Details
          </Typography>
        </div>
        <div>
          <div className={"warmup-duration"}>
            <Typography component="span" variant="subtitle2">
              Evidence
            </Typography>
          </div>
          <textarea
            className={"warmup-subtext"}
            placeholder="evidence"
            rows={2}
            value={saveData.evidence}
            onChange={(event) =>
              handleDataChange("evidence", event.target.value)
            }
          />
        </div>
        <div>
          <div className={"warmup-duration"}>
            <Typography component="span" variant="subtitle2">
              Hypothesis
            </Typography>
          </div>
          <textarea
            className={"warmup-subtext"}
            placeholder="hypothesis"
            rows={2}
            value={saveData.hypothesis}
            onChange={(event) =>
              handleDataChange("hypothesis", event.target.value)
            }
          />
        </div>
      </div>
      <UploadFiles
        uploadFiles={saveData.uploadFiles}
        mediaType={MediaType.Any}
        sessionPlanId={props.sessionPlanId}
        activityId={props.id}
        onChange={handleUploadFiles}
        doUpload={props.doSave}
      />
    </React.Fragment>
  );
}
