import React, { useState, useEffect } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
// import { createStyles, makeStyles /*, Theme*/ } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AssessQuestion from "./AssessQuestion";
import "../../../styles/styles.css";
import { LargeText, PresentChoiceText } from "../../Presentation/Presentation";
import {
  AssessQuestionType,
  AssessQuestionTypes,
  getAssessQuestionTypeString,
} from "./Questions/QuestionTypes";
import { questionStyles } from "../../../styles/QuestionStyles";
import clsx from "clsx";
import Duration from "../Duration/Duration";

// const useStyles = makeStyles((theme: any) =>
//   createStyles({
//     radioFormControl: {
//       margin: 0,
//     },
//     radioGroup: {
//       margin: 0,
//     },
//     radioRoot: {
//       margin: 0,
//       padding: "2px 10px",
//     },
//   })
// );

const getRoutinePromptText = () => {
  return "Trivia Question:";
};

interface TriviaProps {
  saveData: any;
  color: string;
  validate?: boolean;
  onChange?: any;
  subslideIndex?: number;
}

export function TriviaSlide(props: TriviaProps) {
  const index: number = props.subslideIndex ? props.subslideIndex : 0;
  if (
    props.saveData.triviaQuestions &&
    index < props.saveData.triviaQuestions.length
  ) {
    const question: any = props.saveData.triviaQuestions[index];

    return (
      <React.Fragment key={`trivia-question-` + index}>
        <LargeText
          title={getRoutinePromptText()}
          value={question.question}
          backgroundColor="learnidy-green-bg"
          isMultiLine={false}
        />
        {question.choices &&
          question.choices.map((choice: any, index: number) => {
            return (
              <PresentChoiceText
                key={`trivia-choice-` + index}
                value={choice}
                backgroundColor="learnidy-green-bg"
                isMultiLine={true}
              />
            );
          })}
      </React.Fragment>
    );
  }

  return <React.Fragment></React.Fragment>;
}

const getDefaultQuestion = (): object => {
  return {
    type: AssessQuestionType.MultipleChoice,
    question: "",
    choices: null,
  };
};

export default function TriviaForm(props: TriviaProps) {
  // const classes = useStyles();
  const questionClasses = questionStyles();
  const [maxQuestions] = useState(10);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [saveData, setSaveData] = useState<any>({
    triviaDuration:
      props.saveData && props.saveData.triviaDuration
        ? props.saveData.triviaDuration
        : 10,
    triviaQuestions:
      props.saveData && props.saveData.triviaQuestions
        ? props.saveData.triviaQuestions
        : [getDefaultQuestion()],
    triviaShowBoardToggle:
      props.saveData && props.saveData.triviaShowBoardToggle
        ? props.saveData.triviaShowBoardToggle
        : false,
    triviaAddTimerToggle:
      props.saveData && props.saveData.triviaAddTimerToggle
        ? props.saveData.triviaAddTimerToggle
        : false,
  });

  useEffect(() => {
    if (!hasLoaded) {
      if (props.onChange)
        props.onChange({
          triviaDuration: saveData.triviaDuration,
        });

      setHasLoaded(true);
    }
  }, [hasLoaded, saveData, props]);

  const handleDataChange = (key: string, value: any) => {
    if (!key) {
      return;
    }

    const data = { ...saveData };
    data[key] = value;
    setSaveData(data);

    if (props.onChange) {
      props.onChange({ [key]: value });
    }
  };

  const handleDurationChange = (val: string) => {
    handleDataChange("triviaDuration", val);
  };

  const handleQuestionChange = (index: number, value: string) => {
    const data = { ...saveData };
    const question: any = data.triviaQuestions[index];
    question.question = value;
    setSaveData(data);

    if (props.onChange) {
      props.onChange({ triviaQuestions: data.triviaQuestions });
    }
  };

  const handleQuestionSelectChange = (index: number, value: unknown) => {
    const data = { ...saveData };
    const question: any = data.triviaQuestions[index];
    question.type = value;

    setSaveData(data);
  };

  const handleChoiceChange = (
    index: number,
    choices: any,
    answer: string | number | object | undefined
  ) => {
    const data = { ...saveData };
    const question: any = data.triviaQuestions[index];
    question.choices = choices;
    question.answer = answer;

    setSaveData(data);

    if (props.onChange) {
      props.onChange({ triviaQuestions: data.triviaQuestions });
    }
  };

  const handleAddQuestion = () => {
    const data = { ...saveData };
    if (!data.triviaQuestions || data.triviaQuestions.length >= maxQuestions)
      return;

    data.triviaQuestions.push(getDefaultQuestion());
    setSaveData(data);

    if (props.onChange) {
      props.onChange({ triviaQuestions: data.triviaQuestions });
    }
  };

  return (
    <React.Fragment>
      <span className={clsx("activity-form-name", props.color)}>Trivia</span>
      <p className={"activity-form-desc"}>
        Use a trivia format to quiz learners in a fun and competitive setting
        with the whole class.
      </p>
      <div className={clsx("warmup-header-break", props.color + "-border")} />
      <div className={"warmup-duration"}>
        <Typography
          component="span"
          variant="subtitle1"
          className="warmup-tot-duration-text"
        >
          Total Duration:
        </Typography>
        <Duration
          time={saveData.triviaDuration}
          onChange={handleDurationChange}
        />
      </div>
      {saveData.triviaQuestions.map((question: any, questionIndex: number) => (
        <div
          key={`kcf-kcq-${questionIndex + 1}`}
          className={"warmup-subsection"}
        >
          <div className={"warmup-subsection"}>
            <div className={"warmup-duration"}>
              <Typography
                component="span"
                variant="subtitle2"
                className="warmup-subtitle"
              >
                {getRoutinePromptText()}
              </Typography>
            </div>
            <textarea
              className={"warmup-subtext"}
              placeholder="What question do you want to ask learners?"
              rows={2}
              value={question.question}
              onChange={(event) =>
                handleQuestionChange(questionIndex, event.target.value)
              }
            />
          </div>
          <div className={"warmup-subsection"}>
            <div>
              <Typography
                component="span"
                variant="subtitle2"
                className="warmup-subtitle"
              >
                Settings
              </Typography>

              <div className={"warmup-duration warmup-subsection-small"}>
                <Typography
                  component="span"
                  variant="body1"
                  className="warmup-subtext"
                >
                  Show trivia board to class
                </Typography>
                <Switch
                  checked={saveData.triviaShowBoardToggle}
                  onChange={(event) =>
                    handleDataChange(
                      "triviaShowBoardToggle",
                      event.target.checked
                    )
                  }
                  name="triviaShowBoardToggle"
                  color="primary"
                />
              </div>
              <div className={"warmup-duration warmup-subsection-small"}>
                <Typography
                  component="span"
                  variant="body1"
                  className="warmup-subtext"
                >
                  Add timer
                </Typography>
                <Switch
                  checked={saveData.triviaAddTimerToggle}
                  onChange={(event) =>
                    handleDataChange(
                      "triviaAddTimerToggle",
                      event.target.checked
                    )
                  }
                  name="triviaAddTimerToggle"
                  color="primary"
                />
              </div>
              <div className={"warmup-duration"}>
                <Typography
                  component="span"
                  variant="body1"
                  className="warmup-subtext"
                >
                  Question Type
                </Typography>
                <Select
                  classes={{ root: questionClasses.typeSelect }}
                  variant="filled"
                  value={question.type}
                  onChange={(event) =>
                    handleQuestionSelectChange(
                      questionIndex,
                      event.target.value
                    )
                  }
                >
                  {AssessQuestionTypes.map(
                    (type: AssessQuestionType, index: number) => (
                      <MenuItem
                        key={`kcf-aqt-${index + 1}`}
                        value={type}
                        disabled={type === AssessQuestionType.Matching}
                      >
                        {getAssessQuestionTypeString(type)}
                      </MenuItem>
                    )
                  )}
                </Select>
              </div>
              <AssessQuestion
                type={question.type}
                choices={question.choices}
                answer={question.answer}
                onChange={(
                  choices: any,
                  answer: string | number | object | undefined
                ) => handleChoiceChange(questionIndex, choices, answer)}
              />
            </div>
          </div>
        </div>
      ))}
      <div
        className={
          "class-add-objectives class-input class-col-center class-row-center"
        }
      >
        <IconButton
          className={"class-add-objective-button learnidy-green-bg"}
          onClick={handleAddQuestion}
        >
          <AddIcon fontSize="inherit" />
        </IconButton>
        <Typography component="span" variant="body2" className="">
          + Add Trivia Question
        </Typography>
      </div>
    </React.Fragment>
  );
}
