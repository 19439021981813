import React, { useState, useEffect } from "react";
import IncrementInput from "../../IncrementInput/IncrementInput";
import Typography from "@material-ui/core/Typography";
import "../../../styles/styles.css";
import {
  PresentCard,
  PresentCardGroup,
  PresentDescText,
} from "../../Presentation/Presentation";
import clsx from "clsx";

interface ThinkPairShareProps {
  saveData: any;
  subslideIndex: number;
  color: string;
  onChange?: any;
}

export function ThinkPairShareSlide(props: ThinkPairShareProps) {
  const getCardWidth = (cardIndx: number) => {
    if (props.subslideIndex === 0) return "33%";
    if (props.subslideIndex === cardIndx) return "60%";
    return "20%";
  };

  const getCardTime = (cardIndx: number) => {
    switch (cardIndx) {
      case 1:
        return `(${props.saveData.thinkPromptTime} mins)`;

      case 2:
        return `(${props.saveData.pairPromptTime} mins)`;

      case 3:
        return `(${props.saveData.sharePromptTime} mins)`;
    }

    return "";
  };

  const getCardTitle = (cardIndx: number) => {
    if (props.subslideIndex === cardIndx) {
      switch (cardIndx) {
        case 1:
          return "Think " + getCardTime(cardIndx);

        case 2:
          return "Pair " + getCardTime(cardIndx);

        case 3:
          return "Share " + getCardTime(cardIndx);
      }
    }

    return undefined;
  };

  const getCardTextSize = (
    cardIndx: number
  ): "xlarge" | "large" | "medium" | "small" => {
    if (props.subslideIndex === 0) return "xlarge";
    if (props.subslideIndex === cardIndx) return "xlarge";
    return "large";
  };

  const getCardAlign = (cardIndx: number): "start" | "center" | "end" => {
    if (props.subslideIndex === cardIndx) return "start";
    return "center";
  };

  const getDisabled = (cardIndx: number): boolean => {
    if (props.subslideIndex === 0 || props.subslideIndex === cardIndx)
      return false;

    return true;
  };

  return (
    <React.Fragment>
      {props.subslideIndex === 0 && (
        <PresentDescText
          value={`
            A three part activity where you first fill out 
            a written prompt about a question. Next, share 
            your thoughts with a matched partner. Finally, 
            the whole group is brought back together to listen 
            to other pairs share their responses.`}
        />
      )}
      <PresentCardGroup equalSpace={false} justify="center">
        <PresentCard
          title={getCardTitle(1)}
          value={
            props.subslideIndex === 1 ? props.saveData.thinkPrompt : "Think"
          }
          color={props.color}
          width={getCardWidth(1)}
          size={getCardTextSize(1)}
          align={getCardAlign(1)}
          caption={props.subslideIndex === 0 ? getCardTime(1) : undefined}
          disabled={getDisabled(1)}
        />
        <PresentCard
          title={getCardTitle(2)}
          value={props.subslideIndex === 2 ? props.saveData.pairPrompt : "Pair"}
          color={props.color}
          width={getCardWidth(2)}
          size={getCardTextSize(2)}
          align={getCardAlign(2)}
          caption={props.subslideIndex === 0 ? getCardTime(2) : undefined}
          disabled={getDisabled(2)}
        />
        <PresentCard
          title={getCardTitle(3)}
          value={
            props.subslideIndex === 3 ? props.saveData.sharePrompt : "Share"
          }
          color={props.color}
          width={getCardWidth(3)}
          size={getCardTextSize(3)}
          align={getCardAlign(3)}
          caption={props.subslideIndex === 0 ? getCardTime(3) : undefined}
          disabled={getDisabled(3)}
        />
      </PresentCardGroup>
    </React.Fragment>
  );
}

export default function ThinkPairShareForm(props: ThinkPairShareProps) {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [saveData, setSaveData] = useState<any>({
    thinkPairShareDuration: 0,
    thinkPrompt:
      props.saveData && props.saveData.thinkPrompt
        ? props.saveData.thinkPrompt
        : "",
    pairPrompt:
      props.saveData && props.saveData.pairPrompt
        ? props.saveData.pairPrompt
        : "",
    sharePrompt:
      props.saveData && props.saveData.sharePrompt
        ? props.saveData.sharePrompt
        : "",
    thinkPromptTime:
      props.saveData && props.saveData.thinkPromptTime
        ? props.saveData.thinkPromptTime
        : 5,
    pairPromptTime:
      props.saveData && props.saveData.pairPromptTime
        ? props.saveData.pairPromptTime
        : 5,
    sharePromptTime:
      props.saveData && props.saveData.sharePromptTime
        ? props.saveData.sharePromptTime
        : 5,
  });

  useEffect(() => {
    if (!hasLoaded) {
      const data = { ...saveData };
      data.thinkPairShareDuration =
        data.thinkPromptTime + data.pairPromptTime + data.sharePromptTime;

      setSaveData(data);
      if (props.onChange) {
        props.onChange({
          thinkPairShareDuration: data.thinkPairShareDuration,
          thinkPromptTime: data.thinkPromptTime,
          pairPromptTime: data.pairPromptTime,
          sharePromptTime: data.sharePromptTime,
        });
      }

      setHasLoaded(true);
    }
  }, [hasLoaded, saveData, props]);

  const handleDataChange = (key: string, value: any) => {
    if (!key) return;

    const data = { ...saveData };
    data[key] = value;
    setSaveData(data);

    if (props.onChange) props.onChange({ [key]: value });
  };

  const handleTimeChange = (key: string, value: number) => {
    const data = { ...saveData };
    data[key] = value;
    data.thinkPairShareDuration = getDuration(data);
    setSaveData(data);

    if (props.onChange) {
      props.onChange({
        thinkPairShareDuration: data.thinkPairShareDuration,
        [key]: value,
      });
    }
  };

  const getDuration = (data: any): number => {
    return data.thinkPromptTime + data.pairPromptTime + data.sharePromptTime;
  };

  return (
    <React.Fragment>
      <span className={clsx("activity-form-name", props.color)}>
        Think, Pair, Share
      </span>
      <p className={"activity-form-desc"}>
        A three part activity where learners first fill out a written prompt
        about their thoughts on a question you choose. This is followed by them
        sharing their thoughts with their partner and filling out another prompt
        during that sharing session. Finally, learners will fill out a third
        written prompt while listening to pairs share their responses.
      </p>
      <div className={clsx("warmup-header-break", props.color + "-border")} />
      <div className={"warmup-duration"}>
        <Typography
          component="span"
          variant="subtitle1"
          className="warmup-tot-duration-text"
        >
          Total Duration:
        </Typography>
        <Typography
          component="span"
          variant="body2"
          className="warmup-tot-duration-text-alt learnidy-blue-bg"
        >
          {saveData.thinkPairShareDuration} mins
        </Typography>
      </div>
      <div className={"warmup-subsection"}>
        <div className={"warmup-duration"}>
          <Typography
            component="span"
            variant="subtitle2"
            className="warmup-subtitle"
          >
            Think Prompt<span style={{ color: "red" }}>*</span>:
          </Typography>
          <IncrementInput
            time={saveData.thinkPromptTime}
            onChange={(value: number) =>
              handleTimeChange("thinkPromptTime", value)
            }
          />
        </div>
        <textarea
          className={"warmup-subtext"}
          placeholder="What question do you want learners to think about on their own?"
          rows={2}
          value={saveData.thinkPrompt}
          onChange={(event) =>
            handleDataChange("thinkPrompt", event.target.value)
          }
        />
      </div>
      <div className={"warmup-subsection"}>
        <div className={"warmup-duration"}>
          <Typography
            component="span"
            variant="subtitle2"
            className="warmup-subtitle"
          >
            Pair Prompt<span style={{ color: "red" }}>*</span>:
          </Typography>
          <IncrementInput
            time={saveData.pairPromptTime}
            onChange={(value: number) =>
              handleTimeChange("pairPromptTime", value)
            }
          />
        </div>
        <textarea
          className={"warmup-subtext"}
          placeholder="What instructions do you have for learners while they are sharing their thoughts with their partner? For example, Discuss your ideas with your partner. Then write down..."
          rows={3}
          value={saveData.pairPrompt}
          onChange={(event) =>
            handleDataChange("pairPrompt", event.target.value)
          }
        />
      </div>
      <div className={"warmup-subsection"}>
        <div className={"warmup-duration"}>
          <Typography
            component="span"
            variant="subtitle2"
            className="warmup-subtitle"
          >
            Share Prompt<span style={{ color: "red" }}>*</span>:
          </Typography>
          <IncrementInput
            time={saveData.sharePromptTime}
            onChange={(value: number) =>
              handleTimeChange("sharePromptTime", value)
            }
          />
        </div>
        <textarea
          className={"warmup-subtext"}
          placeholder="This is the prompt for written submission for students to fill out while other pairs are sharing."
          rows={2}
          value={saveData.sharePrompt}
          onChange={(event) =>
            handleDataChange("sharePrompt", event.target.value)
          }
        />
      </div>
    </React.Fragment>
  );
}
