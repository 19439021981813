import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
// import { createStyles, makeStyles /*, Theme*/ } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import MediaViewer from "../../Presentation/MediaViewer";
import "../../../styles/styles.css";
import UploadFiles from "../../../components/UploadFiles/UploadFiles";
import { LargeText, PresentDescText } from "../../Presentation/Presentation";
import clsx from "clsx";
import Duration from "../Duration/Duration";
import { MediaType } from "../../../constants/media";

// const useStyles = makeStyles((theme: any) =>
//   createStyles({
//     radioFormControl: {
//       margin: 0,
//     },
//     radioGroup: {
//       margin: 0,
//     },
//     radioRoot: {
//       margin: 0,
//       padding: "2px 10px",
//     },
//   })
// );

interface GroupWorkProps {
  saveData: any;
  id: string;
  sessionPlanId: string;
  color: string;
  validate?: boolean;
  onChange?: any;
  doSave?: boolean;
}

export function GroupWorkSlide(props: GroupWorkProps) {
  return (
    <React.Fragment>
      <PresentDescText
        value={`Collaboratively prepare review materials, brainstorm ideas, and work on assignments for class in groups using the provided shared workspace.`}
      />
      <Typography component="label" variant="subtitle1" classes={{}}>
        {`Number of Groups: ${props.saveData.roleplayNumTopicGroups}`}
      </Typography>
      <LargeText
        value={props.saveData.groupWorkPrompt}
        backgroundColor="learnidy-teal-bg"
        isMultiLine={false}
      />
      <MediaViewer uploadFiles={props.saveData.uploadFiles} />
    </React.Fragment>
  );
}

export default function GroupWorkForm(props: GroupWorkProps) {
  //   const classes = useStyles();
  const [hasLoaded, setHasLoaded] = useState(false);
  //   const [groupSettingsError] = React.useState(false);
  //   const [requiredText] = React.useState("Please select a choice");
  const [saveData, setSaveData] = useState<any>({
    groupWorkDuration:
      props.saveData && props.saveData.groupWorkDuration
        ? props.saveData.groupWorkDuration
        : 10,
    roleplayGroupSettings:
      props.saveData && props.saveData.roleplayGroupSettings
        ? props.saveData.roleplayGroupSettings
        : "",
    groupWorkPrompt:
      props.saveData && props.saveData.groupWorkPrompt
        ? props.saveData.groupWorkPrompt
        : "",
    groupWorkSharedLinkToggle:
      props.saveData && props.saveData.groupWorkSharedLinkToggle
        ? props.saveData.groupWorkSharedLinkToggle
        : false,
    groupWorkSharedLink:
      props.saveData && props.saveData.groupWorkSharedLink
        ? props.saveData.groupWorkSharedLink
        : "",
    roleplayNumTopicGroups:
      props.saveData && props.saveData.roleplayNumTopicGroups
        ? props.saveData.roleplayNumTopicGroups
        : 1,
    uploadFiles:
      props.saveData && props.saveData.uploadFiles
        ? props.saveData.uploadFiles
        : [],
  });

  useEffect(() => {
    if (!hasLoaded) {
      if (props.onChange)
        props.onChange({
          groupWorkDuration: saveData.groupWorkDuration,
        });

      setHasLoaded(true);
    }
  }, [hasLoaded, saveData, props]);

  const handleDataChange = (key: string, value: any) => {
    if (!key) {
      return;
    }

    const data = { ...saveData };
    data[key] = value;
    setSaveData(data);

    if (props.onChange) {
      props.onChange({ [key]: value });
    }
  };

  const handleNumTopicChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let len: number = parseInt(event.target.value);
    if (!Number.isInteger(len)) len = 0;

    if (len < 0) len = 0;
    else if (len > 100) len = 100;

    handleDataChange("roleplayNumTopicGroups", len);
  };

  const handleDurationChange = (val: string) => {
    handleDataChange("groupWorkDuration", val);
  };

  const handleUploadFiles = (files: any) => {
    const data = { ...saveData };
    data.uploadFiles = [...files];
    setSaveData(data);

    if (props.onChange) {
      props.onChange({ uploadFiles: data.uploadFiles });
    }
  };

  return (
    <React.Fragment>
      <span className={clsx("activity-form-name", props.color)}>
        Group Work
      </span>
      <p className={"activity-form-desc"}>
        Groupwork allows learners to collaboratively prepare review materials,
        brainstorm ideas, and work on assignments for class in groups.
      </p>
      <div className={clsx("warmup-header-break", props.color + "-border")} />
      <div className={"warmup-duration"}>
        <Typography
          component="span"
          variant="subtitle1"
          className="warmup-tot-duration-text"
        >
          Total Duration:
        </Typography>
        <Duration
          time={saveData.groupWorkDuration}
          onChange={handleDurationChange}
        />
      </div>
      <div className={"warmup-subsection"}>
        <div className={"warmup-duration"}>
          <Typography
            component="span"
            variant="subtitle2"
            className="warmup-subtitle"
          >
            Group Work Prompt
          </Typography>
        </div>
        <textarea
          className={"warmup-subtext"}
          placeholder="What group work prompt do you want to provide all learners?"
          rows={2}
          value={saveData.groupWorkPrompt}
          onChange={(event) =>
            handleDataChange("groupWorkPrompt", event.target.value)
          }
        />
      </div>
      <div className={"warmup-subsection"}>
        <div>
          <Typography
            component="span"
            variant="subtitle2"
            className="warmup-subtitle"
          >
            Group Work Details
          </Typography>
        </div>
        {saveData.groupWorkSharedLinkToggle ? (
          <div className={"warmup-duration warmup-subsection-small"}>
            <TextField
              variant="outlined"
              size="small"
              value={saveData.groupWorkSharedLink}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleDataChange("groupWorkSharedLink", event.target.value)
              }
            />
          </div>
        ) : null}
        <div className={"warmup-duration warmup-subsection-small"}>
          <Typography component="span" variant="body2">
            How many topic groups do you want to create?
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            value={saveData.roleplayNumTopicGroups}
            onChange={handleNumTopicChange}
          />
        </div>
      </div>
      <UploadFiles
        uploadFiles={saveData.uploadFiles}
        mediaType={MediaType.Any}
        sessionPlanId={props.sessionPlanId}
        activityId={props.id}
        onChange={handleUploadFiles}
        doUpload={props.doSave}
      />
    </React.Fragment>
  );
}
