import React from "react";
import Header from "../../components/Header/Header";
import "../common.css";
import "./Home.css";

function HomeComponent(props: any) {
  return (
    <React.Fragment>
      <Header {...props} />
    </React.Fragment>
  );
}

export default HomeComponent;
