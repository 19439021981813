import React, { useState } from "react";
import { SessionPlanModel } from "../../models/SessionPlanModel";
import ReviewInformationSummary from "./ReviewInformationSummary";
// import CloseIcon from "@material-ui/icons/Close";
// import { makeStyles, Theme } from "@material-ui/core/styles";
// import { widgetStyles } from "../../styles/WidgetStyles";
// import { Button, Typography } from "@material-ui/core";
import Widget from "../Widget/Widget";

// const useStyles = makeStyles((theme: Theme) => ({
//   reviewRoot: {
//     height: "100%",
//     backgroundColor: theme.palette.background.paper,
//     display: "flex",
//   },
// }));

interface ReviewInformationProps {
  //   session?: CalendarEvent;
  sessionPlan: SessionPlanModel;
  show: boolean;
  onCancel?: any;
  onSave?: any;
  onLoadSessionPlan?: any;
}

export default function ReviewInformation(props: ReviewInformationProps) {
  //   const classes = useStyles();
  //   const widgetClasses = widgetStyles();
  const [collapsed, setCollapsed] = useState(false);

  const handleCancel = () => {
    if (props.onCancel) {
      props.onCancel();
    }
  };

  const handleCollapse = (isCollapsed: boolean) => {
    setCollapsed(isCollapsed);
  };

  const getWidgetRender = () => {
    // return (
    //   <div className={widgetClasses.widgetRoot}>
    //     <div className={widgetClasses.header}>
    //       <Typography
    //         component="div"
    //         variant="subtitle1"
    //         className={widgetClasses.widgetTitle}
    //       >
    //         Review
    //       </Typography>
    //       <Button
    //         // classes={{ root: classes.customEventButton }}
    //         // variant=""
    //         onClick={handleCancel}
    //       >
    //         <CloseIcon fontSize="default" />
    //       </Button>
    //     </div>
    //     <div className={widgetClasses.widgetBody}>
    //       <form className={"class-form"}>
    //         <div className={widgetClasses.widgetCol}>
    //           <div className={"class-input"}>
    //             <ReviewInformationSummary sessionPlan={props.sessionPlan} />
    //           </div>
    //         </div>
    //       </form>
    //     </div>
    //   </div>
    // );

    return (
      <Widget
        title={"Review"}
        startCollapsed={collapsed}
        onCollapse={handleCollapse}
        onCancel={handleCancel}
      >
        <ReviewInformationSummary sessionPlan={props.sessionPlan} />
      </Widget>
    );
  };

  return <React.Fragment>{props.show && getWidgetRender()}</React.Fragment>;
}
