import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const questionStyles = makeStyles((theme: Theme) =>
  createStyles({
    addAnswer: {
      width: "10em",
      background: "none",
      boxShadow: "none",
    },
    addAnswerText: {
      fontWeight: "bold",
    },
    question: {
      marginTop: 10,
      border: "none",
      fontSize: "0.88rem",
      fontFamily: `Nunito, "Roboto", Helvetica, sans-serif`,
      resize: "none",
      overflowX: "hidden",
      overflowY: "auto",
    },
    radioRow: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginBottom: 14,
    },
    routineTrashBtn: {
      boxShadow: "none",
      minWidth: "40px",
      minHeight: "40px",
      padding: 0,
      margin: 0,
    },
    mcRadioLabel: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "row",
    },
    fullWidth: {
      width: "100%",
    },
    mcRadioTextRoot: {
      border: "none",
    },
    mcRadioBtn: {},
    mcTrashBtn: {
      boxShadow: "none",
      //   maxWidth: "40px",
      //   maxHeight: "40px",
      minWidth: "36px",
      minHeight: "36px",
      padding: 0,
      margin: "0 0 0 10px",
    },
    primaryIcon: {
      color: "rgba(0,0,0,0.54)",
    },
    questionTextArea: {
      margin: "20px 0",
      "& textarea": {
        padding: "10px 0 10px 10px",
      },
    },
    typeSelect: {
      padding: "10px 10px",
    },
  })
);
