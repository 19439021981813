export enum AssessQuestionType {
  MultipleChoice,
  MultipleAnswer,
  Textbox,
  FillInTheBlank,
  Matching,
}

export const AssessQuestionTypes: AssessQuestionType[] = [
  AssessQuestionType.MultipleChoice,
  AssessQuestionType.MultipleAnswer,
  AssessQuestionType.Textbox,
  AssessQuestionType.FillInTheBlank,
  AssessQuestionType.Matching,
];

export const getAssessQuestionTypeString = (type: AssessQuestionType) => {
  switch (type) {
    case AssessQuestionType.MultipleChoice:
      return "Multiple choice";
    case AssessQuestionType.MultipleAnswer:
      return "Multiple answer";
    case AssessQuestionType.Textbox:
      return "Textbox";
    case AssessQuestionType.FillInTheBlank:
      return "Fill in the blank";
    case AssessQuestionType.Matching:
      return "Matching";
  }
};

export interface QuestionProps {
  choices: string[];
  answer?: string | number | object | undefined;
  type: AssessQuestionType;
  onChange: any;
}
