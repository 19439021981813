import React, { useState } from "react";
import { Card, IconButton, Typography } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import "./Activity.css";
import { ActivityTypes } from "./ActivityTypes";
import { ACTIVITY_HEIGHT_PER_MINUTE } from "../../constants/session";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";

export const calculateHeight = (duration: number) => {
  return duration * ACTIVITY_HEIGHT_PER_MINUTE;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    activitySection: {
      display: "grid",
      gridTemplateColumns: "1fr auto",
      flexGrow: 1,
    },
    activityContent: {
      margin: 6,
    },
    activityBtns: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
    },
    activityIcon: {
      fontSize: "1rem",
      // [theme.breakpoints.up("md")]: {
      //   fontSize: "2.4rem",
      // },
      "@media (min-width:260px)": {
        fontSize: "1.5rem",
      },
    },
    editLabel: {
      marginLeft: 12,
      fontSize: "0.9rem",
      color: theme.palette.error.main,
    },
  })
);

export interface ActivityProps {
  children?: React.ReactNode;
  id: string;
  type: ActivityTypes;
  name: string;
  description?: string;
  icon?: any;
  color: string;
  activeSession: boolean;
  onSave?: any;
  onDelete?: any;
  onShowEditForm?: any;
  saveData: any;
  activityActionRender: any;
}

export default function Activity(props: ActivityProps) {
  //   constructor(props: ActivityProps) {
  //     super(props);

  //     this.state = {
  //       activityActionRender: props.activityActionRender,
  //       saveData: props.saveData,
  //       showEditForm: false,
  //     };
  //   }
  const classes = useStyles();
  const [saveData, setSaveData] = useState(props.saveData);
  const [activityActionRender, setActivityActionRender] = useState(
    props.activityActionRender
  );
  const [showEditForm, setShowEditForm] = useState(false);

  const handleEdit = () => {
    if (props.onShowEditForm) {
      setShowEditForm(true);
      props.onShowEditForm(true);
    }
  };

  const handleDelete = () => {
    if (props.onDelete) {
      props.onDelete(props.id);
    }
  };

  const handleSave = (activityData: any) => {
    // Mark this activity touched once it is saved
    if (!activityData.saveData.touched) {
      activityData.saveData.touched = true;
    }

    setSaveData(activityData.saveData);
    setActivityActionRender(activityData.activityActionRender);

    if (props.onSave) {
      props.onSave({ id: props.id, saveData: activityData.saveData });
    }
  };

  const handleClose = (event: any) => {
    // Enable dragging first before hiding popup
    if (props.onShowEditForm) props.onShowEditForm(false);
    setShowEditForm(false);

    if (event) event.stopPropagation();
  };

  const getChildrenWithProps = () => {
    const childrenWithProps = React.Children.map(props.children, (child) => {
      // checking isValidElement is the safe way and avoids a typescript error too
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          onClose: (event: any) => handleClose(event),
          onSave: handleSave,
          saveData: saveData,
        });
      }

      return child;
    });

    return childrenWithProps;
  };

  const getActivityName = (activityProps: any) => {
    if (activityProps.type === ActivityTypes.Custom) {
      if (activityProps.saveData.activityName) {
        return activityProps.saveData.activityName;
      }
    }

    return activityProps.name;
  };

  return (
    <div
      className="class-activity"
      onClick={handleEdit}
      style={{
        height: calculateHeight(saveData.duration).toString() + "px",
      }}
    >
      <Card className={"activity "}>
        <div className={clsx("first-half", props.color + "-bg")}>
          {props.activeSession ? (
            <div className="activity-first-half-duration">
              <span>{saveData.duration}</span>
              <span>mins</span>
            </div>
          ) : (
            props.icon
          )}
        </div>
        <div className={classes.activitySection}>
          <div className={classes.activityContent}>
            <Typography component="h5" variant="h5" className="activity-name">
              {getActivityName(props)}
              {!saveData.touched && props.activeSession && (
                <Typography
                  component="label"
                  variant="caption"
                  className={classes.editLabel}
                >
                  (click to edit)
                </Typography>
              )}
            </Typography>
            {props.activeSession ? (
              activityActionRender
            ) : (
              <Typography
                component="p"
                variant="subtitle1"
                className="activity-desc"
              >
                {props.description}
              </Typography>
            )}
          </div>
          {props.activeSession && (
            <div className={classes.activityBtns}>
              <IconButton
                className="delete-activity-btn"
                disabled={!props.activeSession}
                onClick={handleDelete}
              >
                <Delete classes={{ root: classes.activityIcon }} />
              </IconButton>
            </div>
          )}
        </div>
      </Card>
      {showEditForm === true ? getChildrenWithProps() : null}
    </div>
  );
}
