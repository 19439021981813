import React, { useState, useEffect } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Select from "@material-ui/core/Select";
import { createStyles, makeStyles /*, Theme*/ } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AssessQuestion, { AssessQuestionSlide } from "./AssessQuestion";
import "../../../styles/styles.css";
import {
  AssessQuestionType,
  AssessQuestionTypes,
  getAssessQuestionTypeString,
} from "./Questions/QuestionTypes";
import { Button } from "@material-ui/core";
import { questionStyles } from "../../../styles/QuestionStyles";
import clsx from "clsx";
import Duration from "../Duration/Duration";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    radioFormControl: {
      margin: 0,
    },
    radioGroup: {
      margin: 0,
    },
    radioRoot: {
      margin: 0,
      padding: "2px 10px",
    },
    addQuestion: {
      fontWeight: "bold",
    },
  })
);

const getRoutinePromptText = () => {
  return "Knowledge Check Question:";
};

interface KnowledgeCheckProps {
  saveData: any;
  subslideIndex: number;
  color: string;
  validate?: boolean;
  onChange?: any;
}

export function KnowledgeCheckSlide(props: KnowledgeCheckProps) {
  const [showAnswer, setShowAnswer] = useState(false);

  // Hide the answer when the slide changes
  useEffect(() => {
    setShowAnswer(false);
  }, [props.subslideIndex]);

  const handleShowAnswer = () => {
    setShowAnswer(true);
  };

  if (
    props.saveData.knowledgeCheckQuestions &&
    props.subslideIndex < props.saveData.knowledgeCheckQuestions.length
  ) {
    const question: any =
      props.saveData.knowledgeCheckQuestions[props.subslideIndex];

    if (question) {
      return (
        <AssessQuestionSlide
          question={question}
          subslideIndex={props.subslideIndex}
          showAnswer={showAnswer}
          onShowAnswer={handleShowAnswer}
        />
      );
    }
  }

  return <React.Fragment></React.Fragment>;
}

const getDefaultQuestion = (): object => {
  return {
    type: AssessQuestionType.MultipleChoice,
    question: "",
    choices: null,
  };
};

export default function KnowledgeCheckForm(props: KnowledgeCheckProps) {
  const classes = useStyles();
  const questionClasses = questionStyles();
  const [maxQuestions] = useState(10);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [saveData, setSaveData] = useState<any>({
    knowledgeCheckDuration:
      props.saveData && props.saveData.knowledgeCheckDuration
        ? props.saveData.knowledgeCheckDuration
        : 10,
    knowledgeCheckQuestions:
      props.saveData && props.saveData.knowledgeCheckQuestions
        ? props.saveData.knowledgeCheckQuestions
        : [getDefaultQuestion()],
  });

  useEffect(() => {
    if (!hasLoaded) {
      if (props.onChange)
        props.onChange({
          knowledgeCheckDuration: saveData.knowledgeCheckDuration,
        });

      setHasLoaded(true);
    }
  }, [hasLoaded, saveData, props]);

  const getPlaceholderText = (type: AssessQuestionType) => {
    switch (type) {
      case AssessQuestionType.FillInTheBlank:
        return "We live on planet ____ which is located in the Milky Way ____.";

      default:
        return "What question do you want to ask learners?";
    }
  };

  const handleDataChange = (key: string, value: any) => {
    if (!key) {
      return;
    }

    const data = { ...saveData };
    data[key] = value;
    setSaveData(data);

    if (props.onChange) {
      props.onChange({ [key]: value });
    }
  };

  const handleDurationChange = (val: string) => {
    handleDataChange("knowledgeCheckDuration", val);
  };

  const handleQuestionChange = (index: number, value: string) => {
    const data = { ...saveData };
    const question: any = data.knowledgeCheckQuestions[index];
    question.question = value;
    setSaveData(data);

    if (props.onChange) {
      props.onChange({ knowledgeCheckQuestions: data.knowledgeCheckQuestions });
    }
  };

  const handleQuestionSelectChange = (index: number, value: unknown) => {
    const data = { ...saveData };
    const question: any = data.knowledgeCheckQuestions[index];
    question.type = value;

    setSaveData(data);
  };

  const handleChoiceChange = (
    index: number,
    choices: [],
    answer: string | number | object | undefined
  ) => {
    const data = { ...saveData };
    const question: any = data.knowledgeCheckQuestions[index];
    question.choices = choices;
    question.answer = answer;

    setSaveData(data);

    if (props.onChange) {
      props.onChange({ knowledgeCheckQuestions: data.knowledgeCheckQuestions });
    }
  };

  const handleAddQuestion = () => {
    const data = { ...saveData };
    if (
      !data.knowledgeCheckQuestions ||
      data.knowledgeCheckQuestions.length >= maxQuestions
    ) {
      return;
    }

    data.knowledgeCheckQuestions.push(getDefaultQuestion());
    setSaveData(data);

    if (props.onChange) {
      props.onChange({ knowledgeCheckQuestions: data.knowledgeCheckQuestions });
    }
  };

  const handleDeleteQuestion = (index: number) => {
    const data = { ...saveData };
    if (
      !data.knowledgeCheckQuestions ||
      index < 0 ||
      index >= data.knowledgeCheckQuestions.length
    ) {
      return;
    }

    data.knowledgeCheckQuestions.splice(index, 1);
    setSaveData(data);
    if (props.onChange) {
      props.onChange({ knowledgeCheckQuestions: data.knowledgeCheckQuestions });
    }
  };

  return (
    <React.Fragment>
      <span className={clsx("activity-form-name", props.color)}>
        Knowledge Check
      </span>
      <p className={"activity-form-desc"}>
        Knowledge checks are quizzes that are meant to give the learner an idea
        of how well they know the material, are way for learners to review their
        learning progress and see where any gaps exist.
      </p>
      <div className={clsx("warmup-header-break", props.color + "-border")} />
      <div className={"warmup-duration"}>
        <Typography
          component="span"
          variant="subtitle1"
          className="warmup-tot-duration-text"
        >
          Total Duration:
        </Typography>
        <Duration
          time={saveData.knowledgeCheckDuration}
          onChange={handleDurationChange}
        />
      </div>
      {saveData.knowledgeCheckQuestions.map(
        (question: any, questionIndex: number) => (
          <div
            key={`kcf-kcq-${questionIndex + 1}`}
            className={"warmup-subsection"}
          >
            <div className={"warmup-subsection"}>
              <div className={"warmup-duration"}>
                <Typography
                  component="span"
                  variant="subtitle2"
                  className="warmup-subtitle"
                >
                  {getRoutinePromptText()}
                </Typography>
                <Button
                  variant="contained"
                  className={questionClasses.routineTrashBtn}
                  onClick={() => handleDeleteQuestion(questionIndex)}
                >
                  <DeleteIcon fontSize="small" />
                </Button>
              </div>
              <textarea
                className={questionClasses.question}
                placeholder={getPlaceholderText(question.type)}
                rows={2}
                value={question.question}
                onChange={(event) =>
                  handleQuestionChange(questionIndex, event.target.value)
                }
              />
            </div>
            <div className={"warmup-subsection"}>
              <div>
                <Typography
                  component="span"
                  variant="subtitle2"
                  className="warmup-subtitle"
                >
                  Settings
                </Typography>
                <div className={"warmup-duration"}>
                  <Typography
                    component="span"
                    variant="body1"
                    className="warmup-subtext"
                  >
                    Question Type
                  </Typography>
                  <Select
                    classes={{ root: questionClasses.typeSelect }}
                    variant="filled"
                    value={question.type}
                    onChange={(event) =>
                      handleQuestionSelectChange(
                        questionIndex,
                        event.target.value
                      )
                    }
                  >
                    {AssessQuestionTypes.map(
                      (type: AssessQuestionType, index: number) => (
                        <MenuItem
                          key={`kcf-aqt-${index + 1}`}
                          value={type}
                          disabled={type === AssessQuestionType.Matching}
                        >
                          {getAssessQuestionTypeString(type)}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </div>
                <AssessQuestion
                  type={question.type}
                  choices={question.choices}
                  answer={question.answer}
                  onChange={(
                    choices: [],
                    answer: string | number | object | undefined
                  ) => handleChoiceChange(questionIndex, choices, answer)}
                />
              </div>
            </div>
          </div>
        )
      )}
      <div
        className={
          "class-add-objectives class-input class-col-center class-row-center"
        }
      >
        <IconButton
          className={"class-add-objective-button learnidy-green-bg"}
          onClick={handleAddQuestion}
        >
          <AddIcon fontSize="inherit" />
        </IconButton>
        <Typography
          component="span"
          variant="subtitle1"
          className={classes.addQuestion}
        >
          Add Knowledge Check Question
        </Typography>
      </div>
    </React.Fragment>
  );
}
