import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import SignUpForm from "../../components/SignUp/SignUpForm";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "80vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: theme.palette.background.default,
    },
  })
);

export default function SignUpScreen(props: any) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <SignUpForm {...props} />
    </div>
  );
}
