import React from "react";
import Header from "../../components/Header/Header";
import SignUpScreen from "./SignUpScreen";

export default function SignUp(props: any) {
  return (
    <React.Fragment>
      <Header {...props} />
      <SignUpScreen {...props} />
    </React.Fragment>
  );
}
