import React, { useState, useEffect } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
// import { createStyles, makeStyles /*, Theme*/ } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { v4 as uuidv4 } from "uuid";
import "../../../styles/styles.css";
import {
  PresentCard,
  PresentCardGroup,
  PresentDescText,
} from "../../Presentation/Presentation";
import clsx from "clsx";
import Duration from "../Duration/Duration";

// const useStyles = makeStyles((theme: any) =>
//   createStyles({
//     root: {
//       display: "flex",
//       flexDirection: "column",
//       textAlign: "center",
//     },
//     name: theme.formName,
//   })
// );

const getRoutinePromptText = () => {
  return "Fishbowl Prompt";
};

interface FishbowlProps {
  saveData: any;
  color: string;
  onChange?: any;
}

export function FishbowlSlide(props: FishbowlProps) {
  return (
    <React.Fragment>
      <PresentDescText
        value={`
          A select number of students will be assigned to 
          particpate in the inner circle and engage in a 
          small group discussion. The rest of the class 
          will listen and observe from the outer circle. 
          Be prepared switch circles in the next round.`}
      />
      <PresentCardGroup justify="center">
        <PresentCard
          title="Fishbowl Prompt (Inner Group)"
          subtitle={props.saveData.fishbowlSettings + " students"}
          value={props.saveData.fishbowlPrompt}
          color={props.color}
          titleSize="large"
          size="large"
        />
        <PresentCard
          title="Outer Group Instructions"
          value={props.saveData.fishbowlOuterCircle}
          color={props.color}
          titleSize="large"
          size="large"
        />
      </PresentCardGroup>
    </React.Fragment>
  );
}

export default function FishbowlForm(props: FishbowlProps) {
  // const classes = useStyles();
  const [hasLoaded, setHasLoaded] = useState(false);
  const [numParticipants] = useState<number[]>(
    Array.from({ length: 11 }, (v, k) => k)
  );
  const [saveData, setSaveData] = useState<any>({
    fishbowlDuration:
      props.saveData && props.saveData.fishbowlDuration
        ? props.saveData.fishbowlDuration
        : 10,
    fishbowlPrompt:
      props.saveData && props.saveData.fishbowlPrompt
        ? props.saveData.fishbowlPrompt
        : "",
    fishbowlSettings:
      props.saveData && props.saveData.fishbowlSettings
        ? props.saveData.fishbowlSettings
        : 0,
    fishbowlOuterCircle:
      props.saveData && props.saveData.fishbowlOuterCircle
        ? props.saveData.fishbowlOuterCircle
        : "",
  });

  useEffect(() => {
    if (!hasLoaded) {
      if (props.onChange)
        props.onChange({ fishbowlDuration: saveData.fishbowlDuration });

      setHasLoaded(true);
    }
  }, [hasLoaded, saveData, props]);

  const handleDataChange = (key: string, value: any) => {
    if (!key) {
      return;
    }

    const data = { ...saveData };
    data[key] = value;
    setSaveData(data);

    if (props.onChange) {
      props.onChange({ [key]: value });
    }
  };

  const handleDurationChange = (val: string) => {
    handleDataChange("fishbowlDuration", val);
  };

  return (
    <React.Fragment>
      <span className={clsx("activity-form-name", props.color)}>Fishbowl</span>
      <p className={"activity-form-desc"}>
        A large group dialogue by focusing on a small group discussion in an
        inner circle while the rest of the group listens and observes from the
        outer circle.
      </p>
      <div className={clsx("warmup-header-break", props.color + "-border")} />
      <div className={"warmup-duration"}>
        <Typography
          component="span"
          variant="subtitle1"
          className="warmup-tot-duration-text"
        >
          Total Duration:
        </Typography>
        <Duration
          time={saveData.fishbowlDuration}
          onChange={handleDurationChange}
        />
      </div>
      <div className={"warmup-subsection"}>
        <div className={"warmup-duration"}>
          <Typography
            component="span"
            variant="subtitle2"
            className="warmup-subtitle"
          >
            {getRoutinePromptText()}
            <span style={{ color: "red" }}>*</span>:
          </Typography>
        </div>
        <textarea
          className={"warmup-subtext"}
          placeholder="What question, scenario, or statement do you want inner participants to discuss?"
          rows={3}
          value={saveData.fishbowlPrompt}
          onChange={(event) =>
            handleDataChange("fishbowlPrompt", event.target.value)
          }
        />
      </div>
      <div className={"warmup-subsection"}>
        <div>
          <Typography
            component="span"
            variant="subtitle2"
            className="warmup-subtitle"
          >
            Fishbowl Settings:
          </Typography>
          <div className={"warmup-duration"}>
            <Typography
              component="span"
              variant="body1"
              className="warmup-subtext"
            >
              # of Inner Circle Participants
            </Typography>
            <Select
              value={saveData.fishbowlSettings}
              onChange={(event) =>
                handleDataChange("fishbowlSettings", event.target.value)
              }
            >
              {numParticipants.map((item: any, index: number) => (
                <MenuItem key={uuidv4()} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
      </div>
      <div className={"warmup-subsection"}>
        <div className={"warmup-duration"}>
          <Typography component="span" variant="subtitle2">
            Instructions for Outer Circle Participants
          </Typography>
        </div>
        <textarea
          className={"warmup-subtext"}
          placeholder="While observing, what do you want outer circle participants be doing? (i.e. taking notes, writing down questions, etc)"
          rows={3}
          value={saveData.fishbowlOuterCircle}
          onChange={(event) =>
            handleDataChange("fishbowlOuterCircle", event.target.value)
          }
        />
      </div>
    </React.Fragment>
  );
}
