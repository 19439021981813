import React, { useState, useEffect } from "react";
import TimelineForm, { TimelineSlide } from "./TimelineForm";
import ConceptMapForm, { ConceptMapSlide } from "./ConceptMapForm";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
// import MenuItem from "@material-ui/core/MenuItem";
// import Select from "@material-ui/core/Select";
// import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import TimelineIcon from "@material-ui/icons/Timeline";
import MapIcon from "@material-ui/icons/Map";
// import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import "../../../styles/styles.css";
import {
  ActivityActionToNameMap,
  ActivityFormProps,
  ActivityMode,
  ActivityToNameMap,
} from "../../Activity/ActivityTypes";
import {
  Present,
  PresentDescText,
  PresentMinsText,
  PresentSubtitleText,
  PresentTitleText,
} from "../../Presentation/Presentation";
import { createActivityActionRender } from "../Editing";
import clsx from "clsx";

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       width: 1280,
//       height: 820,
//     },
//   })
// );

export const getRoutineDesc = (action: string) => {
  if (action === "timeline")
    return "Learners analyze historical evidence in order to form and test hypotheses about past events. Using a timeline format, learners will inquire and investigate questions posed by the educator.";
  if (action === "conceptMap")
    return "Concept maps are visual representations learners create to connect ideas, concepts, and terms. Learners can use them to organize information they already know and to incorporate new learning with this prior knowledge.";

  return "";
};

const getDuration = (data: any): number => {
  switch (data.activityAction) {
    case "timeline":
      return data.timelineDuration;

    case "conceptMap":
      return data.conceptMapDuration;

    default:
      return 10;
  }
};

export default function InvestigateForm(props: ActivityFormProps) {
  // const classes = useStyles();
  const [hasLoaded, setHasLoaded] = useState(false);
  const [activityActionRender, setActivityActionRender] = useState<any>();
  const [doSave, setDoSave] = useState(false);
  const [saveData, setSaveData] = useState<any>(props.saveData);

  useEffect(() => {
    if (!hasLoaded) {
      const data = { ...saveData };
      if (!data.activityAction) {
        data.activityAction = "timeline";
        setSaveData(data);
      }

      setActivityActionRender(
        createActivityActionRender(ActivityActionToNameMap[data.activityAction])
      );
      setHasLoaded(true);
    }
  }, [hasLoaded, saveData]);

  const handleActivityAction = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: any
  ) => {
    if (!value) return;

    const data = { ...saveData };
    data.activityAction = value;
    setSaveData(data);
    setActivityActionRender(
      createActivityActionRender(ActivityActionToNameMap[value])
    );
  };

  const handleDataChange = (data: any) => {
    if (!data) return;

    const newObj = Object.assign(saveData, data);
    newObj.duration = getDuration(newObj);
    setSaveData(newObj);

    if (doSave) {
      setDoSave(false);
      handleSaveResume(null);
    }
  };

  const handleSave = (event: any) => {
    if (
      saveData.activityAction === "timeline" ||
      saveData.activityAction === "conceptMap"
    ) {
      // Tell upload files to save first. Then, we resume saving when it calls back
      setDoSave(true);
    } else {
      handleSaveResume(event);
    }
  };

  const handleSaveResume = (event: any) => {
    if (props.onSave) {
      props.onSave({ saveData, activityActionRender });
    }

    handleClose(event);
  };

  const handleClose = (event: any) => {
    if (props.onClose) {
      props.onClose(event);
    }
  };

  const getSlide = () => {
    return (
      <Present>
        <PresentTitleText
          value={ActivityToNameMap["Investigate"]}
          colorClass="learnidy-red"
        />
        <PresentSubtitleText
          value={ActivityActionToNameMap[saveData.activityAction]}
          colorClass="learnidy-red"
        />
        <PresentMinsText value={saveData.duration} />
        <PresentDescText value={getRoutineDesc(saveData.activityAction)} />

        {saveData.activityAction === "timeline" && (
          <TimelineSlide
            saveData={saveData}
            id={props.id}
            sessionPlanId={props.sessionPlanId}
            color={props.color}
          />
        )}
        {saveData.activityAction === "conceptMap" && (
          <ConceptMapSlide
            saveData={saveData}
            id={props.id}
            sessionPlanId={props.sessionPlanId}
            color={props.color}
          />
        )}
      </Present>
    );
  };

  return props.activityMode === ActivityMode.SlideShow ? (
    getSlide()
  ) : (
    <Dialog
      fullScreen={false}
      open={true}
      maxWidth={"lg"}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <div className={"activity-dialog"} style={{ width: 1280, height: 820 }}>
        <div className={"activity-form"}>
          <span className={clsx("activity-form-name", props.color)}>
            Investigate
          </span>
          <div className={"activity-form-btn-group"}>
            <ToggleButtonGroup
              value={saveData.activityAction}
              exclusive={true}
              orientation="vertical"
              onChange={handleActivityAction}
              aria-label="activity action"
            >
              <ToggleButton
                value="timeline"
                aria-label="timeline"
                disabled={false}
                classes={{
                  selected: props.color + "-border",
                }}
              >
                <Typography
                  component="div"
                  variant="subtitle1"
                  className="activity-name"
                >
                  Timeline
                </Typography>
                {saveData.activityAction === "timeline" ? (
                  <Badge>
                    <CheckCircleIcon className={props.color} />
                  </Badge>
                ) : null}
                <TimelineIcon
                  style={{ fontSize: 70 }}
                  classes={{ root: "learnidy-red" }}
                />
              </ToggleButton>
              <ToggleButton
                value="conceptMap"
                aria-label="conceptMap"
                classes={{
                  selected: props.color + "-border",
                }}
              >
                <Typography
                  component="span"
                  variant="subtitle1"
                  className="activity-name"
                >
                  Concept Map
                </Typography>
                {saveData.activityAction === "conceptMap" ? (
                  <Badge>
                    <CheckCircleIcon className={props.color} />
                  </Badge>
                ) : null}
                <MapIcon
                  style={{ fontSize: 70 }}
                  classes={{ root: "learnidy-red" }}
                />
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
        <div className={"activity-form"}>
          <Button
            className={"activity-form-close"}
            variant="outlined"
            onClick={handleClose}
          >
            <CloseRoundedIcon fontSize="small" />
          </Button>
          {saveData.activityAction === "timeline" && (
            <TimelineForm
              saveData={saveData}
              id={props.id}
              sessionPlanId={props.sessionPlanId}
              color={props.color}
              onChange={(data: any) => handleDataChange(data)}
              doSave={doSave}
            />
          )}
          {saveData.activityAction === "conceptMap" && (
            <ConceptMapForm
              saveData={saveData}
              id={props.id}
              sessionPlanId={props.sessionPlanId}
              color={props.color}
              onChange={(data: any) => handleDataChange(data)}
              doSave={doSave}
            />
          )}
          <div className={"warmup-footer"}>
            <Button variant="outlined" onClick={handleSave} disabled={doSave}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
