import React, { useState, useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import "../../../styles/styles.css";
import { LargeText, PresentDescText } from "../../Presentation/Presentation";
import clsx from "clsx";
import Duration from "../Duration/Duration";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stanceRow: {
      width: "100%",
      margin: "15px 0 0 0",
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      gap: 20,
    },
    stanceValueText: {
      backgroundColor: theme.palette.action.disabled,
      color: theme.palette.grey[600],
      width: 150,
      padding: 8,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    gridContainer: {
      width: "100%",
      marginTop: 60,
      display: "flex",
      justifyContent: "center",
    },
    stanceGrid: {
      display: "grid",
      gap: 2,
      gridTemplate: "auto auto / auto auto",
      backgroundColor: theme.palette.grey[800],
      "& > *": {
        backgroundColor: theme.palette.background.default,
      },
      width: "60%",
    },
    stanceGridItem: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: 20,
    },
    stanceGridTitleText: {
      fontWeight: "bold",
      textAlign: "center",
    },
    stanceGridSubtitleText: {
      marginTop: 20,
    },
  })
);

const stanceArray: any[] = [
  {
    corner: "Corner 1 Stance",
    value: "Strongly agree",
  },
  {
    corner: "Corner 2 Stance",
    value: "Agree",
  },
  {
    corner: "Corner 3 Stance",
    value: "Strongly disagree",
  },
  {
    corner: "Corner 4 Stance",
    value: "Disagree",
  },
];

interface FourCornersProps {
  saveData: any;
  color: string;
  onChange?: any;
}

export function FourCornersSlide(props: FourCornersProps) {
  const classes = useStyles();
  const [stances] = useState<any[]>(stanceArray);

  return (
    <React.Fragment>
      <PresentDescText
        value={`
          Read the statement below and identify what your stance 
          is on that specific statement. Be ready to share why 
          you chose that stance.`}
      />
      <LargeText
        value={props.saveData.fourCornersTopic}
        backgroundColor={props.color + "-bg"}
        isMultiLine={true}
      />
      <div className={classes.gridContainer}>
        <div className={classes.stanceGrid}>
          {stances.map((stance: any, index: number) => (
            <div className={classes.stanceGridItem} key={"fc-stance-" + index}>
              <Typography
                component="h3"
                variant="h3"
                className={classes.stanceGridTitleText}
              >
                {stance.corner}
              </Typography>
              <Typography
                component="h4"
                variant="h4"
                className={classes.stanceGridSubtitleText}
              >
                {stance.value}
              </Typography>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
}

export default function FourCornersForm(props: FourCornersProps) {
  const classes = useStyles();
  const [hasLoaded, setHasLoaded] = useState(false);
  const [stances] = useState<any[]>(stanceArray);
  const [saveData, setSaveData] = useState<any>({
    fourCornersDuration:
      props.saveData && props.saveData.fourCornersDuration
        ? props.saveData.fourCornersDuration
        : 10,
    fourCornersTopic:
      props.saveData && props.saveData.fourCornersTopic
        ? props.saveData.fourCornersTopic
        : "",
  });

  useEffect(() => {
    if (!hasLoaded) {
      if (props.onChange)
        props.onChange({ fourCornersDuration: saveData.fourCornersDuration });

      setHasLoaded(true);
    }
  }, [hasLoaded, saveData, props]);

  const handleDataChange = (key: string, value: any) => {
    if (!key) {
      return;
    }

    const data = { ...saveData };
    data[key] = value;
    setSaveData(data);

    if (props.onChange) {
      props.onChange({ [key]: value });
    }
  };

  const handleDurationChange = (val: string) => {
    handleDataChange("fourCornersDuration", val);
  };

  return (
    <React.Fragment>
      <span className={clsx("activity-form-name", props.color)}>
        Four Corners
      </span>
      <p className={"activity-form-desc"}>
        A Four Corners discussion requires learners to show their position on a
        specific statement (strongly agree, agree, disagree, strongly disagree)
        by finding a position in a particular corner of the physical or digital
        space and sharing their stance.
      </p>
      <div className={clsx("warmup-header-break", props.color + "-border")} />
      <div className={"warmup-duration"}>
        <Typography
          component="span"
          variant="subtitle1"
          className="warmup-tot-duration-text"
        >
          Total Duration:
        </Typography>
        <Duration
          time={saveData.fourCornersDuration}
          onChange={handleDurationChange}
        />
      </div>
      <div className={"warmup-subsection"}>
        <div className={"warmup-duration"}>
          <Typography
            component="span"
            variant="subtitle2"
            className="warmup-subtitle"
          >
            Four Corners Topic
          </Typography>
        </div>
        <textarea
          className={"warmup-subtext"}
          placeholder="What topic do you want learners to discuss about?"
          rows={3}
          value={saveData.fourCornersTopic}
          onChange={(event) =>
            handleDataChange("fourCornersTopic", event.target.value)
          }
        />
      </div>
      <div className={"warmup-subsection"}>
        <div>
          <Typography
            component="span"
            variant="subtitle2"
            className="warmup-subtitle"
          >
            Four Corners Settings
          </Typography>
          {stances.map((stance: any, index: number) => (
            <div className={classes.stanceRow} key={"fc-stance-" + index}>
              <Typography
                component="span"
                variant="body1"
                className="warmup-subtext"
              >
                {stance.corner}
              </Typography>
              <Typography
                component="span"
                variant="caption"
                className={classes.stanceValueText}
              >
                {stance.value}
              </Typography>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
}
