import React, { useState, useEffect } from "react";
import Switch from "@material-ui/core/Switch";
// import { createStyles, makeStyles /*, Theme*/ } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import "../../../styles/styles.css";
import UploadFiles from "../../../components/UploadFiles/UploadFiles";
import { LargeText } from "../../Presentation/Presentation";
import clsx from "clsx";
import Duration from "../Duration/Duration";
import { MediaType } from "../../../constants/media";

// const useStyles = makeStyles((theme: any) =>
//   createStyles({
//     radioFormControl: {
//       margin: 0,
//     },
//     radioGroup: {
//       margin: 0,
//     },
//     radioRoot: {
//       margin: 0,
//       padding: "2px 10px",
//     },
//   })
// );

interface ConceptMapProps {
  saveData: any;
  id: string;
  sessionPlanId: string;
  color: string;
  validate?: boolean;
  onChange?: any;
  doSave?: boolean;
}

export function ConceptMapSlide(props: ConceptMapProps) {
  return (
    <React.Fragment>
      <LargeText
        title="Concept Map Prompt:"
        value={props.saveData.conceptMapPrompt}
        backgroundColor="learnidy-red-bg"
      />
    </React.Fragment>
  );
}

export default function ConceptMapForm(props: ConceptMapProps) {
  //   const classes = useStyles();
  const [hasLoaded, setHasLoaded] = useState(false);
  //   const [groupSettingsError] = React.useState(false);
  //   const [requiredText] = React.useState("Please select a choice");
  const [saveData, setSaveData] = useState<any>({
    conceptMapDuration:
      props.saveData && props.saveData.conceptMapDuration
        ? props.saveData.conceptMapDuration
        : 10,
    conceptMapPrompt:
      props.saveData && props.saveData.conceptMapPrompt
        ? props.saveData.conceptMapPrompt
        : "",
    conceptMapWhiteboardToggle:
      props.saveData && props.saveData.conceptMapWhiteboardToggle
        ? props.saveData.conceptMapWhiteboardToggle
        : false,
    uploadFiles:
      props.saveData && props.saveData.uploadFiles
        ? props.saveData.uploadFiles
        : [],
  });

  useEffect(() => {
    if (!hasLoaded) {
      if (props.onChange)
        props.onChange({
          conceptMapDuration: saveData.conceptMapDuration,
        });

      setHasLoaded(true);
    }
  }, [hasLoaded, saveData, props]);

  const handleDataChange = (key: string, value: any) => {
    if (!key) {
      return;
    }

    const data = { ...saveData };
    data[key] = value;
    setSaveData(data);

    if (props.onChange) {
      props.onChange({ [key]: value });
    }
  };

  const handleDurationChange = (val: string) => {
    handleDataChange("conceptMapDuration", val);
  };

  const handleUploadFiles = (files: any) => {
    const data = { ...saveData };
    data.uploadFiles = [...files];
    setSaveData(data);

    if (props.onChange) {
      props.onChange({ uploadFiles: data.uploadFiles });
    }
  };

  return (
    <React.Fragment>
      <span className={clsx("activity-form-name", props.color)}>
        Concept Map
      </span>
      <p className={"activity-form-desc"}>
        Concept maps are visual representations learners create to connect
        ideas, concepts, and terms. Learners can use them to organize
        information they already know and to incorporate new learning with this
        prior knowledge.
      </p>
      <div className={clsx("warmup-header-break", props.color + "-border")} />
      <div className={"warmup-duration"}>
        <Typography
          component="span"
          variant="subtitle1"
          className="warmup-tot-duration-text"
        >
          Total Duration:
        </Typography>
        <Duration
          time={saveData.conceptMapDuration}
          onChange={handleDurationChange}
        />
      </div>
      <div className={"warmup-subsection"}>
        <div className={"warmup-duration"}>
          <Typography
            component="span"
            variant="subtitle2"
            className="warmup-subtitle"
          >
            Concept Map Prompt
          </Typography>
        </div>
        <textarea
          className={"warmup-subtext"}
          placeholder="What concept map instructions do you want to provide all learners?"
          rows={2}
          value={saveData.conceptMapPrompt}
          onChange={(event) =>
            handleDataChange("conceptMapPrompt", event.target.value)
          }
        />
      </div>
      <div className={"warmup-subsection"}>
        <div className={"warmup-duration"}>
          <Typography
            component="span"
            variant="subtitle2"
            className="warmup-subtitle"
          >
            Concept Map Details
          </Typography>
        </div>
        <div className={"warmup-duration warmup-subsection-small"}>
          <Typography
            component="span"
            variant="body1"
            className="warmup-subtext"
          >
            Generate whiteboard
          </Typography>
          <Switch
            checked={saveData.conceptMapWhiteboardToggle}
            onChange={(event) =>
              handleDataChange(
                "conceptMapWhiteboardToggle",
                event.target.checked
              )
            }
            name="conceptMapWhiteboardToggle"
            color="primary"
          />
        </div>
      </div>
      <UploadFiles
        uploadFiles={saveData.uploadFiles}
        mediaType={MediaType.Any}
        sessionPlanId={props.sessionPlanId}
        activityId={props.id}
        onChange={handleUploadFiles}
        doUpload={props.doSave}
      />
    </React.Fragment>
  );
}
