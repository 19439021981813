import React, { useState } from "react";
import Collapse from "@material-ui/core/Collapse";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { widgetStyles } from "../../styles/WidgetStyles";
import { Button } from "@material-ui/core";

interface WidgetProps {
  children?: React.ReactNode;
  startCollapsed?: boolean;
  title: string;
  onCollapse?: any;
  onCancel?: any;
}

export default function Widget(props: WidgetProps) {
  // const classes = useStyles();
  const widgetClasses = widgetStyles();
  //   const buttonClasses = buttonStyles();
  const [collapsed, setCollapsed] = useState<boolean>(
    props.startCollapsed ? props.startCollapsed : false
  );

  const handleCancel = () => {
    if (props.onCancel) {
      props.onCancel();
    }
  };

  const handleCollapse = () => {
    if (props.onCollapse) {
      props.onCollapse(!collapsed);
    }

    setCollapsed((val) => !val);
  };

  const getWidgetRender = () => {
    return (
      <div className={widgetClasses.widgetRoot}>
        <div className={widgetClasses.header}>
          <Button onClick={handleCollapse}>
            {collapsed && <ArrowRightIcon fontSize="default" />}
            {!collapsed && <ArrowDropDownIcon fontSize="default" />}
          </Button>
          <Typography
            component="div"
            variant="subtitle1"
            className={widgetClasses.widgetTitle}
          >
            {props.title}
          </Typography>
          <Button onClick={handleCancel}>
            <CloseIcon fontSize="default" />
          </Button>
        </div>
        <Collapse in={!collapsed} style={{ width: "100%" }}>
          <div className={widgetClasses.widgetBody}>
            <form className={"class-form"}>
              <div className={widgetClasses.widgetCol}>
                <div className={"class-input"}>{props.children}</div>
              </div>
            </form>
          </div>
        </Collapse>
      </div>
    );
  };

  return getWidgetRender();
}
