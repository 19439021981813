import React from "react";
import SignInScreen from "./SignInScreen";
import "../common.css";
import Header from "../../components/Header/Header";

export default function SignIn(props: any) {
  return (
    <React.Fragment>
      <Header {...props} />
      <SignInScreen {...props} />
    </React.Fragment>
  );
}
