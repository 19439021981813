export const Subjects: any = [
  "Art",
  "Music",
  "Language Arts",
  "Math",
  "Science",
  "English",
  "Social Studies",
  "Foreign Language",
  "Other",
];

export const Grades: string[] = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
];

export const GradesToStringMap = {
  "0": "Kindergarten",
  "1": "1st grade",
  "2": "2nd grade",
  "3": "3rd grade",
  "4": "4th grade",
  "5": "5th grade",
  "6": "6th grade",
  "7": "7th grade",
  "8": "8th grade",
  "9": "9th grade",
  "10": "10th grade",
  "11": "11th grade",
  "12": "12th grade",
};
