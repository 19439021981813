import { useState, useEffect, useContext } from "react";
import SessionPlan from "../SessionPlan/SessionPlan";
import "../SessionTabs/SessionTabs.css";
import { SessionPlanModel } from "../../models/SessionPlanModel";
import { CalendarEvent } from "../Session/CalendarEvent";
// import ReviewInformation from "../ReviewInformation/ReviewInformation";
import ClassInformation from "../ClassInformation/ClassInformation";
// import SessionCalendar from "../Session/SessionCalendar";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ClassModel from "../ClassInformation/ClassModel";
import LearningObjectives from "../Lesson/LearningObjectives";
import ReviewInformation from "../ReviewInformation/ReviewInformation";
import StandardsWidget from "../Lesson/StandardsWidget";
import { MenuContext } from "../../hooks/useMenu";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sessionPlanMgr: {
      height: "90vh",
      display: "flex",
      padding: "1em 0 0 1em",
      gap: "1em",
    },
    dashboardColumn: {
      width: "35em",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      gap: "1em",
      paddingRight: 10,
      overflowY: "auto",
    },
  })
);

interface SessionPlanManagerProps {
  classId?: string;
  session?: CalendarEvent;
  sessionPlan: SessionPlanModel;
  loadedPlanId?: string;
  showClassEvent: number;
  onNext?: any;
  onSessionPlanChange?: any;
  onLoadClass?: any;
  onLoadSessionPlan?: any;
  onUpdateSessionPlanLocal?: any;
}

export default function SessionPlanManager(props: SessionPlanManagerProps) {
  const classes = useStyles();
  const [selectClass, setSelectClass] = useState(0);
  const [showLearningObjectives, setShowLearningObjectives] = useState(true);
  const [showStandards, setShowStandards] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const menu = useContext(MenuContext);

  useEffect(() => {
    if (menu.clickedAddClass) {
      menu.onAddClass(false);
      menu.onShowClass(true);
    }
  }, [menu.clickedAddClass, menu]);

  useEffect(() => {
    setShowLearningObjectives(menu.showObjectives);
  }, [menu.showObjectives, menu]);

  useEffect(() => {
    setShowStandards(menu.showStandards);
  }, [menu.showStandards]);

  useEffect(() => {
    setShowReview(menu.showReview);
  }, [menu.showReview]);

  const handleSaveSessionPlan = (
    plan: SessionPlanModel,
    didSessionChange: boolean
  ) => {
    if (props.onSessionPlanChange) {
      props.onSessionPlanChange(plan, didSessionChange);
    }
  };

  const handleClassCancel = () => {
    menu.onShowClass(false);
  };

  const handleSaveClass = (data: ClassModel) => {
    if (props.onLoadClass) props.onLoadClass(data.id);
  };

  const handleShowClass = (id: string, doShow: boolean) => {
    handleLoadClass(id);
    menu.onShowClass(doShow);
  };

  const handleLoadClass = (
    id: string,
    createPlanIfNotExist: boolean = false
  ) => {
    // setTabValue(AppState.Class);
    if (props.onLoadClass) props.onLoadClass(id, createPlanIfNotExist);
  };

  const handleCancelLearningObjectives = () => {
    menu.onShowObjectives(false);
  };

  const handleCancelStandards = () => {
    menu.onShowStandards(false);
  };

  const handleCancelReview = () => {
    menu.onShowReview(false);
  };

  const handleLoadSessionPlan = (id: string) => {
    if (props.onLoadSessionPlan) props.onLoadSessionPlan(id);
  };

  const handleUpdateSessionPlanLocal = (data: any) => {
    if (props.onUpdateSessionPlanLocal) props.onUpdateSessionPlanLocal(data);
  };

  const handleShowSelectSession = () => {
    if (!props.classId && props.sessionPlan.classId) {
      handleLoadClass(props.sessionPlan.classId);
    }

    setSelectClass((val) => selectClass + 1);
  };

  const handleSelectSession = (event: CalendarEvent) => {
    // Save the session id to the plan
    let plan: SessionPlanModel = { ...props.sessionPlan };
    plan.sessionId = event.id;
    handleSaveSessionPlan(plan, true);

    // save the session plan id to the session
  };

  return (
    <div className={classes.sessionPlanMgr}>
      <SessionPlan
        sessionPlan={props.sessionPlan}
        onSave={handleSaveSessionPlan}
        onSelectSession={handleShowSelectSession}
        onShowClass={handleShowClass}
      />
      <div className={classes.dashboardColumn}>
        <LearningObjectives
          sessionPlan={props.sessionPlan}
          show={showLearningObjectives}
          onSave={handleSaveSessionPlan}
          onCancel={handleCancelLearningObjectives}
        />
        <StandardsWidget
          sessionPlan={props.sessionPlan}
          show={showStandards}
          onSave={handleSaveSessionPlan}
          onCancel={handleCancelStandards}
        />
        <ClassInformation
          classId={props.classId}
          sessionPlan={props.sessionPlan}
          show={menu.showClass}
          selectClass={selectClass}
          onCancel={handleClassCancel}
          onSaveClass={handleSaveClass}
          onLoadClass={handleLoadClass}
          onShowClass={handleShowClass}
          onLoadSessionPlan={handleLoadSessionPlan}
          onUpdateSessionPlanLocal={handleUpdateSessionPlanLocal}
          onSelectSession={handleSelectSession}
        />
        <ReviewInformation
          sessionPlan={props.sessionPlan}
          show={showReview}
          onCancel={handleCancelReview}
        />
      </div>
    </div>
  );
}
