import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import React from 'react';

import './IncrementInput.css';

interface IncrementProps {
  time: number
  onChange?: any
}

interface IncrementState {
  time: number
}

class IncrementInput extends React.Component<IncrementProps, IncrementState> {
  constructor(props: IncrementProps) {
    super(props);

    this.state = {
      time: props.time
    }
  }

  public handleIncrement = () => {
    if(this.props.onChange) {
      const newTime: number = this.state.time+1;
      this.setState({time: newTime});
      this.props.onChange(newTime);
    }
  }

  public handleDecrement = () => {
    if(this.props.onChange) {
      const newTime: number = this.state.time > 0? this.state.time-1 : 0;
      this.setState({time: newTime});
      this.props.onChange(newTime);
    }
  }

  public render() {
    return (
      <div className={"increment"}>
        <Fab color="primary" aria-label="subtract" onClick={this.handleDecrement}>
          <RemoveIcon />
        </Fab>
        <Typography component="span" variant="body1" className="increment-text">{this.state.time} {this.state.time > 1? "mins" : "min"}</Typography>
        <Fab color="primary" aria-label="add" onClick={this.handleIncrement}>
          <AddIcon />
        </Fab>
      </div>
    )
  }
}

export default IncrementInput;