import React, { useState, useEffect } from "react";
import UploadFiles from "../UploadFiles/UploadFiles";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Typography from "@material-ui/core/Typography";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import CreateIcon from "@material-ui/icons/Create";
import {
  ActivityActionToNameMap,
  ActivityMode,
  ActivityToNameMap,
} from "../Activity/ActivityTypes";
import {
  LargeText,
  Present,
  PresentDescText,
  PresentMinsText,
  PresentSubtitleText,
  PresentTitleText,
} from "../Presentation/Presentation";
import "../../styles/styles.css";
import clsx from "clsx";
import Duration from "./Duration/Duration";
import { MediaType } from "../../constants/media";

export const getRoutineDesc = () => {
  return "Provide your own customized learning activity or routine.";
};

export const createActivityActionRender = (activityName: string) => {
  return (
    <Typography component="p" variant="subtitle1" className="activity-desc">
      {activityName}
    </Typography>
  );
};

interface CustomProps {
  saveData: any;
  id: string;
  sessionPlanId: string;
  onSave?: any;
  onClose?: any;
  activityMode?: ActivityMode;
  color?: string;
}

export default function CustomForm(props: CustomProps) {
  const [didLoad, setDidLoad] = useState(false);
  const [activityActionRender, setActivityActionRender] = useState<any>();
  const [doSave, setDoSave] = useState(false);
  const [saveData, setSaveData] = useState({
    activityAction:
      props.saveData && props.saveData.activityAction
        ? props.saveData.activityAction
        : "custom",
    activityName:
      props.saveData && props.saveData.activityName
        ? props.saveData.activityName
        : "",
    duration:
      props.saveData && props.saveData.duration ? props.saveData.duration : 10,
    instructions:
      props.saveData && props.saveData.instructions
        ? props.saveData.instructions
        : "",
    uploadFiles:
      props.saveData && props.saveData.uploadFiles
        ? props.saveData.uploadFiles
        : [],
  });

  useEffect(() => {
    if (!didLoad) {
      const data = { ...saveData };
      if (!data.activityAction) {
        data.activityAction = "custom";
        setSaveData(data);
      }

      setActivityActionRender(
        createActivityActionRender(ActivityActionToNameMap[data.activityName])
      );
      setDidLoad(true);
    }
  }, [didLoad, saveData]);

  const handleDurationChange = (val: string) => {
    setSaveData({ ...saveData, duration: val });
  };

  const handleInstructionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const data = { ...saveData };
    data.instructions = event.target.value;
    setSaveData(data);
  };

  const handleActivityNameChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const data = { ...saveData };
    data.activityName = event.target.value;
    setSaveData(data);

    setActivityActionRender(
      createActivityActionRender(ActivityActionToNameMap[event.target.value])
    );
  };

  const handleUploadFiles = (files: any) => {
    const data = { ...saveData };
    data.uploadFiles = [...files];
    setSaveData(data);

    if (doSave) {
      setDoSave(false);
      handleSaveResume(null);
    }
  };

  const handleSave = (event: any) => {
    // Tell upload files to save first. Then, we resume saving when it calls back
    setDoSave(true);
  };

  const handleSaveResume = (event: any) => {
    if (props.onSave) {
      props.onSave({ saveData, activityActionRender });
    }

    handleClose(event);
  };

  const handleClose = (event: any) => {
    if (props.onClose) {
      props.onClose(event);
    }
  };

  const getSlideTitle = () => {
    if (saveData && saveData.activityName && saveData.activityName.length > 0) {
      return saveData.activityName;
    }

    return ActivityToNameMap["Custom"];
  };

  const getSlide = () => {
    return (
      <Present>
        <PresentTitleText value={getSlideTitle()} colorClass="learnidy-grey" />
        <PresentSubtitleText
          value={ActivityActionToNameMap[saveData.activityAction]}
          colorClass="learnidy-grey"
        />
        <PresentMinsText value={saveData.duration} />
        <PresentDescText value={getRoutineDesc()} />

        <LargeText
          title={getSlideTitle() + " Prompt:"}
          value={props.saveData.instructions}
          backgroundColor="learnidy-grey-bg"
          isMultiLine={false}
        />
      </Present>
    );
  };

  return props.activityMode === ActivityMode.SlideShow ? (
    getSlide()
  ) : (
    <Dialog
      fullScreen={false}
      open={true}
      maxWidth={"lg"}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <div className={"activity-dialog"} style={{ width: 1280, height: 720 }}>
        <div className={"activity-form"}>
          <span className={clsx("activity-form-name", props.color)}>
            Custom
          </span>
          <div className={"activity-form-btn-group"}>
            <ToggleButtonGroup
              value={saveData.activityAction}
              exclusive={true}
              orientation="vertical"
              //   onChange={handleActivityAction}
              aria-label="activity action"
            >
              <ToggleButton
                value="custom"
                aria-label="custom"
                disabled={false}
                classes={{
                  selected: props.color + "-border",
                }}
              >
                <Typography
                  component="span"
                  variant="subtitle1"
                  className="activity-name"
                >
                  {saveData.activityName}
                </Typography>
                {saveData.activityAction === "custom" ? (
                  <Badge>
                    <CheckCircleIcon className={props.color} />
                  </Badge>
                ) : null}
                <CreateIcon className={"warmup-icon learnidy-grey"} />
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
        <div className={"activity-form"}>
          <Button
            className={"activity-form-close"}
            variant="outlined"
            onClick={handleClose}
          >
            <CloseRoundedIcon fontSize="small" />
          </Button>
          <TextField
            variant="outlined"
            label={"Title"}
            InputLabelProps={{ shrink: true }}
            placeholder={"Enter Activity Title Here"}
            value={saveData.activityName}
            onChange={handleActivityNameChange}
          />
          <p className={"activity-form-desc"}>
            Provide your own customized learning activity or routine.
          </p>
          <div className={"warmup-header-custom learnidy-grey"} />
          <div className={"warmup-duration"}>
            <Typography
              component="span"
              variant="subtitle1"
              className="warmup-tot-duration-text"
            >
              Total Duration:
            </Typography>
            <Duration
              time={saveData.duration}
              onChange={handleDurationChange}
            />
          </div>
          <div className={"warmup-subsection"}>
            <Typography
              component="span"
              variant="subtitle1"
              className="warmup-tot-duration-text"
            >
              Custom Prompt
            </Typography>
            <textarea
              className={"warmup-subtext"}
              placeholder="What activty or routine do you want to provide all learners?"
              rows={5}
              value={saveData.instructions}
              onChange={handleInstructionChange}
            />
          </div>
          <UploadFiles
            uploadFiles={saveData.uploadFiles}
            mediaType={MediaType.Any}
            sessionPlanId={props.sessionPlanId}
            activityId={props.id}
            onChange={handleUploadFiles}
            doUpload={doSave}
          />
          <div className={"warmup-footer"}>
            <Button variant="outlined" onClick={handleSave} disabled={doSave}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
