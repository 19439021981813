import { useState, useEffect, useRef } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Fade from "@material-ui/core/Fade";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: "absolute",
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.grey[800],
      borderRadius: 0,
      height: "60px",
      display: "flex",
      alignItems: "center",
    },
    message: {
      color: theme.palette.common.white,
      margin: "0 1em",
    },
    escape: {
      border: "1px solid white",
      borderRadius: 0,
      padding: "0.1em 0.2em",
    },
  })
);

export default function ExitMessage(props: any) {
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState(false);
  const mounted: any = useRef();

  useEffect(() => {
    if (!mounted.current) {
      setShowDialog(true);
      setTimeout(() => {
        setShowDialog(false);
      }, 3000);
      mounted.current = true;
    }
  }, [showDialog]);

  const getElement = () => {
    return (
      <div className={classes.container}>
        <Fade in={showDialog}>
          <Paper elevation={4} className={classes.paper}>
            <Typography
              component="label"
              variant="h5"
              className={classes.message}
            >
              Press <span className={classes.escape}>esc</span> to exit full
              screen
            </Typography>
          </Paper>
        </Fade>
      </div>
    );
  };

  return getElement();
}
