export const SIGN_UP_ROUTE = "/signup";
export const SIGN_IN_ROUTE = "/signin";
export const RESET_PASSWORD_ROUTE = "/resetpassword";
export const LANDING = "/";
export const TEMPLATES = "/templates";
export const HOME = "/home";
export const ACCOUNT = "/account";
export const HOME_ROUTE = "/";
export const TERMS_AND_CONDITIONS_ROUTE =
  "https://learnidy.com/termsandconditions.html";
export const PRIVACY_POLICY_ROUTE = "https://learnidy.com/privacypolicy.html";
export const FINISH_SIGN_UP_ROUTE = "/finishsignup";
