// import React, { useState, useEffect } from "react";
// import Badge from "@material-ui/core/Badge";
// import Button from "@material-ui/core/Button";
// import Dialog from "@material-ui/core/Dialog";
// import FormControl from "@material-ui/core/FormControl";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import InputBase from "@material-ui/core/InputBase";
// import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
// import ToggleButton from "@material-ui/lab/ToggleButton";
// import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
// import CheckCircleIcon from "@material-ui/icons/CheckCircle";
// import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
// import Filter1Icon from "@material-ui/icons/Filter1";
// import Filter2Icon from "@material-ui/icons/Filter2";
// import Filter3Icon from "@material-ui/icons/Filter3";
// import FlashOnIcon from "@material-ui/icons/FlashOn";
// import {
//   LargeText,
//   PresentTitleText,
//   PresentMinsText,
//   PresentDescText,
// } from "./Presentation";
// import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import "../../styles/styles.css";

export const createActivityActionRender = (title: string) => {
  return (
    <Typography component="p" variant="subtitle1" className="activity-desc">
      {title}
    </Typography>
  );
};
