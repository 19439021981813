import React, { useState, useEffect } from "react";
import RoleplayForm, { RoleplaySlide } from "./RoleplayForm";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import ExtensionIcon from "@material-ui/icons/Extension";
import PeopleIcon from "@material-ui/icons/People";
import PersonIcon from "@material-ui/icons/Person";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import "../../../styles/styles.css";
import IDoYouDoForm, { IDoYouDoSlide } from "./IDoYouDoForm";
import { createActivityActionRender } from "../Editing";
import {
  ActivityActionToNameMap,
  ActivityFormProps,
  ActivityMode,
  ActivityToNameMap,
} from "../../Activity/ActivityTypes";
import {
  Present,
  PresentDescText,
  PresentMinsText,
  PresentSubtitleText,
  PresentTitleText,
} from "../../Presentation/Presentation";
import clsx from "clsx";

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     toggleSelected: {
//       borderColor: "#f4a957 !important",
//     },
//   })
// );

export const getRoutineDesc = (action: string) => {
  if (action === "roleplay")
    return "Assign the larger group to work in smaller teams to discuss a topic, answer a question, or tackle a specific challenge. Designate roles for each learner within a group such as a facilitator, notetaker, and etc. Roleplay groups are designed to increase participation.";
  if (action === "iDoYouDo")
    return "I Do, You Do is a two part practice routine where learners first engage in guided practice of a skill with the educator first and then are scaffolded and move onto independent practice on their own.";

  return "";
};

const getDuration = (data: any): number => {
  switch (data.activityAction) {
    case "roleplay":
      return data.roleplayGroupsDuration;

    case "iDoYouDo":
      return data.iDoYouDoDuration;

    default:
      return 10;
  }
};

export default function PracticeForm(props: ActivityFormProps) {
  // const classes = useStyles();
  const [hasLoaded, setHasLoaded] = useState(false);
  const [activityActionRender, setActivityActionRender] = useState<any>();
  const [doSave, setDoSave] = useState(false);
  const [saveData, setSaveData] = useState<any>(props.saveData);

  useEffect(() => {
    if (!hasLoaded) {
      const data = { ...saveData };
      if (!data.activityAction) {
        data.activityAction = "roleplay";
        setSaveData(data);
      }

      setActivityActionRender(
        createActivityActionRender(ActivityActionToNameMap[data.activityAction])
      );
      setHasLoaded(true);
    }
  }, [hasLoaded, saveData]);

  const handleActivityAction = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: any
  ) => {
    if (!value) return;

    const data = { ...saveData };
    data.activityAction = value;
    setSaveData(data);
    setActivityActionRender(
      createActivityActionRender(ActivityActionToNameMap[value])
    );
  };

  const handleDataChange = (data: any) => {
    if (!data) return;

    const newObj = Object.assign(saveData, data);
    newObj.duration = getDuration(newObj);
    setSaveData(newObj);

    if (doSave) {
      setDoSave(false);
      handleSaveResume(null);
    }
  };

  const handleSave = (event: any) => {
    if (saveData.activityAction === "roleplay") {
      // Tell upload files to save first. Then, we resume saving when it calls back
      setDoSave(true);
    } else {
      handleSaveResume(event);
    }
  };

  const handleSaveResume = (event: any) => {
    if (props.onSave) {
      props.onSave({ saveData, activityActionRender });
    }

    handleClose(event);
  };

  const handleClose = (event: any) => {
    if (props.onClose) {
      props.onClose(event);
    }
  };

  const getSlide = () => {
    return (
      <Present>
        <PresentTitleText
          value={ActivityToNameMap["Practice"]}
          colorClass="learnidy-orange"
        />
        <PresentSubtitleText
          value={ActivityActionToNameMap[saveData.activityAction]}
          colorClass="learnidy-orange"
        />
        <PresentMinsText value={saveData.duration} />
        <PresentDescText value={getRoutineDesc(saveData.activityAction)} />

        {saveData.activityAction === "roleplay" && (
          <RoleplaySlide
            saveData={saveData}
            id={props.id}
            sessionPlanId={props.sessionPlanId}
            color={props.color}
          />
        )}
        {saveData.activityAction === "iDoYouDo" && (
          <IDoYouDoSlide saveData={saveData} color={props.color} />
        )}
      </Present>
    );
  };

  return props.activityMode === ActivityMode.SlideShow ? (
    getSlide()
  ) : (
    <Dialog
      fullScreen={false}
      open={true}
      maxWidth={"lg"}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <div className={"activity-dialog"} style={{ width: 1280, height: 820 }}>
        <div className={"activity-form"}>
          <span className={clsx("activity-form-name", props.color)}>
            Practice
          </span>
          <p className={"activity-form-desc"}>
            Spark conversations by creating breakout groups, debates, or have
            learners meet in pairs.
          </p>
          <div className={"activity-form-btn-group"}>
            <ToggleButtonGroup
              value={saveData.activityAction}
              exclusive={true}
              orientation="vertical"
              onChange={handleActivityAction}
              aria-label="activity action"
            >
              <ToggleButton
                value="roleplay"
                aria-label="roleplay"
                disabled={false}
                classes={{
                  selected: props.color + "-border",
                }}
              >
                <Typography
                  component="div"
                  variant="subtitle1"
                  className="activity-name"
                >
                  Roleplay
                </Typography>
                {saveData.activityAction === "roleplay" ? (
                  <Badge>
                    <CheckCircleIcon className={props.color} />
                  </Badge>
                ) : null}
                <ExtensionIcon
                  style={{ fontSize: 70 }}
                  classes={{ root: "learnidy-orange" }}
                />
              </ToggleButton>
              <ToggleButton
                value="iDoYouDo"
                aria-label="iDoYouDo"
                classes={{
                  selected: props.color + "-border",
                }}
              >
                <Typography
                  component="span"
                  variant="subtitle1"
                  className={"activity-name"}
                >
                  I Do, You Do
                </Typography>
                {saveData.activityAction === "iDoYouDo" ? (
                  <Badge>
                    <CheckCircleIcon className={props.color} />
                  </Badge>
                ) : null}
                <div>
                  <PeopleIcon
                    style={{ fontSize: 80 }}
                    className={"learnidy-orange"}
                  />
                  <ArrowForwardIcon style={{ fontSize: 30 }} />
                  <PersonIcon
                    style={{ fontSize: 80 }}
                    className={"learnidy-orange"}
                  />
                </div>
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
        <div className={"activity-form"}>
          <Button
            className={"activity-form-close"}
            variant="outlined"
            onClick={handleClose}
          >
            <CloseRoundedIcon fontSize="small" />
          </Button>
          {saveData.activityAction === "roleplay" && (
            <RoleplayForm
              saveData={saveData}
              id={props.id}
              sessionPlanId={props.sessionPlanId}
              color={props.color}
              onChange={(data: any) => handleDataChange(data)}
              doSave={doSave}
            />
          )}
          {saveData.activityAction === "iDoYouDo" && (
            <IDoYouDoForm
              saveData={saveData}
              color={props.color}
              onChange={(data: any) => handleDataChange(data)}
            />
          )}
          <div className={"warmup-footer"}>
            <Button
              variant="outlined"
              className={"learnidy-orange-bg"}
              onClick={handleSave}
              disabled={doSave}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
