import { withRouter } from "react-router-dom";
import { auth, createDbUser } from "../../firebase/firebase";
import { HOME_ROUTE } from "../../constants/routes";
import Header from "../../components/Header/Header";

const FinishSignUpComponent = (props: any) => {
  // Store the user in the DB
  if (auth.currentUser && auth.currentUser.emailVerified) {
    createDbUser(auth.currentUser)
      .then(() => {
        props.history.push(HOME_ROUTE);
      })
      .catch((error) => {
        console.error("User create error: ", error);
        props.history.push(HOME_ROUTE);
      });
  }

  return (
    <div>
      <Header {...props} />
    </div>
  );
};

export default withRouter(FinishSignUpComponent);
