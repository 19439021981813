export const AllowedUploadFileTypes = [
  "pdf",
  "doc",
  "docx",
  "xls",
  "xlsx",
  "txt",
  "jpg",
  "png",
  "bmp",
  "tiff",
];

export const IsAcceptableFileType = (fileName: string): boolean => {
  const tokens: string[] = fileName.split(".");
  const extension: string = tokens[tokens.length - 1];
  const result = AllowedUploadFileTypes.find((value) => value === extension);
  return result !== undefined;
};
