import React, { useState } from "react";
import { questionStyles } from "../../../../styles/QuestionStyles";
import { QuestionProps } from "./QuestionTypes";
import TextField from "@material-ui/core/TextField";

const getDefaultChoices = (choices: any): string => {
  if (choices && typeof choices === "string") {
    return choices;
  }

  return "";
};

const getDefaultAnswer = (answer: any): string => {
  if (answer && typeof answer === "string") {
    return answer;
  }

  return "";
};

export function FillInTheBlankQuestion(props: QuestionProps) {
  const classes = questionStyles();
  const [choices /*, setChoices*/] = useState(getDefaultChoices(props.choices));
  const [answer, setAnswer] = useState(getDefaultAnswer(props.answer));

  //   const handleQuestionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //     setChoices(event.target.value);
  //     if (props.onChange) props.onChange(event.target.value, answer);
  //   };

  const handleAnswerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAnswer(event.target.value);
    if (props.onChange) props.onChange(choices, event.target.value);
  };

  return (
    <div className={classes.questionTextArea}>
      {/* <TextField
        className={classes.questionTextArea}
        variant="outlined"
        label={"Question"}
        InputLabelProps={{ shrink: true }}
        placeholder={
          "We live on planet ____ which is located in the Milky Way ____. "
        }
        multiline
        rows={4}
        value={choices}
        onChange={handleQuestionChange}
      /> */}
      <TextField
        className={classes.fullWidth}
        variant="filled"
        value={answer}
        size="small"
        label={"Answer(s)"}
        InputLabelProps={{ shrink: true }}
        placeholder={"Earth, Galaxy"}
        onChange={handleAnswerChange}
      />
    </div>
  );
}
