import { useState, useEffect } from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { useHistory } from "react-router-dom";
import { auth, uiConfig, createDbUser } from "../../firebase/firebase";
import firebase from "firebase/app";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import EmailIcon from "@material-ui/icons/Email";
import { HOME_ROUTE, SIGN_UP_ROUTE } from "../../constants/routes";
import SignInEmailForm from "../../components/SignIn/SignInEmailForm";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      width: "100%",
      marginBottom: 20,
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)",
    },
    title: {
      fontWeight: "bold",
      marginBottom: 10,
      color: theme.palette.primary.main,
    },
    subtitle: {
      fontWeight: "bold",
      marginBottom: 10,
      color: theme.palette.getContrastText(theme.palette.background.default),
    },
    authSection: {
      marginTop: "40px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    haveAccountSection: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "16px",
    },
    haveAccountText: {},
    logIn: {
      fontWeight: "bold",
      marginLeft: "0.4em",
      color: theme.palette.primary.main,
    },
    email: {
      backgroundColor: "#db4437",
      color: "white",
      border: "none",
      borderRadius: 2,
      maxWidth: 220,
      minWidth: 64,
      minHeight: 40,
      width: "100%",
      textTransform: "none",
      fontWeight: 500,
      fontSize: "14px",
      fontFamily: "Roboto,Helvetica,Arial,sans-serif",
      padding: "8px 16px",
      "&:hover": {
        backgroundColor: "#db4437",
      },
      "& .MuiButton-label": {
        justifyContent: "left",
      },
      "& .MuiButton-startIcon": {
        margin: "0 16px 0 0",
      },
    },
  })
);

interface SignInFormProps {
  onSignIn?: () => void;
}

export default function SignInForm(props: SignInFormProps) {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [showEmailSignIn, setShowEmailSignIn] = useState(false);
  const [hasCreatedDbUser, setHasCreatedDbUser] = useState(false);
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      const isSigningIn: boolean = !!user;
      setIsSignedIn(isSigningIn);

      if (user && isSignedIn && !hasCreatedDbUser) {
        registerUser(auth.currentUser);
      }
    });

    return unsubscribe;
  });

  const registerUser = (user: firebase.User | null) => {
    if (user && isSignedIn) {
      // TODO: Use a server function and only update if it doesn't exist
      createDbUser(user)
        .then(() => {
          if (props.onSignIn) {
            console.log("calling props.onSignIn");
            props.onSignIn();
          }

          setHasCreatedDbUser(true);
          history.push(HOME_ROUTE);
        })
        .catch((error) => {
          console.error("User create error: ", error);
        });
    }
  };

  const handleEmailSignIn = () => {
    setShowEmailSignIn(true);
  };

  const handleSignInEmailCancel = () => {
    setShowEmailSignIn(false);
  };

  return (
    <div>
      {!showEmailSignIn && (
        <div className={classes.authSection}>
          <Button
            className={classes.email}
            variant="outlined"
            onClick={handleEmailSignIn}
            startIcon={<EmailIcon />}
          >
            {"Sign in with email"}
          </Button>
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
        </div>
      )}
      {showEmailSignIn && (
        <SignInEmailForm onCancel={handleSignInEmailCancel} />
      )}
      <span className={classes.haveAccountSection}>
        <Typography
          component="span"
          variant="subtitle1"
          className={classes.haveAccountText}
        >
          Don't have an account yet?
        </Typography>
        <Link to={SIGN_UP_ROUTE} id="nav-link-signup">
          <Typography
            component="span"
            variant="subtitle1"
            className={classes.logIn}
          >
            Sign Up
          </Typography>
        </Link>
      </span>
    </div>
  );
}
