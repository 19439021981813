import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";
import "firebase/analytics";
import {
  CLASSES_COLLECTION_NAME,
  SESSIONPLANS_COLLECTION_NAME,
  SESSIONS_COLLECTION_NAME,
  USERS_COLLECTION_NAME,
} from "../constants/database";
import {
  TERMS_AND_CONDITIONS_ROUTE,
  PRIVACY_POLICY_ROUTE,
} from "../constants/routes";

const firebaseConfig = {
  apiKey: "AIzaSyCTlQm5rE3QQWihfZ3nnfmOWp4ah5QERRA",
  authDomain: "learnidy-3f9d4.firebaseapp.com",
  projectId: "learnidy-3f9d4",
  storageBucket: "learnidy-3f9d4.appspot.com",
  messagingSenderId: "930999382340",
  appId: "1:930999382340:web:a60f9423aaae68f2ace711",
  measurementId: "G-4BGK61HD8M",
};

// Initialize Firebase and create a reference
export const app = firebase.initializeApp(firebaseConfig);

// Reference to Firebase Cloud Firestore
export const db = app.firestore(app);

// Reference to Firebase Storage
export const storage = firebase.storage();

// Enable local function testing
// firebase.functions().useEmulator("localhost", 5001);

// Reference to Functions
export const functions = firebase.functions();

// Reference to Firebase Authentication
export const auth = app.auth();

export const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: firebase.auth().isSignInWithEmailLink(window.location.href)
    ? "redirect"
    : "popup",
  signInSuccessUrl: "/",
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    // firebase.auth.EmailAuthProvider.PROVIDER_ID,
    // {
    //   provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
    //   signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
    //   // requireDisplayName: true
    // },
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
  },
  tosUrl: TERMS_AND_CONDITIONS_ROUTE,
  privacyPolicyUrl: PRIVACY_POLICY_ROUTE,
};

// Subscribe to authentication changes for the user
export const onAuthStateChange = (callback) => {
  // return auth.onAuthStateChanged((user) => {
  return firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      callback({ loggedIn: true, email: user.email, photoURL: user.photoURL });
    } else {
      callback({ loggedIn: false, email: null, photoURL: null });
    }
  });
};

export const signOut = async () => {
  await firebase.auth().signOut();
  // await firebase.auth().currentUser.getIdToken(true);
  // await firebase.auth().updateCurrentUser();
};

export const getBatch = () => {
  return db.batch();
};

export const commitBatch = (batch) => {
  return batch.commit();
};

export const createDbUser = (user) => {
  return db
    .collection(USERS_COLLECTION_NAME)
    .doc(user.uid)
    .set(
      {
        name: user.displayName,
        email: user.email,
        emailVerified: user.emailVerified,
        photoURL: user.photoURL ? user.photoURL : "",
      },
      { merge: true }
    );
};

export const querySessionTimes = (classId) => {
  return db
    .collection(USERS_COLLECTION_NAME)
    .doc(auth.currentUser?.uid)
    .collection(CLASSES_COLLECTION_NAME)
    .doc(classId)
    .collection(SESSIONS_COLLECTION_NAME)
    .get();
};

export const querySessionTime = (classId, sessionTimeId) => {
  return db
    .collection(USERS_COLLECTION_NAME)
    .doc(auth.currentUser?.uid)
    .collection(CLASSES_COLLECTION_NAME)
    .doc(classId)
    .collection(SESSIONS_COLLECTION_NAME)
    .doc(sessionTimeId)
    .get();
};

export const saveSession = (classId, sessionId, data, doMerge = false) => {
  return db
    .collection(USERS_COLLECTION_NAME)
    .doc(auth.currentUser?.uid)
    .collection(CLASSES_COLLECTION_NAME)
    .doc(classId)
    .collection(SESSIONS_COLLECTION_NAME)
    .doc(sessionId)
    .set(data, { merge: doMerge });
};

export const saveSessionBatch = (
  batch,
  classId,
  sessionId,
  data,
  doMerge = false
) => {
  let ref = db
    .collection(USERS_COLLECTION_NAME)
    .doc(auth.currentUser?.uid)
    .collection(CLASSES_COLLECTION_NAME)
    .doc(classId)
    .collection(SESSIONS_COLLECTION_NAME)
    .doc(sessionId);

  batch.set(ref, data, { merge: doMerge });
};

export const deleteSession = (classId, sessionId) => {
  return db
    .collection(USERS_COLLECTION_NAME)
    .doc(auth.currentUser?.uid)
    .collection(CLASSES_COLLECTION_NAME)
    .doc(classId)
    .collection(SESSIONS_COLLECTION_NAME)
    .doc(sessionId)
    .delete();
};

export const deleteSessionBatch = (batch, classId, sessionId) => {
  let ref = db
    .collection(USERS_COLLECTION_NAME)
    .doc(auth.currentUser?.uid)
    .collection(CLASSES_COLLECTION_NAME)
    .doc(classId)
    .collection(SESSIONS_COLLECTION_NAME)
    .doc(sessionId);

  batch.delete(ref);
};

export const deleteSessionPlanFromSessionDB = (
  classId,
  sessionId,
  sessionPlanId
) => {
  return db
    .collection(USERS_COLLECTION_NAME)
    .doc(auth.currentUser?.uid)
    .collection(CLASSES_COLLECTION_NAME)
    .doc(classId)
    .collection(SESSIONS_COLLECTION_NAME)
    .doc(sessionId)
    .update({ sessionPlanId: null });
};

export const createSessionId = () => {
  return db
    .collection(USERS_COLLECTION_NAME)
    .doc(auth.currentUser?.uid)
    .collection(CLASSES_COLLECTION_NAME)
    .doc();
};

export const querySessionPlans = () => {
  return db
    .collection(USERS_COLLECTION_NAME)
    .doc(auth.currentUser?.uid)
    .collection(SESSIONPLANS_COLLECTION_NAME)
    .orderBy("modifiedAt", "desc")
    .get();
};

export const querySessionPlansByClass = (classId) => {
  return db
    .collection(USERS_COLLECTION_NAME)
    .doc(auth.currentUser?.uid)
    .collection(SESSIONPLANS_COLLECTION_NAME)
    .where("classId", "==", classId)
    .orderBy("modifiedAt", "desc")
    .get();
};

export const querySessionPlan = (id) => {
  return db
    .collection(USERS_COLLECTION_NAME)
    .doc(auth.currentUser?.uid)
    .collection(SESSIONPLANS_COLLECTION_NAME)
    .doc(id)
    .get();
};

export const queryLatestSessionPlan = () => {
  return db
    .collection(USERS_COLLECTION_NAME)
    .doc(auth.currentUser?.uid)
    .collection(SESSIONPLANS_COLLECTION_NAME)
    .orderBy("modifiedAt", "desc")
    .limit(1)
    .get();
};

export const saveSessionPlanDB = (data, sessionPlanId, doMerge = false) => {
  return db
    .collection(USERS_COLLECTION_NAME)
    .doc(auth.currentUser?.uid)
    .collection(SESSIONPLANS_COLLECTION_NAME)
    .doc(sessionPlanId)
    .set(data, { merge: doMerge });
};

export const saveSessionPlanBatchDB = (
  batch,
  data,
  sessionPlanId,
  doMerge = false
) => {
  let ref = db
    .collection(USERS_COLLECTION_NAME)
    .doc(auth.currentUser?.uid)
    .collection(SESSIONPLANS_COLLECTION_NAME)
    .doc(sessionPlanId);

  batch.set(ref, data, { merge: doMerge });
};

export const createSessionPlanId = () => {
  return db
    .collection(USERS_COLLECTION_NAME)
    .doc(auth.currentUser?.uid)
    .collection(SESSIONPLANS_COLLECTION_NAME)
    .doc();
};

export const queryClass = (id) => {
  return db
    .collection(USERS_COLLECTION_NAME)
    .doc(auth.currentUser?.uid)
    .collection(CLASSES_COLLECTION_NAME)
    .doc(id)
    .get();
};

export const queryClasses = (id) => {
  return db
    .collection(USERS_COLLECTION_NAME)
    .doc(auth.currentUser?.uid)
    .collection(CLASSES_COLLECTION_NAME)
    .get();
};

export const saveClass = (data, id) => {
  return db
    .collection(USERS_COLLECTION_NAME)
    .doc(auth.currentUser?.uid)
    .collection(CLASSES_COLLECTION_NAME)
    .doc(id)
    .set(data);
};

export const createClassId = () => {
  return db
    .collection(USERS_COLLECTION_NAME)
    .doc(auth.currentUser?.uid)
    .collection(CLASSES_COLLECTION_NAME)
    .doc();
};

export const saveFile = (fileName, file) => {
  return storage.ref().child(fileName).put(file);
};
