import "../common.css";
import './Templates.css';
// import { withAuthorization } from "../../firebase/withAuthorization";

import { Component } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import debateImg from '../../assets/debate.png';
import fishbowlImg from '../../assets/fishbowl.png';
import fourCornersImg from '../../assets/fourcorners.png';
import thinkPairShareImg from '../../assets/thinkpairshare.png';

class TemplatesComponent extends Component {
  public render() {
    return (
      <div>
        <Carousel>
          <div>
            <img src={debateImg} alt=""/>
            <p className="legend">Legend 1</p>
          </div>
          <div>
            <img src={fishbowlImg} alt="" />
            <p className="legend">Legend 2</p>
          </div>
          <div>
            <img src={fourCornersImg} alt="" />
            <p className="legend">Legend 3</p>
          </div>
          <div>
            <img src={thinkPairShareImg} alt="" />
            <p className="legend">Legend 3</p>
          </div>
        </Carousel>
      </div>
    );
  }
};

// const authCondition = (authUser: any) => !!authUser;
// export default withAuthorization(authCondition)(TemplatesComponent);

export default TemplatesComponent;
